import {NgModule} from "@angular/core";
import {RouterModule, ROUTES, Routes} from "@angular/router";
import {MsalBroadcastService, MsalGuard, MsalService} from "@azure/msal-angular";
import {AuthService} from "./services/auth.service";
import {NotFoundComponent} from "./components/pages/not-found/not-found.component";
import {HomeComponent} from "./components/pages/home/home.component";
import {BlankComponent} from "./components/shared/blank/blank.component";
import {GeneralComponent} from "./components/shared/template/general/general.component";
import {DocumentsComponent} from "./components/pages/documents/documents.component";
import {LanguageComponent} from "./components/shared/template/language/language.component";
import {BadRequestComponent} from "./components/pages/bad-request/bad-request.component";
import {FunctionsComponent} from "./components/pages/functions/functions.component";

const routes: Routes = [
  {
    path: 'home',
    data: {
      pagename: 'PAGE_HOME'
    },
    component: HomeComponent
  },
  //
  // {
  //   path: 'master-data',
  //   //canActivate: [AdminRouteGuard],
  //   children: [
  //     {
  //       path: 'destinations',
  //       data: {
  //         pagename: 'PAGE_MASTER_DATA_DESTINATIONS'
  //       },
  //       component: HomeComponent//DestinationsComponent
  //     },
  //     {
  //       path: 'raw-destinations',
  //       data: {
  //         pagename: 'PAGE_MASTER_DATA_RAW_DESTINATIONS'
  //       },
  //       component: HomeComponent//RawDestinationsComponent
  //     },
  //     {
  //       path: 'subproduct-lines',
  //       data: {
  //         pagename: 'PAGE_MASTER_DATA_SUBPRODUCT_LINE'
  //       },
  //       component: HomeComponent//SubproductLinesComponent
  //     },
  //     {
  //       path: 'units',
  //       data: {
  //         pagename: 'PAGE_MASTER_DATA_UNITS'
  //       },
  //       component: HomeComponent//UnitsComponent
  //     },
  //   ]
  // },
  // {
  //   path: 'help',
  //   data: {
  //     pagename: 'PAGE_HELP'
  //   },
  //   component: HomeComponent//HelpComponent, canActivate: [AdminRouteGuard]
  // },
  // {
  //   path: 'home',
  //   data: {
  //     pagename: 'PAGE_HOME'
  //   },
  //   component: HomeComponent//HomepageComponent
  // },
  // {
  //   path: 'administration',
  //   //canActivate: [AdminRouteGuard],
  //   children: [
  //     {
  //       path: 'context',
  //       data: {
  //         pagename: 'CONTEXT_PAGE'
  //       },
  //       component: HomeComponent//AdministrationComponent
  //     },
  //     {
  //       path: 'team',
  //       data: {
  //         pagename: 'TEAM_PAGE'
  //       },
  //       component: HomeComponent//AdministrationComponent
  //     },
  //     {
  //       path: 'task',
  //       data: {
  //         pagename: 'TASK_PAGE'
  //       },
  //       component: HomeComponent//AdministrationComponent
  //     },
  //     {
  //       path: 'contact_groups',
  //       data: {
  //         pagename: 'CONTACT_GROUP_PAGE'
  //       },
  //       component: HomeComponent//AdministrationComponent
  //     },
  //     {
  //       path: 'contact_groups_details',
  //       data: {
  //         pagename: 'CONTACT_GROUP_DETAIL_PAGE'
  //       },
  //       component: HomeComponent//AdministrationComponent
  //     },
  //     {
  //       path: 'attachment_category',
  //       data: {
  //         pagename: 'ATTACHMENT_CATEGORY_PAGE'
  //       },
  //       component: HomeComponent//AdministrationComponent
  //     },
  //     {
  //       path: 'monitor-su',
  //       data: {
  //         pagename: 'PAGE_MONITOR_SU'
  //       },
  //       component: HomeComponent//MonitorSUComponent
  //     },
  //     {
  //       path: 'preview-su',
  //       data: {
  //         pagename: 'PAGE_MONITOR_SU'
  //       },
  //       component: HomeComponent//PreviewSUComponent
  //     }
  //   ]
  // },
  // {
  //   path: 'monitor/container',
  //   data: {
  //     pagename: 'CONTAINER_DETAIL_PAGE'
  //   },
  //   component: HomeComponent//ContainerDetailComponent
  // },
  // {
  //   path: 'notification/history',
  //   data: {
  //     pagename: 'CONTAINER_NOTIFICATION_HISTORY_PAGE'
  //   },
  //   component: HomeComponent//ComunicationTrackerComponent
  // },
  // {
  //   path: '',
  //   redirectTo: '/monitor/preview',
  //   pathMatch: 'full'
  // },
  // from here framework
  {
    path: "",
    component: HomeComponent,
    pathMatch: "full",
    canActivate: [MsalGuard],
  },
  {
    path: "utils",
    component: FunctionsComponent,
    pathMatch: "full",
    canActivate: [MsalGuard],
  },
  {
    path: "page",
    children: [
      {
        path: "primary",
        children: [
          {
            path: "**",
            component: GeneralComponent,
            canActivate: [MsalGuard],
          },
        ],
        canActivate: [MsalGuard],
      },
      {
        path: "secondary",
        children: [
          {
            path: "**",
            component: GeneralComponent,
            canActivate: [MsalGuard],
          },
        ],
        canActivate: [MsalGuard],
      },
      {
        path: "rework",
        children: [
          {
            path: "**",
            component: GeneralComponent,
            canActivate: [MsalGuard],
          },
        ],
        canActivate: [MsalGuard],
      },
      {
        path: "declaration",
        children: [
          {
            path: "**",
            component: GeneralComponent,
            canActivate: [MsalGuard],
          },
        ],
        canActivate: [MsalGuard],
      },
      {
        path: "eur",
        children: [
          {
            path: "**",
            component: GeneralComponent,
            canActivate: [MsalGuard],
          },
        ],
        canActivate: [MsalGuard],
      },
      {
        path: "balance",
        children: [
          {
            path: "**",
            component: GeneralComponent,
            canActivate: [MsalGuard],
          },
        ],
        canActivate: [MsalGuard],
      },
      {
        path: "warehousing",
        children: [
          {
            path: "**",
            component: GeneralComponent,
            canActivate: [MsalGuard],
          },
        ],
        canActivate: [MsalGuard],
      },
      // CUSTOM MONITOR PAGE
      {
        path: 'monitor',
        children: [
          {
            path: "**",
            component: GeneralComponent,
            canActivate: [MsalGuard],
          }
        ]
      },
      {
        path: "masterdata",
        children: [

          {
            path: "translation",
            component: LanguageComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "**",
            component: GeneralComponent,
            canActivate: [MsalGuard],
          },

        ],
        canActivate: [MsalGuard],
      },
      {
        path: "administration",
        children: [
          {
            path: "**",
            component: GeneralComponent,
            canActivate: [MsalGuard],
          },

        ],
        canActivate: [MsalGuard],
      },
      {
        path: "documents",
        children: [
          {
            path: "other",
            component: DocumentsComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "**",
            component: GeneralComponent,
            canActivate: [MsalGuard],
          },
        ],
        canActivate: [MsalGuard],
      },
      {
        path: "management",
        children: [
          {
            path: "languages",
            component: LanguageComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "translation",
            component: LanguageComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "**",
            component: GeneralComponent,
            canActivate: [MsalGuard],
          },
        ],
        canActivate: [MsalGuard],
      },
    ],
    canActivate: [MsalGuard],
  },
  {
    path: "blank",
    component: BlankComponent,
  },
  {
    path: "404",
    component: NotFoundComponent,
  },
  {
    path: "400",
    component: BadRequestComponent,
  },
  {
    path: "**",
    redirectTo: "/404",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AuthService,
    MsalService,
    MsalBroadcastService,
    {
      provide: ROUTES,
      useFactory: () => {
        return [...routes];
      },
      multi: true,
      deps: [AuthService, MsalService, MsalBroadcastService],
    },
  ],
})
export class AppRoutingModule {
}

