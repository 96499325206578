<div class="notification-container">
  <custom-alert [containerClass]="'alert-container-in-modal'"></custom-alert>
  <div *ngIf="notification" style="width: 100%">
    <div [ngClass]="['notification-row', 'border-bottom', isEmailVisible ? 'highlight-bg' : '']" (click)="emailVisibleHandler()">
      <div>
        <mat-icon>{{ isEmailVisible ? 'drafts' : 'email' }}</mat-icon>
      </div>
      <div class="thread-item">
        {{ 'LABEL_THREAD_NUMBER' | translate }}: #{{notification.notificationThreadId}}
      </div>
      <div class="full">
        {{notification.notificationThreadDesc}}
      </div>
      <div>
        <mat-icon>{{ isEmailVisible ? 'expand_less' : 'expand_more' }}</mat-icon>
      </div>
    </div>
    <div *ngIf="isEmailVisible">
      <div class="thread-content"
           [ngClass]="{
              'border-user': (notificationEntity.notificationFrom == userEmail || notificationEntity.insertUser == userEmail),
              'border-system': (notificationEntity.notificationFrom == 'system'),
              'border-bottom': isEmailVisible
           }"
           *ngFor="let notificationEntity of notification.notificationEntities; let n = index">
        <div class="thread-header">
          <div class="from bold" *ngIf="!!notificationEntity.username">
            {{ notificationEntity.username }}
          </div>
          <div class="small full" *ngIf="!!notificationEntity.username">
            {{' \<' + notificationEntity.insertUser + '\> ' }}
          </div>
          <div class="small full" *ngIf="!(!!notificationEntity.username)">
            {{notificationEntity.notificationFrom}}
          </div>

          <!-- REPLY BUTTON -->
          <div class='icon hover' *ngIf="('ACT_NOTIFY' | haveAction: (actionsets?.actions ?? []) | async) && !(!!notification.username)">
            <!-- per vedere il bottone bisogna avere l'azione ACT_REPLY&ndash;&gt; -->
            <mat-icon class="action-button"
                      mat-raised-button color="primary" action-type="button"
                      [matMenuTriggerFor]="actionsMenu">
              reply
            </mat-icon>
            <!-- vengono mostrati solamente i bottoni per i quali si ha il ruolo -->
            <mat-menu #actionsMenu="matMenu" xPosition="after">
              <div *ngFor="let btn of notificationOption; let i= index">
                <!-- isActionPresent('monitor.notify.' + btn.notificationId) -->
                <button *ngIf="(btn.notificationId | haveAction: (actionsets?.actions ?? []) | async)" mat-menu-item
                        (click)="reply(notificationEntity, btn.notificationId, notification.appOwnerNotificationId, notification.notificationThreadDesc)">{{btn.notificationLabel}}</button>

              </div>
            </mat-menu>

            <!--VECCHIA LOGICA PER ESTRAZIONE RUOLI-->

            <!--        <div class="thread-info">-->
            <!--          <div class="thread-actions">-->

            <!--                          <button *ngIf="isActionPresent('monitor.notify') && !(!!notification.username)" class="action-button"-->
            <!--                                  mat-raised-button color="primary" action-type="button"-->
            <!--                                  [matMenuTriggerFor]="actionsMenu">Reply</button>-->

            <!--            <mat-menu #actionsMenu="matMenu" xPosition="after">-->
            <!--                              <div *ngFor="let btn of objectThread.btnsActions; let i= index">-->

            <!--                                <button *ngIf="isActionPresent('monitor.notify.' + btn.notificationId)" mat-menu-item-->
            <!--                                        (click)="reply(notification, btn.notificationId, objectThread.object.appOwnerNotificationId, objectThread.object.notificationThreadDesc)">{{btn.notificationLabel}}</button>-->

            <!--                              </div>-->
            <!--            </mat-menu>-->
            <!--          </div>-->

            <!--        </div>-->

          </div>

          <div class="small">
            <div class="thread-date">
              {{notificationEntity.insertDate}}
            </div>
          </div>
        </div>

        <!--MAIL SUBJECT-->
        <div class="thread-header-mail">
          <div class="thread-header-mail-row">
            <div class="from bold">
              {{ 'LABEL_SUBJECT' | translate }}:
            </div>
            <div class="small full">
              {{notificationEntity.notificationSubject}}
            </div>
          </div>

          <!--MAIL FROM-->
          <div class="thread-header-mail-row">
            <div class="from bold">
              {{ 'LABEL_FROM' | translate }}:
            </div>
            <div class="small full">
              {{notificationEntity.notificationFrom}}
            </div>
          </div>

          <!--MAIL TO-->
          <div class="thread-header-mail-row">
            <div class="from bold">
              {{ 'LABEL_TO' | translate }}:
            </div>
            <div class="small full">
              {{notificationEntity.notificationTo}}
            </div>
          </div>

          <!--MAIL CC-->
          <div class="thread-header-mail-row">
            <div class="from bold">
              {{ 'LABEL_CC' | translate }}:
            </div>
            <div class="small full">
              {{notificationEntity.notificationCc}}
            </div>
          </div>
        </div>

        <div class="thread-header-mail">
          <div class="thread-header-mail mt-16"
               [ngClass]="{'thread-subcontainer': notificationEntity.notificationFrom === userEmail}">
            <div class="thread-body">
              <div class="thread-title-mail"
                   *ngIf="!!notificationEntity.notificationAttachments && notificationEntity.notificationAttachments.length > 0">
                <div class="from bold">{{ 'LABEL_ATTACHMENTS' | translate }}:</div>
              </div>
              <div class="thread-body-attachment-container"
                   *ngFor="let attachment of notificationEntity.notificationAttachments; let a = index">
                <label class="attachment-title">{{attachment['name']}}</label>
                <button mat-button (click)="download(attachment)">
                  <mat-icon>file_download</mat-icon>
                </button>
                <button mat-button (click)="attachFile(attachment, notification.notificationThreadId)">
                  <mat-icon>file_present</mat-icon>
                </button>
                <!--        vecchia logica        -->
                <!--                <button mat-button (click)="download(attachment)">-->
                <!--                  <mat-icon>file_download</mat-icon>-->
                <!--                </button>-->
                <!--                <button mat-button (click)="attachFile(attachment, objectThread.object.notificationThreadId)">-->
                <!--                  <mat-icon>file_present</mat-icon>-->
                <!--                </button>-->
              </div>
            </div>

            <div class="thread-body">
              <div class="thread-body-html" [innerHtml]="notificationEntity.notificationBody">

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</div>
