<div class="table-upper-bar">
  <div class="d-flex align-items-center">
    <div *ngIf="datasets.multiselect
         && !!actionsets
         && !!actionsets.actions
         && (actionsets?.actions ?? []).length > 0
         && (('ACT_EXPORT' | haveAction: (actionsets?.actions ?? []) | async)
           || ('ACT_DELETE' | haveAction: (actionsets?.actions ?? []) | async)
           || ('ACT_SEND' | haveAction: (actionsets?.actions ?? []) | async)
           || ('ACT_SAVE_REWORK' | haveAction: (actionsets?.actions ?? []) | async)
           || ('ACT_DRAFT' | haveAction: (actionsets?.actions ?? []) | async)
           || ('ACT_CARDS_DOWNLOAD' | haveAction: (actionsets?.actions ?? []) | async)
           || ('ACT_APPROVE' | haveAction: (actionsets?.actions ?? []) | async)
           || ('ACT_OPEN' | haveAction: (actionsets?.actions ?? []) | async)
           || ('ACT_CLOSE' | haveAction: (actionsets?.actions ?? []) | async)
           || ('ACT_INSP_CONTAINER' | haveAction: (actionsets?.actions ?? []) | async)
           || ('ACT_COM_TRACKER' | haveAction: (actionsets?.actions ?? []) | async)
           )" ngbDropdown>
      <custom-btn color="primary" id="dropdownBasic1" ngbDropdownToggle>
        {{ 'LABEL_ACTIONS' | translate }} <i class="bi bi-caret-down"></i>
      </custom-btn>
      <div *ngIf="!!actionsets && !!actionsets.actions
           && (actionsets?.actions ?? []).length > 0
           && (('ACT_EXPORT' | haveAction: (actionsets?.actions ?? []) | async)
           || ('ACT_WITHDRAW' | haveAction: (actionsets?.actions ?? []) | async)
           || ('ACT_SAVE_REWORK' | haveAction: (actionsets?.actions ?? []) | async)
           || ('ACT_DELETE' | haveAction: (actionsets?.actions ?? []) | async)
           || ('ACT_SEND' | haveAction: (actionsets?.actions ?? []) | async)
           || ('ACT_DRAFT' | haveAction: (actionsets?.actions ?? []) | async)
           || ('ACT_CARDS_DOWNLOAD' | haveAction: (actionsets?.actions ?? []) | async)
           || ('ACT_APPROVE' | haveAction: (actionsets?.actions ?? []) | async)
           || ('ACT_OPEN' | haveAction: (actionsets?.actions ?? []) | async)
           || ('ACT_CLOSE' | haveAction: (actionsets?.actions ?? []) | async)
           || ('ACT_INSP_CONTAINER' | haveAction: (actionsets?.actions ?? []) | async)
           || ('ACT_COM_TRACKER' | haveAction: (actionsets?.actions ?? []) | async)
           )"
           aria-labelledby="dropdownBasic1" ngbDropdownMenu>
        <ng-container *ngFor="let action of actionsets?.actions">
          <button (click)="cancelClaimRow(-1)" *ngIf="action.id == 'ACT_WITHDRAW'"
                  ngbDropdownItem>{{ 'LABEL_CANCEL_CLAIM' | translate }}
          </button>
          <button (click)="actionEmitter(action.id)" *ngIf="action.id == 'ACT_EXPORT'" ngbDropdownItem>{{
              "ACT_EXPORT" |
                translate
            }}
          </button>

          <button (click)="actionEmitter(action.id + '_ALL')" *ngIf="action.id == 'ACT_SAVE_REWORK'" ngbDropdownItem>{{
              "ACT_SAVE_REWORK" |
                translate
            }}
          </button>

          <button (click)="actionEmitter(action.id)" *ngIf="action.id == 'ACT_DELETE'" ngbDropdownItem>{{
              "ACT_DELETE" |
                translate
            }}
          </button>
          <button (click)="actionEmitter(action.id)" *ngIf="action.id == 'ACT_SEND'" ngbDropdownItem>{{
              "ACT_SEND" |
                translate
            }}
          </button>
          <button (click)="actionEmitter(action.id)" *ngIf="action.id == 'ACT_DRAFT'" ngbDropdownItem>{{
              "ACT_DRAFT" |
                translate
            }}
          </button>
          <button (click)="actionEmitter(action.id + '_MULTI')" *ngIf="action.id == 'ACT_CARDS_DOWNLOAD'"
                  ngbDropdownItem>{{ "ACT_DOWNLOAD" | translate }}
          </button>
          <button (click)="actionEmitter(action.id)" *ngIf="action.id == 'ACT_APPROVE'" ngbDropdownItem>{{
              "ACT_APPROVE" |
                translate
            }}
          </button>
          <button (click)="actionEmitter(action.id)" *ngIf="action.id == 'ACT_OPEN'" ngbDropdownItem>{{
              "ACT_OPEN" |
                translate
            }}
          </button>
          <button (click)="actionEmitter(action.id)" *ngIf="action.id == 'ACT_CLOSE'" ngbDropdownItem>{{
              "ACT_CLOSE" |
                translate
            }}
          </button>
        </ng-container>
      </div>
    </div>


    <!--    ITOEM-5: added custom logic to handle notify operation-->
    <ng-container *ngIf="notificationOption != null && notificationOption.length > 0">
      <div aria-labelledby="dropdownNotificationMenu" ngbDropdown>
        <custom-btn color="primary" id="dropdownNotification" ngbDropdownToggle>
          {{ 'LABEL_NOTIFY' | translate }} <i class="bi bi-caret-down"></i>
        </custom-btn>
        <div id="dropdownNotificationMenu" ngbDropdownMenu>
          <ng-container *ngFor="let option of notificationOption">
            <button *ngIf="(option.notificationId | haveAction: (actionsets?.actions ?? []) | async)"
                    (click)="notifyRecords(option)" ngbDropdownItem>
              {{ option.notificationLabel | translate }}
            </button>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <!--    ITOEM-05: added custom logic to handle manual action operation-->
    <ng-container *ngIf="manualOption != null && manualOption.length > 0">
      <div aria-labelledby="dropdownNotificationMenu" ngbDropdown>
        <custom-btn color="primary" id="dropdownManualOption" ngbDropdownToggle>
          {{ 'LABEL_MANUAL_OPTION' | translate }} <i class="bi bi-caret-down"></i>
        </custom-btn>
        <div id="dropdownManualOptionMenu" ngbDropdownMenu>
          <ng-container *ngFor="let option of manualOption">
            <button *ngIf="(option.manualActionId | haveAction: (actionsets?.actions ?? []) | async)"
                    (click)="actionEmitter(option.manualActionId + '_MANUAL_ACTION')"
                    ngbDropdownItem>
              {{ option.manualActionName | translate }}
            </button>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngFor="let action of actionsets?.actions">
      <custom-btn (click)="actionEmitter(action.id+'_ALL')" *ngIf="action.id == 'ACT_EXPORT'" [iconButton]="true"
                  [iconClasses]="'insert_drive_file'" [iconColor]="'green'" matTooltip="{{ 'LABEL_EXPORT' | translate }}">
      </custom-btn>
      <custom-btn (click)="actionEmitter(action.id+'_ALL')" *ngIf="action.id == 'ACT_IMPORT'" [iconButton]="true"
                  [iconClasses]="action.icon" [iconColor]="'green'" matTooltip="{{ 'LABEL_IMPORT' | translate }}">
      </custom-btn>
      <custom-btn (click)="actionEmitter(action.id+'_ALL')" *ngIf="action.id == 'ACT_CONVERT_FILE'" [iconButton]="true"
            [iconClasses]="action.icon" [iconColor]="'green'" matTooltip="{{ 'ACT_CONVERT_FILE' | translate }}">
      </custom-btn>
      <custom-btn (click)="actionEmitter(action.id + '_ALL')" *ngIf="action.id == 'ACT_IMPORT_PREVIEW'" [iconButton]="true"
                  [iconClasses]="action.icon" [iconColor]="'green'" matTooltip="{{ 'ACT_IMPORT_PREVIEW' | translate }}">
      </custom-btn>
    </ng-container>
  </div>

  <span *ngIf="!datasets.multiselect && actionsets?.actions?.length == 0" class="spacer"></span>


  <div class="d-flex">
    <ng-container *ngIf="actReportAction !== null">
      <!-- LM- Add the report button -->
      <custom-btn (click)="actionEmitter(actReportAction.id+'_ALL')" color="primary"
                  class="report-btn">
        {{
          "ACT_REPORT" |
            translate
        }}
      </custom-btn>
    </ng-container>

    <ng-container *ngIf="actExportReportAction !== null">
      <!-- LM- Add the report button -->
      <custom-btn (click)="actionEmitter(actExportReportAction.id+'_ALL')" color="primary"
                  class="report-btn">
        {{
          "ACT_EXPORT_REPORT" |
            translate
        }}
      </custom-btn>
    </ng-container>

    <!-- [MB 14/10/2024] ITEUR-83: Add the add language button -->
    <ng-container *ngIf="actAddLanguage !== null">
      <custom-btn (click)="actionEmitter(actAddLanguage.id+'_ALL')" color="primary"
                  class="report-btn">
        {{
          "ACT_ADD_LANGUAGE" |
            translate
        }}
      </custom-btn>
    </ng-container>

    <custom-btn (click)="onClearSortClick()" *ngIf="length > 0" class="clear-sort-btn"
                color="primary">{{
        "ACT_CLEAR_SORT" |
          translate
      }}
    </custom-btn>
    <custom-btn (click)="onResetSortClick()" *ngIf="length > 0" class="reset-sort-btn"
                color="primary">{{
        "ACT_RESET_SORT" |
          translate
      }}
    </custom-btn>

    <custom-table-paginator (onPaginatorChange)="onPageChange($event)" *ngIf="length > 0"
                            [numElementsLoaded]="numElementsLoaded" [numElementsSize]="length"
                            [page]="pageEvent.pageIndex"
                            [paginatorLoaderList]="[50, 100, 250]"
                            [paginatorLoaderTitle]="('LABEL_TABLE_PAGINATOR' | translate)"
                            [paginatorLoader]="50" class="right">
    </custom-table-paginator>
    <ng-container *ngFor="let action of actionsets?.actions">
      <div *ngIf="action.id == 'ACT_ADD'"
           [matTooltip]="(disabledActions | getValueOnCondition:'id':'motivation':action.id:action.label | async) ?? ''">
        <custom-btn (click)="onAddClick()" [disabled]="(action.id | haveAction:disabledActions | async) ?? false"
                    class="t3" color="primary">{{ "ACT_ADD" | translate }}
        </custom-btn>
      </div>
      <div *ngIf="action.id == 'ACT_TO_LANE'"
           [matTooltip]="(disabledActions | getValueOnCondition:'id':'motivation':action.id:action.label | async) ?? ''">
        <custom-btn (click)="actionEmitter(action.id + '_ALL')"
                    [disabled]="(action.id | haveAction:disabledActions | async) ?? false" class="t3" color="primary">{{
            "ACT_ADD"
              | translate
          }}
        </custom-btn>
      </div>
      <div *ngIf="action.id == 'ACT_TO_PROBLEM'"
           [matTooltip]="(disabledActions | getValueOnCondition:'id':'motivation':action.id:action.label | async) ?? ''">
        <custom-btn (click)="actionEmitter(action.id + '_ALL')"
                    [disabled]="(action.id | haveAction:disabledActions | async) ?? false" class="t3" color="primary">{{
            "ACT_ADD"
              | translate
          }}
        </custom-btn>
      </div>
      <div *ngIf="action.id == 'ACT_ADD_DAM_WH'"
           [matTooltip]="(disabledActions | getValueOnCondition:'id':'motivation':action.id:action.label | async) ?? ''">
        <custom-btn (click)="actionEmitter(action.id + '_ALL')"
                    [disabled]="(action.id | haveAction:disabledActions | async) ?? false" class="t3" color="primary">{{
            "ACT_ADD"
              | translate
          }}
        </custom-btn>
      </div>
    </ng-container>
  </div>

</div>

<div class="table-responsive flex-grow-1 d-flex">
  <div class="table-container">
<!--    <cdk-virtual-scroll-viewport #scrollViewport [itemSize]="ITEM_SIZE"-->
<!--                                 class="w-100 flex-grow-1">-->
    <div>
      <table class="table table-striped ">
        <thead #tableTr [style.top]="inverseOfTranslation" style="position: sticky">
        <tr>
          <th *ngIf="datasets.multiselect" style="width: 10px;">
            <mat-checkbox (change)="$event ? tableController.toggleAllRows() : null"
                          [aria-label]="tableController.checkboxLabel()"
                          [checked]="tableController.selection.hasValue() && tableController.isAllSelected()"
                          [indeterminate]="tableController.selection.hasValue() && !tableController.isAllSelected()">
            </mat-checkbox>
          </th>

          <th (click)="sortColumn(column.columnName)" *ngFor="let column of datasets['datas']; let i = index"
              [style.min-width.px]="(tableController.columnSize[column.columnName] + 16)"
              [style.width.px]="(tableController.columnSize[column.columnName] + 16)" class="pointer">
              <span class="table-button-container">
                {{ column.columnLabel | translate }}
                <mat-icon
                  *ngIf="isSortable && !!tableController.sortedColumns.get(column.columnName) && tableController.sortedColumns.get(column.columnName)?.type == 'asc'">
                  arrow_upward</mat-icon>
                <mat-icon
                  *ngIf="isSortable && !!tableController.sortedColumns.get(column.columnName) && tableController.sortedColumns.get(column.columnName)?.type == 'desc'"
                  class="sorter-icon">arrow_downward</mat-icon>
                <label
                  *ngIf="isSortable && !!tableController.sortedColumns.get(column.columnName) && (tableController.sortedColumns.get(column.columnName)?.type == 'asc' || tableController.sortedColumns.get(column.columnName)?.type == 'desc')"
                  class="sort-order">{{ tableController.sortedColumns.get(column.columnName)?.order }}</label>
              </span>
          </th>
          <th
            *ngIf="((actionsets?.actions ?? []).length > 0) && ((actionsets?.actions ?? []) | haveOneAction : actionsToCheck)"
            class="actions">
            <span></span>
          </th>

        </tr>
        </thead>
        <tbody>

        <tr *ngIf="tableController.dataSet.length <= 0">
          <td [attr.colspan]="(datasets['datas'].length ?? 1) + 1" style="display: table-cell">
            <div class="not-data-found">{{ "LABEL_NO_DATA_FOUND" | translate }}</div>
          </td>
        </tr>

<!--        <ng-container *cdkVirtualFor="let dataRow of tableController.dataSet; let i=index">-->
        <ng-container *ngFor="let dataRow of tableController.dataSet; let i=index">
          <tr (click)="onRowClick.emit(i)"
              [class.changed-row]="dataRow.isChanged" [class.deleted-row]="dataRow.deleted"
              [class.selected-row]="tableController.rowNumber == i">

            <td *ngIf="datasets.multiselect" class="align-middle">
              <mat-checkbox (change)="$event ? tableController.selection.toggle(dataRow) : null"
                            (click)="$event.stopPropagation()" [aria-label]="tableController.checkboxLabel(dataRow)"
                            [checked]="tableController.selection.isSelected(dataRow)">
              </mat-checkbox>
            </td>

            <td *ngFor="let column of datasets['datas'];"
                [style.min-width.px]="(tableController.columnSize[column.columnName] + 16)"
                [style.width.px]="(tableController.columnSize[column.columnName] + 16)"
                [ngClass]="getMetadataErrorCss(column, dataRow)"
                [matTooltip]="getMetadataErrorTooltip(column, dataRow) | translate"
                class="align-middle">
                <span *ngIf="tableController.dataSet[i]?.errors && tableController.dataSet[i]?.errors[column.columnName]"
                      [matTooltip]="tableController.dataSet[i]?.errors[column.columnName].error_list.join(';') | strarrTranslate"
                      [ngStyle]="{'width.px': (tableController.columnSize[column.columnName] - 20)}">
                  <mat-icon *ngIf="tableController.dataSet[i].errors[column.columnName].error_type == 'DANGER'"
                            class="error-mat-icon d-inline"
                            style="color: red !important">error
                  </mat-icon>
                  <mat-icon *ngIf="tableController.dataSet[i].errors[column.columnName].error_type == 'WARNING'"
                            class="error-mat-icon d-inline"
                            style="color: orange !important">warning
                  </mat-icon>
                  <mat-icon *ngIf="tableController.dataSet[i].errors[column.columnName].error_type == 'INFO'"
                            class="error-mat-icon d-inline"
                            style="color: dodgerblue !important">info
                  </mat-icon>
                </span>

              <ng-container *ngIf="!(!!dataRow.editing) && !(!!dataRow.newRow)  else editMode">
                <ng-container *ngIf="column.isPk else notPk">
                  <ng-container [ngSwitch]="column.type">
                    <ng-container *ngSwitchCase="'DTY_LIST'">
                      <ng-container
                        *ngIf="('ACT_EDIT_DAM' | haveAction: (actionsets?.actions ?? []) | async) else notEditDam">
                          <span (click)="editRowId(i, 'ACT_EDIT_DAM')"
                                [matTooltip]="tableController.dataSet[i][column.columnName]"
                                [ngStyle]="{'width.px': (tableController.columnSize[column.columnName] -20)}"
                                class="text-nowrap pointer text-decoration-underline text-overflow-custom">
                            {{
                              (((tableController.filterValues) | getDescriptionFromOptionValues: column.columnName:
                                tableController.dataSet[i][column.columnName] :
                                tableController.dataSet[i][column.columnName] : i) | async) ??
                              tableController.dataSet[i][column.columnName]
                            }}


                          </span>

                      </ng-container>
                      <ng-template #notEditDam>
                        <ng-container
                          *ngIf="('ACT_EDIT_PROBLEM' | haveAction: (actionsets?.actions ?? []) | async) else notEditProblem">
                            <span (click)="editRowId(i, 'ACT_EDIT_PROBLEM')"
                                  [matTooltip]="tableController.dataSet[i][column.columnName]"
                                  [ngStyle]="{'width.px': (tableController.columnSize[column.columnName] - 20)}"
                                  class="text-nowrap pointer text-decoration-underline text-overflow-custom">

                              {{
                                (((tableController.filterValues) | getDescriptionFromOptionValues: column.columnName:
                                  tableController.dataSet[i][column.columnName] :
                                  tableController.dataSet[i][column.columnName] : i) | async) ??
                                tableController.dataSet[i][column.columnName]
                              }}

                            </span>

                        </ng-container>
                        <ng-template #notEditProblem>
                            <span [matTooltip]="tableController.dataSet[i][column.columnName]"
                                  [ngStyle]="{'width.px': (tableController.columnSize[column.columnName] - 20)}"
                                  class="text-nowrap text-overflow-custom text-overflow-custom">
                              {{
                                (((tableController.filterValues) | getDescriptionFromOptionValues: column.columnName:
                                  tableController.dataSet[i][column.columnName] :
                                  tableController.dataSet[i][column.columnName] : i) | async) ??
                                tableController.dataSet[i][column.columnName]
                              }}

                            </span>

                        </ng-template>
                      </ng-template>
                    </ng-container>
                    <ng-container *ngSwitchCase="'DTY_RADIO'">
                      <i
                        *ngIf="(!!tableController.dataSet[i][column.columnName] && ((!!tableController.dataSet[i][column.columnName] && tableController.dataSet[i][column.columnName]  === 'Y') || tableController.dataSet[i][column.columnName]  === true || tableController.dataSet[i][column.columnName]  === 'true'))"
                        class="bi bi-record-circle"></i>
                      <i
                        *ngIf="!(!!tableController.dataSet[i][column.columnName] && ((!!tableController.dataSet[i][column.columnName] && tableController.dataSet[i][column.columnName]  === 'Y') || tableController.dataSet[i][column.columnName]  === true || tableController.dataSet[i][column.columnName]  === 'true'))"
                        class="bi bi-circle"></i>
                    </ng-container>
                    <ng-container *ngSwitchCase="'DTY_STATUS'">
                      <span *ngSwitch="tableController.dataSet[i][column.columnName]"
                            [ngStyle]="{'width.px': (tableController.columnSize[column.columnName] - 20)}">

                        <ng-container *ngSwitchCase="'INFO'">
                          <mat-icon>info_icon</mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchCase="'ERROR'">
                          <mat-icon>error_icon</mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchCase="'WARNING'">
                          <mat-icon>warning_icon</mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchCase="'NEW'">
                          <mat-icon class="dty-status-new">fiber_new</mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchCase="'OPEN'">
                          <mat-icon class="dty-status-info">lock_open</mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchCase="'REOPENED'">
                          <mat-icon class="dty-status-warning">lock_open</mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchCase="'CLOSED'">
                          <mat-icon class="dty-status-error">lock</mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchCase="'UPDATED'">
                          <mat-icon class="dty-status-error">info</mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchCase="'STAGED'">
                          <mat-icon class="dty-status-warning">inventory_2</mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchCase="'SENT'">
                          <mat-icon class="dty-status-info">mark_email_read</mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchCase="'READY'">
                          <mat-icon class="dty-status-info">pending</mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          <mat-icon>default_icon</mat-icon>
                        </ng-container>

                      </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'DTY_YES_NO'">
                        <span *ngIf="tableController.dataSet[i][column.columnName] == 'Y'"
                              [ngStyle]="{'width.px': (tableController.columnSize[column.columnName] - 20)}">
                          <mat-icon>check_small</mat-icon>
                        </span>
                      <!--<custom-checkbox [disabled]="true" [falseValue]="'N'"
                                     [ngModel]="tableController.dataSet[i][column.columnName]"
                                     [trueValue]="'Y'">
                    </custom-checkbox>-->
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <ng-container
                        *ngIf="(('ACT_EDIT_DAM' | haveAction: (actionsets?.actions ?? []) | async)) else notEditDam">
                          <span (click)="editRowId(i, 'ACT_EDIT_DAM')"
                                [ngStyle]="{'width.px': (tableController.columnSize[column.columnName] - 20)}"
                                class="text-nowrap pointer text-decoration-underline text-overflow-custom">
                            {{ tableController.dataSet[i][column.columnName] }}
                          </span>

                      </ng-container>
                      <ng-template #notEditDam>
                        <ng-container
                          *ngIf="('ACT_EDIT_PROBLEM' | haveAction: (actionsets?.actions ?? []) | async) else notEditProblem">
                            <span (click)="editRowId(i, 'ACT_EDIT_PROBLEM')"
                                  [ngStyle]="{'width.px': (tableController.columnSize[column.columnName] - 20)}"
                                  class="text-nowrap pointer text-decoration-underline text-overflow-custom">
                              {{ tableController.dataSet[i][column.columnName] }}

                            </span>

                        </ng-container>
                        <ng-template #notEditProblem>
                          <ng-container
                            *ngIf="('ACT_SHOW_DETAIL' | haveAction: (actionsets?.actions ?? []) | async) else notShowDetail">
                              <span (click)="editRowId(i, 'ACT_SHOW_DETAIL')"
                                    [ngStyle]="{'width.px': (tableController.columnSize[column.columnName] - 20)}"
                                    class="text-nowrap pointer text-decoration-underline text-overflow-custom">
                                {{ tableController.dataSet[i][column.columnName] }}

                              </span>

                          </ng-container>
                          <ng-template #notShowDetail>
                              <span [ngStyle]="{'width.px': (tableController.columnSize[column.columnName] - 20)}"
                                    class="text-nowrap text-overflow-custom">
                                {{ tableController.dataSet[i][column.columnName] }}
                                <!------------->
                              </span>
                          </ng-template>
                        </ng-template>
                      </ng-template>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-template #notPk>
                  <ng-container [ngSwitch]="column.type">
                    <ng-container *ngSwitchCase="'DTY_LIST'">

                        <span [matTooltip]="(((tableController.filterValues) | getDescriptionFromOptionValues: column.columnName:
                                tableController.dataSet[i][column.columnName] :
                                tableController.dataSet[i][column.columnName] : i) | async) ??
                              tableController.dataSet[i][column.columnName]"
                              [ngStyle]="{'width.px': (tableController.columnSize[column.columnName] - 20)}"
                              class="d-inline-block text-nowrap text-overflow-custom">
  <!--                        {{-->
  <!--                          tableController.dataSet[i][column.columnName]-->
  <!--                        }}-->
                              {{
                                (((tableController.filterValues) | getDescriptionFromOptionValues: column.columnName:
                                  tableController.dataSet[i][column.columnName] :
                                  tableController.dataSet[i][column.columnName] : i) | async) ??
                                tableController.dataSet[i][column.columnName]
                              }}

                        </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'DTY_RADIO'">
                      <i
                        *ngIf="(!!tableController.dataSet[i][column.columnName] && ((!!tableController.dataSet[i][column.columnName] && tableController.dataSet[i][column.columnName]  === 'Y') || tableController.dataSet[i][column.columnName]  === true || tableController.dataSet[i][column.columnName]  === 'true'))"
                        class="bi bi-record-circle"></i>
                      <i
                        *ngIf="!(!!tableController.dataSet[i][column.columnName] && ((!!tableController.dataSet[i][column.columnName] && tableController.dataSet[i][column.columnName]  === 'Y') || tableController.dataSet[i][column.columnName]  === true || tableController.dataSet[i][column.columnName]  === 'true'))"
                        class="bi bi-circle"></i>
                    </ng-container>
                    <ng-container *ngSwitchCase="'DTY_STATUS'">
                      <span [ngStyle]="{'width.px': (tableController.columnSize[column.columnName] - 20)}">
                        <ng-container [ngSwitch]="tableController.dataSet[i][column.columnName]">

                          <ng-container *ngSwitchCase="'INFO'">
                            <mat-icon class="dty-status-info">check_circle</mat-icon>
                          </ng-container>

                          <ng-container *ngSwitchCase="'ERROR'">
                            <mat-icon class="dty-status-error">error</mat-icon>
                          </ng-container>

                          <ng-container *ngSwitchCase="'WARNING'">
                            <mat-icon class="dty-status-warning">warning</mat-icon>
                          </ng-container>

                          <ng-container *ngSwitchCase="'NEW'">
                            <mat-icon class="dty-status-new">fiber_new</mat-icon>
                          </ng-container>

                          <ng-container *ngSwitchCase="'OPEN'">
                            <mat-icon class="dty-status-info">lock_open</mat-icon>
                          </ng-container>

                          <ng-container *ngSwitchCase="'REOPENED'">
                            <mat-icon class="dty-status-warning">lock_open</mat-icon>
                          </ng-container>

                          <ng-container *ngSwitchCase="'CLOSED'">
                            <mat-icon class="dty-status-error">lock</mat-icon>
                          </ng-container>

                          <ng-container *ngSwitchCase="'UPDATED'">
                            <mat-icon class="dty-status-error">info</mat-icon>
                          </ng-container>

                          <ng-container *ngSwitchCase="'STAGED'">
                            <mat-icon class="dty-status-warning">inventory_2</mat-icon>
                          </ng-container>

                          <ng-container *ngSwitchCase="'SENT'">
                            <mat-icon class="dty-status-info">mark_email_read</mat-icon>
                          </ng-container>

                          <ng-container *ngSwitchCase="'READY'">
                            <mat-icon class="dty-status-info">pending</mat-icon>
                          </ng-container>


                          <ng-container *ngSwitchDefault>
                            <mat-icon>help</mat-icon>
                          </ng-container>
                        </ng-container>
                      </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'DTY_YES_NO'">
                        <span *ngIf="tableController.dataSet[i][column.columnName] == 'Y'"
                              [ngStyle]="{'width.px': (tableController.columnSize[column.columnName] - 20)}">
                          <mat-icon>check_small</mat-icon>
                        </span>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <!--                            [ngClass]="isInvalid(tableController.dataSet[i][column.columnName], tableController.dataSet, column.patternInfo) && 'invalid'" -->

                        <span [ngStyle]="{'width.px': (tableController.columnSize[column.columnName] - 20)}"
                              class="span text-nowrap text-overflow-custom">
                          {{ tableController.dataSet[i][column.columnName] }}

                        </span>
                    </ng-container>
                  </ng-container>
                </ng-template>
              </ng-container>

              <ng-template #editMode>
                <custom-text-field (change)="onInputChange(i)"
                                   (onErrorChange)="tableController.setErrors($event, i, column.columnName, ((!column.editable && !(!!tableController.dataSet[i].newRow)) || (disabledColumns.indexOf(column.columnName) > -1)))"
                                   *ngIf="(column.type == 'DTY_NVAR' || column.type == 'DTY_INT' || column.type == 'DTY_FLOAT')"
                                   [(value)]="tableController.dataSet[i][column.columnName]" [columnId]="column.id"
                                   [disabled]="((!column.editable && !(!!tableController.dataSet[i].newRow) || column.columnName == 'ID' )) || (disabledColumns.indexOf(column.columnName) > -1)"
                                   [isNumber]="column.type == 'DTY_INT' || column.type == 'DTY_FLOAT'"
                                   [maxlength]="column.maxLength"
                                   [mywidth]="tableController.columnSize[column.columnName]"
                                   [onError]="tableController.getErrors(i, column.columnName)"
                                   [pattern]="column.patternInfo"
                                   [regexps]="column.regExpList">


                </custom-text-field>


                <ng-container
                  *ngIf="column.type == 'DTY_LIST' && (!(!!tableController.dataSet[i]?.newRow) || !column.isPk) else listElseBlock">
                  <custom-select (change)="onInputChange(i)" *ngIf="column.type == 'DTY_LIST'"
                                 [(defaultValue)]="tableController.dataSet[i][column.columnName]"
                                 [disabled]="(!column.editable && !(!!tableController.dataSet[i].newRow)) || (disabledColumns.indexOf(column.columnName) > -1)"
                                 [values]="(tableController.filterValues | async)[column.columnName]"
                                 [searchable] = "true">
                  </custom-select>

                </ng-container>
                <ng-template #listElseBlock>
                  <custom-text-field (change)="onInputChange(i)"
                                     (onErrorChange)="tableController.setErrors($event, i, column.columnName, ((!column.editable && !(!!tableController.dataSet[i].newRow)) || (disabledColumns.indexOf(column.columnName) > -1 )))"
                                     *ngIf="column.type == 'DTY_LIST'"
                                     [(value)]="tableController.dataSet[i][column.columnName]"
                                     [disabled]="(!column.editable && !(!!tableController.dataSet[i].newRow)) || (disabledColumns.indexOf(column.columnName) > -1)"
                                     [isNumber]="column.type == 'DTY_INT' || column.type == 'DTY_FLOAT'"
                                     [maxlength]="column.maxLength"
                                     [mywidth]="tableController.columnSize[column.columnName]"
                                     [onError]="tableController.getErrors(i, column.columnName)"
                                     [pattern]="column.patternInfo"
                                     [regexps]="column.regExpList">


                  </custom-text-field>

                </ng-template>

                <custom-datepicker (valueChange)="onInputChange(i)" *ngIf="column.type == 'DTY_DATE'"
                                   [(value)]="tableController.dataSet[i][column.columnName]"
                                   [disabled]="(!column.editable && !(!!tableController.dataSet[i].newRow)) || (disabledColumns.indexOf(column.columnName) > -1)">
                </custom-datepicker>

                <custom-timepicker (valueChange)="onInputChange(i)" *ngIf="column.type == 'DTY_TIME'"
                                   [(value)]="tableController.dataSet[i][column.columnName]"
                                   [disabled]="(!column.editable && !(!!tableController.dataSet[i].newRow)) || (disabledColumns.indexOf(column.columnName) > -1)">
                </custom-timepicker>

                <custom-checkbox (ngModelChange)="onInputChange(i)" *ngIf="column.type == 'DTY_YES_NO'"
                                 [(ngModel)]="tableController.dataSet[i][column.columnName]" [falseValue]="'N'"
                                 [trueValue]="'Y'">
                </custom-checkbox>

                <ng-container *ngIf="column.type == 'DTY_RADIO'">
                  <svg (click)="onRadioButtonClick(column, i)"
                       *ngIf="(!!tableController.dataSet[i][column.columnName] && ((!!tableController.dataSet[i][column.columnName] && tableController.dataSet[i][column.columnName]  === 'Y') || tableController.dataSet[i][column.columnName]  === true || tableController.dataSet[i][column.columnName]  === 'true')) else noChecked"
                       height="16" version="1.1"
                       viewBox="0 0 1080 1080" width="16" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                      <g id="cecba6d2-22c4-412d-813e-4c5f33181f02" transform="matrix(1 0 0 1 540 540)">
                        <rect height="1080" rx="0" ry="0"
                              style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1; visibility: hidden;"
                              vector-effect="non-scaling-stroke" width="1080" x="-540" y="-540"/>
                      </g>
                    <g id="20fee9bb-4806-4f36-b1d0-e03c37859aac" transform="matrix(1 0 0 1 540 540)">
                      </g>

                    <g id="14c1cfa6-3eb0-4294-87b4-53fec07a944d" transform="matrix(14.92 0 0 14.92 537.4 537.4)">
                        <circle cx="0" cy="0" r="35"
                                style="stroke: rgb(1,30,65); stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;"
                                vector-effect="non-scaling-stroke"/>
                      </g>
                    <g transform="matrix(67.5 0 0 67.5 540 540)">
                        <g style="">
                          <g transform="matrix(1 0 0 1 0 0)">
                            <path
                              d="M 8 15 C 4.134006751184446 15 1 11.865993248815554 1 8 C 1 4.134006751184446 4.134006751184446 1 8 1 C 11.865993248815554 1 15 4.134006751184444 15 7.999999999999998 C 15 11.865993248815553 11.865993248815556 14.999999999999998 8.000000000000002 15 z M 8 16 C 12.418277998646348 16 16 12.418277998646348 16 8 C 16 3.5817220013536524 12.418277998646348 0 8 0 C 3.5817220013536524 0 0 3.5817220013536524 0 8 C 0 12.418277998646348 3.5817220013536524 16 8 16 z"
                              stroke-linecap="round"
                              style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(1,30,65); fill-rule: nonzero; opacity: 1;"
                              transform=" translate(-8, -8)" vector-effect="non-scaling-stroke"/>
                          </g>
                          <g transform="matrix(1 0 0 1 0 0)">
                            <path
                              d="M 11 8 C 11 9.65685424949238 9.65685424949238 11 8 11 C 6.343145750507619 11 5 9.65685424949238 5 8 C 5 6.343145750507619 6.343145750507619 5 8 5 C 9.65685424949238 5 11 6.3431457505076185 11 7.999999999999999 z"
                              stroke-linecap="round"
                              style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(1,30,65); fill-rule: nonzero; opacity: 1;"
                              transform=" translate(-8, -8)" vector-effect="non-scaling-stroke"/>
                          </g>
                        </g>
                      </g>
                    </svg>
                  <ng-template #noChecked>
                    <svg (click)="onRadioButtonClick(column, i)" height="16" version="1.1" viewBox="0 0 1080 1080"
                         width="16" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                        <g id="f1f3d078-6b09-4804-bd2e-b24aedd87810" transform="matrix(1 0 0 1 540 540)">
                          <rect height="1080" rx="0" ry="0"
                                style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1; visibility: hidden;"
                                vector-effect="non-scaling-stroke" width="1080" x="-540" y="-540"/>
                        </g>
                      <g id="7e8a0f25-93ee-4b5e-8ac7-13ea41c4eabf" transform="matrix(1 0 0 1 540 540)">
                        </g>
                      <g id="3f1dd5db-e4df-4081-b09f-4e22389903ef" transform="matrix(13.74 0 0 13.74 539.95 539.95)">
                          <circle cx="0" cy="0" r="35"
                                  style="stroke: rgb(1,30,65); stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;"
                                  vector-effect="non-scaling-stroke"/>
                        </g>
                      <g transform="matrix(67.5 0 0 67.5 540 540)">
                          <path
                            d="M 8 15 C 4.134006751184446 15 1 11.865993248815554 1 8 C 1 4.134006751184446 4.134006751184446 1 8 1 C 11.865993248815554 1 15 4.134006751184444 15 7.999999999999998 C 15 11.865993248815553 11.865993248815556 14.999999999999998 8.000000000000002 15 z M 8 16 C 12.418277998646348 16 16 12.418277998646348 16 8 C 16 3.5817220013536524 12.418277998646348 0 8 0 C 3.5817220013536524 0 0 3.5817220013536524 0 8 C 0 12.418277998646348 3.5817220013536524 16 8 16 z"
                            stroke-linecap="round"
                            style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(1,30,65); fill-rule: nonzero; opacity: 1;"
                            transform=" translate(-8, -8)"/>
                        </g>
                      </svg>
                  </ng-template>
                </ng-container>
                <ng-container *ngIf="column.type == 'DTY_STATUS'">
                      <span [ngStyle]="{'width.px': (tableController.columnSize[column.columnName] - 20)}">
                        <ng-container [ngSwitch]="tableController.dataSet[i][column.columnName]">

                          <ng-container *ngSwitchCase="'INFO'">
                            <mat-icon class="dty-status-info">check_circle</mat-icon>
                          </ng-container>

                          <ng-container *ngSwitchCase="'ERROR'">
                            <mat-icon class="dty-status-error">error</mat-icon>
                          </ng-container>

                          <ng-container *ngSwitchCase="'WARNING'">
                            <mat-icon class="dty-status-warning">warning</mat-icon>

                          <ng-container *ngSwitchCase="'NEW'">
                            <mat-icon class="dty-status-new">fiber_new</mat-icon>
                          </ng-container>

                          <ng-container *ngSwitchCase="'OPEN'">
                            <mat-icon class="dty-status-info">lock_open</mat-icon>
                          </ng-container>

                          <ng-container *ngSwitchCase="'REOPENED'">
                            <mat-icon class="dty-status-warning">lock_open</mat-icon>
                          </ng-container>

                          <ng-container *ngSwitchCase="'CLOSED'">
                            <mat-icon class="dty-status-error">lock</mat-icon>
                          </ng-container>

                          <ng-container *ngSwitchCase="'UPDATED'">
                            <mat-icon class="dty-status-error">info</mat-icon>
                          </ng-container>

                          <ng-container *ngSwitchCase="'STAGED'">
                            <mat-icon class="dty-status-warning">inventory_2</mat-icon>
                          </ng-container>

                          <ng-container *ngSwitchCase="'SENT'">
                            <mat-icon class="dty-status-info">mark_email_read</mat-icon>
                          </ng-container>

                          <ng-container *ngSwitchCase="'READY'">
                            <mat-icon class="dty-status-info">pending</mat-icon>
                          </ng-container>

                          </ng-container>
                          <ng-container *ngSwitchDefault>
                            <mat-icon>help</mat-icon>
                          </ng-container>
                        </ng-container>
                      </span>
                </ng-container>
              </ng-template>
            </td>
            <td
              *ngIf="((actionsets?.actions ?? []).length > 0) && ((actionsets?.actions ?? []) | haveOneAction : actionsToCheck)"
              class="actions">
                <span class="table-button-container">

                  <!-- ITOEM-5 added restart table action -->
                  <ng-container
                    *ngIf="!keyExistsIn(dataRow, 'editing') && !keyExistsIn(dataRow, 'newRow') && !(!!dataRow['STATUS'] && dataRow['STATUS'] == 'CANCELED') && ('ACT_RELOAD' | haveAction: (actionsets?.actions ?? []) | async)">
                    <button (click)="restartTask(i, 'ACT_RELOAD')" class="table-button" color="primary" mat-icon-button
                            matTooltip="{{'LABEL_RESTART' | translate}}">
                      <mat-icon>restart_alt</mat-icon>
                    </button>
                  </ng-container>

                  <ng-container
                    *ngIf="!keyExistsIn(dataRow, 'editing') && !keyExistsIn(dataRow, 'newRow') && !(!!dataRow['STATUS'] && dataRow['STATUS'] == 'CANCELED') && ('ACT_EDIT' | haveAction: (actionsets?.actions ?? []) | async)">
                    <button (click)="editRow(i)" class="table-button" color="primary" mat-icon-button
                            matTooltip="{{'LABEL_EDIT' | translate}}">
                      <mat-icon>edit</mat-icon>
                    </button>
                  </ng-container>

                  <!-- ITOEM-5 added audit table action -->
                  <ng-container
                    *ngIf="!keyExistsIn(dataRow, 'editing') && !keyExistsIn(dataRow, 'newRow') && !(!!dataRow['STATUS'] && dataRow['STATUS'] == 'CANCELED') && ('ACT_AUDIT' | haveAction: (actionsets?.actions ?? []) | async)">
                    <button (click)="showAudit(i, 'ACT_AUDIT')" class="table-button" color="primary" mat-icon-button
                            matTooltip="{{'LABEL_AUDIT' | translate}}">
                      <mat-icon>history</mat-icon>
                    </button>
                  </ng-container>

                  <ng-container
                    *ngIf="!keyExistsIn(dataRow, 'editing') && !keyExistsIn(dataRow, 'newRow') && (!!dataRow['STATUS'] && dataRow['STATUS'] == 'CANCELED') && ('ACT_EDIT' | haveAction: (actionsets?.actions ?? []) | async)">
                    <button (click)="editRow(i)" class="table-button" color="primary" mat-icon-button
                            matTooltip="{{'LABEL_SHOW_DETAIL' | translate}}">
                      <mat-icon>search</mat-icon>
                    </button>
                  </ng-container>
                  <ng-container
                    *ngIf="!keyExistsIn(dataRow, 'editing') && !keyExistsIn(dataRow, 'newRow') && ('ACT_SHOW_DETAIL' | haveAction: (actionsets?.actions ?? []) | async)">
                    <button (click)="editRowId(i, 'ACT_SHOW_DETAIL')" class="table-button" color="primary" mat-icon-button
                            matTooltip="{{'LABEL_SHOW_DETAIL' | translate}}">
                      <mat-icon>search</mat-icon>
                    </button>
                  </ng-container>
                  <ng-container
                    *ngIf="!keyExistsIn(dataRow, 'editing') && !keyExistsIn(dataRow, 'newRow') && ('ACT_EDIT_SCR' | haveAction: (actionsets?.actions ?? []) | async) &&  tableController.invokeFunction('ACT_EDIT_SCR', dataRow)">
                    <button (click)="editRowId(i, 'ACT_EDIT_SCR')" class="table-button" color="primary" mat-icon-button
                            matTooltip="{{'LABEL_EDIT' | translate}}">
                      <mat-icon>edit</mat-icon>
                    </button>
                  </ng-container>
                  <ng-container
                    *ngIf="!keyExistsIn(dataRow, 'editing') && !keyExistsIn(dataRow, 'newRow') && ('ACT_EDIT_SCR' | haveAction: (actionsets?.actions ?? []) | async) &&  !tableController.invokeFunction('ACT_EDIT_SCR', dataRow)">
                    <button (click)="editRowId(i, 'ACT_EDIT_SCR')" class="table-button" color="primary" mat-icon-button
                            matTooltip="{{'LABEL_SHOW_DETAIL' | translate}}">
                      <mat-icon>search</mat-icon>
                    </button>
                  </ng-container>
                  <ng-container
                    *ngIf="!keyExistsIn(dataRow, 'editing') && !keyExistsIn(dataRow, 'newRow') && ('ACT_EDIT_DAM' | haveAction: (actionsets?.actions ?? []) | async) &&  tableController.invokeFunction('ACT_EDIT_DAM', dataRow)">
                    <button (click)="editRowId(i, 'ACT_EDIT_DAM')" class="table-button" color="primary" mat-icon-button
                            matTooltip="{{'LABEL_EDIT' | translate}}">
                      <mat-icon>edit</mat-icon>
                    </button>
                  </ng-container>
                  <ng-container
                    *ngIf="!keyExistsIn(dataRow, 'editing') && !keyExistsIn(dataRow, 'newRow') && ('ACT_EDIT_DAM' | haveAction: (actionsets?.actions ?? []) | async) &&  !tableController.invokeFunction('ACT_EDIT_DAM', dataRow)">
                    <button (click)="editRowId(i, 'ACT_EDIT_DAM')" class="table-button" color="primary" mat-icon-button
                            matTooltip="{{'LABEL_SHOW_DETAIL' | translate}}">
                      <mat-icon>search</mat-icon>
                    </button>
                  </ng-container>
                  <ng-container
                    *ngIf="!keyExistsIn(dataRow, 'editing') && !keyExistsIn(dataRow, 'newRow') && ('ACT_EDIT_PROBLEM' | haveAction: (actionsets?.actions ?? []) | async)">
                    <button (click)="editRowId(i, 'ACT_EDIT_PROBLEM')" class="table-button" color="primary"
                            mat-icon-button matTooltip="{{'LABEL_EDIT' | translate}}">
                      <mat-icon>edit</mat-icon>
                    </button>
                  </ng-container>
                  <ng-container
                    *ngIf="!keyExistsIn(dataRow, 'editing') && !keyExistsIn(dataRow, 'newRow') && ('ACT_SHOW_CAMERA' | haveAction: (actionsets?.actions ?? []) | async)">
                    <button (click)="editRowId(i, 'ACT_SHOW_CAMERA')"
                            [matTooltip]="('LABEL_CAMERA_ROLL' | translate)"

                            class="table-button" color="primary" mat-icon-button>
                      <mat-icon [class.hasatt]="!!dataRow['files'] && dataRow['files'].length > 0">camera_roll</mat-icon>
                    </button>
                  </ng-container>
                  <ng-container
                    *ngIf="(dataRow.newRow || dataRow.editing) && ('ACT_SAVE' | haveAction: (actionsets?.actions ?? []) | async)">
                    <button (click)="commitEdit(i, 'ACT_SAVE_ID')" class="table-button" color="primary" mat-icon-button
                            matTooltip="{{'LABEL_SAVE' | translate}}">
                      <mat-icon>save</mat-icon>
                    </button>
                  </ng-container>
                  <ng-container
                    *ngIf="!dataRow.newRow && !dataRow.editing && ('ACT_INSP_CONTAINER' | haveAction: (actionsets?.actions ?? []) | async)">
                    <button (click)="showDetail(i)" class="table-button" color="primary" mat-icon-button
                            matTooltip="{{'LABEL_INSPECT_CONTAINER' | translate}}">
                      <mat-icon>search</mat-icon>
                    </button>
                  </ng-container>
                  <ng-container
                    *ngIf="!dataRow.newRow && !dataRow.editing && ('ACT_DOWNLOAD' | haveAction: (actionsets?.actions ?? []) | async)">
                    <button (click)="downloadFile(i)"
                      class="table-button" color="primary" mat-icon-button matTooltip="{{'LABEL_DOWNLOAD' | translate}}">
                      <mat-icon>download</mat-icon>
                    </button>
                  </ng-container>
                  <ng-container
                    *ngIf="!dataRow.newRow && !dataRow.editing && ('ACT_COM_TRACKER' | haveAction: (actionsets?.actions ?? []) | async)">
                    <button (click)="openNotificationHistory(i)" class="table-button" color="primary" mat-icon-button
                            matTooltip="{{'LABEL_COMMUNICATION_TRACKER' | translate}}">
                      <mat-icon>chat</mat-icon>
                    </button>
                  </ng-container>


                  <ng-container
                    *ngIf="(dataRow.editing) && ('ACT_SAVE_REWORK' | haveAction: (actionsets?.actions ?? []) | async)">
                  <button (click)="commitEdit(i, 'ACT_SAVE_REWORK')" class="table-button" color="primary" mat-icon-button
                          matTooltip="{{'LABEL_SAVE_REWORK' | translate}}">
                    <mat-icon>save</mat-icon>
                  </button>
                </ng-container>



                  <ng-container
                    *ngIf="!dataRow.newRow && !dataRow.editing && ('ACT_CARD_UPLOAD' | haveAction: (actionsets?.actions ?? []) | async)">
                    <button (click)="editRowId(i, 'ACT_CARD_UPLOAD')" class="table-button" color="primary" mat-icon-button
                            matTooltip="{{'LABEL_UPLOAD_FILE' | translate}}">
                      <mat-icon>cloud_upload</mat-icon>
                    </button>
                  </ng-container>

                  <ng-container
                    *ngIf="!dataRow.newRow && !dataRow.editing &&  ('ACT_CARD_DOWNLOAD' | haveAction: (actionsets?.actions ?? []) | async)">
                    <button (click)="editRowId(i, 'ACT_CARD_DOWNLOAD')" class="table-button" color="primary"
                            mat-icon-button matTooltip="{{'LABEL_DOWNLOAD' | translate}}">
                      <mat-icon>cloud_download</mat-icon>
                    </button>
                  </ng-container>


                  <ng-container
                    *ngIf="!dataRow.newRow && !dataRow.editing &&  ('ACT_CARD_DOWNLOAD' | haveAction: (actionsets?.actions ?? []) | async)">
                    <button (click)="editRowId(i, 'ACT_PREVIEW')" class="table-button" color="primary" mat-icon-button
                            matTooltip="{{'LABEL_PREVIEW' | translate}}">
                      <mat-icon>camera_roll</mat-icon>
                    </button>
                  </ng-container>

                  <ng-container
                    *ngIf="((dataRow.newRow || dataRow.editing) && ('ACT_EDIT' | haveAction: (actionsets?.actions ?? []) | async)) && !(('ACT_CANCEL' | haveAction:disabledActions | async) ?? false)">
                    <button (click)="exitRow(i)" class="table-button" color="primary" mat-icon-button
                            matTooltip="{{'LABEL_CANCEL' | translate}}">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </ng-container>
                  <ng-container *ngIf="dataRow.deleted; else deleteDefault">
                    <button (click)="deleteRow(i, true)" class="table-button" color="primary" mat-icon-button
                            matTooltip="{{'LABEL_RESTORE' | translate}}">
                      <mat-icon>settings_backup_restore</mat-icon>
                    </button>
                  </ng-container>

                  <ng-container
                    *ngIf="('ACT_CREATE_SCORECARD_DAM' | haveAction: (actionsets?.actions ?? []) | async) && tableController.invokeFunction('ACT_CREATE_SCORECARD_DAM', dataRow)">
                    <button (click)="editRowId(i, 'ACT_CREATE_SCORECARD_DAM')" class="table-button" color="primary"
                            mat-icon-button matTooltip="{{'Create Damage' | translate}}">
                      <mat-icon>edit_document</mat-icon>
                    </button>
                  </ng-container>
                  <ng-template #deleteDefault>
                    <ng-container
                      *ngIf="('ACT_DELETE' | haveAction: (actionsets?.actions ?? []) | async) && tableController.invokeFunction('ACT_DELETE', dataRow)">
                      <button (click)="deleteRow(i)"
                              *ngIf="(('ACT_DELETE' | haveAction: (actionsets?.actions ?? []) | async)) && !(('ACT_DELETE'| haveAction:disabledActions | async) ?? false)"
                              class="table-button" color="primary" mat-icon-button
                              matTooltip="{{'LABEL_DELETE' | translate}}">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </ng-container>
                    <ng-container
                      *ngIf="('ACT_WITHDRAW' | haveAction: (actionsets?.actions ?? []) | async) && tableController.invokeFunction('ACT_WITHDRAW', dataRow)">
                      <button (click)="cancelClaimRow(i)"
                              *ngIf="('ACT_WITHDRAW' | haveAction: (actionsets?.actions ?? []) | async)"
                              class="table-button"
                              color="primary" mat-icon-button matTooltip="{{'LABEL_CANCEL_CLAIM' | translate}}">
                        <mat-icon>backspace</mat-icon>
                      </button>
                    </ng-container>
                    <ng-container
                      *ngIf="('ACT_INNER_TABLE' | haveAction: (actionsets?.actions ?? []) | async) && tableController.invokeFunction('ACT_INNER_TABLE', dataRow)">
                      <button (click)="viewInnerTable(i)"
                              *ngIf="('ACT_INNER_TABLE' | haveAction: (actionsets?.actions ?? []) | async)"
                              class="table-button"
                              color="primary" mat-icon-button matTooltip="{{'LABEL_INNER_TABLE' | translate}}">
                        <mat-icon>{{ dataRow.isInnerTableVisible ? 'expand_less' : 'expand_more' }}</mat-icon>
                      </button>
                    </ng-container>

                  </ng-template>


                </span>
            </td>

          </tr>
<!--      DISPLAY INNER TABLE INSIDE TABLE ROW      -->
          <tr *ngIf="pageId=='PAG_MONITOR' && dataRow.isInnerTableVisible">
            <td [attr.colspan]="datasets['datas'].length" style="padding: unset" class="inner">
              <detail-data-table
                class="container-section"
                [apiParent]="apiParent"
                [apiUrl]="apiUrl + '/inner'"
                [pageId]="'INNER_'+ pageId"
                [monitorId]="dataRow.ID"
                [isInner]="true"
              ></detail-data-table>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>

<!--    </cdk-virtual-scroll-viewport>-->
  </div>
</div>

<br/>
