<ng-container *ngIf="isLoading; then initialLoading; else baseStuffLoaded"></ng-container>
<ng-template #initialLoading>
	<div class="spinner-class">
		<ng-template #initialSpinner>
			<div class="spinner-class">
			</div>
		</ng-template>

		<ngx-loading
			[show]="true"
			[config]="{ backdropBorderRadius: '3px' }"
			[template]="initialSpinner"
		>
		</ngx-loading>
	</div>
</ng-template>


<ng-template #baseStuffLoaded>
	<app-topbar (onClickLogin)="login()" (onClickLogout)="logout()" (toggleSidenav)="appDrawer.toggle()"></app-topbar>

	<mat-sidenav-container autosize class="sidenav-container">

		<mat-sidenav #appDrawer [mode]="'over'" fixedTopGap="56" [ngClass]="{ 'sidenav-testing': isDevMode(), sidenav: !isDevMode() }" [fixedInViewport]="mobileQuery.matches">
			<mat-nav-list *ngIf="!this.foundYes">
				<app-menu-list-item  *ngFor="let item of navItems" [item]="item"></app-menu-list-item>



			</mat-nav-list>
		</mat-sidenav>





		<mat-sidenav-content>
			<!--<div class="fixed-alert">
			  <ngb-alert type="custom" [dismissible]="false"><strong>Warning!</strong> DDT will be not available on 15th Feb from 5 PM till 6 PM CET for scheduled maintenance.</ngb-alert>
			</div>-->



			<div class="fixed-alert">
				<ng-container *ngFor="let alert of (allertService.allerts$ | async)">
					<ngb-alert  [dismissible]="false" type={{alert.priority}}> {{alert.text}}</ngb-alert>
				</ng-container>

			</div>


			<div class="body-content-container">
				<router-outlet></router-outlet>
			</div>

			<custom-alert></custom-alert>

			<!--<ngx-spinner style="color: white" bdColor="rgba(0, 0, 0, 0.95)" size="medium" color="#fff" type="square-loader"
			  [fullScreen]="true" [name]="'layout'">
			</ngx-spinner>



			<ngx-spinner style="color: white" bdColor="rgba(0, 0, 0, 0.95)" size="medium" color="#fff" type="square-loader"
						 [fullScreen]="true" [name]="'data'">
			  <p style="font-size: 1.5rem; color: white" *ngIf="spinner.spinnerTitles.length > 0">Loading {{ spinner.spinnerTitles[0] }}</p>
			</ngx-spinner>-->

			<ng-template #customLoadingTemplate>
				<div class="spinner-class">
					<p style="font-size: 1.5rem; color: white">{{ spinner.spinnerTitles.length > 0 ? spinner.spinnerTitles[0] : '...' }}</p>
				</div>
			</ng-template>
			<ngx-loading
				[show]="spinner.loading"
				[config]="{ backdropBorderRadius: '3px' }"
				[template]="customLoadingTemplate"
			>
			</ngx-loading>

		</mat-sidenav-content>

	</mat-sidenav-container>

</ng-template>
