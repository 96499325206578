import {Pipe, PipeTransform} from '@angular/core';
import {Observable, of} from "rxjs";
import {isEqual} from 'lodash';

@Pipe({
  name: 'isEdited'
})
export class CompareEditingPipe implements PipeTransform {

  transform(value: any, compare: any): Observable<boolean> {
    console.log(value, compare, !isEqual(value, compare))
    return of(!isEqual(value, compare));
  }

}
