import {Pipe, PipeTransform} from '@angular/core';
import {Observable, of} from "rxjs";
import {Action} from "../models/layout";

@Pipe({
  name: 'haveOneAction',
  pure: false
})
export class HaveOneActionPipe implements PipeTransform {

  transform(actions: Action[] | undefined, checks: string[]): boolean {
    return actions?.some(action => checks.some(act => act == action.id)) ?? false;
  }

}
