import {Component, Inject, Input, OnDestroy, OnInit} from "@angular/core";
import {TableControllerService} from "../../../services/table-controller.service";
import {FilterControllerService} from "../../../services/filter-controller.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";


@Component({
  selector: "monitor-detail",
  templateUrl: "./monitor-detail.component.html",
  styleUrls: ["./monitor-detail.component.scss"],
  host: {'class': 'h-100'},
  providers: [TableControllerService, FilterControllerService],
})
export class MonitorDetailComponent implements OnDestroy, OnInit
{
  id: string;
  container: string;

  constructor(
    // retrieve dialog input values
    @Inject(MAT_DIALOG_DATA) public data: any
  )
  {
    this.id = this.data['monitorId'];
    this.container = this.data['container'];
  }

  ngOnDestroy(): void
  {

  }

  ngOnInit(): void
  {

  }

}
