import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {EventMessage, EventType} from '@azure/msal-browser';
import {filter} from 'rxjs';
import {LayoutServiceService} from '../../../services/layout.service';
import {ActionSet, DataSet, Layout} from '../../../models/layout';
import {SpinnerService} from "../../../services/spinner.service";

@Component({
  selector: 'app-dynamic-page',
  templateUrl: './dynamic-page.component.html',
  styleUrls: ['./dynamic-page.component.scss'],
})
export class DynamicPageComponent implements OnInit {
  pageLayouts: Layout[] = [];
  pageActionset: ActionSet[] = [];
  pageDataset: DataSet[] = [];

  titlePage: string = '';
  pageId: string = '';

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public router: Router,
    private route: ActivatedRoute,
    public layoutService: LayoutServiceService,
    private spinner: SpinnerService
  ) {
  }

  onFilter(event: any) {
    ////(event)
  }

  ngOnInit(): void {
    this.spinner.show()
    this.route.queryParams.subscribe((params) => {
      this.pageId = params['id'] ?? [];
      this.titlePage = params['title'] ?? '';

      const layoutsString = localStorage.getItem('layout');
      if (!!layoutsString) {
        this.spinner.show()
        const layouts = JSON.parse(layoutsString);
        this.spinner.hide()
        if (!!layouts && !!layouts[this.pageId]) {
          this.spinner.show()
          const pageLayout = layouts[this.pageId];
          if (
            Math.abs(
              (new Date(pageLayout['instant']).getTime() -
                new Date().getTime()) /
              60000
            ) < 360
          ) {
            //('recupero da memoria')
            this.pageLayouts = pageLayout['instance'];
            this.spinner.hide()
          } else {
            //('recupero da endpoint')
            this.spinner.hide()
            this.spinner.show()
            this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
              this.pageLayouts = res?.layouts ?? [];
              const layoutsString = localStorage.getItem('layout');
              if (!!layoutsString) {
                let layouts = JSON.parse(layoutsString);
                layouts[this.pageId] = {
                  instance: res?.layouts,
                  instant: new Date(),
                };
                localStorage.setItem('layout', JSON.stringify(layouts));
              } else {
                let layouts = {};
                layouts[this.pageId] = {
                  instance: res?.layouts,
                  instant: new Date(),
                };
                localStorage.setItem('layout', JSON.stringify(layouts));
              }
              ////(this.layout)
              this.spinner.hide();
              /* if(!!this.pageLayouts && this.pageLayouts.length > 0){
                 this.pageActionset = this.pagelayouts.actionset ?? [];
                 this.pageDataset = this.pagelayouts.dataset ?? [];
                 ////(this.pageActionset)
               }*/
            });
          }
        } else {
          this.spinner.show()
          this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
            this.pageLayouts = res?.layouts ?? [];
            const layoutsString = localStorage.getItem('layout');
            if (!!layoutsString) {
              let layouts = JSON.parse(layoutsString);
              layouts[this.pageId] = {
                instance: res?.layouts,
                instant: new Date(),
              };
              localStorage.setItem('layout', JSON.stringify(layouts));
            } else {
              let layouts = {};
              layouts[this.pageId] = {
                instance: res?.layouts,
                instant: new Date(),
              };
              localStorage.setItem('layout', JSON.stringify(layouts));
            }
            ////(this.layout)
            this.spinner.hide();
            /* if(!!this.pageLayouts && this.pageLayouts.length > 0){
               this.pageActionset = this.pagelayouts.actionset ?? [];
               this.pageDataset = this.pagelayouts.dataset ?? [];
               ////(this.pageActionset)
             }*/
          });
        }
      } else {
        this.spinner.show()
        this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
          this.pageLayouts = res?.layouts ?? [];
          const layoutsString = localStorage.getItem('layout');
          if (!!layoutsString) {
            let layouts = JSON.parse(layoutsString);
            layouts[this.pageId] = {
              instance: res?.layouts,
              instant: new Date(),
            };
            localStorage.setItem('layout', JSON.stringify(layouts));
          } else {
            let layouts = {};
            layouts[this.pageId] = {
              instance: res?.layouts,
              instant: new Date(),
            };
            localStorage.setItem('layout', JSON.stringify(layouts));
          }
          ////(this.layout)
          this.spinner.hide();
          /* if(!!this.pageLayouts && this.pageLayouts.length > 0){
             this.pageActionset = this.pagelayouts.actionset ?? [];
             this.pageDataset = this.pagelayouts.dataset ?? [];
             ////(this.pageActionset)
           }*/
        });
        this.spinner.hide()
      }

      this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
        this.pageLayouts = res?.layouts ?? [];
        ////(this.pageLayouts)
        /* if(!!this.pageLayouts && this.pageLayouts.length > 0){
               this.pageActionset = this.pagelayouts.actionset ?? [];
               this.pageDataset = this.pagelayouts.dataset ?? [];
               ////(this.pageActionset)
             }*/
      });
      this.spinner.hide();
    });
  }
}
