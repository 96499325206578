<div *ngIf="!filterModel.isHide" class="filter-container">
  <label class="fs-6 fw-bold filter-title">{{ filterModel.columnLabel | translate }}</label>
  <div class="filter-content">

    <div [ngSwitch]="filterModel.filterType" class="filter-type-container">
      <!-- DATE FILTER -->
      <div *ngSwitchCase="filterType.DATE">
        <custom-datepicker (keydown.enter)="onEnter($event)" (valueChange)="onFirstFilterChange($event)"
                           *ngIf="filterModel.filterSelectedOption.haveFirstParam" [(value)]="filterModel.firstValue">
        </custom-datepicker>
        <label *ngIf="filterModel.filterSelectedOption.haveSecondParam">AND</label>
        <custom-datepicker (keydown.enter)="onEnter($event)" *ngIf="filterModel.filterSelectedOption.haveSecondParam"
                           [(value)]="filterModel.secondValue">
        </custom-datepicker>
      </div>

      <!-- SELECTION FILTER -->
      <div *ngSwitchCase="filterType.SELECTION">
        <custom-select (defaultValueChange)="onFirstFilterChange($event)" (keydown.enter)="onEnter($event)"
                       *ngIf="filterModel.filterSelectedOption.haveFirstParam"
                       [defaultValue]="filterModel.firstValue"
                       [values]="filterModel.filterValues!"></custom-select>
        <label *ngIf="filterModel.filterSelectedOption.haveSecondParam" class="fs-7 text-center">AND</label>
        <custom-select (defaultValueChange)="onSecondFilterChange($event)" (keydown.enter)="onEnter($event)"
                       *ngIf="filterModel.filterSelectedOption.haveSecondParam"
                       [defaultValue]="filterModel.secondValue" [values]="filterModel.filterValues!">
        </custom-select>
      </div>

      <!-- MULTI SELECTION FILTER -->
      <div *ngSwitchCase="filterType.MULTI_SELECTION">
        <ng-container *ngIf="filterModel.isResultsetLinked else emptyValues">
          <multi-select (keydown.enter)="onEnter($event)"
                              (ngModelChange)="onFirstFilterChange($event)"
                              [(ngModel)]="filterModel.firstValue"
                              [disabled]="!(filterModel.filterSelectedOption.haveFirstParam ?? false) " [searchable]="true"
                              [extraClass]="'filter-multi-select'" [options]="(filterValues | async)[filterModel.columnName | cml2snk]">
          </multi-select>
          <label *ngIf="filterModel.filterSelectedOption.haveSecondParam" class="fs-7 text-center">AND</label>
          <custom-multiselect (keydown.enter)="onEnter($event)"
                              (ngModelChange)="onSecondFilterChange($event)"
                              *ngIf="filterModel.filterSelectedOption.haveSecondParam"
                              [(ngModel)]="filterModel.secondValue"
                              [options]="(filterValues | async)[filterModel.columnName | cml2snk]">
          </custom-multiselect>
        </ng-container>
        <ng-template #emptyValues>
          <custom-multiselect (keydown.enter)="onEnter($event)"
                              (ngModelChange)="onFirstFilterChange($event)" *ngIf="filterModel.filterSelectedOption.haveFirstParam"
                              [(ngModel)]="filterModel.firstValue"
                              [extraClass]="'likeText'" [options]="(filterModel.filterValues | getOptions | async) ?? []">
          </custom-multiselect>
          <label *ngIf="filterModel.filterSelectedOption.haveSecondParam" class="fs-7 text-center">AND</label>
          <custom-multiselect (keydown.enter)="onEnter($event)"
                              (ngModelChange)="onSecondFilterChange($event)"
                              *ngIf="filterModel.filterSelectedOption.haveSecondParam"
                              [(ngModel)]="filterModel.secondValue"
                              [options]="(filterModel.filterValues | getOptions | async) ?? []">
          </custom-multiselect>
        </ng-template>

      </div>

      <!-- ITOEM-5: added status filter-->
      <!-- STATUS FILTER -->
      <div *ngSwitchCase="filterType.STATUS">
        <custom-multiselect (keydown.enter)="onEnter($event)"
                            (ngModelChange)="onFirstFilterChange($event)"
                            [(ngModel)]="filterModel.firstValue"
                            [extraClass]="'likeText'" [options]="(filterModel.filterValues | getOptions | async) ?? []">
        </custom-multiselect>

      </div>

      <!-- TEXT e NUMBER FILTER -->
      <div *ngSwitchDefault>
        <custom-text-field (keydown.enter)="onEnter($event)" *ngIf="filterModel.filterSelectedOption.haveFirstParam"
                           [(value)]="filterModel.firstValue" [appearance]="'fill'"
                           [isNumber]="filterModel.filterType == filterType.NUMBER"
                           [placeholder]="'First value'"></custom-text-field>
        <label *ngIf="filterModel.filterSelectedOption.haveSecondParam">AND</label>
        <custom-text-field (keydown.enter)="onEnter($event)" *ngIf="filterModel.filterSelectedOption.haveSecondParam"
                           [appearance]="'fill'" [placeholder]="'Second value'"
                           [value]="filterModel.firstValue"></custom-text-field>
      </div>

    </div>
    <custom-select (defaultValueChange)="onChange($event)" [defaultValue]="filterModel.filterSelectedOption.id"
                   [extraClass]="'filterOption'" [preventIdChange]="false"
                   [values]="filterModel.lFilterOptions"></custom-select>

  </div>

</div>
