<ng-container *ngIf="header != null">

  <mat-panel-title class="title">
    {{ 'TITLE_MONITOR_DETAIL' | translate }}
  </mat-panel-title>

  <div class="container">
    <div *ngFor="let field of fields" class="field-container">
      <ng-container [ngSwitch]="field.type">

        <ng-container *ngSwitchCase="'LIST'">
          <span class="ellipsis"> {{ field.label | translate }}</span>
          <custom-select [(defaultValue)]="header[field.id]"
                         [disabled]="field.option == null || field.option.length == 0 || isLoadingDetail"
                         [values]="field.option"
                         [searchable] = "true"
                         [extraClass] = "'filter-multi-select'">
          </custom-select>

        </ng-container>

        <ng-container *ngSwitchCase="'DATE'">
          <span class="ellipsis"> {{ field.label | translate }}</span>
          <custom-datepicker
            [(value)]="header[field.id]"
            [disabled]="isLoadingDetail">
          </custom-datepicker>
        </ng-container>

        <ng-container *ngSwitchCase="'TIME'">
          <span class="ellipsis"> {{ field.label | translate }}</span>
          <custom-timepicker
            [(value)]="header[field.id]"
            [disabled]="isLoadingDetail">
          </custom-timepicker>
        </ng-container>


        <ng-container *ngSwitchDefault>
          <span class="ellipsis"> {{ field.label | translate }}</span>
          <custom-text-field [(value)]="header[field.id]"
                             [appearance]="'fill'"
                             [placeholder]="field.label | translate "
                             [disabled]="isLoadingDetail"
                             [isNumber]="field.isNumber">


          </custom-text-field>
        </ng-container>

      </ng-container>

    </div>

  </div>
    <custom-btn color="primary" (click)="updateRecord()" [disabled]="isLoadingDetail">
      {{ 'LABEL_SAVE' | translate }}
    </custom-btn>

    <custom-btn color="primary" (click)="getMonitorHeader()" [disabled]="isLoadingDetail">
      {{ 'LABEL_CANCEL' | translate }}
    </custom-btn>
  <div>

  </div>
</ng-container>

