import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, from, mergeMap, Observable, of} from "rxjs";
import {environment} from 'src/environments/environment';
import {MsalService} from "@azure/msal-angular";

@Injectable()
export class AuthService {
  nameBS = new BehaviorSubject<string>('');
  nameObs = this.nameBS.asObservable();

  emailBS = new BehaviorSubject<string>('');
  emailObs = this.emailBS.asObservable();

  roleBS = new BehaviorSubject<string>('');
  roleObs = this.roleBS.asObservable();

  test: String = 'test';

  constructor(private http: HttpClient, private msalService: MsalService) {
  }

  headers = new HttpHeaders();

  getMenu() {
    return this.http.get<any>(environment.authApiUrl + 'layout/OEM', {});
  }

  getPages() {
    return this.http.get<any>(environment.authApiUrl + 'pages/OEM', {});
  }

  getUser() {
    return this.http.get<any>(environment.authApiUrl + 'users/OEM', {});
  }

  setFilter(filters: string) {
    return this.http.post<any>(environment.authApiUrl + 'users/filter/OEM', {filters});
  }

  getAvailableRole() {
    const params: HttpParams = new HttpParams()
    return this.http.get<any>(environment.authApiUrl + 'users/available-roles/OEM', {params});
  }

  setUserRole(
    role: string
  ): Observable<any[]> {
    const httpoptions: Object = {
      params: new HttpParams().set('role', role).set('app', 'OEM')
    };
    return this.http.post<any>(
      environment.authApiUrl + "masterdata/user/active-role",
      role,
      httpoptions
    );

  }

  refresh() {

    of(Object.keys(localStorage)
      .filter(key => !key.includes("appVersion"))
      .map(key => localStorage.removeItem(key))
    ).subscribe(res =>
      this.msalService.logoutRedirect()
    );

  }

  // FROM SRC OLD OLD
  // TODO TEST
  // CAMBIATO IL RECUPERO EMAIL
  getHeader(screen: string) {
    return from(this.getUser()).pipe(
      mergeMap(user => {
        const requestBody = {
          table: screen,
          //role: this._role,
          email: user.oContent.userEmail
        }
        return this.http.post<any[]>(environment.apiUrl + 'oemcc_view', requestBody, {});
      })
    );
  }

  //todo test
  // cambiato il recupero email
  getAction() {
    return from(this.getUser()).pipe(
      mergeMap(user => {
        const requestBody = {
          email: user.oContent.userEmail
        }
        return this.http.post<any[]>(environment.apiUrl + 'oemcc_actions', requestBody, {});
      })
    )
  }

  //todo testare
  // cambiato il recupero emal
  getHeaderImport(screen: string) {
    return from(this.getUser()).pipe(
      mergeMap(user => {
        const requestBody = {
          table: screen,
          email: user.oContent.userEmail
        }
        return this.http.post<any[]>(environment.apiUrl + 'oemcc_view_import', requestBody, {});
      })
    )
  }

  getUserList() {
    return this.http.get<any[]>(environment.apiUrl + 'oemcc_delegate/users');
  }

  getTableSetDetail(tableSet: string) {
    const params = new HttpParams().set('tableSet', tableSet);
    return this.http.get<any[]>(environment.apiUrl + 'oemcc_table_set_detail', { params });
  }

  getUsersToDelegate() {
    return this.http.get<any[]>(environment.apiUrl + 'oemcc_delegate/users');
  }

  newDelegate(delegation: {
    granteeUserId: string,
    expirationDate: string
  }) {
    return this.http.post<any>(environment.apiUrl + 'oemcc_delegate', delegation, {});
  }

  newAdminDelegate(delegation: {
    grantorUserId: string,
    granteeUserId: string,
    expirationDate: string
  }) {
    return this.http.post<any>(environment.apiUrl + 'oemcc_delegate/admin', delegation, {});
  }
}
