export interface LanguageModel {
  id: string,
  content: JSONObject
}

export type JSONValue =
  | string
  | number
  | boolean
  | JSONObject
  | JSONArray;

export interface JSONObject {
  [x: string]: JSONValue;
}

export interface JSONArray extends Array<JSONValue> {
}

export interface IJEDataTranslation {
  id: number;
  key: string;
  valid: boolean;
  error: string;
  languages: { [language: string]: string };
  edits: string[]
}

export enum IJEDataTranslationError {
  INVALID_KEY = 'The key is invalid.',
  KEY_NOT_EMPTY = 'The Key must be filled.',
  DUPLICATE_PATH = 'The key path is already defined.'
}

export interface IJEView {
  type: IJEViewType;
  selectionId?: number;
}

export enum IJEViewType {
  TABLE = 'table',
  LIST = 'list'
}

export interface IJEPage {
  pageNumber: number;
  pageSize: number;
  count?: number;
  totalPages?: number;
}

export interface IJESort {
  column: string;
  ascending: boolean;
}
