import {
	AfterViewChecked,
	AfterViewInit, ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	isDevMode,
	NgZone,
	ViewChild
} from '@angular/core';
import {BehaviorSubject, filter, forkJoin, interval, Observable, of, Subject, Subscription, takeUntil} from 'rxjs';
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService,} from '@azure/msal-angular';
import {InteractionStatus, RedirectRequest,} from '@azure/msal-browser';
import {Clipboard} from '@angular/cdk/clipboard';
import {AuthService} from './services/auth.service';
import {NavService} from './services/nav.service';
import {NavItem} from './models/NavItem';
import {MediaMatcher} from '@angular/cdk/layout';
import {NavigationService} from "./services/utils/navigation.service";
import {TranslateService} from "@ngx-translate/core";
import {GeneralService} from "./services/crud/general.service";
import {CheckForUpdateService} from "./services/check-for-update.service";
import {SpinnerService} from "./services/spinner.service";
import {cloneDeep} from "lodash";
import {catchError, map, tap} from "rxjs/operators";
import {AnnounceService} from "./services/announcements.service";
import {FormControl} from '@angular/forms';
import {HttpSpinnerInterceptorService} from './services/http-spinner.interceptor.service';

export interface Announce {
  id: string
  priority: string
  text: string
  message_goes_on: string
  message_goes_off: string
  block_users_starts: string
}

export interface ExtendedAnnounce {
  id: string
  priority: string
  text: string
  message_goes_on: string
  message_goes_off: string
  block_users_starts: string
  interval: Subscription
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewChecked {
  title = 'OEM';

  private readonly _destroying$ = new Subject<void>();

  _ShowTableSubject = new BehaviorSubject<string>('');
  ShowTableSubject = this._ShowTableSubject.asObservable();

  isLoggedIn: boolean = false;
  email: string = this.msalService.instance.getActiveAccount()?.username || '';

  role: String = '';

  appDrawer!: ElementRef;

  @ViewChild('appDrawer', {static: false}) set drawer(drawer: ElementRef) {
    if (drawer) {
      this.appDrawer = drawer;
    }
  }

  mobileQuery!: MediaQueryList;

  private _mobileQueryListener!: () => void;

  navItems: NavItem[] = [];

  allerts: ExtendedAnnounce[] = [];

  foundYes: boolean = false;

  isLoading: boolean = true;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private broadcastService: MsalBroadcastService,
    private authService: AuthService,
    private clipboard: Clipboard,
    private navService: NavService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private navigation: NavigationService,
    public translate: TranslateService,
    private httpService: GeneralService,
    private swUpdate: CheckForUpdateService,
    public spinner: SpinnerService,
    private ngZone: NgZone,
    public allertService: AnnounceService
  ) {
    this.swUpdate.checkVersion();
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.httpService.getAllertByValidity().subscribe(res => {


      this.allertService.replaceAllert(res?.oContent?.map(announce => {

        const self = cloneDeep({ // Store a reference to the object
          id: announce.id,
          priority: announce.priority,
          text: announce.text,
          message_goes_on: announce.message_goes_on,
          message_goes_off: announce.message_goes_off,
          block_users_starts: announce.block_users_starts,
          interval: interval(5000).pipe(tap(temp => {

            if (new Date(self.block_users_starts) <= new Date() && new Date(self.message_goes_off) > new Date() && self.block_users_starts !== null && (self.block_users_starts !== 'Thu Jan 01 1970 01:00:00 GMT+0100')) {

              self.priority = 'custom'; // Access object's properties using sel
              self.interval.unsubscribe();//
              this.pagesInit();
              // this.navigation.home()
            } else if (new Date(announce.message_goes_off) <= new Date()) {
              self.interval.unsubscribe();
              this.pagesInit();
              // this.navigation.home();
            } else {
              this.pagesInit();
              self.priority = 'custom';
            }

          })).subscribe()
        });
        return self;
      }) ?? []);

    });

  }

  /*updateToLatest(): void {
    console.log('Updating to latest version.');
    this.swUpdate.activateUpdate().then(() => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    });
  }*/

  ngOnInit(): void {
	this.isLoading = true;

	//Langauges stuff...
	const defualtLanguage = "en";
	//The follwings localStorage element are setted when the user change language
	let selectedLanguage:string = localStorage.getItem('selectedLanguage')?? defualtLanguage; //Id of the language
	this.translate.addLangs([selectedLanguage]);
	this.translate.defaultLang = defualtLanguage;
	this.translate.currentLang = selectedLanguage;

	const getTranslationsForDefault: Observable<any> = this.translate.getTranslation(defualtLanguage);
	const setCurrentLanguageAndGetTranslation = this.translate.getTranslation(selectedLanguage);
	const getAvailableLanguages =  this.httpService.getAvailableLanguages();
	forkJoin({
		  defaultTranslation: getTranslationsForDefault,
		  currentTranslation: setCurrentLanguageAndGetTranslation,
		  availableLanguages: getAvailableLanguages})
	.pipe(catchError((error: any, caught: any) =>
		  {
			  console.error("Error loading translation information: ", error);
			  return of(error);
		  }
	))
	.subscribe(res => {
		  this.translate.setTranslation(selectedLanguage, res.currentTranslation); //Current language
		  this.translate.setTranslation(defualtLanguage, res.defaultTranslation); //Default language
		  this.translate.addLangs(res.availableLanguages ?? ['en']); //Add the list of available langauges
		  this.isLoading = false;
	});

    localStorage.setItem('changes', 'false')
    this.broadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.isLoggedIn = this.msalService.instance.getAllAccounts().length > 0;


        if (this.isLoggedIn) {
          this.msalService.instance.setActiveAccount(
            this.msalService.instance.getAllAccounts()[0]
          );

          this.authService.nameBS.next(
            this.msalService.instance.getActiveAccount()?.name || ''
          );

          this.authService.emailBS.next(
            this.msalService.instance.getActiveAccount()?.username || ''
          );

          this.authService.getUser().subscribe(res => {

            if (!!(res['oContent'])['sFilterSettings']) {
              const filter = JSON.parse((res['oContent'])['sFilterSettings'])
              localStorage.setItem('filter', filter['filters'])
            }

          })


          this.pagesInit();
        }
      });

    /*this.httpService.getAllertByValidity().subscribe(res => {
      this.allerts = res?.oContent?.map(announce => (
        new ExtendedAnnounce(
          announce.id,
          announce.priority,
          announce.text,
          announce.message_goes_on,
          announce.message_goes_off,
          announce.block_users_starts,

          (id: string) => {
            this.allerts = this.allerts.filter(a => a.id !== id)
          })
      )) ?? []


    });*/


  }

	ngAfterViewChecked()
	{
		if(this.appDrawer){
			this.navService.appDrawer = this.appDrawer;
		}
	}

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  getToken(): string {
    return String(localStorage.getItem('token'));
  }

  copyToken(): void {
    this.ShowTableSubject.subscribe((token) => {
      this.clipboard.copy(token);
    });
  }

  login(): void {
    if (!!!this.msalService.instance.getActiveAccount()) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.msalService.loginRedirect();
      }
    } else {
      this._ShowTableSubject.next(
        <string>this.msalService.instance.getActiveAccount()?.idToken
      );

      localStorage.setItem(
        'token',
        <string>this.msalService.instance.getActiveAccount()?.idToken
      );
    }
  }

  logout() {
    this.msalService.instance.logout();
  }

  isDevMode() {
    return isDevMode();
  }

  private pagesInit() {
    this.authService.getMenu().subscribe((res) => {
      /*this.httpService.getAllertByValidity().subscribe(res => {
        this.allerts = res?.oContent?.map(announce => (
          new ExtendedAnnounce(
            announce.id,
            announce.priority,
            announce.text,
            announce.message_goes_on,
            announce.message_goes_off,
            announce.block_users_starts,

            (id: string) => {
              this.allerts = this.allerts.filter(a => a.id !== id)
            })
        )) ?? []


      });*/
      if (!!res) {
        this.navItems = res['oContent']['userMenus'];

        this.authService.roleBS.next(
          res['oContent']['userRoles'][0]['_sRoleId']
        );
      }
    });

    this.authService.getPages().subscribe(res => {
      /*this.httpService.getAllertByValidity().subscribe(res => {
        this.allerts = res?.oContent?.map(announce => (
          new ExtendedAnnounce(
            announce.id,
            announce.priority,
            announce.text,
            announce.message_goes_on,
            announce.message_goes_off,
            announce.block_users_starts,

            (id: string) => {
              this.allerts = this.allerts.filter(a => a.id !== id)
            })
        )) ?? []


      });*/
      this.navigation.setNavigationItems((res['oContent'])['pages']);
    })
  }
}
