<h1 mat-dialog-title>{{ ('' + data.dataTitle | translate) }}</h1>
<div mat-dialog-content>

  <div class="example-container">
    <h2>{{ 'FILTER_HIDDEN' | translate }}</h2>
    <div #hideList="cdkDropList" (cdkDropListDropped)="drop($event)" [cdkDropListConnectedTo]="[viewList]" [cdkDropListData]="data.hideList"
         cdkDropList class="example-list">
      <div *ngFor="let item of data.hideList; let i = index" cdkDrag class="example-box">
        {{ ('' + item.label) | translate }}
        <span (click)="transferArrayItem(i, 'view')" class="material-symbols-outlined pointer right">
          arrow_forward_ios
        </span>

      </div>
    </div>
  </div>
  <div class="example-container">
    <h2>{{ 'FILTER_VISIBLE' | translate }}</h2>
    <div #viewList="cdkDropList" (cdkDropListDropped)="drop($event)" [cdkDropListConnectedTo]="[hideList]" [cdkDropListData]="data.viewList"
         cdkDropList class="example-list">
      <div *ngFor="let item of data.viewList; let i = index" cdkDrag class="example-box">

        <span (click)="transferArrayItem(i, 'hide')" class="material-symbols-outlined pointer left">
          arrow_back_ios
        </span>
        {{ ('' + item.label) | translate }}
      </div>
    </div>
  </div>
</div>
<div align="end" mat-dialog-actions>
  <custom-btn (click)="onNoClick()" [color]="'basic'">{{ "ACT_CANCEL" | translate }}</custom-btn>
  <custom-btn [color]="'primary'" [mat-dialog-close]="{action: 'reset'}">{{ "FILTER_RESET" | translate }}</custom-btn>
  <custom-btn [color]="'accent'" [mat-dialog-close]="{hideList: data.hideList,
        viewList: data.viewList}">{{ "LABEL_OK" | translate }}
  </custom-btn>
</div>
