import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {interval} from "rxjs";


@Injectable()
export class CheckForUpdateService {

  constructor(private http: HttpClient) {
    console.log('Version controller launched')
    const everySixHours$ = interval(/*6 **/ 60 * 60 * 1000);

    everySixHours$.subscribe(isCheckTime => {
      console.log('Check version...')
      this.checkVersion();
    })
  }

  checkVersion() {
    const savedVersion = localStorage.getItem('appVersion');

    this.http.get<any>(`${window.location.origin}/oem/assets/version.json`, {responseType: 'text' as 'json'}).subscribe(
      (data) => {
        const deployedVersion = JSON.parse(data);
        if ((this.isVersionGreater(deployedVersion.version, savedVersion ? Number(savedVersion) : undefined))) {
          localStorage.clear();
          localStorage.setItem('appVersion', deployedVersion.version);
          Object.keys(sessionStorage).forEach(function (key) {
            if (!key.startsWith("sort")) {
              sessionStorage.removeItem(key)
            }

          });
          if (confirm('A new version of OEM Desktop find. If you don\'t refresh the site you could incurred in data issue. Do you want update OEM version?')) {
            window.location.reload();
          }
        }
      },
      (error) => {
        console.error('Error retrieving version information:', error);
      }
    );
  }

  isVersionGreater(v1: number, v2?: number): boolean {

    if (v2) {
      return v1 > v2;
    }
    return true;
  }
}
