import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {EventEmitter} from '@angular/core';
import {RegExpInterface} from "../../../models/RegExpInterface";
import {RegexParser} from "../../../utils/generic";

@Component({
  selector: "custom-text-field",
  templateUrl: "./custom-text-field.component.html",
  styleUrls: ["./custom-text-field.component.scss"],
})
export class CustomTextFieldComponent implements OnInit, AfterViewInit {
  @ViewChild('customInput') inputToCheck;

  @Input()
  value: any = "";

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter();

  @Input()
  onError?: boolean = false;

  @Output()
  onErrorChange: EventEmitter<any> = new EventEmitter();

  @Input()
  appearance!: MatFormFieldAppearance;

  @Input()
  label: String | undefined;

  @Input()
  placeholder: String | undefined;

  @Input()
  disabled: Boolean | undefined = false;

  @Input()
  isNumber: Boolean = false;

  @Input()
  isMandatory: Boolean = true;

  @Input()
  formControl;

  @Input()
  matAutocomplete;

  @Input()
  min = 0;

  @Input()
  minlength;

  @Input()
  maxlength;

  @Input()
  columnId = "";

  @Input()
  regexps: RegExpInterface[] = []

  @Input()
  pattern = '';

  @Input()
  mywidth = 0;


  constructor() {
  }

  ngOnInit(): void {
    if (!!this.inputToCheck) {
      this.onErrorChange.emit(!this.inputToCheck.nativeElement.validity.valid)
    }
  }

  ngAfterViewInit() {
    if (!!this.inputToCheck) {

      this.onErrorChange.emit(!this.inputToCheck.nativeElement.validity.valid)
    }
  }

  onValueChange($event: any) {
    if (this.isNumber) {
      this.valueChange.emit(+this.value);
    } else {
      this.valueChange.emit(this.value);
    }

  }

  onInputChange = (value) => {
    if (this.regexps.length > 0) {
      this.regexps.forEach(regExp => {
        value.target.value = value.target.value.replace(RegexParser(regExp.regExp), regExp.replaceValue)
      })

      /*this.regexps.forEach(regExp => {

        value.target.value = value.target.value.replace(RegexParser(regExp.regExp), regExp.replaceValue)
      })*/
    }
    this.onErrorChange.emit(!this.inputToCheck.nativeElement.validity.valid)

  }

}

