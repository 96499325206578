<div class="container">
  <monitor-header
    class="container-section"
    [id]="id">
  </monitor-header>

  <detail-data-table
    class="container-section"
    [apiParent]="'monitor'"
    [apiUrl]="'monitor-detail/monitor-detail'"
    [titlePage]="'TITLE_CONTAINER_DETAILS'"
    [pageId]="'PAG_MONITOR_DETAIL'"
    [monitorId]="id"
  ></detail-data-table>

  <monitor-detail-attachment
    [id]="id"
    [container] = "container"
    [apiParent]="'monitor'"
    [apiUrl]="'monitor-detail/monitor-attachment'"
    class="container-section">
  </monitor-detail-attachment>

  <detail-data-table
    class="container-section"
    [apiParent]="'monitor'"
    [apiUrl]="'monitor-detail/monitor-inv-party'"
    [titlePage]="'TITLE_INVOLVED_PARTY'"
    [pageId]="'PAG_MONITOR_INV_PARTY'"
    [monitorId]="id"
  ></detail-data-table>

  <detail-data-table
    class="container-section"
    [apiParent]="'monitor'"
    [apiUrl]="'monitor-detail/monitor-events'"
    [titlePage]="'TITLE_EVENTS'"
    [pageId]="'PAG_MONITOR_EVENT'"
    [monitorId]="id"
  ></detail-data-table>

  <detail-data-table
    class="container-section"
    [apiParent]="'monitor'"
    [apiUrl]="'monitor-detail/monitor-cost'"
    [titlePage]="'TITLE_COSTS'"
    [pageId]="'PAG_MONITOR_COST'"
    [monitorId]="id"
  ></detail-data-table>

  <div mat-dialog-actions align="end">
    <button mat-button color="primary" [mat-dialog-close]="false">{{"ACT_CLOSE" | translate}}</button>
  </div>

</div>

