import { ArrayDataSource, DataSource, ListRange, SelectionModel } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, Observable, of, startWith, Subscription } from 'rxjs';
import { MonitorService } from 'src/app/services/monitor.service';
import { AuditDialogComponent } from '../audit-dialog/audit-dialog.component';
import { PageEventType } from '../paginator/paginator.component';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AuthService } from 'src/app/services/auth.service';
import _ from "lodash";
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-static-data-table',
  templateUrl: './static-data-table.component.html',
  styleUrls: ['./static-data-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class StaticDataTableComponent implements OnInit {

  //@ViewChild(CdkVirtualScrollViewport, { static: true }) viewPort: CdkVirtualScrollViewport;

  ITEM_SIZE = 48;
  offset: number = 0;
  offsetObs: Observable<number>;
  offsetChange = new BehaviorSubject(0);

  @Input()
  length: number = 0

  @Input() isFilter: boolean = true;

  @Input()
  screen: string;

  @Input()
  isChanged: boolean;

  @Input()
  dataSource: Observable<any>;

  trueDataSource: ArrayDataSource<any>;

  //dataSourceTable: DataTableSource;

  dataTable: MatTableDataSource<{ [key: string]: any }> = new MatTableDataSource<{ [key: string]: any }>([]);

  @Input()
  headers: Observable<Header[]>;
  @Input()
  loadingTable: boolean = false;
  @Input()
  showMerge: boolean = false;

  @ViewChild(MatSort) sort: MatSort;


  @Output()
  onCheckboxClick: EventEmitter<{ info: boolean, warning: boolean, error: boolean }> = new EventEmitter<{ info: boolean, warning: boolean, error: boolean }>();
  @Output()
  onEditStart: EventEmitter<Header> = new EventEmitter<Header>();
  @Output()
  onEditCell: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditFinalDestinationCell: EventEmitter<{ col: any, element: any }> = new EventEmitter<{ col: any, element: any }>();
  @Output()
  onMergeClick: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output()
  onSortChange: EventEmitter<{ sorting: Map<string, { order: number, type: 'asc' | 'desc' }>; pageEvent: PageEventType }> = new EventEmitter<{ sorting: Map<string, { order: number, type: 'asc' | 'desc' }>; pageEvent: PageEventType }>();
  @Output()
  onPaginationChange: EventEmitter<PageEventType> = new EventEmitter<PageEventType>();
  @Output()
  onShowOpen: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onActionClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onReopenRecordsClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onCloseRecordsClick: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onDeleteRecordClick: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  searchForDeleting: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  searchForDuplicate: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onExpandEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onDetailChangeEmit: EventEmitter<any> = new EventEmitter<any>();

  displayedColumns: string[] = [];
  errors: CellError[] = [];
  @Input()
  pageEvent: PageEventType = { pageIndex: 0, previousPageIndex: 0, loader: 50, size: 50 };

  oldElement: any;

  @Input()
  numElementsLoaded: number = 0;

  @Input()
  numElementsSize: number = 0;

  @Input() public set dataSourceObservable(d: Observable<any[]>) {
    if (d !== null) {
      this.dataSource = d;
      d.subscribe(table => {
        this.dataTable.data = table;
        this.trueDataSource = new ArrayDataSource(table);
        //this.dataSourceTable.matTableDataSource.data = table;
        //this.viewPort.checkViewportSize();
      })
      /*d.subscribe((res: any[]) => {
        this.dataSource = res;
      });*/
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource']) {

      this.dataSource = changes['dataSource'].currentValue
      changes['dataSource'].currentValue.subscribe(table => {
        !!table ? this.dataTable.data = table : this.dataTable.data = [];
        !!table ? this.trueDataSource = new ArrayDataSource(table) : this.trueDataSource = new ArrayDataSource([]);
        //!!table ? this.dataSourceTable.matTableDataSource.data = table : this.dataSourceTable.matTableDataSource.data = []
      })
    }
  }

  @Input()
  sortedColumns: Map<string, { order: number, type: 'asc' | 'desc' }> = new Map();

  selection = new SelectionModel<any>(true, []);

  sortingList: Array<{ column: String; isAsc: boolean }> = [];

  isEnded: boolean = false;

  @Input()
  checkboxes: { info: boolean, warning: boolean, error: boolean };

  keyDownEvent: boolean = false;
  rendered: boolean = false;

  displayedHeaders: Header[] = [];

  permittedActions: String[] = [];

  subscriptionArray: Subscription[] = [];

  showDisabled: boolean = false;

  showOnlyDuplicate: boolean = true;

  expandedElement: any | null;

  constructor(public dialog: MatDialog,
    private monitorService: MonitorService,
    public authService: AuthService,
    private router: Router,
    private zone: NgZone,) {
    this.pageEvent = { pageIndex: 0, previousPageIndex: 0, loader: 50, size: 50 };

    //this.dataSourceTable = new DataTableSource();
  }

  ngOnInit(): void {

    this.headers.subscribe(headers => {

      this.displayedColumns = headers.map((header: Header) => header.field);
      this.displayedHeaders = headers;
    })

    this.subscriptionArray.push(this.authService.getAction().subscribe(actions => {
      this.permittedActions = actions['actions'];
      this.subscriptionArray.forEach(sub => sub.unsubscribe());
    }));
    /*this.dataSourceTable.attach(this.viewPort);
    this.viewPort.scrolledIndexChange
      .pipe(
        map(() => this.viewPort.getOffsetToRenderedContentStart() * -1),
        distinctUntilChanged(),
      )
      .subscribe(offset => {
        this.offset = offset
      });

    this.viewPort.renderedRangeStream.subscribe(range => {
      this.offset = range.start * (-this.ITEM_SIZE);
    });*/

    /*this.offsetObs = this.viewPort.renderedRangeStream.pipe(
      map(() => -(this.viewPort.getOffsetToRenderedContentStart()+56))
    );*/

    /*this.viewPort.elementScrolled().subscribe((ev:any) => {
      const start = Math.floor(ev.currentTarget.scrollTop / this.ITEM_SIZE);
      this.offset = this.ITEM_SIZE * (start);
      this.viewPort.setRenderedContentOffset(this.offset);
      this.offsetChange.next(this.offset);
    })*/
  }

  onPageChange(pageEvent: any) {
    //this.pageEvent = pageEvent;
    this.onPaginationChange.emit(pageEvent)
  }

  onAction(element: any, action: string) {
    this.onActionClick.emit({ element, action })
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      //sort direction
    }
  }

  onClickCell(element: any, col: Header) {
    if (!this.isAdding() && !element.newRow) {
      if (col.editable && element.editing !== col.field) {
        //Remove editing from any cell
        this.dataSource.subscribe(table => table.filter((el) => !!el.editing).forEach((el) => el.editing = null))

        //Set this cell in editing
        this.oldElement = { ...element };
        element.editing = col.field
        this.onEditStart.emit(col)
      } else if (col.editable && element.editing === col.field) {
        this.onCellExit(element, col);
      }
    }
    /*if (!this.isAdding() && !element.newRow) {
      if (col.editable && element.editing !== col.field && col.field !== 'rdcName') {
        //Remove editing from any cell
        this.dataSource.subscribe(table => table.filter((el) => !!el.editing).forEach((el) => el.editing = null))

        //Set this cell in editing
        element.editing = col.field

        this.onEditStart.emit(col)
      } else if (col.editable && element.editing === col.field && col.field !== 'rdcName') {
        this.onCellExit(element, col);
      } else if (col.editable && element.editing !== col.field && col.field === 'rdcName') {
        //Remove editing from any cell
        this.dataSource.subscribe(table => table.filter((el) => !!el.editing).forEach((el) => el.editing = null))

        this.onEditStart.emit(col)
      }
    }*/
  }

  onCellFocus($event: any) {
    if (!!$event && !!$event.target)
      $event.target.select()
  }

  onKeyUpCell(e: any, element: any, col: Header) {
    //On enter stops editing the cell
    if (e.key === 'Enter' || e.keyCode === 13) {
      element.editing = null
      this.onCellExit(element, col)
    }
    //On arrows moves the editing status
    /*else if (e.keyCode == '38') {
      // up arrow
      let currentElIndex = 0;
      this.dataSource.subscribe(table => currentElIndex = table.findIndex((el) => el === element))
      if (currentElIndex !== 0) {
        this.dataSource.subscribe(table => table[currentElIndex - 1].editing = element.editing);
        element.editing = null;
        this.onCellExit(element, col)
      }
    }
    else if (e.keyCode == '40') {
      // down arrow
      let currentElIndex = 0;
      this.dataSource.subscribe(table => currentElIndex = table.findIndex((el) => el === element))
      let dataSourceLength = 0;
      this.dataSource.subscribe(table => dataSourceLength = table.length)
      if (currentElIndex !== dataSourceLength - 1) {
        this.dataSource.subscribe(table => table[currentElIndex + 1].editing = element.editing);
        element.editing = null;
        this.onCellExit(element, col)
      }
    }
    else if (e.keyCode == '37') {
      // left arrow
      let currentCellIndex = this.headers.findIndex((column) => column === col)
      if (currentCellIndex !== 0) {
        element.editing = this.headers[currentCellIndex - 1].field;
        this.onCellExit(element, col)
      }
    }
    else if (e.keyCode == '39') {
      // right arrow
      let currentCellIndex = this.headers.findIndex((column) => column === col)
      if (currentCellIndex !== this.headers.length - 1) {
        element.editing = this.headers[currentCellIndex + 1].field;
        this.onCellExit(element, col)
      }
    }*/
  }

  validateCell(e: any, element: any, col: Header): boolean {
    let error: any = null
    if (col.validation) {
      if (col.validation.required && !e)
        error = "Field required"
      else if (col.validation.maxChar !== undefined && e.length > col.validation.maxChar)
        error = "Max characters " + col.validation.maxChar
      else if (col.validation.minChar !== undefined && e.length < col.validation.minChar)
        error = "Min characters " + col.validation.minChar
      else
        error = null
    } else
      error = null

    let cellError = this.errors.find((err) => err.elementId === element.id && err.headerId === col.id)
    if (!!cellError)
      cellError.message = error
    else if (error !== null)
      this.errors.push({ elementId: element.id, headerId: col.id, message: error })

    return !error;
  }

  onCellExit(element: any, col: Header) {
    if (col.field === 'portOfLoadingEtd' || col.field === 'portEta' || col.field === 'portEtaActual' || col.field === 'actualSlotDate') {
      element[col.field].value = this.dateFormatter(element[col.field].value)
    }
    if (!element.newRow) {
      this.onEditCell.emit({ record: element, oldRecord: this.oldElement, cel: col.field });
      element.editing = undefined;
    }
  }

  onDatepickerClose(element: any, col: Header, e: any) {
    if (e.substring(0, 1) !== '0') {
      this.onCellExit(element, col)
    }
  }

  hasError(elementId: number, colId: number) {
    return this.errors.find((err) => err.elementId === elementId && err.headerId === colId)
  }

  getErrorMessage(elementId: number, colId: number) {
    return this.errors.find((err) => err.elementId === elementId && err.headerId === colId)?.message
  }

  isAdding() {
    let isAdding = false;
    this.dataSource.subscribe(table => {
      table.some((item) => item.newRow) ?? false;
    })
    return isAdding;
  }

  checkCellError(errors: any[], field: string) {
    let classes: any = {};
    let errorType = undefined;

    errors?.some((error: any) => {
      if (error?.property === field) {
        classes.errorCell = true;
        return true;
      }
      return false;
    })
    return classes;
  }

  addBorder(element, field) {

    if (!!element.meta) {
      if (!!element.meta.remarks) {
        if ((!element.meta.remarks.every((remark) => (remark.column !== field) || remark.status !== 'ERROR') && !(field == 'destinationCid' || field == 'destinationCountry')))
          return 'error'
        else if (element.meta.remarks.every((remark) => remark.column === field && remark.status !== 'ERROR') && element.meta.remarks.every((remark) => remark.column === field && remark.status === 'INFO' && remark.action === 'AUTOCALC'))
          return 'info'
        else if (element.meta.remarks.every((remark) => remark.column === field && remark.status !== 'ERROR') && element.meta.remarks.every((remark) => remark.column === field && remark.status === 'WARNING' && remark.action === 'COMPARE'))
          return 'compare'
        else if (element.meta.remarks.every((remark) => remark.column === field && remark.status !== 'ERROR') && element.meta.remarks.some((remark) => remark.column === field && remark.status === 'WARNING'))
          return 'warning'
      }
    }
    return 'null';
  }

  addBorderRow(element, field) {
    if (!!element.meta) {
      if (!!element.meta.remarks) {
        if (element.meta.remarks.some((remark) => remark.column !== field && remark.action === 'COMPARE'))
          return 'compareRow'
        if (element.meta.remarks.some((remark) => remark.column !== field && remark.status === 'WARNING' && remark.action === 'FIND'))
          return 'newRow'
      }
    }
    return 'null';
  }

  logspaceFunction() {
    //return logspace(25, this.length, 2);
    return [50, 100, 250];
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    let numRows = -1;
    this.dataSource.subscribe(table => numRows = table.length);
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(evt: any) {
    if (evt.checked) {
      this.dataSource.subscribe(table => {
        this.selection.select(...table);
      })
      //this.selection.select(...this.dataSource);
      return;
    } else {
      this.selection.clear();
      return;
    }
  }


  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row}`;
  }

  isCheckboxDisabled(row: any) {
    return false;
  }


  sortColumn(name: string) {
    let poolMap: Map<string, { order: number, type: 'asc' | 'desc' }> = new Map();
    this.sortedColumns.forEach((value, key, map) => {
      poolMap.set(key, value);
    });
    if (!!this.sortedColumns.get(name) && (this.sortedColumns.get(name).type == 'asc' || this.sortedColumns.get(name).type == 'desc')) {
      poolMap.delete(name);
      if (this.sortedColumns.get(name).type == 'asc') {
        const order = this.sortedColumns.get(name).order;
        this.sortedColumns = new Map();
        this.sortedColumns.set(name, { order: order, type: 'desc' });
        poolMap.forEach((value, key, map) => {
          this.sortedColumns.set(key, value);
        })
      } else {
        const order = this.sortedColumns.get(name).order;
        this.sortedColumns.delete(name);
        this.sortedColumns.forEach((value, key, map) => {
          this.sortedColumns.set(key, { order: value.order > order ? value.order - 1 : value.order, type: value.type });
        })
      }
    } else {
      if (!(!!this.sortedColumns.get(name))) {
        let maxIndex = 0;
        this.sortedColumns.forEach((value, key, map) => {
          maxIndex = maxIndex < value.order ? value.order : maxIndex;
        })
        this.sortedColumns.set(name, { order: maxIndex + 1, type: 'asc' });
      }
    }
    this.onSortChange.emit({ sorting: this.sortedColumns, pageEvent: this.pageEvent });
  }

  mergeToMonitor() {
    let selected = new Array();
    this.selection.selected.forEach((el) => {
      selected.push(Object.assign({}, el));
    })
    this.onMergeClick.emit(selected);
    this.selection = new SelectionModel<Element>(true, []);
  }

  emitCheck(evt: any) {
    /*if (!this.checkboxes.info && !this.checkboxes.warning && !this.checkboxes.error) {
      this.checkboxes = {
        info: true,
        warning: true,
        error: true
      }
      this.checkboxes[evt.source.name] = false;
    }*/
    this.onCheckboxClick.emit(this.checkboxes)
  }

  getCellToolTip(element: any, field: any) {

    if (field !== 'actions' && field !== 'select' && !!element.errorList && element.errorList.lenght > 0) {
      let remarksList: string[] = element.errorList.map(remark => {
        if (remark.column === field) {
          let nameColumn
          this.headers.subscribe(headers => {
            nameColumn = headers.find(column => column.field == remark.column).label;
          })
          if (remark.action == 'AUTOCALC') {
            return nameColumn + " has been self-calculated";
          } else {
            if (remark.column === 'rdcName') {
              return 'Final Destination, CID are not mapped to Master Data'
            } else if (remark.column === 'container') {
              if (remark.action === 'VALIDATE') {
                return remark.notice
              } else if (remark.action === 'COMPARE') {
                return 'Previus value: ' + remark.notice
              }

            } else {
              if (remark.column !== 'destinationCountry' && remark.column !== 'destinationCid')
                if (!!remark.notice && remark.notice.includes(remark.column))
                  return remark.notice.replace(remark.column, nameColumn);
                else if (!!remark.notice)
                  return nameColumn + " " + remark.notice;
            }
          }
        }
      });
      return remarksList.join(' \n ');
    }
    return null;
  }

  typeof(x: any) {
    return typeof x;
  }

  checkInfo = (value) => !!value && (value.status == 'INFO');
  checkWarning = (value) => !!value && (value.status != 'ERROR');
  someWarning = (value) => !!value && (value.status == 'WARNING');
  checkError = (value) => !!value && (value.status == 'ERROR');
  someError = (value) => !!value && (value.status == 'ERROR');

  filterInfoCounter = (obj) => !(!!obj.meta) || !(!!obj.meta.remarks) || obj.meta.remarks.every(remark => remark.status == 'INFO')

  reduceInfoCounter = (counter, obj) => {
    if (!(!!obj.meta) || !(!!obj.meta.remarks) || obj.meta.remarks.some(remark => remark.status == 'INFO')) counter += 1
    return counter;
  }

  reduceWarningCounter = (counter, obj) => {
    if (!!(obj.meta) && !!(obj.meta.remarks) && obj.meta.remarks.some(remark => remark.status == 'WARNING')) counter += 1
    return counter;
  }

  reduceErrorCounter = (counter, obj) => {
    if (!!(obj.meta) && !!(obj.meta.remarks) && obj.meta.remarks.some(remark => remark.status == 'ERROR')) counter += 1
    return counter;
  }

  dateFormatter(stringDate: any) {
    if (!!stringDate && typeof stringDate === 'string' && stringDate.includes('-')) {
      stringDate = stringDate.substring(8, 10) + "/" + stringDate.substring(5, 7) + "/" + stringDate.substring(0, 4);
    }
    return stringDate;
  }

  isDuplicated(element: any) {
    if (!!element && !!element.meta && !!element.meta.remarks) {
      const remarks = element.meta.remarks;
      if (!!remarks.find(remark => remark.column === 'container')) {
        return true;
      }
    }
    return false;
  }


  dateInit(date: any, id: any) {
    if (!!date) {
      date = date.toString();
      id = id.toString();
      if (date.includes('/')) {
        date = date.substring(6, 10) + '-' + date.substring(3, 5) + '-' + date.substring(0, 2);
        if ((<HTMLInputElement>document.getElementById(id)).value != null) {
          (<HTMLInputElement>document.getElementById(id)).value = date;
        }
      }
    }
  }

  timeInit(date: string, id: string) {

    /*if(date.includes('/')) {
      date = date.substring(6, 10) + '-' + date.substring(3, 5) + '-' + date.substring(0, 2);
      if ((<HTMLInputElement>document.getElementById(id)).value != null) {
        (<HTMLInputElement>document.getElementById(id)).value= date;
      }
    }*/
  }

  reopenRecords() {
    if (this.screen == 'preview-su' || this.screen == 'monitor-su') {
      const ids = this.selection.selected.filter(el => el.status.value != 'NEW').map(el => { return el.id.value });
      this.onReopenRecordsClick.emit(ids);
      this.selection.clear();
    } else {
      const ids = this.selection.selected.map(el => el.monitor_ID.value);
      this.onReopenRecordsClick.emit(ids);
      this.selection.clear();
    }
  }

  /*closeRecords() {
    const ids = this.selection.selected.map(el => el.monitor_ID.value);
    this.onCloseRecordsClick.emit(ids);
    this.selection.clear();
  }*/

  showAudit(recordId: number) {
    this.dialog.open(AuditDialogComponent, {
      data: {
        title: "Audit details",
        info: recordId,
        closeButtonLabel: "Close",
      }
    });
  }

  isActionPresent(action: string) {
    if (this.permittedActions.indexOf(action) > -1)
      return true;
    else
      return false
  }

  resetSorting(event) {
    event.stopPropagation();

    this.sortedColumns = new Map();
    this.onSortChange.emit({ sorting: this.sortedColumns, pageEvent: this.pageEvent });
  }

  deleteSelection() {
    const ids = this.selection.selected.map(el => el.id.value);
    this.onDeleteRecordClick.emit(_.cloneDeep(ids));
    this.selection.clear();
  }

  deleteRecord(id: any) {
    this.onDeleteRecordClick.emit([id]);
    this.selection.clear();
  }

  onToggleChange(event: MatSlideToggleChange) {
    console.log(event);
    this.searchForDeleting.emit(event.checked);
  }

  checkFor(status: string) {
    return this.selection.selected.some(el => el.status.value == status)
  }

  checkForNot(status: string) {
    return this.selection.selected.some(el => el.status.value != status)
  }

  onToggleChangeDuplicate(event: MatSlideToggleChange) {
    this.searchForDuplicate.emit(event.checked);
  }

  showDetail(id: string, container: string) {
    this.zone.run(() => {
      this.router.navigate(['/monitor/container'], { queryParams: { id: id, number: container } });
    });
  }

  openNotificationHistory(id: string, container: string) {
    this.zone.run(() => {
      this.router.navigate(['/notification/history'], { queryParams: { id: id, number: container } });
    });
  }

  onExpandClick(row: any) {
    this.expandedElement = this.expandedElement === row ? null : row;

    this.onExpandEmitter.emit(row.id.value);
  }

  onDetailChange(row: any) {
    this.onDetailChangeEmit.emit(row);
    this.expandedElement = row;
  }

}

export interface Header {
  id: number,
  label: string,
  field: string,
  editable?: boolean,
  actions?: any[],
  validation?: CellValidation,
  cellTextClass?: string,
  cellClass?: string,
  headerClass?: string,
  editType?: string,
  selectOptions?: any[],
  fix?: boolean
}

export interface CellValidation {
  required?: boolean;
  type?: "text" | "number" | "date"
  maxChar?: number;
  minChar?: number;
}

export interface CellError {
  elementId: number,
  headerId: number,
  message?: string,
}
