<button *ngIf="!(!!iconButton) || !iconButton" [disabled]="disabled" class="normal-btn" class="btn-m" color="{{color!='basic'?color:''}}"
        mat-raised-button style="margin: 0.5rem;
  padding: 0;" type="button">
  <ng-content select="[position=left]"></ng-content>
  <label [ngClass]="color==='primary' || color==='warn' ?  'white-label' : ''">

    <ng-content></ng-content>

  </label>
  <ng-content select="[position=right]"></ng-content>
</button>

<button *ngIf="!!iconButton" class="icon-btn" color="primary" mat-icon-button>
  <mat-icon [style.color]="iconColor != null ? iconColor : ''">{{ iconClasses }}</mat-icon>
  <div class="divider"></div>
</button>
