import { Component, Input } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";
import { interval, Observable, Subject, Subscription } from "rxjs";
//import { Logger } from "src/app/services/logger.service";


export enum AlertType {
  INFO,
  WARNING,
  DANGER,
  SUCCESS
}
export interface AlertInformation {
  color: ThemePalette,
  mode: ProgressSpinnerMode,
  title:string,
  description:string | null,
  type: AlertType,
  stop: boolean,
  value:number,
  sub: Subscription,
  timer$: Observable<number>,
  close: any
}
@Component({
  selector: 'alert-old-component',
  templateUrl: './alert-old.component.html',
  styleUrls: ['./alert-old.component.scss']
})
export class AlertOldComponent {

  alertType = AlertType;

  alertInformations: AlertInformation[];

  curSec: number = 0;

  // constructor(private _logger: Logger){
  //   this.alertInformations = []
  // }

  /**
   *
   * @name viewMessage
   * @param {AlertType} type Type of notification (INFO, WARNING, DANGER, SUCCESS)
   * @param {string} title Title of notification
   * @param {String} description Optional description of notification
   * @param {number} timeout Number of seconds before the notification desappears. If type==DANGER or timeout ommitted the notification doesn't disappear automatically
   * @returns
   */
  public viewMessage(type:AlertType, title:string, description?: string, timeout?:number){

    let alertInfo: AlertInformation = {
      color: 'primary',
      mode: 'determinate',
      title: title,
      description: description,
      type: type,
      stop: !(!!timeout) || type === AlertType.DANGER,
      value: 100,
      timer$: interval(100),
      sub: null,
      close: null
    }

    alertInfo.close = () => {
      alertInfo.sub.unsubscribe();
      if(alertInfo.stop){
        this.alertInformations = this.alertInformations.filter(al => al.type !== alertInfo.type || al.title !== alertInfo.title);
      }else{
        alertInfo.stop = true;
      }
    }

    alertInfo.sub = alertInfo.timer$.subscribe((sec) => {
      if(alertInfo.stop === true){
        alertInfo.sub.unsubscribe();
      }else{
        alertInfo.value = (100 - sec*10 / timeout)
        this.curSec = sec;
        if (this.curSec === timeout*10) {
          alertInfo.stop = true;
          alertInfo.close();
          //this.alertInformations = this.alertInformations.filter(al => al.type !== alertInfo.type || al.title !== alertInfo.title);
          //alertInfo.sub.unsubscribe();
        }
      }
    });



    if(this.alertInformations.length > 0){
      const res = this.alertInformations.findIndex(alertElem => alertElem.title===title && alertElem.type === type);
      if(!!res && res > -1){
        return;
        //this._logger.log("There is another error", LoggerType.INFO);
      }
    }
    this.alertInformations.push(alertInfo);
  }

  public closeAllAlerts(){
    this.alertInformations.forEach(a => {

      a.stop = true;
      a.close();
    })
  }

}
