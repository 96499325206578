import {Pipe, PipeTransform} from '@angular/core';
import {Observable, of} from "rxjs";

@Pipe({
  name: 'sortArray',
  pure: false
})
export class SortArrayPipe implements PipeTransform {

  transform(arrayToSort: any[], propertyToSort: string, type: 'DESC' | 'ASC'): any[] {
    try {
      return arrayToSort.sort((a: any, b: any) => {
        return type == 'DESC' ? (!!a[propertyToSort] ? 1 : 0) : (!!a[propertyToSort] ? 0 : 1);
      });
    } catch (e) {
      return arrayToSort;
    }

  }

}
