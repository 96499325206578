import {Component, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from "../../../services/auth.service";
import {SpinnerService} from "../../../services/spinner.service";


@Component({
  selector: 'app-bad-request',
  templateUrl: './bad-request.component.html',
  styleUrls: ['./bad-request.component.scss']
})
export class BadRequestComponent implements OnInit {
  widthValue: number = 200;

  heightValue: number = 200;

  constructor(
    protected router: Router,
    private authService: AuthService,
    private spinner: SpinnerService,
  ) {
  }

  ngOnInit(): void {
    this.spinner.hideAll();
    this.widthValue = +document.documentElement.style.getPropertyValue('--win-width')
    this.heightValue = +document.documentElement.style.getPropertyValue('--win-height')
  }

  refreshAll() {
    this.authService.refresh();
    this.router.navigateByUrl('/');
  }
}


