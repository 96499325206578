<div class="container">
  <custom-alert [containerClass]="'alert-container-in-modal'"></custom-alert>
  <mat-panel-title class="title">
    {{ 'TITLE_IMPORT_PREVIEW' | translate }}
  </mat-panel-title>
  <div class="row">
    <div class="spacer"></div>
    <custom-btn color="primary" style="width: unset" (click)="save()" [disabled]="isSaving">
      {{ 'LABEL_SAVE' | translate }}
    </custom-btn>
  </div>
  <div [ngClass]="{ 'table-container': true, 'disabled': isSaving }">
    <table class="table">
      <!-- Header -->
      <thead>
      <tr>
        <th *ngFor="let col of columns">{{ col.label }}</th>
      </tr>
      </thead>

      <!-- Rows -->
      <tbody>
      <tr *ngFor="let row of rows">
        <td class="cell-auto" *ngFor="let col of columns" [ngClass]="getCellInfo(col.column, row).class" matTooltip="{{getCellInfo(col.column, row).tooltips}}">{{ row[col.column] }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">{{'ACT_CLOSE' | translate}}</button>
  </div>
</div>
