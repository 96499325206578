<div class="form-group custom-select-container">
  <label *ngIf="!!title" class="fs-6 fw-bold filter-title">{{ title }}</label>
  <table class="table table-striped ">
    <thead>
    <tr>

      <th style="width: 30px">
        <span></span>
      </th>

      <th style="width: 30px">
        <span></span>
      </th>

      <!--<th style="width: 30px">
        <span></span>
      </th>-->

      <th>
        <span></span>
      </th>

    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let option of options; let i=index">
      <td>
        <mat-checkbox (change)="$event ? select(option['ID']) : null"
                      (click)="$event.stopPropagation()"
                      [aria-label]="option['SELECTED']"
                      [checked]="option['SELECTED']" [disabled]="disable">
        </mat-checkbox>
      </td>
      <td *ngIf="option['EXPIRED'] && !option['TOO_NEW']"><span
        [matTooltip]="option?.errors['id']?.error_list?.join(';')"><mat-icon
        style="color: orange !important;">warning</mat-icon></span></td>
      <td *ngIf="option['TOO_NEW'] && !option['EXPIRED']"><span
        [matTooltip]="option?.errors['id']?.error_list?.join(';')"><mat-icon
        style="color: dodgerblue !important;">info</mat-icon></span></td>
      <td *ngIf="option['TOO_NEW'] && option['EXPIRED']"><span
        [matTooltip]="option?.errors['id']?.error_list?.join(';')"><mat-icon
        style="color: orangered !important;">info</mat-icon></span></td>
      <td *ngIf="!option['EXPIRED'] && !option['TOO_NEW']">
        <span></span>
      </td>
      <td>
        <span>{{ option['LABEL'] }}</span>
      </td>
    </tr>
    </tbody>
  </table>
</div>
