import {Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatSelect, MatSelectChange} from "@angular/material/select";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {CustomMultiselectComponent} from "../custom-multiselect/custom-multiselect.component";

const MULTISELECT_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => MultiSelectComponent),
	multi: true,
};

@Component({
  selector: 'multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers:[MULTISELECT_VALUE_ACCESSOR]
})
export class MultiSelectComponent implements OnChanges,ControlValueAccessor {
	@Input()
	label: String | undefined;

	@Input()
	placeholder: String | undefined;

	@Input()
	options?: any[];

	@Input()
	value: string = '';

	@Input()
	disabled: boolean | undefined = false;

	@Output()
	onSelectionChange: EventEmitter<string> = new EventEmitter<string>()

	@Input()
	extraClass: string = '';

	@Input()
	searchable: boolean = false;

	searchValue: string = ""; //The ID rendered are filtered by this value

	filterOptions: any[] | undefined; //Contains the filter options on selector

	@ViewChild('selectElement') selectElement: MatSelect | undefined;
	@ViewChild('searchInput') searchInput: ElementRef | undefined;

	constructor()
	{
	}

	/**
	 * Responds to changes in the component's input properties.
	 * Specifically, updates the `filterOptions` array when the `options` input changes.
	 *
	 * @param changes - An object of current and previous property values.
	 */
	ngOnChanges(changes: SimpleChanges)
	{
		if(changes["options"]?.currentValue){
			this.filterOptions = [...changes["options"].currentValue];
		}
	}

	/**
	 * Handles the selection change event from the MatSelect component.
	 * Invokes the registered onChange callback with the new selected value.
	 *
	 * @param event - The MatSelectChange event containing the new selection value.
	 */
	oncSelectionChange(event: MatSelectChange): void
	{
		let value = event.value;
		this.onChange(value);
	}

	/**
	 * Updates the `filterOptions` based on the search input value.
	 * If a search value is provided, filters the `options` array to include
	 * only those options whose `id` includes the search value.
	 * If no search value is provided, resets `searchValue` and sets
	 * `filterOptions` to the full `options` array.
	 *
	 * @param event - The search input value used to filter options.
	 */
	onSearchValueChange(event: any){
		if(event){
			this.filterOptions = this.filterOptions?.filter(option => option.id.toUpperCase().includes(event.toUpperCase()) || option.name.toUpperCase().includes(event.toUpperCase()));
		}else{
			this.searchValue = "";
			this.filterOptions = this.options;
		}
	}

	/**
	 * Resets the scroll position of the MatSelect panel to the top
	 * when the dropdown is opened.
	 *
	 * @param event - The event triggered when the dropdown is opened.
	 */
	openedChange(event: any) {
		let panel : HTMLDivElement = this.selectElement?.panel?.nativeElement as HTMLDivElement;
		if(panel){
			panel.scrollTop = 0;
		}

		this.searchInput?.nativeElement.focus();
	}

	onChange: (value: string) => void = () => {};
	onTouched: () => void = () => {};

	writeValue(value: string): void {
		this.value = value || '';
	}

	registerOnChange(fn: (value: string) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

}
