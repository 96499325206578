import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AlertService} from '../alert/alert.service';
import {GeneralService} from 'src/app/services/crud/general.service';
import {TableControllerService} from 'src/app/services/table-controller.service';
import {lastValueFrom} from 'rxjs';
import {ALERT_TYPE} from '../alert/alert.enumerate';
import * as saveAs from "file-saver";
import {Action} from "../../../models/layout";


export interface File {
  id: string,
  path: string,
  name: string,
  type: string,
  parent: string,
  category: string,
  url: string
}

@Component({
  selector: 'app-dialog-download',
  templateUrl: './dialog-download.component.html',
  styleUrls: ['./dialog-download.component.scss'],
  providers: [TableControllerService],
})
export class DialogDownloadComponent {
  docList: any[] = [];
  fileId: any = "";
  apiParent: string = "documents";

//////
  attachments: File[] = [];


/////

  constructor(
    public dialogRef: MatDialogRef<DialogDownloadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private generalService: GeneralService,
    public alertService: AlertService,
    public tableController: TableControllerService,
  ) {
    console.log(this.data)
    this.docList = data.content
    this.attachments = data.content
    this.fileId = data.content.LOADING_CARDS_ID;

    console.log(this.docList)
    console.log(this.attachments)

  }

  ngOnInit() {

  }

  haveTableAction(actions: Action[] | undefined) {

    return actions?.some(action => ['ACT_EDIT', 'ACT_SAVE', 'ACT_TO_PROBLEM', 'ACT_EDIT_PROBLEM'].some(act => act == action.id)) ?? false;
  }

  deletePicture(id: string) {

    lastValueFrom(this.generalService.deleteAttachment(id))
      .then((res) => {
        this.alertService.add({
          type: ALERT_TYPE.SUCCESS,
          message: "Delete document success",
          timeout: 5000,
          selfClose: null,
        });

        this.attachments = this.attachments.filter(f => f.id !== id)

      })
      .catch((err) => {
        this.alertService.add({
          type: ALERT_TYPE.WARNING,
          message: "Delete document was wrong",
          timeout: 5000,
          selfClose: null,
        });
      });
    window.location.reload;
  }

  /*
    lastValueFrom(
      this.generalService.deleteDocOrPhoto(id)
        )
        .then((blob) => {
          if (blob.size == 0) {
            this.alertService.add({
              type: ALERT_TYPE.WARNING,
              message:
                "There is no file associated with the loading card " + this.tableController.row["LOADING_CARDS_ID"],
              timeout: 5000,
              selfClose: null,
            });
            return;
          } else {
            //saveAs(blob, nome);
            this.alertService.add({
              type: ALERT_TYPE.SUCCESS,
              message: "Resource deleted successfully",
              timeout:5000,
              selfClose:null,

            }),
            window.location.reload();

        }
        })
        .catch((err) => {
          this.alertService.add({
            type: ALERT_TYPE.WARNING,
            message: "Something went wrong during the delete ",
            timeout: 5000,
            selfClose: null,

          });

        });*/

  downlaodFile(file: File) {
    saveAs(file.url, file.name)
  }

  download(nome: string, id: string) {

    console.log(nome)
    console.log(id)


    lastValueFrom(
      this.generalService.downloadCard("documents/cards" + "/download", id)
    )
      .then((blob) => {
        if (blob.size == 0) {
          this.alertService.add({
            type: ALERT_TYPE.WARNING,
            message:
              "There is no file associated with the loading card " + this.tableController.row["LOADING_CARDS_ID"],
            timeout: 5000,
            selfClose: null,
          });
          return;
        } else {
          saveAs(blob, nome);
        }
      })
      .catch((err) => {
        this.alertService.add({
          type: ALERT_TYPE.WARNING,
          message: "Something went wrong during the download ",
          timeout: 5000,
          selfClose: null,

        });

      });
  }
}





