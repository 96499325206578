<div class="form-group">
  <div class="input-group">
    <!-- Time picker -->
    <input type="time" class="form-control input-time" [(ngModel)]="value"
           (focusout)="onFocusOut()" (ngModelChange)="onTimeChange($event)"
           [readonly]="disabled" placeholder="HH:mm" />
    <button class="btn btn-outline-secondary bi bi-clock btn-picker"
            type="button" [disabled]="disabled">
      <i class="fa fa-clock"></i>
    </button>
  </div>
</div>
