import {Pipe, PipeTransform} from '@angular/core';
import {filter, Observable, of, take} from "rxjs";
import {map, first} from "rxjs/operators";

@Pipe({
  name: 'getListTooltipV2Classic',
  pure: false
})
export class GetListTooltipV2ClassicPipe implements PipeTransform {

  transform(optionValues: {
    [key: string]: any[]
  }, columnName: string, defaultValue: string, dataSetElementElement: any): string {

    return optionValues[columnName].filter(option => option.id === dataSetElementElement).map((element) => element.name).pop()

    /*return optionValues.filter(
      (option) => option.id == dataSetElementElement
    )[0]?.name ?? defaultValue;*/
  }

}
