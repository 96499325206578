import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'save-confirm',
  templateUrl: './save-confirm.component.html',
  styleUrls: ['./save-confirm.component.scss']
})
export class SaveConfirmComponent {

  @Output()
  onConfirm: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public dialogRef: MatDialogRef<SaveConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
  }

  onNoClick() {
    this.onConfirm.emit('no');
    this.dialogRef.close();
  }

  onConfirmClick() {
    this.onConfirm.emit('one');
    this.dialogRef.close();
  }

  onAllConfirmClick() {
    this.onConfirm.emit('all');
    this.dialogRef.close();
  }
}
