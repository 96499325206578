import {cloneDeep} from "lodash";

export const TABLE_STYLE = `
<style>
    table {
        border: 2px solid #1C6EA4 !important;
        background-color: #EEEEEE !important;
        width: 100% !important;
        text-align: left !important;
        font-family: Roboto, 'Helvetica Neue', sans-serif !important;
        overflow-x: auto !important;
        border-collapse: collapse;
    }

    table td,
    table th {
        border-left: 1px solid rgba(0, 0, 0, 0.05);
        padding: 3px 2px !important;
        max-width: 13rem;
    }

    td div {
        display: block;
        width: max-content !important;
    }

    table tbody td {
        font-size: 13px !important;
    }

    table thead {
        background: #1C6EA4 !important;
    }

    table thead th {
        font-size: 15px !important;
        font-weight: bold !important;
        color: #FFFFFF !important;
    }

    table thead th:first-child {
        border-left: none !important;
    }
</style>

`;

export const removeItemFromLocalstorage = (
  item: string,
  posStart?: number,
  posEnd?: number
) => {
  if (!!posStart && !!posEnd) {
    Object.entries(localStorage)
      .map((x) => x[0])
      .filter((x) =>
        !!posEnd && posEnd > posStart
          ? x.substring(posStart, posEnd) == item
          : x.substring(posStart) == item
      )
      .map((x) => localStorage.removeItem(x));
  } else {
    Object.entries(localStorage)
      .map((x) => x[0])
      .filter((x) => x.startsWith(item))
      .map((x) => {
        ////(x);
        localStorage.removeItem(x);
      });
  }
};

export const splitFilter = (item: string) => {
  if (item.includes(':')) {
    const fv = item.split(':');
    return {
      key: fv[0],
      value: fv[1],
      compare: ':',
    };
    //return item.split(':').push(':')
  } else if (item.includes('<')) {
    const fv = item.split('<');
    return {
      key: fv[0],
      value: fv[1],
      compare: '<',
    };
    //return item.split('<').push('<')
  } else if (item.includes('>')) {
    const fv = item.split('>');
    return {
      key: fv[0],
      value: fv[1],
      compare: '>',
    };
    //return item.split('>').push('>')
  } else if (item.includes('=')) {
    const fv = item.split('=');
    return {
      key: fv[0],
      value: fv[1],
      compare: '=',
    };
    //return item.split('=').push('=')
  } else if (item.includes('~')) {
    const fv = item.split('~');
    return {
      key: fv[0],
      value: fv[1],
      compare: '~',
    };
    //return item.split('~').push('~')
  } else if (item.includes('!')) {
    const fv = item.split('!');
    return {
      key: fv[0],
      value: fv[1],
      compare: '!',
    };
    //return item.split('!').push('!')
  } else if (item.includes('^')) {
    const fv = item.split('^');
    return {
      key: fv[0],
      value: fv[1].replace(/[\])}[{(]/g, ''),
      compare: '!',
    };
    //return item.split('!').push('!')
  }
  return null;
};

/*export const groupBy = (
  objectArray: any[],
  property: string,
  compareTo: string
) => {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    if (!acc[key].some((e) => e[compareTo] === obj[compareTo]))
      acc[key].push(obj);
    return acc;
  }, {});
};*/

export const reviver = (key, value) => {
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value);
    }
  }
  return value;
};

export const replacer = (key, value) => {
  if (value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries()), // or with spread: value: [...value]
    };
  } else {
    return value;
  }
};

export const refactorName = (columnName) => {
  const string = columnName
    .toLowerCase()
    .replace(/(^|_)./g, (s) => s.slice(-1).toUpperCase());
  return string.charAt(0).toLowerCase() + string.slice(1);
  //return columnName.toLowerCase().split('').map((value, index, array)=>index===0?value:(value.charAt(0).toUpperCase()+value.splice(1))).join('')
};

export const camelToSnakeCase = (str) => {
  return (str.split(/(?=[A-Z])/g).join('_')).toUpperCase();
};

export const keyExistsIn = (obj, key) => {
  //////(key, key in obj);
  return key in obj;
};

export const hasSubArray = (master: any[], sub: any[]) => {
  /*if (sub.every((i => v => i = master.indexOf(v, i) + 1)(0))) {
    return master;
  }
  return master.concat(sub);*/
  sub.forEach(elem => {
    const index = master.findIndex(e => e == elem);
    if (index !== -1) {
      master[index] = elem;
    } else {
      master.push(elem);
    }
  })
  return cloneDeep(master);

}

export const RegexParser = (input) => {

  // Validate input
  if (typeof input !== "string") {
    throw new Error("Invalid input. Input must be a string");
  }

  // Parse input
  var m = input.match(/(\/?)(.+)\1([a-z]*)/i);

  if (m == null) {
    throw new Error("No regexp found");
  }

  // Invalid flags
  if (m[3] && !/^(?!.*?(.).*?\1)[gmixXsuUAJ]+$/.test(m[3])) {
    return RegExp(input);
  }

  // Create the regular expression
  return new RegExp(m[2], m[3]);
};

export const isBefore = (date1, date2) => {

  let parts = date1?.split('/');

  if (!!date1) {
    if (!!parts && parts[1]?.length == 1) parts[1] = '0' + parts[1];
    if (!!parts && parts[0]?.length == 1) parts[0] = '0' + parts[0];

    date1 = Number(parts[2] + parts[1] + parts[0]);
  }

  if (!!date2) {
    parts = date2?.split('/');

    if (!!parts && parts[1]?.length == 1) parts[1] = '0' + parts[1];
    if (!!parts && parts[0]?.length == 1) parts[0] = '0' + parts[0];

    date2 = Number(parts[2] + parts[1] + parts[0]);
  }

  return date1 < date2;
}

export function css(element, property) {
  return window.getComputedStyle(element, null).getPropertyValue(property);
}

export function get_tex_size(txt, elTag) {
  const element = document.createElement('canvas');
  let context = element.getContext("2d");
  if (context) {
    context.font = css(document.getElementsByTagName(elTag)[0], 'font');
    return {'width': context.measureText(txt).width, 'height': parseInt(context.font)};
  }

  return {'width': 0, 'height': 0}

}


export function transformIdToValueFromList(column: any, defaultValue: string, dataSetElementElement: any): string {
  return column.optionValues.filter(
    (option) => option.id == dataSetElementElement
  )[0]?.name ?? defaultValue;
}
