import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-add-translation',
  templateUrl: './add-translation.component.html',
  styleUrls: ['./add-translation.component.scss']
})
export class AddTranslationComponent implements OnInit {

  translationKey: string = ''

  constructor(
    public dialogRef: MatDialogRef<AddTranslationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }


  onCancelClick() {
    this.dialogRef.close(false)
  }

  onSaveClick() {
    if (!!this.translationKey) {
      this.dialogRef.close({
        name: this.translationKey
      })
    }
  }

}
