import {Injectable} from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse, HttpHeaderResponse, HttpResponseBase
} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {map, filter, tap, finalize, catchError} from 'rxjs/operators';
import {MessagingService} from "./utils/messaging.service";

@Injectable()
export class HttpLoggerInterceptor implements HttpInterceptor {

  constructor(private messenger: MessagingService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();
    let response: {
      ok: string,
      request: any,
      response: any,
      header?: any
    }

    return next.handle(req).pipe(
      map(event => {
        if (event instanceof HttpResponse) {
          response = {
            ok: event.status + ' - ' + event.statusText,
            response: event,
            request: req
          }
          const elapsed = Date.now() - started;
          const msg = `${req.method} "${req.urlWithParams}"
             ${response.ok} in ${elapsed} ms.`;
          this.messenger.add(started, req.method, response.ok, req.urlWithParams, msg, response);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        const response = {
          ok: error.status + ' - ' + error.statusText,
          response: error,
          request: req
        }
        const elapsed = Date.now() - started;
        const msg = `${req.method} "${req.urlWithParams}"
                      ${response.ok} in ${elapsed} ms.`;
        this.messenger.add(started, req.method, response.ok, req.urlWithParams, msg, response);
        return throwError(() => error);
      })
    );
  }
}
