import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'custom-select-values',
  templateUrl: './custom-select-values.component.html',
  styleUrls: ['./custom-select-values.component.scss']
})
export class CustomSelectValuesComponent implements OnInit {

  @Input()
  options: any[] = [];

  @Input()
  title?: string;

  @Output()
  optionsChange: EventEmitter<any[]> = new EventEmitter();

  @Input()
  disable: boolean = false;


  constructor() {
  }

  ngOnInit(): void {
  }

  select(id) {
    if (!this.disable) {
      this.options = this.options.map(opt => {
        if (opt['ID'] == id) {
          opt['SELECTED'] = !opt['SELECTED'];
        }
        return opt;
      })
    }

  }

}
