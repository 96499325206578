<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content>
  <p>{{ data.content | translate }}</p>
  <p *ngIf="data.content2 != undefined">{{ data.content2 | translate }}</p>
</div>
<div [class.justify-content-end]="!!!data.cancelButtonLabel" class="buttons-container" mat-dialog-actions>
  <button *ngIf="!!data.cancelButtonLabel" [color]="data.cancelButtonColor ?? 'primary'" [mat-dialog-close]="false"
          class="dialog-button" mat-raised-button><span
    [class.text-white]="data.cancelButtonColor != 'basic' && data.cancelButtonColor != 'accent'">{{ data.cancelButtonLabel }}</span>
  </button>
  <button *ngIf="!!data.confirmButtonLabel" [color]="data.confirmButtonColor ?? 'primary'" [mat-dialog-close]="true"
          class="dialog-button" mat-raised-button><span
    [class.text-white]="data.confirmButtonColor != 'basic' && data.confirmButtonColor != 'accent'">{{ data.confirmButtonLabel }}</span>
  </button>
</div>
