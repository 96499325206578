<div class="container">
  <pre><code [highlight]="obsCode" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
  <div class="card-container">
    <button (click)="onSubClick()" class="card card-small" tabindex="0">
      <span>{{ buttonString }} Observable</span>
    </button>
  </div>

  <div class="terminal">
  <pre>
        Output: {{ randomNumber }}
  </pre>
  </div>
</div>

