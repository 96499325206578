import {Pipe, PipeTransform} from '@angular/core';
import {refactorName} from "../utils/generic";

@Pipe({
  name: 'refactorName'
})
export class RefactorNamePipe implements PipeTransform {

  transform(value: string | String, ...args: unknown[]): String {
    return refactorName(value);
  }

}
