import {Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'custom-btn',
  templateUrl: './custom-btn.component.html',
  styleUrls: ['./custom-btn.component.scss'],
})
export class CustomBtnComponent implements OnInit {
  @Input()
  color?: String | string;

  @Input()
  iconColor?: String;

  @Input()
  iconButton?: boolean;
  @Input()
  btnClasses?: String;
  @Input()
  iconClasses?: String;
  @Input()
  fontSize?: String;

  @Input()
  matTooltip: string = '';

  @Input()
  disabled: Boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  @HostBinding('class')
  get hostClass(): string {
    return this.disabled ? 'disabled' : 'enabled';
  }


}
