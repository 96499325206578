<a
  (click)="onItemSelected(item)"
  *ngIf="!!!item.hidden && item.displayName"
  [ngClass]="{ 'selected-route': navService.selectedItem == item }"
  [ngStyle]="{ 'padding-left': depth * 12 + 'px'}"
  class="menu-list-item"
  mat-list-item
>
  <mat-icon
    [ngClass]="{ 'elx-blue': item.children && item.children.length }"
    class="routeIcon"
  >{{ item.iconName }}
  </mat-icon>
  <span [ngClass]="{ 'elx-blue': item.children && item.children.length }">{{
      item.displayName
    }}</span>
  <span *ngIf="item.children && item.children.length > 1" fxFlex>
    <span fxFlex></span>
    <mat-icon [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'">
      expand_more
    </mat-icon>
  </span>
</a>
<div *ngIf="expanded && item.children && item.children.length > 1">
  <app-menu-list-item
    *ngFor="let child of item.children"
    [depth]="depth + 3"
    [item]="child"
  >
  </app-menu-list-item>
</div>
