import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import {Header} from "../../components/shared/monitor-data-table/admin-data-table.component";
import {PageEventType} from "../../components/shared/paginator/paginator.component";
@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {

  @ViewChild('childDataTable') childDataTable;
  @ViewChild('alertMessageMonitor') alertMessageMonitor;

  userContextHeader: Observable<Header[]> = of([
    { id: 0, field: 'userId', label: 'User ID', editable: true },
    { id: 1, field: 'contextId', label: 'Context ID', editable: true },
    { id: 2, field: 'value', label: 'Value', editable: true },
    { id: 3, field: 'actions', label: 'Actions', editable: false, headerClass: "w-1-20", actions: ["delete"] }
  ]);

  userTeamHeader: Observable<Header[]> = of([
    { id: 0, field: 'userId', label: 'User ID', editable: true },
    { id: 1, field: 'team', label: 'Team', editable: true },
    { id: 2, field: 'actions', label: 'Actions', editable: false, headerClass: "w-1-20", actions: ["delete"] }
  ]);

  taskHeader: Observable<Header[]> = of([
    { id: 0, field: 'id', label: 'Id', editable: false },
    { id: 1, field: 'type', label: 'Type', editable: false },
    { id: 2, field: 'status', label: 'Status', editable: false },
    { id: 3, field: 'payload', label: 'Payload', editable: false },
    { id: 4, field: 'createdAt', label: 'Created At', editable: false },
    { id: 5, field: 'startedAt', label: 'Started At', editable: false },
    { id: 6, field: 'endedAt', label: 'Ended At', editable: false },
    { id: 7, field: 'details', label: 'Details', editable: false },
    { id: 8, field: 'actions', label: 'Actions', editable: false, headerClass: "w-1-20", actions: ["restart_alt"] }
  ]);

  contactGroupHeader: Observable<Header[]> = of([
    { id: 0, field: 'contactGroupId', label: 'Id', editable: false },
    { id: 1, field: 'contactGroupName', label: 'Name', editable: true },
    { id: 2, field: 'typology', label: 'Typology', editable: true, editType: "select" },
    { id: 3, field: 'actions', label: 'Actions', editable: false, headerClass: "w-1-20", actions: ["delete"] }
    /*{ id: 3, field: 'insertUser', label: 'Insert User', editable: false },
    { id: 4, field: 'insertDate', label: 'Insert Date', editable: false },
    { id: 5, field: 'updateUser', label: 'Update User', editable: false },
    { id: 6, field: 'updateDate', label: 'Update Date', editable: false },*/
  ]);

  attachmentCategoryHeader: Observable<Header[]> = of([
    { id: 0, field: 'categoryId', label: 'Identifier', editable: false },
    { id: 1, field: 'categoryName', label: 'Name', editable: true },
    { id: 3, field: 'actions', label: 'Actions', editable: false, headerClass: "w-1-20", actions: ["delete"] }
    /*{ id: 2, field: 'insertUser', label: 'Insert User', editable: false },
    { id: 3, field: 'insertDate', label: 'Insert Date', editable: false },
    { id: 4, field: 'updateUser', label: 'Update User', editable: false },
    { id: 5, field: 'updateDate', label: 'Update Date', editable: false },*/
  ])

  filterUserContext = [
    {
      name: 'User ID',
      columnProp: 'userId'
    },
    {
      name: 'Context ID',
      columnProp: 'contextId'
    },
    {
      name: 'Value',
      columnProp: 'value'
    }
  ];

  filterUserTeam = [
    {
      name: 'User ID',
      columnProp: 'userId'
    },
    {
      name: 'Team',
      columnProp: 'team'
    }
  ];

  taskFilter = [
    {
      name: 'Type',
      columnProp: 'type',
      /*filterType: 'multipleSelect',
      value: ['OTM', 'DEFAULT', 'ELUM']*/
    },
    {
      name: 'Status',
      columnProp: 'Status',
      /*filterType: 'multipleSelect',
      value: ['CREATED', 'SUCCESS', 'FAULTED']*/
    }
  ];

  contactGroupFilter = [
    {
      name: 'Name',
      columnProp: 'contactGroupName'
    },
    {
      name: 'Typology',
      columnProp: 'typology'
    }
  ];

  attachmentCategoryFilter = [
    {
      name: 'Identifier',
      columnProp: 'categoryId'
    },
    {
      name: 'Name',
      columnProp: 'categoryName'
    }
  ];

  newUserContext = {
    userId: '',
    contextId: '',
    value: '',
    newRow: true
  };

  newUserTeam = {
    userId: '',
    team: '',
    newRow: true
  };

  newContactGroup = {
    contactGroupId: '',
    typology: '',
    insertUser: '',
    insertDate: '',
    updateUser: '',
    updateDate: '',
    newRow: true
  };

  newAttachmentCategory = {
    identifier: '',
    name: '',
    insertUser: '',
    insertDate: '',
    updateUser: '',
    updateDate: '',
    newRow: true
  };

  subscriptionArray: Subscription[] = [];

  actions: string[] = [];

  screen: string = 'administration';

  pageEvent: PageEventType = { pageIndex: 0, previousPageIndex: 0, loader: 50, size: 50 }

  path: string = '';

  selectColumns: Map<string, string> = new Map();

  constructor(private router: Router, routeData: ActivatedRoute) {
    //console.log(routeData.snapshot)
    this.path = routeData.snapshot.routeConfig['path'];

    this.selectColumns.set('typologyId', "oemcc_involved_party_typology");
  }

  ngOnInit(): void {

  }

  checkRoute(path: string) {
    if (this.router.url.includes(path))
      return true;
    else
      return false;
  }

}
