import {Pipe, PipeTransform} from '@angular/core';
import {IMultiSelectOption} from "../components/shared/custom-multiselect/types";
import {Observable, of} from "rxjs";

@Pipe({
  name: 'getOptions'
})
export class GetOptionsPipe implements PipeTransform {

  transform(value: any | any[], ...args: unknown[]): Observable<IMultiSelectOption[]> {
    if (typeof value == 'string') {
      return of([] as IMultiSelectOption[]);
    } else if (Array.isArray(value)) {
      return of(value);
    } else {
      return of([] as IMultiSelectOption[]);
    }
  }

}
