import {Component, Inject, Input, OnDestroy, OnInit} from "@angular/core";
import {TableControllerService} from "../../../services/table-controller.service";
import {FilterControllerService} from "../../../services/filter-controller.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {HttpParams} from "@angular/common/http";
import {Subject, takeUntil} from "rxjs";
import {GeneralService} from "../../../services/crud/general.service";


@Component({
  selector: "monitor-notification",
  templateUrl: "./monitor-notification.component.html",
  styleUrls: ["./monitor-notification.component.scss"],
  host: {'class': 'h-100'},
  providers: [TableControllerService, FilterControllerService],
})
export class MonitorNotificationComponent implements OnDestroy, OnInit
{
  private _interactionCycle: Subject<void> = new Subject<void>();

  actionsets: any[];
  apiParent: string;
  apiUrl: string;
  monitorId: string;
  container: string;
  isLoading: boolean;

  notifications: any[] = [];

  constructor(
    // retrieve dialog input values
    @Inject(MAT_DIALOG_DATA) public data: any,
    private generalDataService: GeneralService
  )
  {
    this.actionsets = this.data['actionsets'];
    this.apiParent = this.data['apiParent'];
    this.apiUrl = this.data['apiUrl'];
    this.monitorId = this.data['monitorId'];
    this.container = this.data['container'];
  }

  ngOnDestroy(): void
  {

  }

  ngOnInit(): void
  {
    this.getNotificationList();
  }

  getNotificationList() {
    this.isLoading = true;
    let params = new HttpParams();
    params = params.append("monitorId", this.monitorId)
    this.generalDataService.getData(this.apiParent + "/" + this.apiUrl + "/notifications", null, params).pipe(
      takeUntil(this._interactionCycle)
    ).subscribe({
      next: (res: any) => {
        this.notifications = res.oContent;
        this.isLoading = false;
      },
      error: (res) => {
        this.isLoading = false;
      }
    });
  }

}
