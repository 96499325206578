<!-- <div class="page-header-container">
  <ng-content select="[page-title]"></ng-content>
  <span class="spacer"></span>
  <ng-content select="[action-type=button]"></ng-content>
</div>
-->

<div class="px-5 action-bar highlight-actionbar">
  <div class="row action-bar">
    <div class="col-auto">
      <div class="row justify-content-start">
        <ng-content select="[leftBtn]"></ng-content>
        <div class="col-auto"></div>
      </div>
    </div>
    <div class="col-auto">
      <div class="row justify-content-center">
        <ng-content select="[centerBtn]"></ng-content>
      </div>
    </div>
    <div class="col-auto">
      <div class="row justify-content-end">
        <div class="col-auto"></div>
        <ng-content select="[rightBtn]"></ng-content>
      </div>
    </div>
  </div>
  <!--<div>
    <ng-content select="[cancelBtn]"></ng-content>
    <ng-content select="[category]"></ng-content>
    <ng-content select="[reason]"></ng-content>
    <ng-content select="[reasonDetail]"></ng-content>
  </div>
  <ng-content select="[saveBtn]"></ng-content>
  <ng-content select="[saveAndCloseBtn]"></ng-content>-->
</div>
