import { HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as saveAs from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, lastValueFrom, Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { MonitorService } from 'src/app/services/monitor.service';
import { groupBy, replacer, reviver, splitFilter } from 'src/app/shared/utils/generic';
import {Header} from "../../../components/shared/monitor-data-table/admin-data-table.component";
import {PageEventType} from "../../../components/shared/paginator/paginator.component";
import {FILTER_OBJ} from "../../../components/shared/filter/filter/filter.component";
import {AlertType} from "../../../components/shared/alert-old/alert-old.component";
import {DndComponent} from "../../../components/shared/dnd/dnd.component";

@Component({
  selector: 'app-monitor-history',
  templateUrl: './monitor-history.component.html',
  styleUrls: ['./monitor-history.component.scss']
})
export class MonitorHistoryComponent implements OnInit {

  @ViewChild('alertMessageMonitor') alertMessageMonitor;
  @ViewChild('childDataTableMonitor') childDataTableMonitor;
  @ViewChild('dataTableContainer') dataTableContainer;

  headersSubject = new BehaviorSubject<Header[]>([]);
  monitorHeaders = this.headersSubject.asObservable();

  monitorDataSourceSubject = new BehaviorSubject<any[]>([]);
  monitorDataSourceObservable = this.monitorDataSourceSubject.asObservable();
  monitorDataSource = [];
  monitorLength = 0;

  containerContentTypeOptions: any[] = [];

  isImported: boolean;
  isChanged: boolean;

  onSaveAllSubscription: Subscription;

  loading: boolean = false;
  pageEvent: PageEventType = { pageIndex: 0, previousPageIndex: 0, loader: 50, size: 50 };

  filterObjMonitor: FILTER_OBJ[] = []

  screen: string = 'monitorHistory';

  sortedColumnsMonitor: Map<string, { order: number, type: 'asc' | 'desc' }>;
  filterValuesMonitor: Array<string>;

  destinationsOptions: any[] = [];

  showClosed: boolean;

  permittedActions: String[] = [];
  subscriptionArray: Subscription[] = [];

  showTableSubject = new BehaviorSubject<boolean>(false);
  showTableObs = this.showTableSubject.asObservable();

  dataTableHeight: number;

  ro = new ResizeObserver((entries, observer) => {
    entries.forEach((entry, index) => {
      this.dataTableHeight = entry.target['offsetHeight'];
      //console.log(Math.round(window.devicePixelRatio * 100))
      this.showTableSubject.next(this.dataTableHeight >= 80);
    });
  });

  constructor(private monitorService: MonitorService,
    private masterDataService: MasterDataService,
    public dialog: MatDialog, public authService: AuthService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef) {
    this.sortedColumnsMonitor = new Map<string, { order: number, type: 'asc' | 'desc' }>([['portEtaActual', { order: 1, type: 'desc' }], ['container', { order: 2, type: 'asc' }]]);
    this.filterValuesMonitor = new Array<string>();
    this.pageEvent = { pageIndex: 0, previousPageIndex: 0, loader: 50, size: 50 };
    this.isImported = false;
    this.isChanged = false;

  }

  ngOnInit(): void {

    lastValueFrom(this.authService.getHeader("oemcc_monitor_history_view")).then(headers => {

      let historyHeaders = [];
      headers["detailModels"].forEach(header => {
        if (header.column != 'containerDetail' && header.column != 'detail') {
          historyHeaders.push({
            id: header.order,
            field: header.column,
            label: header.label,
            editable: header.editable == 'Y' ? true : false,
            editType: header.type,
            selectOptions: header.options,
            headerClass: header.headerClass,
            actions: header.actions
          });
        }

        if (!!header['isFilterable']) {
          this.filterObjMonitor.push(
            {
              name: header['label'],
              columnProp: header['column'],
              filterType: header['filterType'],
              value: !!header['filterSelectOptionValues'] ? header['filterSelectOptionValues'].split(';').map(el => { return { value: el, label: el } }) : header['filterSelectOptionValues'],
              modelValue: ''
            })
        }
      })

      this.headersSubject.next(historyHeaders);
    }).finally(() => {


      this.sortedColumnsMonitor = new Map<string, { order: number, type: 'asc' | 'desc' }>([['portEtaActual', { order: 1, type: 'desc' }], ['container', { order: 2, type: 'asc' }]]);

      this.subscriptionArray.push(
        this.authService.getAction().subscribe(actions => {

          this.permittedActions = actions['actions'];
          this.subscriptionArray.forEach(sub => sub.unsubscribe());
        })
      );

      this.filterValuesMonitor = new Array<string>();
      this.pageEvent = { pageIndex: 0, previousPageIndex: 0, loader: 50, size: 50 };
      this.isImported = false;
      this.isChanged = false;
      const backupSorting = JSON.parse(sessionStorage.getItem('backup.sorting.history'), reviver);
      if (!!backupSorting) {
        this.sortedColumnsMonitor = backupSorting;
      }
      this.getAllDestinationsOptions();
      this.getAllContainerContentTypeOptions().finally(() => {
        const backupFilter = JSON.parse(sessionStorage.getItem('backup.filter.history'))

        if (!!backupFilter) {
          this.filterValuesMonitor = backupFilter;
          let backupArray = backupFilter.map(item => {
            return item.split("&");
          }).flat().map(fItem => {
            return splitFilter(fItem)
          })
          backupArray = groupBy(backupArray, 'key', 'value');

          this.filterObjMonitor.forEach((filter) => {
            if (!!filter.filterType && filter.filterType == 'multipleSelect') {
              if (!!backupArray['' + filter.columnProp] && backupArray['' + filter.columnProp].length > 0) {
                filter.modelValue = filter.value.filter(e => !(backupArray['' + filter.columnProp].some(el => el['value'] == e.value))).map(e => e.value)
              }
            } else {
              if (!!backupArray['' + filter.columnProp] && backupArray['' + filter.columnProp].length > 0) {
                filter.value = (backupArray['' + filter.columnProp])[0].value;
                filter.modelValue = (backupArray['' + filter.columnProp])[0].value;
              }
            }
          })
        }
        const backupSorting = JSON.parse(sessionStorage.getItem('backup.sorting.monitor'), reviver);
        if (!!backupSorting) {
          this.sortedColumnsMonitor = backupSorting;
        }
        this.getMonitor(0, 50, this.sortedColumnsMonitor, this.filterValuesMonitor);

      });

    })
  }

  ngAfterViewInit() {
    this.ro.observe(this.dataTableContainer.nativeElement);
  }

  ngOnDestroy(): void {
    this.subscriptionArray.forEach(sub => sub.unsubscribe());
  }

  getAllDestinationsOptions() {
    this.loading = true;
    this.masterDataService.getListDestinations()
      .subscribe(
        (res: any) => {
          if (!!res) {
            this.destinationsOptions = res.map((destination: any) => ({ value: destination.id.value, label: `${destination.rdcName.value}` })).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
            //this.listOfDestination = res.content.map((destination: any) => ({ value: destination.id, label: `${destination.rdcName}` }));
          }

        },
        (_err: any) => {
          this.loading = false;
        },
        () => this.loading = false)
  }

  getAllContainerContentTypeOptions() {
    this.loading = true;
    return lastValueFrom(this.masterDataService.getContainerContentTypeList()).then(res => {
      if (!!res) {
        this.containerContentTypeOptions = res.map((container_content_type: any) => ({ value: container_content_type.containerContentTypeId.value, label: `${container_content_type.containerContentTypeName.value}` })).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
        //this.listOfDestination = res.content.map((destination: any) => ({ value: destination.id, label: `${destination.rdcName}` }));
        this.filterObjMonitor = this.filterObjMonitor.map(filter => {
          if (!!filter['columnProp'] && filter['columnProp'] == 'containerContentType' && filter['filterType'] == 'multipleSelect') {
            filter['value'] = this.containerContentTypeOptions.map(el => { return { value: el.value, label: el.label } });
          }
          return filter;
        })


      }
    })
  }

  onPageChange(pageEvent: any): void {
    this.pageEvent = pageEvent;
    this.getMonitor(pageEvent.pageIndex, pageEvent.loader, this.sortedColumnsMonitor, this.filterValuesMonitor);
    /*if (pageEvent.pageSize !== this.pageEvent.pageSize) {
      pageEvent.pageIndex = 0;
      this.monitorDataSource = [];
    }
    this.pageEvent = pageEvent;
    if (this.monitorLength > (pageEvent.pageIndex * pageEvent.pageSize) + pageEvent.pageSize)
      this.getMonitor(pageEvent.pageIndex, pageEvent.pageSize, this.sortedColumnsMonitor, this.filterValuesMonitor);*/
  }

  /* onSortChange(pageSorting: { sorting: Map<string, {order: number, type: 'asc' | 'desc'}>; pageEvent: PageEvent }): void {
     this.pageEvent.pageIndex = 0;
     this.monitorDataSource = [];
     this.sortedColumnsMonitor = pageSorting.sorting;
     this.getMonitor(0, pageSorting.pageEvent.pageSize, pageSorting.sorting, this.filterValuesMonitor);
   }

   onApplyFilter(filters: Array<string>): void {
     this.pageEvent.pageIndex = 0;
     this.monitorDataSource = [];
     this.filterValuesMonitor = filters
     this.getMonitor(this.pageEvent.pageIndex, this.pageEvent.pageSize, this.sortedColumnsMonitor, filters);
   }*/


  onSortChange(pageSorting: { sorting: Map<string, { order: number, type: 'asc' | 'desc' }>; pageEvent: PageEventType }): void {
    this.sortedColumnsMonitor = pageSorting.sorting;
    sessionStorage.setItem('backup.sorting.history', JSON.stringify(pageSorting.sorting, replacer));
    this.refreshMonitor(0, this.monitorDataSource.length, pageSorting.sorting, this.filterValuesMonitor);
  }

  onApplyFilter(filters: Array<string>): void {
    this.filterValuesMonitor = filters;
    sessionStorage.setItem('backup.filter.history', JSON.stringify(filters));
    this.refreshMonitor(0, this.pageEvent.loader, this.sortedColumnsMonitor, filters);

  }


  onDestinationEditStart(column: Header) {
    if (column.field === "finalDestination") {
      column.selectOptions = this.destinationsOptions;
    }
  }

  onEditCell(data: { record: any, oldRecord: any, cel: string }): void {
    const { meta, ...newRecord } = data.record;

    /*for (let el in newRecord) {
      if (el === 'portOfLoadingEtd' || el === 'portEta' || el === 'portEtaActual' || el === 'actualSlotDate') {
        if (!!newRecord[el] && typeof newRecord[el] === 'object') {
          newRecord[el] = moment(newRecord[el]).format('DD/MM/YYYY');
        }
      }
    }*/
    this.monitorService.renderRow(newRecord).subscribe((res) => {
      this.monitorDataSource = this.monitorDataSource.map(row => {
        if (row.id === res.id) {
          return res;
        } else {
          return row;
        }
      })
      this.monitorDataSourceSubject.next(this.monitorDataSource);
    }, err => {
      this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Cell edit error", "An error occurred during cell editing.", 10)
    })
  }

  getMonitor(page: number, size: number, sorting: Map<string, { order: number, type: 'asc' | 'desc' }>, filters: Array<string>) {
    this.loading = true;
    this.isChanged = false;
    let params = new HttpParams().set('page', page).set('size', size);
    new Map([...sorting.entries()].sort((a, b) => a[1].order - b[1].order)).forEach((value, key, map) => {
      if (!(!!params.get('sort')))
        params = params.set('sort', key + (value.type == 'desc' ? ',desc' : ''))
      else
        params = params.append('sort', key + (value.type == 'desc' ? ',desc' : ''))
    })
    /*let filterDateToday = new Date();
    filterDateToday.setDate(filterDateToday.getDate() - 60);
    params = params.set('search', 'portEtaActual>'+new Date(filterDateToday).toISOString().slice(0, 10));*/
    filters.forEach(filter => {
      if (!filter.includes('&')) {
        if (!(!!params.get('search')))
          params = params.set('search', filter.replace(',', '§'));
        else
          params = params.append('search', filter.replace(',', '§'));
      } else {
        filter.split('&').forEach(value => {
          if (!(!!params.get('search')))
            params = params.set('search', value.replace(',', '§'));
          else
            params = params.append('search', value.replace(',', '§'));
        })
      }

    });
    //const loadingEvent = this.dialog.open(LoadingComponent);
    this.spinner.show();
    const historySubscribe = this.monitorService.getHistory(params)
      .subscribe(
        (res: any) => {
          if (!!res && !!res.content) {
            this.monitorDataSource = this.hasSubArray(this.monitorDataSource, res.content).map(e => {
              this.destinationsOptions.forEach(elem => {
                if (elem.value === e.destinationId.value) {
                  e.destinationId.value = elem.label
                }
              })
              this.containerContentTypeOptions.forEach(option => {
                if (option.value === e.containerContentType.value) {
                  e.containerContentType.value = option.label
                }
              })
              return e;
            });
            this.monitorDataSourceSubject.next(this.monitorDataSource);
          }
          this.monitorLength = res.totalElements;
          //this.pageEvent.length = res.totalElements;
          this.loading = false;
          ////loadingEvent.close();
          this.spinner.hide();
          this.spinner.hide();
          historySubscribe.unsubscribe()
        },
        (err: any) => {
          this.loading = false;
          this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Monitor history loading error", "An error occurred during monitor history loading. Please contact the administrator if the error persists.", 10)
          ////loadingEvent.close();
          this.spinner.hide();
          this.spinner.hide();
          historySubscribe.unsubscribe()
        },
        () => {
          this.loading = false;
          ////loadingEvent.close();
          this.spinner.hide();
          this.spinner.hide();
          historySubscribe.unsubscribe()
        })
  }

  refreshMonitor(page: number, size: number, sorting: Map<string, { order: number, type: 'asc' | 'desc' }>, filters: Array<string>) {
    this.loading = true;
    this.isChanged = false;
    let params = new HttpParams().set('page', page).set('size', size);
    new Map([...sorting.entries()].sort((a, b) => a[1].order - b[1].order)).forEach((value, key, map) => {
      if (!(!!params.get('sort')))
        params = params.set('sort', key + (value.type == 'desc' ? ',desc' : ''))
      else
        params = params.append('sort', key + (value.type == 'desc' ? ',desc' : ''))
    })
    /*let filterDateToday = new Date();
    filterDateToday.setDate(filterDateToday.getDate() - 60);
    params = params.set('search', 'portEtaActual>'+new Date(filterDateToday).toISOString().slice(0, 10));*/
    filters.forEach(filter => {
      if (!filter.includes('&')) {
        if (!(!!params.get('search')))
          params = params.set('search', filter.replace(',', '§'));
        else
          params = params.append('search', filter.replace(',', '§'));
      } else {
        filter.split('&').forEach(value => {
          if (!(!!params.get('search')))
            params = params.set('search', value.replace(',', '§'));
          else
            params = params.append('search', value.replace(',', '§'));
        })
      }

    });

    /*if(!!this.showClosed) {
      params = params.append('search', 'status:CLOSED');
    }*/

    this.monitorDataSource = [];
    //const loadingEvent = this.dialog.open(LoadingComponent);
    this.spinner.show();
    const historySubscribe = this.monitorService.getHistory(params)
      .subscribe(
        (res: any) => {
          if (!!res && !!res.content) {

            this.monitorDataSource = this.hasSubArray(this.monitorDataSource, res.content).map(e => {
              this.destinationsOptions.forEach(elem => {
                if (elem.value === e.destinationId.value) {
                  e.destinationId.value = elem.label
                }
              })
              this.containerContentTypeOptions.forEach(option => {
                if (option.value === e.containerContentType.value) {
                  e.containerContentType.value = option.label
                }
              })
              return e;
            });
            this.monitorDataSourceSubject.next(this.monitorDataSource);

          }
          this.monitorLength = res.totalElements;
          //this.pageEvent.length = res.totalElements;
          this.loading = false;
          ////loadingEvent.close();
          this.spinner.hide();
          this.spinner.hide();
          historySubscribe.unsubscribe();
        },
        (err: any) => {
          this.loading = false;
          this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Monitor history loading error", "An error occurred during monitor history loading. Please contact the administrator if the error persists.", 10)
          ////loadingEvent.close();
          this.spinner.hide();
          this.spinner.hide();
          historySubscribe.unsubscribe();

        },
        () => {
          this.loading = false;
          ////loadingEvent.close();
          this.spinner.hide();
          this.spinner.hide();
          historySubscribe.unsubscribe();
        })
  }


  hasSubArray(master: any[], sub: any[]) {
    sub.forEach(elem => {
      const index = master.findIndex(e => e.id.value === elem.id.value && e.unit.value === elem.unit.value && e.productType.value === elem.productType.value && e.supplierName.value === elem.supplierName.value && e.supplierCode.value === elem.supplierCode.value);
      if (index !== -1) {
        master[index] = elem;
      } else {
        master.push(elem);
      }
    })
    return master;
  }

  onExportClick() {
    let params = new HttpParams().set('page', this.pageEvent.pageIndex).set('size', this.pageEvent.loader);
    new Map([...this.sortedColumnsMonitor.entries()].sort((a, b) => a[1].order - b[1].order)).forEach((value, key, map) => {
      if (!(!!params.get('sort')))
        params = params.set('sort', key + (value.type == 'desc' ? ',desc' : ''))
      else
        params = params.append('sort', key + (value.type == 'desc' ? ',desc' : ''))
    })
    this.loading = true;
    const fileName = "Csv_" + Date.now() + ".csv";
    //const loadingEvent = this.dialog.open(LoadingComponent);
    this.spinner.show();
    this.filterValuesMonitor.forEach(filter => {
      if (!filter.includes('&')) {
        if (!(!!params.get('search')))
          params = params.set('search', filter.replace(',', '§'));
        else
          params = params.append('search', filter.replace(',', '§'));
      } else {
        filter.split('&').forEach(value => {
          if (!(!!params.get('search')))
            params = params.set('search', value.replace(',', '§'));
          else
            params = params.append('search', value.replace(',', '§'));
        })
      }

    });
    this.monitorService.exportHistoryCsv(params).subscribe(blob => {
      this.alertMessageMonitor.viewMessage(AlertType.SUCCESS, "CSV exported", "A CSV file for selected records has been exported.", 5)
      saveAs(blob, fileName);
      ////loadingEvent.close();
      this.spinner.hide();
      this.spinner.hide();
      this.loading = false;
    }, err => {
      this.spinner.hide();
      ////loadingEvent.close();
      this.spinner.hide();
    })
    /*this.monitorService.exportExcel(selectedRowMonitor.selected).subscribe(blob => {
      this.alertMessageMonitor.viewMessage(AlertType.SUCCESS, "Excel exported", "An excel file for selected records has been exported.", 5)
      saveAs(blob, fileName);
      //loadingEvent.close();
this.spinner.hide();
      this.loading = false;
    }, err => {
      //loadingEvent.close();
this.spinner.hide();
    })*/
  }

  onCancelClick() {
    this.refreshMonitor(0, 50, this.sortedColumnsMonitor, this.filterValuesMonitor);
  }

  onImportClick() {
    const dialogEvent = this.dialog.open(DndComponent, { data: { title: 'UPLOADING', code: 'Upload file: ', message: 'Are you sure you want to upload this file?', btn: 'Upload' } });
    dialogEvent.afterClosed().subscribe(result => {
      if (result.send) {
        this.alertMessageMonitor.closeAllAlerts();
        this.loading = true;
        //const loadingEvent = this.dialog.open(LoadingComponent);
        this.spinner.show();
        this.monitorService.importExcel(result.files[0]).subscribe(res => {
          this.isChanged = true;
          this.alertMessageMonitor.viewMessage(AlertType.SUCCESS, "Excel imported", "" + res.length + " records imported", 5)
          this.loading = false;
          this.spinner.hide();
          ////loadingEvent.close();
          this.spinner.hide();
        }, err => {
          this.loading = false;
          this.spinner.hide();
          ////loadingEvent.close();
          this.spinner.hide();
          if (!!err && !!err.error && !!err.error.errors) {
            this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Error on row: " + err.error.errors.lineNumber, "The value in '" + err.error.errors.field + "' is '" + err.error.errors.rejected + "' but we expect '" + err.error.errors.expected + "'", 30)
          } else {
            this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Excel import error", "An error occurred during file import", 10)
          }
        })
      }
    })
  }

  onReopenRecordsClick(ids: any) {
    //const loadingEvent = this.dialog.open(LoadingComponent);
    this.spinner.show();
    this.monitorService.reopenRecords(ids).subscribe(res => {
      ////loadingEvent.close();
      this.spinner.hide();
      this.spinner.hide();
      this.refreshMonitor(0, this.monitorDataSource.length, this.sortedColumnsMonitor, this.filterValuesMonitor);
    }, (err: any) => {
      this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Records opening error", "An error occurred while opening selected records.")
      ////loadingEvent.close();
      this.spinner.hide();
      this.spinner.hide();
    })
  }

  isActionPresent(action: string) {
    if (this.permittedActions.indexOf(action) > -1)
      return true;
    else
      return false
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    /*if (event.target.innerWidth > this.winInnerHeight) {
      this.minHeight = 80;
    } else {
      this.minHeight = 150;
    }*/
    //this.ro.observe(this.dataTableContainer.nativeElement);

    this.showTableSubject.next(false);
    this.cdr.detectChanges();
    this.showTableSubject.next(true);
  }

  /*onCloseRecordsClick(ids: any) {
    //const loadingEvent = this.dialog.open(LoadingComponent);
    this.spinner.show();
    this.monitorService.closeRecords(ids).subscribe(res => {
      //loadingEvent.close();
this.spinner.hide();
      this.refreshMonitor(0, this.monitorDataSource.length, this.sortedColumnsMonitor, this.filterValuesMonitor);
    }, (err: any) => {
      this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Records closing error", "An error occurred while closing selected records.")
      //loadingEvent.close();
this.spinner.hide();
    })
  }*/

  /*showOpen(event: any) {
    this.showClosed = event;
    this.refreshMonitor(0, this.pageEvent.loader, this.sortedColumnsMonitor, this.filterValuesMonitor);
  }*/
}
