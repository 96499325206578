import {Component, Inject, Input, OnDestroy, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {TableControllerService} from "../../../../services/table-controller.service";
import {FilterControllerService} from "../../../../services/filter-controller.service";
import {AuthService} from "../../../../services/auth.service";
import {ReplyMailComponent} from "../../reply-mail/reply-mail.component";
import {MonitorService} from "../../../../services/monitor.service";
import {lastValueFrom, Subject, takeUntil} from "rxjs";
import {GeneralService} from "../../../../services/crud/general.service";
import * as saveAs from "file-saver";
import {map} from "rxjs/operators";
import {AttachfiletoComponent} from "../../attachfileto/attachfileto.component";
import {HttpParams} from "@angular/common/http";
import {AlertService} from "../../alert/alert.service";
import {ALERT_TYPE} from "../../alert/alert.enumerate";


@Component({
  selector: "monitor-notification-row",
  templateUrl: "./monitor-notification-row.component.html",
  styleUrls: ["./monitor-notification-row.component.scss"],
  host: {'class': 'h-100'},
  providers: [TableControllerService, FilterControllerService],
})
export class MonitorNotificationRowComponent implements OnDestroy, OnInit
{

  private _interactionCycle: Subject<void> = new Subject<void>();

  userEmail: string;

  @Input()
  actionsets: any = {};

  notificationOption: any[] = [];

  @Input()
  apiParent: string;
  @Input()
  apiUrl: string;

  id: string;
  container: string;

  isEmailVisible: boolean = false;

  @Input()
  notification: any;


  constructor(
    // retrieve dialog input values
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public authService: AuthService,
    private monitorService: MonitorService,
    private alertService: AlertService,
    private generalDataService: GeneralService
  )
  {
    this.id = this.data['monitorId'];
  }

  ngOnDestroy(): void
  {

  }

  ngOnInit(): void
  {
    this.authService.getUser().subscribe(user => {
      if (!!user) {
        if (user.oContent.userEmail == null) {
          this.userEmail = user.oContent.userId;
        } else {
          this.userEmail = user.oContent.userEmail;
        }
      }
    });

    // get allowed reply action
    // todo: forse sbagliato, definire delle regole per il recuper delle info, quelle vecchie non sono più valide
    this.getNotificationAction()
  }

  emailVisibleHandler(){
    this.isEmailVisible = !this.isEmailVisible;
  }

  reply(notification, action_id, typeId, description) {

    this.monitorService.getTypology(typeId).subscribe(notificationModel => {
      this.dialog.open(ReplyMailComponent, {
        width: '65vw',
        data: {
          title: 'Preview',
          sendTo: notification.notificationTo + ";" + notification.notificationFrom,
          sendCc: notification.notificationCc,
          notificationThreadId: notification.notificationThreadId,
          notificationTypology: notificationModel["notificationTypology"],
          appOwnerNotificationId: action_id,
          messageId: notification.messageId,
          conversationId: notification.conversationId,
          receivedDateTime: notification.receivedDateTime,
          notificationThreadDesc: description,
          apiParent: this.apiParent,
          apiUrl: this.apiUrl,
        }
      });
    })
  }

  getNotificationAction() {
    this.notificationOption = [];

    this.generalDataService.getNotificationActions(this.apiParent + "/" + this.apiUrl, null).pipe(
      takeUntil(this._interactionCycle)
    ).subscribe({
      next: (notificationOption) => {
        this.notificationOption = notificationOption;
      },
      error: (err) => {
        this.notificationOption = [];
      }
    });

  }

  // todo: need a use case to test download and attach methods
  // --- download attachments methods ---
  download(attachment: any) {
    if (attachment['contentBytes']) {
      var blob = this.base64ToBlob(attachment['contentBytes'], attachment['contentType']);
      saveAs(blob, attachment['name']);
    }
  }
  private base64ToBlob(b64Data, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ''); //IE compatibility...
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }
  // --- download attachments methods ---

  // --- attach attachments methods ---
  attachFile(attachment: any, threadId: number) {
    if (attachment['contentBytes']) {
      var file: File = this.base64ToFile(attachment['contentBytes'], attachment['contentType'], attachment['name']);
      let params = new HttpParams();
      params = params.append("monitorId", this.notification.notificationThreadId)

      lastValueFrom(this.generalDataService.getData(this.apiParent + "/" + this.apiUrl + "/notification", null, params).pipe(map(objs => objs.map(obj => obj.objectPk)))).then(objIds => {
        const dialog = this.dialog.open(AttachfiletoComponent, {
          width: '65vw',
          data: {
            dialog: {
              title: 'Attach ' + attachment['name'] + ' to selected container',
              closeButtonLabel: "Close",
              confirmButtonLabel: "Confirm"
            },
            list: objIds,
            file: attachment
          }
        });
        dialog.afterClosed().subscribe(result => {
          if (!!result && !!result.send) {
            this.monitorService
              .uploadDetailFiles(result.container, result.category, file)
              .reduce(async (previousPromise, nextID) => {
                await previousPromise;
                return lastValueFrom(nextID);
              }, Promise.resolve()).then(value =>
              this.alertService.add({
                type: ALERT_TYPE.INFO,
                message: "File uploaded. " + value + " file was uploaded to specificated container",
                timeout: 5000,
                selfClose: null
              })
            );
          }
        });
      }).catch(err => {
        this.alertService.add({
          type: ALERT_TYPE.WARNING,
          message: "Unknown error. Unable to load object id from threads",
          timeout: 5000,
          selfClose: null
        })
      });
    }
  }
  private base64ToFile(b64Data, contentType = '', fileName = '', sliceSize = 512) {
    return new File([this.base64ToBlob(b64Data, contentType)], fileName)
  }
  // --- attach attachments methods ---

}
