import {Pipe, PipeTransform} from '@angular/core';
import {Observable, of} from "rxjs";

@Pipe({
  name: 'getListTooltip',
  pure: false
})
export class GetListTooltipPipe implements PipeTransform {

  transform(column: any, defaultValue: string, dataSetElementElement: any, row: number): string {
    return column.optionValues.filter(
      (option) => option.id == dataSetElementElement
    )[0]?.name ?? defaultValue;
  }

}
