import {Component, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {TableControllerService} from "../../../../services/table-controller.service";
import {FilterControllerService} from "../../../../services/filter-controller.service";
import {MatDialog} from "@angular/material/dialog";
import {DndComponent} from "../../dnd/dnd.component";
import {MonitorService} from "../../../../services/monitor.service";
import {HttpParams} from "@angular/common/http";
import {Subject, takeUntil} from "rxjs";
import {GeneralService} from "../../../../services/crud/general.service";
import {DetailDataTableComponent} from "../../detail-data-table/detail-data-table.component";

@Component({
  selector: "monitor-detail-attachment",
  templateUrl: "./monitor-detail-attachment.component.html",
  styleUrls: ["./monitor-detail-attachment.component.scss"],
  host: {'class': 'h-100'},
  providers: [TableControllerService, FilterControllerService],
})
export class MonitorDetailAttachmentComponent implements OnDestroy, OnInit
{
  private _interactionCycle: Subject<void> = new Subject<void>();
  @ViewChild('dataTableComponent') detailTable!: DetailDataTableComponent;

  @Input()
  id: string;

  @Input()
  apiUrl: string
  @Input()
  apiParent: string
  @Input()
  container: string;

  categoryOptions: [];
  selectedCategory: string = "";

  constructor(
    private dialog: MatDialog,
    private monitorService: MonitorService,
    private generalDataService: GeneralService
  )
  {
  }

  ngOnDestroy(): void
  {

  }

  ngOnInit(): void
  {
    const optionUrl = '/monitor-detail/options'
    const params = new HttpParams().append('optionId', 'category')
    this.generalDataService.getData(this.apiParent + optionUrl, "excel", params).pipe(
      takeUntil(this._interactionCycle)
    ).subscribe({
      next: (res: any) => {
        this.categoryOptions = res.oContent;
      },
      error: (res) => {

      }
    });
  }

  onImportFile() {
    const dialogEvent = this.dialog.open(DndComponent, {
      data: {
        title: 'UPLOADING',
        code: 'Upload file: ',
        message: 'Are you sure you want to upload this file?',
        btn: 'Upload',
        type: 'any',
      },
    });
    dialogEvent.afterClosed().subscribe((result) => {
      if (!!result && result.send) {
        this.monitorService
          .uploadDetailFile(this.id, this.container, this.selectedCategory, result.files)
          .subscribe((res) => {
            if (!!res) {
              //this.refreshTable();
              this.detailTable.refresh()
            }
          });
      }
    });
  }

}
