import {Pipe, PipeTransform} from '@angular/core';
import {Observable, of} from "rxjs";

@Pipe({
  name: 'getValueOnCondition'
})
export class GetValueOnConditionPipe implements PipeTransform {

  transform(arrayToCheck: any[], propertyToCompare: string, propertyToShow: string, propertyValue: any, defaultValue: string): Observable<string> {
    try {
      return of(
        (arrayToCheck.filter(elem => elem[propertyToCompare] == propertyValue))
          [0][propertyToShow]
      );
    } catch (e) {
      return of(defaultValue)
    }

  }

}
