import {NgModule} from '@angular/core';
import {CustomMultiselectComponent} from './custom-multiselect.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MultiSelectSearchFilter} from './search-filter.pipe';
import {AutofocusDirective} from './autofocus.directive';
import {OffClickDirective} from './off-click.directive';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatSelectModule} from "@angular/material/select";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
  declarations: [CustomMultiselectComponent, MultiSelectSearchFilter, AutofocusDirective, OffClickDirective],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    FormsModule,
    MatTooltipModule,
  ],
  exports: [CustomMultiselectComponent, MultiSelectSearchFilter],
})
export class CustomMultiselectModule {
}
