import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {NavItem} from 'src/app/models/NavItem';
import {NavService} from 'src/app/services/nav.service';
import {DialogComponent} from "../dialog/dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ]),
  ],
})
export class MenuListItemComponent implements OnInit {
  expanded: boolean = false;

  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item!: NavItem;

  @Input() depth!: number;

  selected: boolean = false;

  constructor(public navService: NavService, public router: Router, public dialog: MatDialog, private activatedRoute: ActivatedRoute) {
    if (this.depth === undefined) {
      this.depth = 0;
    }

  }

  ngOnInit(): void {
  }

  onItemSelected(item: NavItem) {

    //(item)
    if (!item.children || !item.children.length || item.children.length <= 1) {
      sessionStorage.setItem('path', item.pageId ?? '$EMPTY$');

      this.navService.selectedItem = item;
      //localStorage.setItem('lastUrl', item.route)
      let selectedItm = item;
      if (item.children?.length == 1) {
        selectedItm = item.children[0];
      }
      if (!!localStorage.getItem('changes') && localStorage.getItem('changes') == 'true') {
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {
            title: 'Unsaved changes',
            content: `If you leave this page the creations or changes you have made so far will not be saved. How would you like to proceed?`,
            cancelButtonLabel: 'Stay',
            cancelButtonColor: 'basic',
            confirmButtonLabel: 'Leave',
            confirmButtonColor: 'accent',
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res && res == true) {
            this.router.navigate([selectedItm.route], {
              queryParams: {
                id: selectedItm.pageId,
                title: selectedItm.displayName
              }
            });
          }
        });
      } else {
        this.router.navigate([selectedItm.route], {
          queryParams: {
            id: selectedItm.pageId,
            title: selectedItm.displayName
          }
        });
      }

      this.navService.closeNav();
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }

  isSelected(item: NavItem): boolean {
    const sessionPageId = sessionStorage.getItem('path')
    /*if{

    }
    return !!(item.route && window.location.pathname.includes(item.route))*/
    console.log(sessionPageId);
    console.log(item)
    return !!(sessionPageId && sessionPageId != '$EMPTY$' && sessionPageId == item.pageId)
  }
}
