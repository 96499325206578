<div class="paginator-container">
  <div class="paginator-load-batch">
    <label class="paginator-load-batch-title">{{ paginatorLoaderTitle }}</label>
    <mat-select #load (selectionChange)="onPageChange($event)" [(ngModel)]="paginatorLoader"
                class="paginator-load-batch-selector">
      <mat-option *ngFor="let option of paginatorLoaderList ?? []" [value]="option">{{ option }}
      </mat-option>
    </mat-select>
  </div>
  <span class="paginator-spacer"></span>
  <div class="paginator-page">
    <label class="paginator-load-page-title">{{
        ((numElementsLoaded) > numElementsSize ? numElementsSize :
          (numElementsLoaded)) + ' / ' + numElementsSize
      }}</label>

    <custom-btn (click)="onLoadClick($event)"
                [disabled]="isAllLoaded || ((numElementsLoaded ) >= numElementsSize)">{{ 'LABEL_LOAD_MORE' | translate }}
    </custom-btn>
  </div>
</div>
