<div class="custom-page-container">
  <app-action-bar>
    <label class="page-title" page-title><label class="page-subtitle">OEMCC - </label> Monitor <span
      *ngIf="isChanged">Import</span></label>
    <custom-btn (click)="onSaveClick()" *ngIf="isActionPresent('monitor.import.save') && isActionPresent('monitor.import')"
            [disabled]="!isChanged || importError" action-type="button" color="primary"
            matTooltip="Save imported records">
      Save All
    </custom-btn>

    <custom-btn (click)="onCancelClick()" *ngIf="isActionPresent('monitor.import')" [disabled]="!isChanged" action-type="button"
            color="primary"
            matTooltip="Cancel imported records">
      Cancel
    </custom-btn>
    <custom-btn (click)="onExportCsvClick()" *ngIf="isActionPresent('monitor.export.csv')" [disabled]="isChanged" action-type="button"
            color="primary"
            matTooltip="Export monitor in CSV file">
      Export CSV
    </custom-btn>

  </app-action-bar>

  <app-filter (applyFilterEv)="onApplyFilter($event)" (resetFilterEv)="onResetFilter($event)" *ngIf="!isChanged"
              [filterObj]="filterObjMonitor">
  </app-filter>

  <div #dataTableContainer class="datatable-container">
    <app-data-table #childDataTableMonitor (onCloseRecordsClick)="onCloseRecordsClick($event)" (onDetailChangeEmit)="onDetailChange($event)"
                    (onEditCell)="onEditCell($event)" (onEditStart)="onDestinationEditStart($event)" (onExportClick)="onExportClick($event)"
                    (onImportClick)="onImportClick()"
                    (onNotifyRecordsClick)="onNotifyRecordsClick($event)"
                    (onOpenRecordsClick)="onOpenRecordsClick($event)" (onPaginationChange)="onPageChange($event)"
                    (onSortChange)="onSortChange($event)"
                    *ngIf="(showTableObs | async) || isChanged || true"
                    [dataSourceObservable]="monitorDataSourceObservable"
                    [dataSource]="monitorDataSourceObservable" [destinationsOptions]="destinationsOptions"
                    [containerContentTypeOptions]="containerContentTypeOptions"
                    [declarantOptions]="declarantOptions"
                    [terminalOptions]="terminalOptions"
                    [editingRowColumn]="editingRowColumn"
                    [headers]="monitorHeaders"
                    [isChanged]="isChanged"
                    [length]="monitorLength" [loadingTable]="loading"
                    [numElementsLoaded]="(monitorDataSourceObservable | async)?.length"
                    [pageEvent]="pageEvent"
                    [screen]="screen"
                    [extraActions]="availableManualActions"
                    (onManualActionClick)="manualAction($event)"
                    [showMerge]="false" [sortedColumns]="sortedColumnsMonitor" class="datatable-content">
    </app-data-table>
  </div>

  <ngx-spinner [fullScreen]="true" [name]="'default'" bdColor="rgba(0, 0, 0, 0.8)" color="#fff" size="large"
               type="square-jelly-box">
    <p style="color: white; font-size: 1.5rem"> Loading... </p>
  </ngx-spinner>

</div>
