import {Inject, Injectable} from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  private dateFormat: DateFormat = {
    firstValue: DATEMODEL.DD,
    secondValue: DATEMODEL.MM,
    thirdValue: DATEMODEL.YYYY,
    delimiter: '-'
  }

  constructor(@Inject('dateFormat') private dateformat: DateFormat) {
    super();
    this.dateFormat = dateformat;
  }


  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.dateFormat.delimiter);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date
      ? date[this.dateFormat.firstValue].toString().padStart(2, "0") + this.dateFormat.delimiter + date[this.dateFormat.secondValue].toString().padStart(2, "0") + this.dateFormat.delimiter + date[this.dateFormat.thirdValue]
      : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  private dateFormat: DateFormat = {
    firstValue: DATEMODEL.DD,
    secondValue: DATEMODEL.MM,
    thirdValue: DATEMODEL.YYYY,
    delimiter: '-'
  }

  constructor(@Inject('dateFormat') private dateformat: DateFormat) {
    super();
    this.dateFormat = dateformat;
  }


  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.dateFormat.delimiter);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? (date[this.dateFormat.firstValue]).toString().padStart(2, "0") + this.dateFormat.delimiter + date[this.dateFormat.secondValue].toString().padStart(2, "0") + this.dateFormat.delimiter + date[this.dateFormat.thirdValue]
      : '';
  }

}

export enum DATEMODEL {
  DD = 'day',
  MM = 'month',
  YYYY = 'year'
}

export interface DateFormat {
  firstValue: DATEMODEL,
  secondValue: DATEMODEL,
  thirdValue: DATEMODEL,
  delimiter: string
}
