import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'custom-timepicker',
  templateUrl: './custom-timepicker.component.html',
  styleUrls: ['./custom-timepicker.component.scss'],
})
export class CustomTimepickerComponent {
  @Input()
  value: string = ''; // Holds the time in "HH:mm" format

  @Input()
  disabled: boolean = false; // Enables/disables the time picker

  @Output()
  valueChange: EventEmitter<string> = new EventEmitter();

  constructor() {}

  onTimeChange(newTime: string) {
    this.value = newTime; // Update the value
    this.valueChange.emit(this.value); // Emit the new time value
  }

  onFocusOut() {
    if (!!this.value && this.value.includes(':')) {
      const [hours, minutes] = this.value.split(':');
      const formattedHours = hours.padStart(2, '0');
      const formattedMinutes = minutes.padStart(2, '0');
      this.value = `${formattedHours}:${formattedMinutes}`;
      this.valueChange.emit(this.value);
    }
  }

}
