<section>
  <custom-alert [containerClass]="'alert-container-in-modal'"></custom-alert>
  <h2 mat-dialog-title>{{data.title}} <span class="status-text-error" *ngIf="sendingMailList.length == 0">* Reply
      Mail</span>
    <span *ngIf="sendingMailList.length > 0">Reply Mail</span>
    <span *ngIf="!fullBtn" class="right" (click)="openFull()">
      <mat-icon>fullscreen</mat-icon>
    </span>
    <span *ngIf="fullBtn" class="right" (click)="exitFull()">
      <mat-icon>fullscreen_exit</mat-icon>
    </span>
  </h2>
  <div class="alert">
    <alert-old-component #alertMessageMonitor></alert-old-component>
  </div>
  <div mat-dialog-content [ngClass]="{'default-height': !fullBtn, 'max-height': fullBtn}" class="column">

<!--    SUBJECT SECTION-->
    <div>
      <span class="subtitle">Subject:</span>
      <mat-form-field class="full-width" appearance="fill">
        <textarea matInput [(ngModel)]="htmlSubjectContent" placeholder="Write your custom subject..."></textarea>
      </mat-form-field>
    </div>

<!--    MAIL BODY SECTION-->
    <div>
      <span class="subtitle">Mail body:</span>
      <angular-editor [(ngModel)]="htmlContent" [config]="htmlConfig"></angular-editor>
      <div class="mail-section">
        <div class="mail-to">
          <span *ngIf="sendingMailList.length > 0" class="subtitle" >Emails To: </span>
          <span *ngIf="sendingMailList.length == 0" class="status-text-error subtitle">* Emails To: (One email is required)</span>

          <div>
            <mat-form-field appearance="fill" class="new-mail-input">
              <mat-label>New To Email</mat-label>
              <input matInput [(ngModel)]='newMail' pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
            </mat-form-field>
            <button mat-stroked-button (click)="done()">
              <mat-icon>person_add</mat-icon>
            </button>
          </div>
          <div class="list">
            <div *ngFor="let email of sendingMailList" class="row hover">
              <mat-icon class="delete-icon" (click)="deleteEmail(email, 'sendTo')">delete</mat-icon>
              <div class="text-child"> {{email}} </div>
            </div>
          </div>
        </div>

        <div class="mail-cc">
          <span class="subtitle">Emails Cc: </span>
          <div>
            <mat-form-field appearance="fill" class="new-mail-input">
              <mat-label>New Cc Email</mat-label>
              <input matInput [(ngModel)]='newMailCc' pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
            </mat-form-field>
            <button mat-stroked-button (click)="done(true)">
              <mat-icon>person_add</mat-icon>
            </button>
          </div>
          <div class="list">
            <div *ngFor="let email of sendingMailCcList" class="row hover">
              <mat-icon class="delete-icon" (click)="deleteEmail(email, 'sendCc')">delete</mat-icon>
              <div class="text-child">{{email}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

<!--    ATTACHMENT SECTION-->
    <div>
      <div class="attachment-section">

        <span class="subtitle">Your attachments: </span>
        <button mat-stroked-button (click)="attachFile()">
          <mat-icon>attach_file</mat-icon>
        </button>

        <div class="attachment-list">
          <div class="list">
            <div *ngFor="let attachment of sendAttachment" class="row hover">
              <mat-icon mat-list-icon class="delete-icon" (click)="deleteEmail(attachment.name, 'sendAttachment')"
                matTooltip="Remove file from list" matTooltipClass="tooltip-red">
                link_off</mat-icon>
              <div class="text-child">{{attachment.name}}</div>
              <div style="flex: 1"></div>
              <div class="text-child"> {{ formatBytes(attachment.size) }} </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="spacer"></div>

  <div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">{{'ACT_CLOSE' | translate}}</button>
    <button mat-button (click)="sendReply()">{{'ACT_REPLY' | translate}}</button>
  </div>
</section>
