import { DomSanitizer } from '@angular/platform-browser';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as saveAs from 'file-saver';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationList, NotificationService, ObjectThread } from 'src/app/services/notification.service';
import { AttachfiletoComponent } from '../attachfileto/attachfileto.component';
import { MatDialog } from '@angular/material/dialog';
import { from, lastValueFrom, map, Subscription } from 'rxjs';
import { BtnAction, MonitorService } from 'src/app/services/monitor.service';
import { ReplyMailComponent } from '../reply-mail/reply-mail.component';
import { LoadingComponent } from '../loading/loading.component';
import { NgxSpinnerService } from 'ngx-spinner';
import {AlertType} from "../alert-old/alert-old.component";


@Component({
  selector: 'app-comunication-tracker',
  templateUrl: './comunication-tracker.component.html',
  styleUrls: ['./comunication-tracker.component.scss']
})
export class ComunicationTrackerComponent implements OnInit {


  id: number;
  container: string;
  userEmail: string;

  noNotify: boolean = true;

  objectThreads: { object: ObjectThread, notifications: NotificationList, btnsActions?: any[] }[] = [];

  notificationThreads: NotificationList[] = [];

  @ViewChild('alertMessageMonitor') alertMessageMonitor;

  noObjectThreads = false;
  showSpeakerNotesOff = false;

  subscriptionArray: Subscription[] = [];

  permittedActions: String[] = [];

  mappedActions: Map<string, BtnAction> = new Map<string, BtnAction>();

  getThreadsSub;
  getNotificationHistorySub;
  queryParamMapSub;

  previousUrl: string;

  constructor(
    private route: ActivatedRoute,
    public notifyService: NotificationService,
    public authService: AuthService,
    public router: Router,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private monitorService: MonitorService,
    private zone: NgZone,
    private spinner: NgxSpinnerService
  ) {
    this.previousUrl = localStorage.getItem('previousUrl');
  }

  ngOnDestroy(): void {

    this.subscriptionArray.forEach(sub => sub.unsubscribe());
  }

  ngOnInit(): void {
    //setTimeout(() => this.showSpeakerNotesOff = true, 2500);

    this.getThreads();

    // todo messo @ts-ignore
    // @ts-ignore
    this.authService.getUser().then(user => {
      if (!!user) {
        if (user.profile.email == undefined || user.profile.email == null) this.userEmail = user.profile.preferred_username;
        else this.userEmail = user.profile.email;
        //this.usr = (user.profile.name.split(' '))[0].slice(0, 1) + (user.profile.name.split(' '))[1].slice(0, 1);
      }
    })

    this.subscriptionArray.push(this.authService.getAction().subscribe(actions => {
      this.permittedActions = actions['actions'];
      this.subscriptionArray.forEach(sub => sub.unsubscribe());
    }));
  }

  asyncMultiple(thread, notifyService: NotificationService, monitorService: MonitorService) {
    console.log("call")
    return lastValueFrom(notifyService.getNotificationHistory(thread.notificationThreadId)).then(history => {

      lastValueFrom(monitorService.getActionsMenu(thread.appOwnerNotificationId)).then(response => {

        history.map(hn => {
          hn.notificationBody = this.sanitizer.bypassSecurityTrustHtml('' + hn.notificationBody);
          return hn;
        });

        this.objectThreads.push({ object: thread, notifications: { notifys: history, threadId: thread.notificationThreadId }, btnsActions: response });

        this.noObjectThreads = false;
      }).catch(err => {
        history.map(hn => {
          hn.notificationBody = this.sanitizer.bypassSecurityTrustHtml('' + hn.notificationBody);
          return hn;
        })
        this.objectThreads.push({ object: thread, notifications: { notifys: history, threadId: thread.notificationThreadId } });
        this.noObjectThreads = false;
      }).finally(() => {
        if (this.objectThreads.length == 0) {
          this.noObjectThreads = true;
          this.showSpeakerNotesOff = true
        } else {
          this.objectThreads.sort((a, b) => (a.object.notificationThreadId > b.object.notificationThreadId) ? -1 : ((b.object.notificationThreadId > a.object.notificationThreadId) ? 1 : 0));
        }

      });
    })
  }

  getThreads() {
    this.objectThreads = [];

    this.queryParamMapSub = this.route.queryParamMap.subscribe((paramMap: Params) => {
      this.id = paramMap['params']['id'];
      this.container = paramMap['params']['number'];

      this.spinner.show();
      this.getThreadsSub = this.notifyService.getThreads(this.id).subscribe(res => {

        if (!!res && res.length == 0) {
          this.noObjectThreads = true;
          this.showSpeakerNotesOff = true;
          this.spinner.hide();
        }

        let actions = res.map((thread) => this.asyncMultiple(thread, this.notifyService, this.monitorService));

        Promise.all(actions).finally(() => {
          this.spinner.hide();
        })



      }, err => {
        this.spinner.hide();
      })
    }, err => {
      this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Error", "An error occurred while retrieving notification threads.");
    });
  }

  isActionPresent(action: string) {
    if (this.permittedActions.indexOf(action) > -1)
      return true;
    else
      return false
  }

  download(attachment: any) {
    if (attachment['contentBytes']) {
      var blob = this.base64ToBlob(attachment['contentBytes'], attachment['contentType']);
      saveAs(blob, attachment['name']);
    }
  }

  attachFile(attachment: any, threadId: number) {
    if (attachment['contentBytes']) {
      var file: File = this.base64ToFile(attachment['contentBytes'], attachment['contentType'], attachment['name']);

      lastValueFrom(this.notifyService.getObjects(threadId).pipe(map(objs => objs.map(obj => obj.objectPk)))).then(objIds => {
        const dialog = this.dialog.open(AttachfiletoComponent, {
          width: '65vw',
          data: {
            dialog: {
              title: 'Attach ' + attachment['name'] + ' to selected container',
              closeButtonLabel: "Close",
              confirmButtonLabel: "Confirm"
            },
            list: objIds,
            file: attachment
          }
        });
        dialog.afterClosed().subscribe(result => {
          if (!!result && !!result.send) {
            this.monitorService
              .uploadDetailFiles(result.container, result.category, file)
              .reduce(async (previousPromise, nextID) => {
                await previousPromise;
                return lastValueFrom(nextID);
              }, Promise.resolve()).then(value => this.alertMessageMonitor.viewMessage(AlertType.INFO, "File uploaded", "" + value + " file was uploaded to specificated container", 5));
          }
        });
      }).catch(err => {
        this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Unknown error", "Unable to load object id from threads", 5);
      });


    }
  }

  private base64ToBlob(b64Data, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ''); //IE compatibility...
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  private base64ToFile(b64Data, contentType = '', fileName = '', sliceSize = 512) {
    return new File([this.base64ToBlob(b64Data, contentType)], fileName)
  }

  reply(notification, action_id, typeId, description) {

    this.monitorService.getTypology(typeId).subscribe(notificationModel => {

      this.dialog.open(ReplyMailComponent, {
        width: '65vw',
        data: {
          title: 'Preview',
          sendTo: notification.notificationTo + ";" + notification.notificationFrom,
          sendCc: notification.notificationCc,
          notificationThreadId: notification.notificationThreadId,
          notificationTypology: notificationModel["notificationTypology"],
          appOwnerNotificationId: action_id,
          messageId: notification.messageId,
          conversationId: notification.conversationId,
          receivedDateTime: notification.receivedDateTime,
          notificationThreadDesc: description,
        }
      });
    })
  }

  navigate(url: any) {
    this.zone.run(() => {
      this.router.navigate(url);
    });
  }

  refresh() {
    /*
    this.queryParamMapSub.unsubscribe();
    this.getThreadsSub.unsubscribe();
    this.getNotificationHistorySub.unsubscribe();
    this.getThreads()
    */

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/notification/history'], { queryParams: { id: this.id, number: this.container } });
    });
  }

  goBack() {
    this.zone.run(() => {
      if (!!this.previousUrl)
        this.router.navigate([this.previousUrl]);
    });
  }

}

interface Attachment {
  id: string,
  lastModifiedDateTime: string,
  name: string,
  contentType: string,
  size: number,
  isInline: boolean,
  contentBytes: string
}
