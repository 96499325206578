import {Injectable} from '@angular/core';
import {ExtendedAnnounce} from "../app.component";
import {BehaviorSubject, Observable} from "rxjs";


@Injectable({
  providedIn: 'root',
})
export class AnnounceService {


  private allectsBS: BehaviorSubject<ExtendedAnnounce[]> = new BehaviorSubject<ExtendedAnnounce[]>([]);

  public allerts$: Observable<ExtendedAnnounce[]> = this.allectsBS.asObservable();


  constructor() {

  }

  replaceAllert(a: ExtendedAnnounce[]) {
    this.allectsBS.next(a);
  }


}
