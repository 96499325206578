import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges, ViewChild,
  ViewChildren
} from "@angular/core";
import {TableControllerService} from 'src/app/services/table-controller.service';
import {keyExistsIn} from 'src/app/utils/generic';
import * as _ from 'lodash';
import {PageEventType} from '../custom-table-paginator/custom-table-paginator.component';
import {MatDialog} from '@angular/material/dialog';
import {cloneDeep} from "lodash";
import {ActionSet} from "../../../models/layout";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {ContactGroupDialogComponent} from "../contact-group-dialog/contact-group-dialog.component";
import {DialogComponent} from "../dialog/dialog.component";
import {MonitorDetailComponent} from "../monitor-detail-component/monitor-detail.component";
import {MonitorNotificationComponent} from "../monitor-notification-component/monitor-notification.component";

@Component({
  selector: "data-table",
  templateUrl: "./data-table.component.html",
  styleUrls: ["./data-table.component.scss"],
})
export class DataTableComponent implements OnInit, AfterViewInit, AfterContentChecked {
  public ITEM_SIZE = 48;


  // `pageId`, `apiParent`, and `apiUrl` are used to manage the inner table logic.
  // To enable an inner table:
  // 1. Create a new page in AUTH named `INNER_<pageId>` (replace `<pageId>` with the actual page ID).
  // 2. Define CRUD methods for the inner table in the API URL, following this pattern:
  //    `<apiParent>/<apiUrl>/inner...`
  // For example:
  // - If `apiParent` = "monitor" and `apiUrl` = "preview", the API URL for the inner table could be:
  //   `/monitor/preview/inner`.
  //
  // This setup allows dynamic management of nested tables within the current structure.
  // Example:
  // Take the page `PAG_MONITOR` as a reference. The backend (BE) logic for this page
  // is handled by endpoints starting with `/api/v1/monitor/monitor`.
  //
  // For `PAG_MONITOR`, an INNER PAGE named `INNER_PAG_MONITOR` is defined.
  // The CRUD methods for `INNER_PAG_MONITOR` are accessible via the endpoint:
  // `/api/v1/monitor/monitor/inner`.
  //
  // This structure demonstrates how the main page and its associated inner page
  // endpoints are organized and managed.

  @Input()
  pageId: string;
  @Input()
  apiParent: string;
  @Input()
  apiUrl: string;

  @ViewChild(CdkVirtualScrollViewport, {static: false})
  public viewPort?: CdkVirtualScrollViewport;

  @Input()
  pageEvent: PageEventType = {
    pageIndex: 0,
    previousPageIndex: 0,
    loader: 50,
    size: 50,
  };

  @Input()
  length: number = 0;

  @Input()
  numElementsLoaded: number = 0;

  @ViewChildren("checkboxes")
  checkboxes: any;

  itemsArray: any[] = [];

  @Input()
  datasets;

  @Input()
  actionsets?: ActionSet;

  // ITOEM-5: added logic to handle notification button
  @Input()
  notificationOption?: any[];

  // ITOEM-5: added logic to handle notification button
  @Input()
  manualOption?: any[];

  pageable: any;

  @Output()
  onActionEmit: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onRowClick: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  onResetSort: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  tableController!: TableControllerService;

  @Input()
  disabledColumns: string[] = [];

  @Input()
  defaultCheckedValue: string[] = [];

  @Input()
  disabledActions: { id: string; motivation: string }[] = [];

  @Input()
  isDetail: Boolean = false;

  @Input()
  isSortable: Boolean = true;


  @Input()
  actionsToCheck: string[] = [
    "ACT_DELETE",
    "ACT_CANCEL",
    "ACT_EDIT",
    "ACT_CARD_DOWNLOAD",
    "ACT_CARD_UPLOAD",
    "ACT_SAVE",
    "ACT_SAVE_REWORK",
    "ACT_SHOW_CAMERA",
    "ACT_EDIT_PROBLEM",
    "ACT_EDIT_DAM",
    "ACT_SHOW_DETAIL",
    "ACT_PREVIEW",
    "ACT_RELOAD",
    "ACT_AUDIT",
    "ACT_INNER_TABLE",
  ];

  //ACT_EDIT', 'ACT_ADD', 'ACT_CANCEL
  @Input()
  visibleItems: number = 1;

  @ViewChild("scrollViewport")
  private cdkVirtualScrollViewport;

  @ViewChild("tableTr", {static: false})
  private tableTr?: ElementRef;

  private trWidth = 0;

  public columnSize = {};

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
  }


  ngAfterContentChecked(): void {
    //console.log(this.tableTr)
  }

  ngOnInit(): void {
    this.getDataset();
  }

  ngOnChanges(change: SimpleChanges) {
  }

  ngAfterViewInit() {

    this.tableController.calculateColumnWidth = (table: ElementRef | undefined = this.tableTr) => {
      if (!!table) {
        // const trWidth = table.nativeElement.offsetWidth;
        // [MB 03/10/2024] ITEUR-77: cannot get width from table because grid size can change
        const trWidth = this.viewPort?.elementRef.nativeElement.offsetWidth || table.nativeElement.offsetWidth;

        const totalSizeColumn = (Object.keys(this.tableController.columnWeight).map(key => this.tableController.columnWeight[key]) ?? []).reduce((accumulator, currentValue) => accumulator + currentValue, 1)

        Object.keys(this.tableController.columnWeight).forEach(key => {
          this.tableController.columnSize[key] = Math.floor((totalSizeColumn < trWidth) ? ((trWidth / totalSizeColumn) * this.tableController.columnWeight[key]) : this.tableController.columnWeight[key])
        })

      }

    }

    //this.viewPort?.setTotalContentSize(this.tableController.dataSet.length * this.ITEM_SIZE); // ITEM_SIZE is the height of each item in pixels
  }


  getDataset() {
  }

  onCheckboxClick() {
    /*this.tableController.selectedRow = this.checkboxes
      .toArray()
      .filter((elementRef) => elementRef.nativeElement.checked)
      .map((elementRef) => elementRef.nativeElement.value);

    ////(this.tableController.selectedRow);*/
  }

  editRow(index: number) {
    this.tableController.editRow(index);
  }

  viewInnerTable(index: number){
    this.tableController.handleInnerGridVisibility(index)
  }

  editRowId(index: number, action: string) {
    this.tableController.commitEditDetail(index);


    this.actionEmitter(action + "_ID");
  }

  // ITOEM-5 added restart table action
  restartTask(index: number, action: string) {
    this.tableController.reloadRow(index, false);
    this.actionEmitter(action + "_ID");
  }

  // ITOEM-5 added audit table action
  showAudit(index: number, action: string) {
    this.tableController.reloadRow(index, false);
    this.actionEmitter(action + "_ID");
  }

  exitRow(index: number) {
    this.tableController.exitRow(index);
    this.tableController.dataSet = cloneDeep(this.tableController.dataSet);
  }

  commitEdit(index: number, action: string) {
    this.tableController.rowNumber = index;
    if (!this.tableController.dataSet[index].newRow) {
      this.tableController.commitEdit(index);
      this.datasets.datas.forEach((column) => {
        if (
          this.tableController.dataSet[index][column.columnName] !=
          this.tableController.dataSet[index].backup[column.columnName]
        ) {
          this.tableController.dataSet[index]["edited"] = true;
        }
      });
    }
    this.actionEmitter(action);
  }

  keyExistsIn(obj, key) {
    return keyExistsIn(obj, key);
  }

  onInputChange(index: number) {
    ////(this.tableController.dataSet[index]);
    this.tableController.dataSet[index].edited = true;
  }

  onAddClick() {
    ////(this.datasets)
    let newRowObject = {};

    if (
      !!this.tableController.dataSet &&
      this.tableController.dataSet.length > 0 &&
      !!this.tableController.dataSet[0] &&
      Object.keys(this.tableController.dataSet[0]).length > 0
    ) {
      newRowObject = _.cloneDeep(this.tableController.dataSet[0]);
      Object.keys(newRowObject).forEach((key) => {
        if (this.defaultCheckedValue.indexOf(key) > -1) {
          newRowObject[key] = true;
        } else {
          newRowObject[key] = "";
        }
      });
    } else {
      newRowObject = cloneDeep({});
      this.datasets.datas.forEach((column) => {
        if (this.defaultCheckedValue.indexOf(column.columnName) > -1) {
          newRowObject[column.columnName] = true;
        } else {
          newRowObject[column.columnName] = "";
        }
      });
    }

    this.tableController.dataSet = this.tableController.onAddClick(_.cloneDeep(newRowObject));

    this.onRowClick.emit(0);
  }

  deleteRow(index: number, rollback: boolean = false) {
    this.tableController.dataSet = this.tableController.deleteRow(index, rollback);

    if (!rollback) {
      this.actionEmitter("ACT_DELETE_ID");
    } else {
      this.actionEmitter("ACT_ROLLBACK");
    }

    console.log(this.tableController.dataSet);
  }

  cancelClaimRow(index: number, rollback: boolean = false) {
    if (index != -1) {
      this.tableController.dataSet = this.tableController.deleteRow(index, rollback);
      if (!rollback) {
        this.actionEmitter("ACT_WITHDRAW_ID");
      } else {
        this.actionEmitter("ACT_ROLLBACK");
      }
    } else {
      this.tableController.selection.selected.forEach((row) => {
        this.tableController.deletedRows.push(row);
      });
      this.actionEmitter("ACT_WITHDRAW_ID");
    }
  }

  saveRow(index: number, isEditing: Boolean) {
    if (isEditing) {
      this.tableController.commitEdit(index);
    } else {
      this.tableController.dataSet = this.tableController.saveRow(index);
    }
  }

  onCancelClick() {
    this.getDataset();
  }

  onPageChange(pageEvent: any) {
    this.tableController.onPaginationChange.emit(pageEvent);
  }

  actionEmitter(action: string, index?: number) {
    if (action.endsWith("_ALL")) {
      this.tableController.commitEditDetail(-1);
    }
    this.onActionEmit.emit(action);
  }

  convertValue(column: any, dataSetElementElement: any, row: number) {
    return (
      column.optionValues.filter((option) => option.id == dataSetElementElement)[0]?.name ??
      this.tableController.dataSet[row][column.columnName]
    );
  }

  onRadioButtonClick(column: any, i: number) {

    this.tableController.dataSet[i].edited = true;
    this.tableController.dataSet[i][column.columnName] = !this.tableController.dataSet[i][column.columnName];
    column.radioGroup?.forEach((columnName) => {
      this.tableController.dataSet[i][columnName] = !this.tableController.dataSet[i][column.columnName];
    });
    console.log(this.tableController.dataSet[i])
  }

  sortColumn(name: string) {
    let poolMap: Map<string, { order: number; type: "asc" | "desc" }> = new Map();
    this.tableController.sortedColumns.forEach((value, key, map) => {
      poolMap.set(key, value);
    });
    if (
      !!this.tableController.sortedColumns &&
      !!this.tableController.sortedColumns.get(name) &&
      (this.tableController.sortedColumns.get(name)?.type == "asc" ||
        this.tableController.sortedColumns.get(name)?.type == "desc")
    ) {
      poolMap.delete(name);
      if (this.tableController.sortedColumns.get(name)?.type == "asc") {
        const order = this.tableController.sortedColumns.get(name)?.order ?? 0;
        this.tableController.sortedColumns = new Map();
        this.tableController.sortedColumns.set(name, {order: order, type: "desc"});
        poolMap.forEach((value, key, map) => {
          this.tableController.sortedColumns.set(key, value);
        });
      } else {
        const order = this.tableController.sortedColumns.get(name)?.order ?? 0;
        this.tableController.sortedColumns.delete(name);
        this.tableController.sortedColumns.forEach((value, key, map) => {
          this.tableController.sortedColumns.set(key, {
            order: value.order > order ? value.order - 1 : value.order,
            type: value.type,
          });
        });
      }
    } else {
      if (!this.tableController.sortedColumns.get(name)) {
        let maxIndex = 0;
        this.tableController.sortedColumns.forEach((value, key, map) => {
          maxIndex = maxIndex < value.order ? value.order : maxIndex;
        });
        this.tableController.sortedColumns.set(name, {order: maxIndex + 1, type: "asc"});
      }
    }

    this.tableController.onSortChange.emit({
      sorting: this.tableController.sortedColumns,
      pageEvent: this.pageEvent,
    });
  }

  onClearSortClick() {
    this.tableController.sortedColumns = new Map();

    this.tableController.onSortChange.emit({
      sorting: this.tableController.sortedColumns,
      pageEvent: this.pageEvent,
    });
  }

  compare(act: { id: string; motivation: string }, id: string) {
    return act.id == id;
  }

  public get inverseOfTranslation(): string {
    if (!this.viewPort) {
      return "-0px";
    }
    const offset = this.viewPort.getOffsetToRenderedContentStart();

    return `-${offset}px`;
  }

  calculateContainerHeight(): string {
    const numberOfItems = this.tableController.dataSet?.length ?? 1;
    // This should be the height of your item in pixels
    const itemHeight = 48;
    // The final number of items you want to keep visible
    const visibleItems = 3;

    setTimeout(() => {
      // Makes CdkVirtualScrollViewport to refresh its internal size values after
      // changing the container height. This should be delayed with a "setTimeout"
      // because we want it to be executed after the container has effectively
      // changed its height. Another option would be a resize listener for the
      // container and call this line there but it may requires a library to detect
      // the resize event.

      this.cdkVirtualScrollViewport.checkViewportSize();
    }, 300);

    // It calculates the container height for the first items in the list
    // It means the container will expand until it reaches `200px` (20 * 10)
    // and will keep this size.
    if (numberOfItems <= visibleItems) {
      return `${itemHeight * numberOfItems}px`;
    }

    // This function is called from the template so it ensures the container will have
    // the final height if number of items are greater than the value in "visibleItems".
    return `${itemHeight * visibleItems}px`;
  }

  onResetSortClick() {
    this.onResetSort.emit(null)
  }

	get actReportAction(): any {
	  	let actReport = this.actionsets?.actions?.filter(act => act.id === 'ACT_REPORT');
		  if(actReport && actReport.length > 0) {
			  return actReport[0]
		  }else{
			  return null;
		  }

	}

  get actAddLanguage(): any {
    let actReport = this.actionsets?.actions?.filter(act => act.id === 'ACT_ADD_LANGUAGE');
    if(actReport && actReport.length > 0) {
      return actReport[0]
    }else{
      return null;
    }

  }

	get actExportReportAction(): any {
		let actReport = this.actionsets?.actions?.filter(act => act.id === 'ACT_EXPORT_REPORT');
		if(actReport && actReport.length > 0) {
			return actReport[0];
		}else{
			return null;
		}
	}

  // ITOEM-05: added logic to display regex errors on columns even in readonly mode
  withErrors(columnName: string, errors: any): string {
    if (!errors || !errors[columnName]) {
      return null;
    } else {
      switch (errors[columnName]['ERROR_STATUS']) {
        case 'ERROR':
          return 'invalid';
        case 'WARNING':
          return 'warning';
        default:
          return null;
      }
    }
  }

  withErrorsTooltip(columnName: string, errors: any): string {
    if (!errors || !errors[columnName]) {
      return null;
    }
    return errors[columnName]['ERROR_NOTICE'];
  }

  // ITOEM-05: added logic to hande notification
  notifyRecords(option: any) {
    const id = option.notificationId;
    const action = option.notificationTypology;
    const label = option.notificationLabel;
    const description = option.notificationDescription;
    const mySelection: any [] = this.tableController.selection.selected;
    const ids : any [] = mySelection.map((row) => row["ID"]);
    if (ids == null || ids.length == 0){
      this.dialog.open(DialogComponent, {
        data: {
          title: "No row selected",
          content: `Please select at least 1 row...`,
          confirmButtonLabel: "Ok",
          confirmButtonColor: "basic"
        }
      });
    } else if (!!id) {
      this.dialog.open(ContactGroupDialogComponent, {
        width: '65vw',
        data: {
          title: label + ' - ' + description,
          ids: ids,
          typology: action,
          notificationId: id,
          closeButtonLabel: "ACT_CLOSE",
          confirmButtonLabel: "ACT_SEND"
        }
      });
    }
  }

  showDetail(i: number) {
    // retrieve monitor ID and pass it to the detail component
    const id = this.tableController.dataSet[i].ID;
    const container = this.tableController.dataSet[i].CONTAINER;

    this.dialog.open(MonitorDetailComponent, {
      // width: '65vw',
      height: '50vw',
      data: {
        monitorId: id,
        container: container,
      }
    });
  }

  openNotificationHistory(i: number) {
    // retrieve monitor ID and pass it to the detail component
    const id = this.tableController.dataSet[i].ID;
    const container = this.tableController.dataSet[i].CONTAINER;

    this.dialog.open(MonitorNotificationComponent, {
      width: '65vw',
      // height: '50vw',
      data: {
        actionsets: this.actionsets,
        monitorId: id,
        container: container,
        apiParent: 'monitor',
        apiUrl: 'monitor-detail',
      }
    });
  }

  downloadFile(index){
    // save index to download
    this.tableController.rowNumber = index;
    this.actionEmitter("DOWNLOAD_FILE");
  }


  /**
   * Explanation of `metadataErrors`:
   *
   * `metadataErrors` is an object that can be passed to the DTOs displayed in the grid.
   * This object must be stored in the DTO within a field named `metadataErrors`.
   *
   * `metadataErrors` is a map with the following structure:
   *
   * Map<String, OEMCCPreviewErrorDTO>
   *
   * The `OEMCCPreviewErrorDTO` contains the following fields:
   *
   * - @JsonProperty("PE_ID"): `Integer peId`
   *
   * - @JsonProperty("PREVIEW_ID"): `Long previewId`
   *
   * - @JsonProperty("ERROR_ID"): `String errorId`
   *
   * - @JsonProperty("ERROR_COLUMN"): `String errorColumn`
   *
   * - @JsonProperty("ERROR_STATUS"): `String errorStatus`
   *   Indicates the type of error. Possible values are:
   *     - "WARNING"
   *     - "ERROR"
   *
   * - @JsonProperty("ERROR_ACTION"): `String errorAction`
   *   Specifies the action required to resolve or ignore the error.
   *
   * - @JsonProperty("ERROR_NOTICE"): `String errorNotice`
   *   A description of the error.
   *
   * - @JsonProperty("ERROR_ICON"): `String errorIcon`
   *
   * The most relevant fields for error handling are:
   * - `errorNotice`: Provides a description of the error.
   * - `errorStatus`: Indicates the severity of the error (either "WARNING" or "ERROR").
   * - `errorAction`: Specifies the type of action needed to resolve or ignore the error.
   * - `errorColumn`: Contains the name of the column where the error occurred, in `snake_case`.
   */

  /**
   * Determines the CSS class for a table cell based on metadata errors.
   *
   * @param column - The column object containing the column name.
   * @param dataset - The dataset object containing metadata errors.
   * @returns A CSS class string ('warning', 'invalid', or an empty string)
   *          based on the error status, or `null` if no errors are present.
   */

  getMetadataErrorCss(column: any, dataset: any) {
    if (!column || !dataset || !dataset.metadataErrors) {
      return null;
    }
    let error = dataset.metadataErrors[column.columnName];
    if (!error){
      return null
    }
    if (error["ERROR_STATUS"] == "WARNING"){
      return "warning";
    } else if (error["ERROR_STATUS"] == "ERROR"){
      return  "invalid";
    }
    return "";
  }

  /**
   * Retrieves a descriptive error message for a table cell based on metadata errors.
   *
   * @param column - The column object containing the column name.
   * @param dataset - The dataset object containing metadata errors.
   * @returns A string combining the error action and error notice,
   *          or `null` if no errors are present.
   */
  getMetadataErrorTooltip(column: any, dataset: any) {
    if (!column || !dataset || !dataset.metadataErrors) {
      return null;
    }
    let error = dataset.metadataErrors[column.columnName];
    if (!error){
      return null
    }
    return error["ERROR_ACTION"] + " - " + error["ERROR_NOTICE"];
  }
}
