import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FILTER_TYPE, FilterInterface} from './filter.interface';
import {IMultiSelectOption, IMultiSelectSettings} from "../../custom-multiselect/types";
import {GeneralService} from "../../../../services/crud/general.service";
import {Observable} from "rxjs";

@Component({
  selector: "custom-filter[filterModel]",
  templateUrl: "./custom-filter.component.html",
  styleUrls: ["./custom-filter.component.scss"],
})
export class CustomFilterComponent implements OnInit {
  filterType = FILTER_TYPE;

  @Input()
  filterModel!: FilterInterface;

  @Input()
  filterValues?: Observable<any>;

  @Output()
  filterModelChange: EventEmitter<FilterInterface> = new EventEmitter<FilterInterface>();

  @Output()
  onEnterEmit: EventEmitter<any> = new EventEmitter<any>();

  multisSelectSettings : IMultiSelectSettings = {
    displayAllSelectedText: true,
    itemClasses: 'custom-menu-item',
    closeOnClickOutside: true,
    pullRight: false,
    enableSearch: true,
    searchRenderLimit: 0,
    searchRenderAfter: 1,
    searchMaxLimit: 0,
    searchMaxRenderedItems: 0,
    checkedStyle: 'checkboxes',
    buttonClasses: 'btn-dropdown',
    containerClasses: 'dropdown-inline',
    selectionLimit: 0,
    minSelectionLimit: 0,
    closeOnSelect: false,
    autoUnselect: false,
    showCheckAll: false,
    showUncheckAll: false,
    fixedTitle: false,
    dynamicTitleMaxItems: 3,
    maxHeight: '300px',
    isLazyLoad: false,
    stopScrollPropagation: false,
    loadViewDistance: 1,
    selectAddedValues: false,
    ignoreLabels: false,
    maintainSelectionOrderInTitle: false,
    focusBack: true
  }

  constructor(private generalDataService: GeneralService) {
  }

  ngOnInit(): void {
  }

  onChange(event: any): void {
    //(this.filterModel);
    this.filterModel.filterSelectedOption = this.filterModel.lFilterOptions.filter((f) => f.id == event)[0];
    this.filterModelChange.emit(this.filterModel);
  }

  onFirstFilterChange(event: any): void {
    this.filterModel.firstValue = event;
    this.filterModelChange.emit(this.filterModel);
  }

  onSecondFilterChange(event: any): void {
    this.filterModel.secondValue = event;
    this.filterModelChange.emit(this.filterModel);
  }

  onClearClick() {
    ////(this.filterModel);
  }

  onEnter($event: any) {
    this.onEnterEmit.emit();
  }
}
