<div *ngIf="!!layout && !!layout['sectionLayouts'] && layout.id == 'LYO_LANGUAGE'">
  <div class="page-title sticky-top p-3">
    <h1 class="fs-1 fw-bold text-left">
      {{ ('LABEL_TRANSLATE_TITLE' | translate) }}
    </h1>
  </div>
  <div class="body-content">
    <input (ngModelChange)="search($event)" [matTooltip]="isDifference ? ('LABEL_FIX_AND_SAVE' | translate) : ('LABEL_SEARCH_KEY' | translate)" [ngModel]="searchValue" class="form-control"
           disabled="{{isDifference}}" placeholder="Search key..." style="align-self: flex-start"
           type="text"/>
    <div class="table-content">
      <table class="table table-striped fixed-table">
        <thead style="position: sticky; top: 0; background-color: var(--bs-gray-400); z-index: 999">
        <tr>
          <th></th>

          <th (click)="sort('KEY', _sort.column === 'KEY' ? !_sort.ascending : true)" class="text-center"
              style="cursor: pointer;">
            {{ 'KEY' | translate}}
          </th>
          <th (click)="sort(column, _sort.column === column ? !_sort.ascending : true)" *ngFor="let column of _languages; index as i"
              class="text-center" style="cursor: pointer;">
            {{ column }}
            <button (click)="deleteLanguage(column)" class="table-button" color="primary" mat-icon-button
                    matTooltip="{{'LABEL_DELETE' | translate}}">
              <mat-icon>delete</mat-icon>
            </button>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let t of translations; index as i">
          <td>
            <button (click)="removeTranslation(t.key)" *ngIf="isAdminIt" class="table-button" color="primary"
                    mat-icon-button matTooltip="{{'LABEL_DELETE' | translate}}">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
          <td>
            <input (focus)="mark(t.id)" (ngModelChange)="update(t, $event, 0)" [class.is-invalid]="!t.valid"
                   [disabled]="!isAdminIt" [ngModel]="t.key | rmDQ" class="form-control editable-input" id="input-key-${t.id}"
                   placeholder="Key..." type="text"/>
            <div class="invalid-feedback error-vscode" id="input-key-${t.id}-feedback">{{ t.error }}</div>
          </td>
          <td *ngFor="let l of _languages; index as il">
            <input (focus)="mark(t.id)" (ngModelChange)="update(t, $event, il+1, l)"
                   [class.is-invalid]="!t.valid"
                   [matTooltip]="((translationsBck[i]).languages[l] | rmDQS)" [ngClass]="{'is-edited': (t.edits.indexOf(l) > -1 || ((translationsBck[i]).languages[l] | rmDQS) != (t.languages[l] | rmDQS))}" [ngModel]="t.languages[l] | rmDQS"
                   class="form-control editable-input" id="{{'input-key-'+t.id}}" placeholder="Translation..."
                   type="text"/>
          </td>
        </tr>
        </tbody>
      </table>

    </div>

  </div>
  <div class="action-content">
    <ng-container class="mt-lg-5">
      <action-bar>
        <div class="col-auto" leftBtn>
          <custom-btn [compareTo]="languages" [masterFrom]="languagesBck" backNavigation
                      color="primary">{{ 'LABEL_BACK' | translate }}
          </custom-btn>
        </div>
        <ng-container rightBtn>
          <div class="col-auto">
            <custom-btn (click)="onSaveClick($event)" color="accent">{{ 'LABEL_SAVE' | translate }}
            </custom-btn>
          </div>
          <div class="col-auto">
            <custom-btn (click)="onAddLanguage()" color="accent">{{ 'ADD_LANGUAGE' | translate }}
            </custom-btn>
          </div>
          <div *ngIf="isAdminIt" class="col-auto">
            <custom-btn (click)="onAddTranslation()" color="accent">{{ 'ADD_TRANSLATION' | translate }}
            </custom-btn>
          </div>
        </ng-container>
      </action-bar>
    </ng-container>
  </div>
</div>
