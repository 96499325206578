import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FILTER_TYPE, FilterInterface} from "../custom-filter/filter.interface";
import {MatDialog} from '@angular/material/dialog';
import {
  DialogData,
  FilterSettingsComponent,
} from '../filter-settings/filter-settings.component';
import {FilterControllerService} from '../../../../services/filter-controller.service';
import {AuthService} from "../../../../services/auth.service";
import {cloneDeep} from "lodash";
import {MatAccordion} from '@angular/material/expansion';

@Component({
  selector: 'filter-panel[pageReference]',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
})
export class FilterPanelComponent {
  @Input()
  filterModels: FilterInterface[] = [];


  @ViewChild(MatAccordion) expansionPanelContainer?: MatAccordion;

  @Output()
  onFilterClick: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  filterController!: FilterControllerService;

  @Input()
  pageReference!: string;

  @Output()
  onResetFilterClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public dialog: MatDialog, private authService: AuthService) {
  }

  openDndDialog(event: MouseEvent) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(FilterSettingsComponent, {
      data: {
        dataTitle: 'FILTER_SETTINGS',
        viewList: cloneDeep(this.filterController.filterModels
          .filter((fil) => !fil.isHide)
          .map((fil) => {
            return {
              id: fil.filterId,
              label: fil.columnLabel,
            };
          })),
        hideList: cloneDeep(this.filterController.filterModels
          .filter((fil) => fil.isHide)
          .map((fil) => {
            return {
              id: fil.filterId,
              label: fil.columnLabel,
            };
          }))
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
        ////('The dialog was closed');
        ////(result);
        if (result?.action == 'reset') {
          this.resetFilter();
        } else if (!!result.hideList) {
          const hideList = result.hideList.map((fil) => fil.id);
          console.log(result.viewList);
          this.filterController.filterModels = this.filterController.filterModels.map((filter) => {

            filter.isHide = hideList.some((elem) => elem === filter.filterId);
            return filter;
          }).sort((a, b) => {
            return result.viewList.findIndex(filter => filter.id == a.filterId) - result.viewList.findIndex(filter => filter.id == b.filterId)
          });
          const filterSettings = localStorage.getItem('filter');
          if (!!filterSettings) {
            const filters = JSON.parse(filterSettings);
            filters[this.pageReference] = {
              instance: cloneDeep(this.filterController.filterModels).map((model) => {
                model.firstValue = null;
                return model;
              }),
              instant: new Date(),
            };
            localStorage.setItem('filter', JSON.stringify(filters));
            this.authService.setFilter(JSON.stringify(filters)).subscribe(res => {
              this.filterController.refreshViewedFilter();
            });
          } else {
            const filters = {};
            filters[this.pageReference] = {
              instance: cloneDeep(this.filterController.filterModels).map((model) => {
                model.firstValue = null;
                return model;
              }),
              instant: new Date(),
            };
            localStorage.setItem('filter', JSON.stringify(filters));

            this.authService.setFilter(JSON.stringify(filters)).subscribe(res => {
              this.filterController.refreshViewedFilter();
            });
          }
        }

      }
    );
  }

  applyFilter() {
    this.expansionPanelContainer?.closeAll();
    this.onFilterClick.emit(true);
  }

  clearFilter() {
    //(this.filterController);
    this.filterController.filterModels.forEach((model) => {
      model.firstValue = null;
      model.filterSelectedOption = model.lFilterOptions[0];
    });

  }


  resetFilter() {
    const filterSettings = localStorage.getItem('filter');
    if (!!filterSettings) {
      let filters = JSON.parse(filterSettings);
      filters[this.pageReference] = {};
      delete filters[this.pageReference]
      localStorage.setItem('filter', JSON.stringify(filters));
      this.authService.setFilter(JSON.stringify(filters)).subscribe(res => {
        this.onResetFilterClick.emit(true)
      });

    }
  }

}
