<h1 class="fs-1 fw-bold text-center mt-10 mb-5">{{ "PAG_FUNCTIONS_TITLE" | translate }}</h1>

<!--<div class="text-center pt-3">
  <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 50 50">
    <path fill="#041E41" d="M0 49.993h49.98V0H0v49.993zM32.035 3.818c8.834 2.896 15.218 11.273 15.242 21.169.024 9.735-6.231 18.092-15.242 21.18V3.818zm-7.053-1.133c1.596 0 3.296.213 4.712.495 0 11.326-10.952 20.647-22.255 20.647l-4.726-.007c.775-11.61 10.38-21.135 22.269-21.135zm-17.539 23.5c11.301 0 22.249 9.311 22.249 20.626-1.414.289-3.199.504-4.71.504-11.893-.016-21.823-9.57-22.272-21.13h4.733zm59.268 3.204v-3.151h10.4v-4.017H66.709v-5.849h11.065v-4.008H62.452v16.843c0 4.258 1.813 7.17 6.069 7.768.085.021.169.037.247.039h.077c.436.049.881.081 1.348.081h7.876v-4.003h-7.841c-1.353 0-3.517-.668-3.517-3.703zm14.383 7.712h4.259V12.358h-4.259v24.743zm16.762-18.457c-5.383 0-9.412 3.709-9.412 9.484v.143c0 5.634 3.886 9.271 9.519 9.271 2.709 0 5.24-.748 7.557-3.21l-2.567-2.495c-1.355 1.355-2.674 2.068-4.848 2.068-3.173 0-5.276-1.854-5.419-4.707h13.974c.071-.713.071-1.497.071-1.497.002-5.028-3.528-9.057-8.875-9.057zm-5.062 7.559c.178-2.176 2.389-3.923 5.062-3.923 2.495 0 4.527 1.783 4.527 3.923h-9.589zm25.906-3.744c1.925 0 3.352.714 4.563 1.961l2.638-2.746c-2.068-2.211-4.527-3.102-7.201-3.102-5.419 0-9.768 4.029-9.768 9.52s4.349 9.521 9.768 9.521c2.674 0 5.134-.891 7.201-3.102l-2.638-2.746c-1.211 1.248-2.638 1.961-4.563 1.961-3.138 0-5.526-2.39-5.526-5.634s2.388-5.633 5.526-5.633zm14.111 6.937h-.005v-6.317h3.873v-3.87h-3.868v-6.85h-4.259v17.288l.012-.001c.122 4.02 1.948 6.758 6.057 7.335.085.021.169.037.247.038h.077c.436.049.881.082 1.348.082h.451l.015-4.003h-.431c-1.353 0-3.517-.667-3.517-3.702zm11.676-7.526v-2.66h-4.224v17.891h4.224v-9.408c0-2.946 1.881-4.793 4.826-4.793.674 0 1.278.07 1.775.178v-4.117a9.118 9.118 0 0 0-1.277-.107c-2.556.001-4.472 1.173-5.324 3.016zm17.015-3.193c-5.431 0-9.762 4.116-9.762 9.478"></path>
  </svg>

</div>-->
<h3 class="fs-1 fw-bold text-center mt-2 mb-2">{{ "UPLOAD_ZIP_FILE" | translate }}</h3>

<div class="d-flex align-items-center justify-content-center pt-3">
  <custom-btn (click)="uploadDoc()" [color]="'primary'"> UPLOAD ZIP</custom-btn>
  <div class="progress-bar-container">
    <div [style.width.%]="loadedDoc" class="progress-bar-content">{{ loadedDoc }}%</div>
  </div>
</div>

<h3 class="fs-1 fw-bold text-center mt-2 mb-2">{{ "DELETE_PROBLEM_LIST" | translate }}</h3>

<div class="d-flex align-items-center justify-content-center pt-3">
  <custom-btn (click)="deleteProblem()" *ngIf="!isDeletingProblem" [color]="'primary'"> DELETE PROBLEM</custom-btn>
  <div *ngIf="isDeletingProblem" class="d-flex align-items-center justify-content-center pt-3">
    <custom-text-field [(value)]="problemList" [appearance]="'fill'" [placeholder]="'Problems'">
    </custom-text-field>
    <custom-btn (click)="deleteProblem()" [color]="'primary'"> DELETE</custom-btn>
  </div>
</div>

<div style="width: 100%; height: 100%; overflow-y: scroll">
  <h3 class="fs-1 fw-bold text-center mt-2 mb-2">{{ "Move damage file" | translate }}</h3>
  <div class="d-flex align-items-center justify-content-center pt-3">
    <custom-btn (click)="moveDamageFile()" [color]="'primary'"> MOVE</custom-btn>
  </div>

  <h3 class="fs-1 fw-bold text-center mt-2 mb-2">{{ "Move problem" | translate }}</h3>
  <div class="d-flex align-items-center justify-content-center pt-3">
    <custom-btn (click)="moveProblemFile()" [color]="'primary'"> MOVE</custom-btn>
  </div>


  <h3 class="fs-1 fw-bold text-center mt-2 mb-2">{{ "Move Card" | translate }}</h3>
  <div class="d-flex align-items-center justify-content-center pt-3">
    <custom-btn (click)="moveCardFile()" [color]="'primary'"> MOVE</custom-btn>
  </div>

  <h3 class="fs-1 fw-bold text-center mt-2 mb-2">{{ "Move Other File" | translate }}</h3>
  <div class="d-flex align-items-center justify-content-center pt-3">
    <custom-btn (click)="moveOtherFile()" [color]="'primary'"> MOVE</custom-btn>
  </div>

  <h3 class="fs-1 fw-bold text-center mt-2 mb-2">{{ "Move Scorecard File" | translate }}</h3>
  <div class="d-flex align-items-center justify-content-center pt-3">
    <custom-btn (click)="moveScorecardFile()" [color]="'primary'"> MOVE</custom-btn>
  </div>

  <h3 class="fs-1 fw-bold text-center mt-2 mb-2">{{ "Move Scorecard problem File" | translate }}</h3>
  <div class="d-flex align-items-center justify-content-center pt-3">
    <custom-btn (click)="moveScorecardProblemFile()" [color]="'primary'"> MOVE</custom-btn>
  </div>

  <h3 class="fs-1 fw-bold text-center mt-2 mb-2">{{ "Change layer of files" | translate }}</h3>
  <div class="d-flex align-items-center justify-content-center pt-3">
    <custom-btn (click)="changeLayer()" [color]="'primary'"> CHANGE LAYER</custom-btn>
  </div>

  <h3 class="fs-1 fw-bold text-center mt-2 mb-2">{{ "Delete files from MEDIA" | translate }}</h3>
  <div class="d-flex align-items-center justify-content-center pt-3">
    <custom-btn (click)="deleteAllFiles()" [color]="'primary'"> DELETE ALL</custom-btn>
  </div>
  <h3 class="fs-1 fw-bold text-center mt-2 mb-2">{{ "Delete files from SCORECARD MEDIA" | translate }}</h3>
  <div class="d-flex align-items-center justify-content-center pt-3">
    <custom-btn (click)="deleteAllScorecardFiles()" [color]="'primary'"> DELETE ALL</custom-btn>
  </div>

  <h3 class="fs-1 fw-bold text-center mt-2 mb-2">{{ "Fix duplicates" | translate }}</h3>
  <div class="d-flex align-items-center justify-content-center pt-3">
    <custom-btn (click)="fixDuplicates()" [color]="'primary'"> FIX DUPLICATES</custom-btn>
  </div>
</div>

<!--<data-table></data-table>-->
