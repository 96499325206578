import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {FILTER_TYPE, getKeyByValue} from "../components/shared/filter/custom-filter/filter.interface";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {refactorName} from "../utils/generic";
import {Layout, PageLayout, SectionLayout} from "../models/layout";
import {RegExpInterface} from "../models/RegExpInterface";

const regex_date = new RegExp("\\_\\-d\\_", "g");

const regex_date_before_today = new RegExp("\\_<00\\_", "g");

const regex_date_after_today = new RegExp("\\_>00\\_", "g");

const regex = new RegExp("\\_\\-\\_", "g");

@Injectable({
  providedIn: "root",
})
export class LayoutServiceService {
  constructor(private http: HttpClient) {
  }

  getPageLayout(page: string): Observable<PageLayout | null> {
    const httpHeader: Object = {
      params: new HttpParams().set("page", page),
    };
    return this.http.get<_ResponseLayout>(environment.authApiUrl + "layout/OEM", httpHeader).pipe(
      map((res) => {
        if (res.iResponseCode != 200) {
          return null;
        } else {
          const pageLayout: PageLayout = {
            id: res.oContent.pageId,
            layouts:
              res.oContent.userPageLayouts.map((userPageLayout) => {
                const layout: Layout = {
                  id: userPageLayout.layoutId,
                  sectionLayouts:
                    userPageLayout.layoutSections.map((layoutSection) => {
                      const sectionLayout: SectionLayout = {
                        id: layoutSection.layoutSectionId,
                        actionset:
                          layoutSection.actionsets.map((actionset) => {
                            return {
                              id: actionset.actionsetId,
                              actions: actionset.actions.map((action) => {
                                return {
                                  id: action.actionId,
                                  icon: action.actionIcon ?? "",
                                  label: action.actionLabel ?? "",
                                  enableToRow: action.actionRow,
                                };
                              }),
                              groupable: actionset.isGroupable,
                            };
                          }) ?? [],
                        dataset:
                          layoutSection.datasets.map((dataset) => {
                            return {
                              id: dataset.datasetId,
                              tableName: dataset.tableName,
                              multiselect: dataset.isMultiselect,
                              datas: dataset.lData.filter(data => !data.isHidden).map((data) => {
                                return {
                                  id: data.dataId,
                                  type: data.dataType,
                                  columnName: data.columnName,
                                  columnLabel: data.columnLabel,
                                  columnPosition: data.columnPosition,
                                  editable: data.isEditable,
                                  selectOptions: data.filterValues ?? [],
                                  isPk: data.isPk,
                                  optionValues: [],
                                  valuesFrom:
                                    data.dataType == "DTY_LIST" && !data.isFixList ? data.filterValues ?? "" : "",
                                  radioGroup: data.radioGroup ?? [],
                                  isRequiredField: data.requiredField ?? false,
                                  maxLength: data.maxLength ?? null,
                                  regExpList: (data.regexpList ? JSON.parse(data.regexpList) : []) as RegExpInterface[],
                                  patternInfo: data.patternInfo ?? null,
                                  fixListValues: data.dataType == "DTY_LIST" && data.isFixList ? data.filterValues ?? "" : "",
                                  sortOrder: data.sortOrder ?? undefined,
                                  sortCriteria: data.sortCriteria ?? undefined,
                                  isResultsetLinked: data.isResultsetLinked
                                };
                              }),
                              filterset: dataset.lData
                                .filter((data) => data.isFilter)
                                .map((data) => {
                                  data.filterTypes = data.filterTypes.sort((a, b) => {
                                    return a.filterRow - b.filterRow
                                  });
                                  return {
                                    isResultsetLinked: data.isResultsetLinked,
                                    columnLabel: data.columnLabel,
                                    columnName: refactorName(data.columnName),
                                    filterId: data.dataId,
                                    filterValueSearch: data.filterValues,
                                    isHide: data.isHidden,
                                    filterSelectedOption: {
                                      id: data.filterTypes[0]?.filterValue,
                                      name: data.filterTypes[0]?.filterDescr,
                                      haveSecondParam:
                                        regex.test(data.filterTypes[0]?.filterValue) ||
                                        regex_date.test(data.filterTypes[0]?.filterValue),
                                      haveFirstParam:
                                        data.filterTypes[0]?.filterValue != "_£_" &&
                                        data.filterTypes[0]?.filterValue != "_n£_" &&
                                        !regex_date_before_today.test(data.filterTypes[0]?.filterValue) &&
                                        !regex_date_after_today.test(data.filterTypes[0]?.filterValue),
                                      classes: "",
                                    },
                                    filterType:
                                      FILTER_TYPE[getKeyByValue(data.filterTypes[0]?.filterId)] ?? FILTER_TYPE.TEXT,
                                    filterValues: data.filterValues,
                                    firstValue: undefined,
                                    lFilterOptions: data.filterTypes.map((filter) => {
                                      return {
                                        id: filter.filterValue,
                                        name: filter.filterDescr,
                                        haveSecondParam:
                                          regex.test(filter.filterValue) || regex_date.test(filter.filterValue),
                                        haveFirstParam:
                                          filter.filterValue != "_£_" &&
                                          filter.filterValue != "_n£_" &&
                                          !regex_date_before_today.test(filter.filterValue) &&
                                          !regex_date_after_today.test(filter.filterValue),
                                        classes: "",
                                      };
                                    }),
                                    secondValue: undefined,
                                    selectedOption: "",
                                  };
                                }),
                            };
                          }) ?? [],
                      };
                      return sectionLayout;
                    }) ?? [],
                };

                return layout;
              }) ?? [],
          };

          return pageLayout;
        }
      })
    );
  }
}

// RESPONSE LAYOUT

interface _ResponseLayout {
  sMessage: string;
  iResponseCode: number;
  oContent: _PageLayout;
}

interface _PageLayout {
  pageId: string;
  userEmail: string;
  appId: string;
  userPageLayouts: _Layout[];
}

interface _Layout {
  layoutId: string;
  layoutSections: _SectionLayout[];
}

interface _SectionLayout {
  layoutSectionId: string;
  actionsets: _ActionSet[];
  datasets: _Dataset[];
}

interface _ActionSet {
  actionsetId: string;
  isGroupable: boolean;
  actions: _Action[];
}

interface _Action {
  actionId: string;
  actionLabel: string | null;
  actionIcon: string | null;
  actionRow: boolean;
}

interface _Dataset {
  datasetId: string;
  tableName: string;
  isMultiselect: boolean;
  lData: _Data[];
}

interface _Data {
  dataId: string;
  dataType: string;
  columnName: string;
  columnLabel: string;
  columnPosition: number;
  isFilter: boolean;
  isHidden: boolean;
  isCalculated: boolean;
  isEditable: boolean;
  isExportable: boolean;
  isFixList: boolean;
  filterTypes: _FilterType[];
  filterValues: any;
  isPk: boolean;
  radioGroup: string[] | undefined | null;
  regexpList: string | undefined | null;
  patternInfo: string | undefined | null;
  maxLength: number | undefined | null;
  requiredField: boolean | undefined | null;
  sortOrder?: number;
  sortCriteria?: 'asc' | 'desc';
  isResultsetLinked: boolean;
}

interface _FilterType {
  "filterId": string,
  "filterRow": number,
  "filterDescr": string,
  "filterValue": string
}


