<div (offClick)="clickedOutside()" *ngIf="options" [class.open]="isVisible" [ngClass]="extraClass"
     class="form-group dropdown-inline">
  <button #selectBtn (click)="toggleDropdown($event)" [class.deactive]="disabled" [disabled]="disabled"
          [matTooltip]="disabled ? '' : title" [ngClass]="settings.buttonClasses" [ssAutofocus]="!focusBack"
          class="filter-options" color="basic" mat-stroked-button>
    <label class="textLabelBtn">
      {{ title }}
    </label>

    <mat-icon style="align-self: center;">expand_more</mat-icon>

  </button>
  <div #scroller (keydown.shift.tab)="focusItem(-1, $event)" (keydown.tab)="focusItem(1, $event)"
       (scroll)="settings.isLazyLoad ? checkScrollPosition($event) : null"
       (wheel)="settings.stopScrollPropagation ? checkScrollPropagation($event, scroller) : null"
       *ngIf="isVisible"
       [class.dropdown-menu-right]="settings.pullRight"
       [class.pull-right]="settings.pullRight"
       [ngClass]="{'chunkydropdown-menu': settings.checkedStyle == 'visual', 'likeText': extraClass == 'likeText'}"
       [style.max-height]="settings.maxHeight" class="dropdown-menu custom-select"
       style="display: block; height: auto; overflow-y: auto;">
    <div *ngIf="settings.enableSearch && (renderFilteredOptions.length > 1 || filterControl.value.length > 0)"
         class="input-group search-container">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">
          <i aria-hidden="true" class="fa fa-search"></i>
        </span>
      </div>
      <input [formControl]="filterControl" [placeholder]="texts.searchPlaceholder" [ssAutofocus]="true" class="form-control"
             class="form-control"
             type="text">
      <div *ngIf="filterControl.value.length>0" class="input-group-append">
        <button (click)="clearSearch($event)" class="btn btn-default btn-secondary" type="button">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
    <a (click)="checkAll()" *ngIf="settings.showCheckAll && !disabledSelection && renderFilteredOptions.length > 1" class="dropdown-item check-control check-control-check" href="javascript:"
       role="menuitem"
       tabindex="-1">
      <span style="width: 16px;"><span
        [ngClass]="{'glyphicon glyphicon-ok': settings.checkedStyle !== 'fontawesome','fa fa-check': settings.checkedStyle === 'fontawesome'}"></span></span>
      {{ texts.checkAll }}
    </a>
    <a (click)="uncheckAll()" *ngIf="settings.showUncheckAll && !disabledSelection && renderFilteredOptions.length > 1" class="dropdown-item check-control check-control-uncheck" href="javascript:"
       role="menuitem"
       tabindex="-1">
      <span style="width: 16px;"><span
        [ngClass]="{'glyphicon glyphicon-remove': settings.checkedStyle !== 'fontawesome','fa fa-times': settings.checkedStyle === 'fontawesome'}"></span></span>
      {{ texts.uncheckAll }}
    </a>
    <a *ngIf="settings.showCheckAll || settings.showUncheckAll" class="dropdown-divider divider"
       href="javascript:"></a>
    <a *ngIf="!renderItems" class="dropdown-item empty" href="javascript:">{{ texts.searchNoRenderText }}</a>
    <a *ngIf="renderItems && !renderFilteredOptions.length" class="dropdown-item empty"
       href="javascript:">{{ texts.searchEmptyResult }}</a>
    <a (click)="fakeBlur($event)" (click)="setSelected($event, option)" (keydown.enter)="setSelected($event, option)"
       (keydown.space)="setSelected($event, option)" *ngFor="let option of renderFilteredOptions; trackBy: trackById"
       [class.active]="isSelected(option)"
       [class.dropdown-header]="option.isLabel" [matTooltip]="option.name" [ngClass]="option.classes"
       [ngStyle]="getItemStyle(option)"
       [ssAutofocus]="option !== focusedItem" class="dropdown-item" href="javascript:"
       tabindex="-1">
      <span *ngIf="!option.isLabel; else label" [ngStyle]="getItemStyleSelectionDisabled()" [style.padding-left]="this.parents.length>0&&this.parents.indexOf(option.id)<0&&'30px'"
            class="custom-span-checkbox"
            role="menuitem" tabindex="-1">
        <ng-container [ngSwitch]="settings.checkedStyle">
          <input #customCb (click)="preventCheckboxCheck($event, option)" *ngSwitchCase="'checkboxes'" [checked]="isSelected(option)"
                 [disabled]="isCheckboxDisabled(option)"
                 [ngStyle]="getItemStyleSelectionDisabled()" class="custom-checkbox" type="checkbox"/>
          <span *ngSwitchCase="'glyphicon'" [class.glyphicon-lock]="isCheckboxDisabled(option)" [class.glyphicon-ok]="isSelected(option)"
                class="glyphicon" style="width: 16px;"></span>
          <span *ngSwitchCase="'fontawesome'" style="width: 16px;display: inline-block;">
            <span *ngIf="isSelected(option)"><i aria-hidden="true" class="fa fa-check"></i></span>
            <span *ngIf="isCheckboxDisabled(option)"><i aria-hidden="true" class="fa fa-lock"></i></span>
          </span>
          <span *ngSwitchCase="'visual'"
                style="display:block;float:left; border-radius: 0.2em; border: 0.1em solid rgba(44, 44, 44, 0.63);background:rgba(0, 0, 0, 0.1);width: 5.5em;">
            <div [ngClass]="{'slideron': isSelected(option)}" class="slider">
              <img *ngIf="option.image != null" [src]="option.image"
                   style="height: 100%; width: 100%; object-fit: contain"/>
              <div *ngIf="option.image == null"
                   style="height: 100%; width: 100%;text-align: center; display: table; background-color:rgba(0, 0, 0, 0.74)">
                <div class="content_wrapper">
                  <span class="glyphicon glyphicon-eye-close" style="font-size:3em;color:white"></span>
                </div>
              </div>
            </div>
          </span>
        </ng-container>
        <!-- ITOEM-5: added status filter -->
        <mat-icon *ngIf="option.matIcon != null " [ngStyle]="{color: option.color}">{{option.matIcon}}</mat-icon>
        <span (click)="fakeBlur($event)"
              [class.disabled]="isCheckboxDisabled(option)" [ngClass]="settings.itemClasses"
              [ngClass]="{'chunkyrow': settings.checkedStyle == 'visual' }" [style.font-weight]="this.parents.indexOf(option.id)>=0?'bold':'normal'">
          {{ option.name }}
        </span>
      </span>
      <ng-template #label>
        <span [class.disabled]="isCheckboxDisabled(option)">{{ option.name }}</span>
      </ng-template>
    </a>
  </div>
</div>
