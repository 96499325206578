<mat-toolbar [ngClass]="{'topbar-test':isDevMode(), 'topbar-prod':!isDevMode()}">
  <button (click)="onMenuClick()" aria-label="Example icon-button with menu icon" mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>
  <span [routerLink]="'/'" class="navlogo">
    <svg height="50" id="Layer_1" viewBox="0 0 779.86 241.03" width="161" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <style>.cls-1 {
      fill: #041e50;
    }</style>
  </defs>
  <path class="cls-1"
        d="M42.85,120.43c41.32,0,81.35,34.04,81.35,75.4-5.17,1.05-11.7,1.84-17.22,1.84-43.48-.06-79.79-34.98-81.43-77.24h17.31ZM106.97,34.52c5.84,0,12.05,.78,17.23,1.81,0,41.4-40.05,75.48-81.37,75.48l-17.28-.03c2.83-42.44,37.95-77.26,81.42-77.26m25.79,4.14c32.3,10.59,55.64,41.21,55.73,77.39,.09,35.58-22.78,66.14-55.73,77.42V38.66ZM15.63,207.46H198.38V24.7H15.63V207.46Z"/>
  <g>
    <path class="cls-1"
          d="M250.68,25.01h52.27v13.64h-37.18v19.92h34.77v13.64h-34.77v10.62c0,10.38,7.48,12.68,12.07,12.68h25.96v13.64h-26.68c-17.87,0-26.44-9.66-26.44-26.8V25.01Z"/>
    <path class="cls-1" d="M314.42,25.01h14.49V109.16h-14.49V25.01Z"/>
    <path class="cls-1"
          d="M337.23,78.74c0-19.56,13.64-32.11,31.87-32.11s30.06,13.64,30.06,30.66c0,0,0,2.66-.24,5.07h-47.32c.48,9.66,7.61,15.94,18.35,15.94,7.36,0,11.83-2.41,16.42-7l8.69,8.45c-7.85,8.33-16.42,10.87-25.59,10.87-19.07,0-32.23-12.31-32.23-31.39v-.48Zm47.2-6.52c0-7.24-6.88-13.28-15.33-13.28-9.05,0-16.54,5.92-17.14,13.28h32.48Z"/>
    <path class="cls-1"
          d="M437.43,46.38c9.05,0,17.38,3.02,24.39,10.5l-8.93,9.3c-4.1-4.23-8.93-6.64-15.45-6.64-10.62,0-18.71,8.09-18.71,19.08s8.09,19.07,18.71,19.07c6.52,0,11.35-2.41,15.45-6.64l8.93,9.3c-7,7.48-15.33,10.5-24.39,10.5-18.35,0-33.08-13.64-33.08-32.23s14.73-32.23,33.08-32.23Z"/>
    <path class="cls-1"
          d="M468.58,25.01h14.49v23.3h13.4v13.16h-13.4v21.25c0,9.3,5.07,13.04,12.68,13.04h.72v13.64h-2.9c-10.02,0-24.99-5.07-24.99-25.72V25.01Z"/>
    <path class="cls-1"
          d="M503.47,48.31h14.37v9.05c2.9-6.28,9.42-10.26,18.11-10.26,1.57,0,3.74,.24,4.35,.36v14c-1.69-.36-3.74-.6-6.04-.6-10.02,0-16.42,6.28-16.42,16.3v31.99h-14.37V48.31Z"/>
    <path class="cls-1"
          d="M575.3,46.5c18.35,0,33.2,14,33.2,32.23s-14.85,32.23-33.2,32.23-33.2-14-33.2-32.23,14.73-32.23,33.2-32.23Zm0,51.31c10.62,0,18.83-8.33,18.83-19.08s-8.21-19.08-18.83-19.08-18.83,8.33-18.83,19.08,8.09,19.08,18.83,19.08Z"/>
    <path class="cls-1" d="M616.83,25.01h14.49V109.16h-14.49V25.01Z"/>
    <path class="cls-1"
          d="M641.57,48.31h14.61v35.49c0,9.54,5.67,13.64,13.28,13.64s13.28-4.1,13.28-13.76V48.31h14.61v36.34c0,17.63-12.8,26.08-28.01,26.08s-27.77-8.33-27.77-25.71V48.31Z"/>
    <path class="cls-1"
          d="M725.24,77.05l-21.01-28.73h15.7l13.28,18.23,13.16-18.23h15.82l-21.01,28.61,23.06,32.23h-15.57l-15.45-21.49-15.45,21.49h-15.57l23.06-32.11Z"/>
  </g>
  <g>
    <path class="cls-1"
          d="M291.6,122.15c12.31,0,23.18,4.59,31.27,12.68l-10.02,9.17c-5.43-5.31-12.92-8.21-21.25-8.21-16.9,0-29.7,12.92-29.7,29.94s12.8,29.82,29.7,29.82c7.97,0,14.97-2.66,20.16-7.24v-15.45h-23.42v-13.4h37.67v33.92c-7.85,9.66-20.28,15.82-34.41,15.82-24.75,0-44.67-18.35-44.67-43.46s19.92-43.58,44.67-43.58Z"/>
    <path class="cls-1"
          d="M336.75,146.9h14.37v9.05c2.9-6.28,9.42-10.26,18.11-10.26,1.57,0,3.74,.24,4.35,.36v14c-1.69-.36-3.74-.6-6.04-.6-10.02,0-16.42,6.28-16.42,16.3v31.99h-14.37v-60.85Z"/>
    <path class="cls-1"
          d="M408.58,145.09c18.35,0,33.2,14,33.2,32.23s-14.85,32.23-33.2,32.23-33.2-14-33.2-32.23,14.73-32.23,33.2-32.23Zm0,51.31c10.62,0,18.83-8.33,18.83-19.08s-8.21-19.07-18.83-19.07-18.83,8.33-18.83,19.07,8.09,19.08,18.83,19.08Z"/>
    <path class="cls-1"
          d="M449.39,146.9h14.61v35.49c0,9.54,5.67,13.64,13.28,13.64s13.28-4.1,13.28-13.76v-35.37h14.61v36.34c0,17.63-12.8,26.08-28.01,26.08s-27.77-8.33-27.77-25.71v-36.7Z"/>
    <path class="cls-1"
          d="M515.42,146.9h14.49v8.81c2.05-5.67,9.18-10.14,19.44-10.14,17.14,0,30.42,13.76,30.42,31.75s-13.28,31.75-30.42,31.75c-10.26,0-17.38-4.47-19.44-10.14v32.11h-14.49v-84.15Zm31.99,49.02c10.26,0,17.99-7.97,17.99-18.59s-7.73-18.59-17.99-18.59-18.59,7.97-18.59,18.59,8.09,18.59,18.59,18.59Z"/>
  </g>
</svg>
    <h1 class="topbar-title">OEMCC</h1>
  </span>

  <span class="toolbar-spacer"></span>
  <span [matMenuTriggerFor]="language" class="menu-language-item"><img
    alt="{{ (!!translate.currentLang ? translate.currentLang : 'gb') | translate }}" height="15" src="{{'assets/flags/4x3/'+(!!translate.currentLang ? translate.currentLang : 'gb')+'.svg'}}"
    width="20"/>
  </span>
  <mat-menu #language="matMenu">
    <span (click)="switchLang(language)" *ngFor="let language of translate.getLangs()" class="menu-language-item"
          mat-menu-item>
      <img alt="{{ language | translate }}" height="15" src="{{'assets/flags/4x3/'+language+'.svg'}}"
           width="20"/> <label class="language-label">{{ (language ?? 'gb') | translate }}</label>
    </span>
  </mat-menu>
  <!--<span class="form-inline">
    <select
      #selectedLang
      (change)="switchLang(selectedLang.value)"
      class="form-control form-select lenguage">
      <option *ngFor="let language of translate.getLangs()"
              [selected]="language === translate.currentLang"
              [value]="language">
        {{ language | translate }}
      </option>
    </select>
  </span>-->

  <button [matMenuTriggerFor]="account" aria-label="Account management" class="elx-blue" mat-button>
    <mat-icon class="user-icon">account_circle</mat-icon>
    {{ name || 'Guest' }}
  </button>

  <mat-menu #account="matMenu" class="elx-blue" xPosition="before">
    <div (click)="$event.stopPropagation()" *ngIf="email !== null" class="account-detail">
      <label class="fw-bold">{{ "LABEL_NAME_USER" | translate }} :</label>
      <label>{{ name }}</label>
    </div>
    <div (click)="$event.stopPropagation()" *ngIf="email !== null" class="account-detail">
      <label class="fw-bold">{{ "LABEL_EMAIL_USER" | translate }} :</label>
      <label>{{ email }}</label>
    </div>
    <div (click)="$event.stopPropagation()" *ngIf="role !== null" class="account-detail">
      <label class="fw-bold">{{ "LABEL_ROLE_USER" | translate }} :</label>

      <custom-select (columnChange)="onRoleChange($event)" [(defaultValue)]="role" [disabled]="availableUserRoles.length < 2"
                     [extraClass]="'likeText'" [values]="availableUserRoles">
      </custom-select>
    </div>
    <!--<div class="account-detail">
      <label class="email-title">User Role: </label>
      <label class="record-label-content">{{role}}</label>
    </div>-->
    <div *ngIf="email === null" class="account-detail">
      <label>You must be logged in</label>
    </div>
    <div *ngIf="role != null && role.length > 0" class="account-action">
      <button (click)="copyToken()" *ngIf="isDevMode()" color="primary" mat-icon-button matTooltip="{{ 'LABEL_GET_USER_TOKEN' | translate }}">
        <mat-icon>token</mat-icon>
      </button>
      <button (click)="info()" color="primary" mat-icon-button matTooltip="{{ 'LABEL_OPEN_APP_INFO' | translate }}">
        <mat-icon>info</mat-icon>
      </button>
      <button (click)="downloadLog()" color="primary" mat-icon-button matTooltip="{{ 'LABEL_DOWNLOAD_LOG' | translate }}">
        <mat-icon>adb</mat-icon>
      </button>
      <button (click)="userInfo()" *ngIf="email !== null && this.isActionPresent('user.settings.view')"
              mat-stroked-button>User Settings
      </button>
      <custom-btn (click)="logout()" *ngIf="email!== null" color="basic">{{ "BTN_LOGOUT" | translate }}</custom-btn>
      <custom-btn (click)="login()" *ngIf="email=== null" color="basic">{{ "BTN_LOGIN" | translate }}</custom-btn>
    </div>
    <div *ngIf="role == null || role.length < 1" class="account-action">
      <custom-btn (click)="refreshAll()" color="accent">Hard Refresh</custom-btn>
      <custom-btn (click)="logout()" *ngIf="email!== null" color="basic">{{"BTN_LOGOUT" | translate }}</custom-btn>
      <custom-btn (click)="login()" *ngIf="email=== null" color="basic">{{"BTN_LOGIN" | translate }}</custom-btn>
    </div>
  </mat-menu>
</mat-toolbar>


