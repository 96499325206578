import {Component, OnInit} from "@angular/core";
import {Layout} from "../../../../models/layout";
import {MsalBroadcastService, MsalService} from "@azure/msal-angular";
import {ActivatedRoute, Router} from "@angular/router";
import {LayoutServiceService} from "../../../../services/layout.service";
import {MatDialog} from "@angular/material/dialog";
import {Location} from "@angular/common";
import {TableControllerService} from "../../../../services/table-controller.service";
import {GeneralService} from "../../../../services/crud/general.service";

import {cloneDeep, isEmpty, isEqual, merge} from "lodash";
import {AlertService} from "../../alert/alert.service";
import {SpinnerService} from "../../../../services/spinner.service";
import {NavigationService} from "../../../../services/utils/navigation.service";
import {
  IJEDataTranslation,
  IJEDataTranslationError,
  IJEPage,
  IJESort,
  IJEView,
  IJEViewType,
  LanguageModel,
} from "../../../../models/LanguageModel";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {AddLanguageComponent} from "./add-language/add-language.component";
import {ALERT_TYPE} from "../../alert/alert.enumerate";
import {DialogComponent} from "../../dialog/dialog.component";
import {AuthService} from "../../../../services/auth.service";
import { lastValueFrom } from "rxjs";

function convertFormat(detailElement: any, columnName: string): string {
  if (columnName == "INSERT_DATE") {
    const newStringDate = detailElement?.replaceAll("  ", " ").replaceAll("/", " ");
    const newStringArray = newStringDate.split(" ");
    return "" + newStringArray[2] + "-" + newStringArray[1] + "-" + newStringArray[0] + " " + newStringArray[3];
  }
  return detailElement;
}

@Component({
  selector: "edit-language",
  templateUrl: "./language.component.html",
  styleUrls: ["./language.component.scss"],
  providers: [TableControllerService],
})
export class LanguageComponent implements OnInit {
  layout: Layout | undefined;

  pageId: string = "";

  languages: Array<LanguageModel> = [];

  languagesBck: Array<LanguageModel> = [];

  htmlTableDiv: SafeHtml = "";

  //MAPPING VARIABLE
  _languages: string[] = [];
  _translationsBck: IJEDataTranslation[] = [];
  _translations: IJEDataTranslation[] = [];
  translations: IJEDataTranslation[] = [];
  translationsBck: IJEDataTranslation[] = [];
  _currentID = 1;
  _currentIDBck = 1;
  _view!: IJEView;
  _page!: IJEPage;
  _sort!: IJESort;

  searchValue?: string;

  isAdminIt: boolean = false;

  isDifference: boolean = false;

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public router: Router,
    private route: ActivatedRoute,
    public layoutService: LayoutServiceService,
    public dialog: MatDialog,
    public efficiencyTableController: TableControllerService,
    public problemsTableController: TableControllerService,
    private generalDataService: GeneralService,
    private spinner: SpinnerService,
    private _location: Location,
    public alertService: AlertService,
    private navigation: NavigationService,
    private sanitize: DomSanitizer,
    private authService: AuthService,
  ) {
    this._defaultValues();
    this.authService.roleObs.subscribe((role) => {
      this.isAdminIt = role == 'ADMIN-IT';
    });
  }

  ngOnInit(): void {
    this.refreshData();
    this.spinner.hideAll();
    this.route.queryParams.subscribe((params) => {
      this.pageId = params["id"] ?? [];

      const layoutsString = localStorage.getItem("layout");
      if (!!layoutsString) {
        const layouts = JSON.parse(layoutsString);
        if (!!layouts && !!layouts[this.pageId]) {
          const pageLayout = layouts[this.pageId];
          if (Math.abs((new Date(pageLayout["instant"]).getTime() - new Date().getTime()) / 60000) < 360) {
            this.layout = pageLayout["instance"];
          } else {
            this.spinner.show();
            this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
              this.layout = res?.layouts[0] ?? undefined;
              const layoutsString = localStorage.getItem("layout");
              if (!!layoutsString) {
                let layouts = JSON.parse(layoutsString);
                layouts[this.pageId] = {
                  instance: res?.layouts[0],
                  instant: new Date(),
                };
                localStorage.setItem("layout", JSON.stringify(layouts));
              } else {
                let layouts = {};
                layouts[this.pageId] = {
                  instance: res?.layouts[0],
                  instant: new Date(),
                };
                localStorage.setItem("layout", JSON.stringify(layouts));
              }
              ////(this.layout)
              this.spinner.hide();
              /* if(!!this.pageLayouts && this.pageLayouts.length > 0){
                     this.pageActionset = this.pageLayouts[0].actionset ?? [];
                     this.pageDataset = this.pageLayouts[0].dataset ?? [];
                     ////(this.pageActionset)
                   }*/
            });
          }
        } else {
          this.spinner.show();
          this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
            this.layout = res?.layouts[0] ?? undefined;
            const layoutsString = localStorage.getItem("layout");
            if (!!layoutsString) {
              let layouts = JSON.parse(layoutsString);
              layouts[this.pageId] = {
                instance: res?.layouts[0],
                instant: new Date(),
              };
              localStorage.setItem("layout", JSON.stringify(layouts));
            } else {
              let layouts = {};
              layouts[this.pageId] = {
                instance: res?.layouts[0],
                instant: new Date(),
              };
              localStorage.setItem("layout", JSON.stringify(layouts));
            }
            ////(this.layout)
            this.spinner.hide();
            /* if(!!this.pageLayouts && this.pageLayouts.length > 0){
                   this.pageActionset = this.pageLayouts[0].actionset ?? [];
                   this.pageDataset = this.pageLayouts[0].dataset ?? [];
                   ////(this.pageActionset)
                 }*/
          });
        }
      } else {
        this.spinner.show();
        this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
          this.layout = res?.layouts[0] ?? undefined;
          const layoutsString = localStorage.getItem("layout");
          if (!!layoutsString) {
            let layouts = JSON.parse(layoutsString);
            layouts[this.pageId] = {
              instance: res?.layouts[0],
              instant: new Date(),
            };
            localStorage.setItem("layout", JSON.stringify(layouts));
          } else {
            let layouts = {};
            layouts[this.pageId] = {
              instance: res?.layouts[0],
              instant: new Date(),
            };
            localStorage.setItem("layout", JSON.stringify(layouts));
          }
          ////(this.layout)
          this.spinner.hide();
          /* if(!!this.pageLayouts && this.pageLayouts.length > 0){
                 this.pageActionset = this.pageLayouts[0].actionset ?? [];
                 this.pageDataset = this.pageLayouts[0].dataset ?? [];
                 ////(this.pageActionset)
               }*/
        });
      }
    });
  }

  ngOnDestroy() {
    localStorage.setItem("changes", "false");
  }i

  actionCatcher(event: string) {
    if (event.startsWith("ACT_ADD")) {
    }
  }

  onSaveClick(event: MouseEvent) {
    this._languages.forEach((language) => {
      let o = {};
      let send = false;
      let langKey = '';
      let currentLanguageTranslation = ''
      this._translations
        .filter((translation) => translation.valid)
        .sort((a, b) => (a.key > b.key ? 1 : -1))
        .forEach((translation) => {
          if (translation.languages[language] && !this._translationsBck.some(tn => translation.id == tn.id && isEqual(tn.languages[language], translation.languages[language]))) {
            o[translation.key] = translation.languages[language];
            send = true;
            langKey= translation.key
            currentLanguageTranslation = translation.languages[language];
          } else {
            o[translation.key] = translation.languages[language];
          }
        });

      this._translationsBck.filter((translation) => translation.valid)
        .sort((a, b) => (a.key > b.key ? 1 : -1))

      if (!send) {
        o = {}
      }


      if (!isEmpty(o)) {
        console.log(o)
        var json = JSON.stringify(o, null, 2);
        json = json.replace(/\n/g, "");
        this.generalDataService.setLanguage(language, {id: langKey, lang:language, value: currentLanguageTranslation}).subscribe({
          next: (value) => {
            this.refreshData();
            this.alertService.add({
              type: ALERT_TYPE.SUCCESS,
              message: "Language " + language + " saved correctly.",
              timeout: 3000,
              selfClose: null
            });
          },
          error: (err) => {
            this.refreshData()
            this.alertService.add({
              type: ALERT_TYPE.DANGER,
              message: "An error occurred on save " + language + " language.",
              timeout: 5000,
              selfClose: null
            });
          },
        });
      }


    });
  }

  /**
   * Create the hierachy based on the key
   */
  private _transformKeysValues(key: string, value: string, o = {}) {
    let separator = key.indexOf(".");
    if (separator > 0) {
      const _key = key.substring(0, separator);
      if (!o[_key]) {
        o[_key] = {};
      }
      this._transformKeysValues(key.substring(separator + 1), value, o[_key]);
    } else if (!o[key] && typeof o !== "string") {
      o[key] = value;
    }
  }

  refreshData() {
    this.generalDataService.getAvailableLanguagesAndTranslations().subscribe((res) => {
      this._currentID = 1;
      this._currentIDBck = 1;
      this.languages = res;
      this.languagesBck = cloneDeep(res);
      this._loadContent(this.languages, true);
      this._loadContentBck(cloneDeep(this.languages), true);
      this.search();
    });
  }

  private _loadContentBck(languageModel: LanguageModel[], wipe: boolean = false) {
    const translate: any = {};
    const keys: string[] = [];
    if (wipe) {
      this._translationsBck = [];
    }

    languageModel.forEach((file: LanguageModel) => {
      var language = file.id;
      try {
        let content = file.content;

        let keysValues = this._getKeysValues(content);

        for (let key in keysValues) {
          if (keys.indexOf(key) === -1) {
            keys.push(key);
          }
        }
        translate[language] = keysValues;
      } catch (e) {
        translate[language] = {};
      }
    });

    keys.forEach((key: string) => {
      const languages: any = {};
      this._languages.forEach((language: string) => {
        const value = translate[language][key];
        languages[language] = value ? value : "";
      });

      const t = this._createFactoryIJEDataTranslationBck();
      t.key = key;
      t.languages = languages;
      //t.edits = (this._translationsBck.filter(trn => trn.key == key)[0])?.edits ?? []
      this._insertBck(t);
    });
  }

  private _loadContent(languageModel: LanguageModel[], wipe: boolean = false) {
    const translate: any = {};
    const keys: string[] = [];
    if (wipe) {
      this._languages = [];
      this._translations = [];
    }
    languageModel.forEach((file: LanguageModel) => {
      var language = file.id;
      if (this._languages.indexOf(language) === -1) {
        this._languages.push(language);
      }

      try {
        let content = file.content;

        let keysValues = this._getKeysValues(content);

        for (let key in keysValues) {
          if (keys.indexOf(key) === -1) {
            keys.push(key);
          }
        }
        translate[language] = keysValues;
      } catch (e) {
        translate[language] = {};
      }
    });

    keys.forEach((key: string) => {
      const languages: any = {};
      this._languages.forEach((language: string) => {
        const value = translate[language][key];
        languages[language] = value ? value : "";
      });

      const t = this._createFactoryIJEDataTranslation();
      t.key = key;
      t.languages = languages;
      //t.edits = (this._translations.filter(trn => trn.key == key)[0])?.edits ?? []
      this._insert(t);
    });
    this.search();
  }

  /**
   * For each values get the unique key with hierachy separate by a separator
   */
  private _getKeysValues(obj: any, _key = "") {
    let kv: any = {};
    for (let key in obj) {
      if (typeof obj[key] !== "string") {
        kv = {...kv, ...this._getKeysValues(obj[key], _key + key + ("." || ""))};
      } else {
        kv[_key + key] = obj[key];
      }
    }
    return kv;
  }

  /**
   * Factories
   */
  private _createFactoryIJEDataTranslation(): IJEDataTranslation {
    return {
      id: this._currentID++,
      valid: true,
      error: "",
      key: "",
      languages: {},
      edits: []
    };
  }

  private _createFactoryIJEDataTranslationBck(): IJEDataTranslation {
    return {
      id: this._currentIDBck++,
      valid: true,
      error: "",
      key: "",
      languages: {},
      edits: []
    };
  }

  private _insert(translation: IJEDataTranslation) {
    this._translations.push(translation);
  }

  private _insertBck(translation: IJEDataTranslation) {
    this._translationsBck.push(cloneDeep(translation))
  }

  private _defaultValues() {
    this._view = {
      type: IJEViewType.TABLE,
      selectionId: 1,
    };

    this._sort = {
      column: "KEY",
      ascending: true,
    };

    this._page = {
      pageSize: 10,
      pageNumber: 1,
    };
  }


  private _get(id: number = 0): IJEDataTranslation {
    return this._translations.find((t) => t.id === id) ?? this._translations[0];
  }

  sort(column: string, ascending: boolean, firstPage: boolean = false) {
    this._sort.ascending = this._sort.column !== column ? true : ascending;
    this._sort.column = column;
  }

  onAddLanguage() {
    const dialogRef = this.dialog.open(AddLanguageComponent, {
      width: "60vw",
      data: {
        availableLanguages: this.languages,
        confirmButtonLabel: "Ok",
        confirmButtonColor: "primary",
        cancelButtonLabel: "Close",
        cancelButtonColor: "accent",
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.name && res.select) {
        let newLanguage: LanguageModel = {
          id: res.name,
          content: res.select,
        };

        this.languages = this.languages.concat([cloneDeep(newLanguage)]);
        this._loadContent(this.languages, true);
      } else if (res && res.name) {
        let newLanguage: LanguageModel = {
          id: res.name,
          content: this.languages.length > 0 ? this.languages[0].content : {},
        };
        this.languages = this.languages.concat([cloneDeep(newLanguage)]);
        this._loadContent(this.languages, true);
      }
    });
  }

  onAddTranslation() {
    let newTranslation: IJEDataTranslation = this._createFactoryIJEDataTranslation();
    this._translations = this._translations.concat(newTranslation);
    this._translationsBck = this._translationsBck.concat(cloneDeep(newTranslation));
    this.search();
  }

  update(translation: IJEDataTranslation, event: any, id: number, lang?: string) /*: IJEDataTranslation*/ {
    this._translations = this._translations.map((trans) => {
      if (id == 0) {
        if (trans.id == translation.id) {
          trans.key = event;
        }
      } else {
        if (lang) {
          if (trans.id == translation.id) {
            trans.languages[lang] = event.replace(/\\n/g, "\n");
            if (this._translationsBck.some(_trans => _trans.id == trans.id && _trans.languages[lang] == trans.languages[lang])) {
              trans.edits = trans.edits.filter(la => la != lang);
            } else {
              if (trans.edits.indexOf(lang) == -1) {
                trans.edits.push(lang)
              }
            }
          }
        }
      }
      return trans;
    });
    this.search();
  }

  private _validateImpacted(translation: IJEDataTranslation, key?: string) {
    if (key === "") {
      return;
    }

    const impacted = this._validatePath(translation, false);

    impacted.forEach((i) => {
      if (
        key === undefined ||
        (!this._comparePath(this._split(translation.key), this._split(i.key)) &&
          this._validatePath(i, true).length === 0)
      ) {
        i.valid = true;
        i.error = "";
        //this._manager.updateTranslation(i);
      }
    });
  }

  private _validate(translation: IJEDataTranslation, keyChanged: boolean = false) {
    if (translation.key === "") {
      translation.valid = false;
      translation.error = IJEDataTranslationError.KEY_NOT_EMPTY;
    } else if (keyChanged) {
      let separator = false;
      //does not start or end with the separator or two consecutive separators
      if (separator && new RegExp(`^${separator}|${separator}{2,}|${separator}$`).test(translation.key)) {
        translation.valid = false;
        translation.error = IJEDataTranslationError.INVALID_KEY;
      } else if (this._validatePath(translation).length > 0) {
        translation.valid = false;
        translation.error = IJEDataTranslationError.DUPLICATE_PATH;
      } else {
        translation.valid = true;
        translation.error = "";
      }
    }
  }

  private _validatePath(translation: IJEDataTranslation, valid: boolean = true) {
    const splitKey = this._split(translation.key);

    return this._translations.filter((t) => {
      if (translation.id === t.id || t.valid !== valid) {
        return false;
      }
      return this._comparePath(splitKey, t.key.split("."));
    });
  }

  private _comparePath(a: string[], b: string[]) {
    const _a = a.length >= b.length ? b : a;
    const _b = a.length < b.length ? b : a;
    return _a.every((v: string, i: number) => v === _b[i]);
  }

  private _split(key: string) {
    if (".") {
      return key.split(".");
    }
    return [key];
  }

  mark(id: number) {
    console.log(id);

    const translation = this._get(id);
    console.log(translation);
    if (translation) {
      this._view.selectionId = id;
    }
  }

  search(event?: any) {
    if (event) this.searchValue = event;

    this.translations = this._translations.filter((t) => (event ? t.key.includes(event) : true));
    this.translationsBck = this._translationsBck.filter((t) => (event ? t.key.includes(event) : true));

  }

  remove(key: string) {
    console.log("id e key")
    //console.log(id)
    console.log(key)
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "Confirm delete [" + key + "] key",
        content: `Are you sure to proceed?`,
        cancelButtonLabel: "No",
        cancelButtonColor: "basic",
        confirmButtonLabel: "Yes",
        confirmButtonColor: "warn",
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this._translations = this._translations.filter((t) => t.key != key);
        this.search();
      }
    });

  }

  removeTranslation(key: string) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: `Confirm delete [${key}] key`,
        content: 'Are you sure to proceed?',
        cancelButtonLabel: 'No',
        cancelButtonColor: 'basic',
        confirmButtonLabel: 'Yes',
        confirmButtonColor: 'warn',
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.generalDataService.deleteLanguage(key).subscribe(
          deleteRes => {
            this.languages = this.languages.filter(languageModel => languageModel.id !== key);

            this._loadContent(this.languages, true);

            this.alertService.add({
              type: ALERT_TYPE.SUCCESS,
              message: 'Translation deleted successfully.',
              timeout: 3000,
              selfClose: null,
            });
          },
          error => {
            this.alertService.add({
              type: ALERT_TYPE.WARNING,
              message: 'An error occurred during Translation deletion.',
              timeout: 5000,
              selfClose: null,
            });
          },
          () => {
            this.refreshData();
          }
        );
      }
    });
  }

  deleteLanguage(column: string) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "Confirm delete [" + column + "] language",
        content: `Are you sure to proceed?`,
        cancelButtonLabel: "No",
        cancelButtonColor: "basic",
        confirmButtonLabel: "Yes",
        confirmButtonColor: "warn",
      },
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.generalDataService.deleteLanguage(column).subscribe(res => {
          this.languages = this.languages.filter(languageModel => languageModel.id != column);
          this._loadContent(this.languages, true);
        })
      }
    })

  }

  checkDifferences() {
    if (isEqual(this.translations, this.translationsBck)) {
      this.searchValue = undefined;
      return false;
    }
    return true;
  }

}
