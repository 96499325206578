import {Directive, HostListener, Input} from '@angular/core';
import {NavigationService} from "../../services/utils/navigation.service";
import {Required} from "../../utils/requiredInputs";
import {MatDialog} from "@angular/material/dialog";
import {DialogComponent} from "../../components/shared/dialog/dialog.component";

@Directive({
  selector: '[backNavigation]'
})
export class BackNavigationDirective {

  @Input()
  masterFrom: any;

  @Input()
  compareTo: any;

  @Input()
  withoutcompare?: boolean = false;

  constructor(private navigation: NavigationService, public dialog: MatDialog) {
  }

  @HostListener('click')
  onClick(): void {

    if (this.withoutcompare) {
      this.navigation.back();
    } else {
      this.navigation.compareAndBack(this.masterFrom, this.compareTo);
    }


  }


}
