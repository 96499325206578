<div *ngFor="let document of attachments" class="document-container">
  <div class="document-row">
    <label class="document-label">{{ document.name }}</label>

    <div class="button-container">
      <custom-btn (click)="downlaodFile(document)" [fontSize]="'16px'" [iconButton]="true"
                  [iconClasses]="'cloud_download'" matTooltip="{{ 'LABEL_DOWNLOAD' | translate }}"></custom-btn>

      <custom-btn (click)="deletePicture(document.id)" [fontSize]="'16px'" [iconButton]="true" [iconClasses]="'delete'"
                  matTooltip="{{ 'LABEL_DELETE' | translate }}"></custom-btn>
    </div>
  </div>
</div>

<mat-dialog-actions align="end">
  <button [mat-dialog-close]="{id:fileId }" cdkFocusInitial mat-button>{{ data['cancelButtonLabel'] }}</button>
</mat-dialog-actions>
