import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';

import {MatSelect, MatSelectChange} from "@angular/material/select";

export interface SelectType
{
  label: String;
  value: any;
}

@Component({
  selector: 'custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent implements OnInit, OnChanges
{
  @Input()
  label: String | undefined;

  @Input()
  placeholder: String | undefined;

  @Input()
  values?: any[];

  @Input()
  defaultValue: any;

  @Input()
  disabled: boolean | undefined = false;

  @Output()
  defaultValueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  columnName?: string;

  @Output()
  columnChange: EventEmitter<string> = new EventEmitter<string>()

  @Input()
  extraClass: string = '';

  @Input()
  preventIdChange: boolean = false;

  @Input()
  searchable: boolean = false;


  searchValue: string = ""; //The ID rendered are filtered by this value

  filterValues: any[] | undefined; //Contains the filter values on selector

  @ViewChild('selectElement') selectElement: MatSelect | undefined;
  @ViewChild('searchInput') searchInput: ElementRef | undefined;

  constructor()
  {
  }

  ngOnInit(): void
  {
    console.log(this.defaultValue);
    if (!this.defaultValue && this.values?.length)
    {
      this.defaultValue = this.values[0].id;
      this.onChange(this.values[0].id);
    }
  }

  ngOnChanges(changes: SimpleChanges)
  {
    if(changes["values"]){
      this.filterValues = [...changes["values"].currentValue];
    }
  }

  onChange(event: MatSelectChange): void
  {

    let value = event.value;
    if (!this.preventIdChange)
    {
      if (this.values)
        this.defaultValue = this.values.find(elem => elem.id == value)
    }
    this.defaultValueChange.emit(value);
    this.columnChange.emit(this.columnName ?? '');
  }

  onSearchValueChange(event: any){
    if(event){
      this.filterValues = this.values?.filter(option => option.id.includes(event));
    }else{
      this.searchValue = "";
      this.filterValues = this.values;
    }
  }

  openedChange(event: any) {
    //Scroll to the top
    let panel = this.selectElement?.panel.nativeElement as HTMLDivElement;
    panel.scrollTop = 0;

    //Give focus to the input so the user can search immediately
    this.searchInput?.nativeElement.focus();
  }
}
