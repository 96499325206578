import {HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {EventEmitter, Injectable, Input, Output, ViewChild} from '@angular/core';
import {interval, Observable} from 'rxjs';
import {map, tap} from "rxjs/operators";
import {SpinnerService} from "./spinner.service";
import {AnnounceService} from "./announcements.service";
import {cloneDeep} from "lodash";
import {NavigationService} from "./utils/navigation.service";
import {GeneralService} from "./crud/general.service";
import {AppComponent} from "../app.component";
import {AuthService} from "./auth.service";
import {NavItem} from "../models/NavItem";


@Injectable()
export class HttpSpinnerInterceptorService implements HttpInterceptor {

  navItems: NavItem[] = [];

  constructor(private spinner: SpinnerService, private aS: AnnounceService, private navigation: NavigationService) {

  }


  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (!req.url.includes('util') && !req.url.includes('lang')) {

      const queryparams = req.urlWithParams ?? '';
      const tablePattern = /table=%22([^%]+)%22;/;
      const tableMatch = queryparams.match(tablePattern);

      let tableSearchKey = '';
      if (tableMatch) {
        const table = tableMatch[1];
        tableSearchKey = table;
      }

      //this.spinner.showWithTitle('data', tableSearchKey);
      return next.handle(req).pipe(map(event => {

        if (event instanceof HttpResponse) {

          if (Object.keys(event.body).includes('announcements')) {
            console.log(req, event)

            if (req.headers.has("Authorization")) {

              this.aS.replaceAllert((event.body?.announcements?.map(announce => {

                const self = cloneDeep({ // Store a reference to the object
                  id: announce.id,
                  priority: announce.priority,
                  text: announce.text,
                  message_goes_on: announce.message_goes_on,
                  message_goes_off: announce.message_goes_off,
                  block_users_starts: announce.block_users_starts,
                  interval: interval(5000).pipe(tap(temp => {

                    if (new Date(self.block_users_starts) <= new Date() && new Date(self.message_goes_off) > new Date() && self.block_users_starts !== null && (self.block_users_starts !== 'Thu Jan 01 1970 01:00:00 GMT+0100')) {

                      self.priority = 'custom'; // Access object's properties using sel
                      self.interval.unsubscribe();//
                      this.spinner.hideAll();
                      // this.navigation.home();
                    } else if (new Date(announce.message_goes_off) <= new Date()) {

                      self.interval.unsubscribe();
                      this.spinner.hideAll();
                      //this.navigation.home();
                    } else {

                      this.spinner.hideAll();
                    }
                  })).subscribe()
                });
                return self;
              }) ?? []))

            }

            return event.clone({
              body: event.body?.data
            });
          }
        }


        return event;
      }))
      /*.pipe(tap({
        next: value => {
          if (value instanceof HttpResponse) {
            this.spinner.hide();
          }
        },
        error: err => {
          this.spinner.hide();
        }
      }));*/
    } else {


      return next.handle(req).pipe(map(event => {

        if (event instanceof HttpResponse && !req.method.includes('get')) {

          if (Object.keys(event.body).includes('announcements')) {

            if (req.headers.has("Authorization")) {


              this.aS.replaceAllert((event.body?.announcements?.map(announce => {


                const self = cloneDeep({ // Store a reference to the object
                  id: announce.id,
                  priority: announce.priority,
                  text: announce.text,
                  message_goes_on: announce.message_goes_on,
                  message_goes_off: announce.message_goes_off,
                  block_users_starts: announce.block_users_starts,
                  interval: interval(5000).pipe(tap(temp => {

                    if (new Date(self.block_users_starts) <= new Date() && new Date(self.message_goes_off) > new Date() && self.block_users_starts !== null && (self.block_users_starts !== 'Thu Jan 01 1970 01:00:00 GMT+0100')) {

                      self.priority = 'custom'; // Access object's properties using sel
                      self.interval.unsubscribe();
                      this.spinner.hideAll();
                      //this.navigation.home();
                    } else if (new Date(announce.message_goes_off) <= new Date()) {
                      self.interval.unsubscribe();
                      this.spinner.hideAll();

                      //this.navigation.home();
                    } else {
                      self.priority = 'custom';
                      this.spinner.hideAll();


                    }

                  })).subscribe()

                });

                return self;
              }) ?? []))
            }
            return event.clone({
              body: event.body?.data
            });
          }
        }
        return event;
      }))
    }

  }

}
