import {Pipe, PipeTransform} from '@angular/core';
import {filter, Observable, of, take} from "rxjs";
import {map, first} from "rxjs/operators";

@Pipe({
  name: 'getDescriptionFromOptionValues',
  pure: false
})
export class GetDescriptionFromOptionValuesPipe implements PipeTransform {

  transform(optionValues: Observable<any>, columnName: string, defaultValue: string, dataSetElementElement: any, row: number): Observable<string> {

    return optionValues.pipe(
      filter((data) => !!data && columnName in data),
      map((data) => data[columnName]),
      map((optionArr) => optionArr?.find((option) => option.id == dataSetElementElement)),
      filter((element) => !!element),
      take(1),
      map((element) => element.name),
      first(name => !!name, defaultValue)
    );
    /*return optionValues.filter(
      (option) => option.id == dataSetElementElement
    )[0]?.name ?? defaultValue;*/
  }

}
