<mat-expansion-panel [expanded]="isFilter">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Filters
    </mat-panel-title>
  </mat-expansion-panel-header>

  <form>
    <div class="row">
      <div class="column">
        <div class="row">
          <ng-container *ngFor="let filter of filterObj">
            <div class="mr-1" *ngIf="(filter.filterType != 'date' && filter.filterType != 'select' && filter.filterType != 'multipleSelect')
                        || filter.filterType == 'select'
                        || filter.filterType == 'multipleSelect'">
              <mat-form-field
                *ngIf="filter.filterType != 'date' && filter.filterType != 'select' && filter.filterType != 'multipleSelect'">
                <mat-label>{{filter.name}}</mat-label>
                <span [class.bold]="!!filter.modelValue">
                  <input matInput #filterFields [(ngModel)]="filter.modelValue" name={{filter.columnProp}} />
                </span>
              </mat-form-field>
              <!-- SELECTION FILTER-->
              <mat-form-field *ngIf="filter.filterType == 'select'">
                <mat-label>Status</mat-label>
                <mat-select class="bold" [(ngModel)]="filter.modelValue" name="{{filter.columnProp}}" #filterFields>
                  <mat-option *ngFor="let option of filter.value" [value]="option">
                    {{option}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="filter.filterType == 'multipleSelect'">
                <mat-label>{{filter.name}}</mat-label>
                <mat-select [(ngModel)]="filter.modelValue" (ngModelChane)="filter.modelValue"
                  name="{{filter.columnProp}}" #filterFields multiple>
                  <mat-option *ngFor="let option of filter.value" [value]="option.value">
                    {{option.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="date-form-field" *ngIf="filter.filterType == 'date'">
              <mat-form-field class="mr-1">
                <mat-label>{{filter.name}}</mat-label>
                <span [class.bold]="!!filter.modelValue">
                  <input matInput type="date" max="9999-12-31" [(ngModel)]="filter.modelValue"
                    name={{filter.columnProp}} id="min" (change)="dateChange($event)" #filterFields />
                </span>
              </mat-form-field>
              <mat-form-field class="mr-1" *ngIf="dateRange.get(filter.columnProp+'-min')">
                <mat-select id="{{  filter.columnProp + '-dd' }}" [(value)]="selectedRange[filter.columnProp]">
                  <mat-option value="s">Same As</mat-option>
                  <mat-option value="b">Before</mat-option>
                  <mat-option value="a">After</mat-option>
                  <mat-option value="i">Between</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="mr-1"
                *ngIf="dateRange.get(filter.columnProp+'-min') && selectedRange[filter.columnProp] === 'i'">
                <mat-label>{{filter.name}}</mat-label>
                <span [class.bold]="!!filter.modelValue">
                  <input matInput type="date" max="9999-12-31" name={{filter.columnProp}} id="max"
                    (change)="dateChange($event)" #filterFields />
                </span>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="action-column mh-025r">
        <div class="column">
          <button class="action-button elx-blue" mat-icon-button color="primary" matTooltip="Apply filter"
            action-type="button" (click)="applyFilter()">
            <mat-icon class="fs-2r">search</mat-icon>
          </button>
          <button class="action-button elx-blue" mat-icon-button color="primary" matTooltip="Clear filter"
            action-type="button" (click)="clearFilter()">
            <mat-icon class="fs-2r">filter_alt_off</mat-icon>
          </button>
          <!--<button class="action-button elx-blue" mat-icon-button color="primary" matTooltip="Reset filter" action-type="button"
                  (click)="resetFilter()"><mat-icon class="fs-2r">restart_alt</mat-icon></button>-->
        </div>
      </div>
    </div>


  </form>
</mat-expansion-panel>
