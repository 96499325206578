import {ChangeDetectorRef, Pipe, PipeTransform} from '@angular/core';
import {camelToSnakeCase, refactorName} from "../utils/generic";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";

@Pipe({
  name: 'tableHeighCalculator'
})
export class CdkHeightCalculatorPipe implements PipeTransform {

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  transform(numberOfItems: number = 1, visibleItems: number, viewPort?: CdkVirtualScrollViewport): string {

    if (numberOfItems == 0)
      numberOfItems = 2;

    // This should be the height of your item in pixels
    const itemHeight = 48;

    viewPort?.checkViewportSize();

    this.changeDetectorRef.detectChanges();

    // It calculates the container height for the first items in the list
    // It means the container will expand until it reaches `200px` (20 * 10)
    // and will keep this size.
    if (numberOfItems <= visibleItems) {
      return `${(itemHeight * numberOfItems) + 60}px`;
    }

    // This function is called from the template so it ensures the container will have
    // the final height if number of items are greater than the value in "visibleItems".
    return `${(itemHeight * visibleItems + 60)}px`;
  }

}
