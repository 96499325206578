import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'dnd-old-component',
  templateUrl: './dnd-old.component.html',
  styleUrls: ['./dnd-old.component.scss']
})
export class DndOldComponent {

  public files: any = [];
  public dialog: { [key: string]: any };
  acceptFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

  constructor(public dialogRef: MatDialogRef<DndOldComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { [key: string]: any }) {
    this.dialog=data
    if(!!data['type'] && data['type'] == 'any'){
      this.acceptFileType = '*/*'
    }
  }

  /**
   * on file drop handler
   */
   onFileDropped($event:any) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(evt:any) {
    const files = evt.target.files;
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
   prepareFilesList(files: Array<any>) {
     const accepted_file = [this.acceptFileType]
    for (const item of files) {
      if(this.acceptFileType !== '*/*' &&  accepted_file.includes(item.type)){
        item.progress = 0;
        this.files.push(item);
      }else if(this.acceptFileType == '*/*'){
        item.progress = 0;
        this.files.push(item);
      }
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes:any, decimals:any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
