import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogComponent} from "../dialog.component";
import {MessagingService} from "../../../../services/utils/messaging.service";

@Component({
  selector: 'application-information',
  templateUrl: './app-info.component.html',
  styleUrls: ['./app-info.component.scss']
})
export class AppInfoComponent implements OnInit {

  @Output()
  onConfirm: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public messager: MessagingService
  ) {
    dialogRef.disableClose = true;
  }

  onNoClick() {
    this.onConfirm.emit(false);
    this.dialogRef.close();
  }

  onConfirmClick() {
    this.onConfirm.emit(true);
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
