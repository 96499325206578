import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import {lastValueFrom, of} from "rxjs";
import _ from "lodash";
import {MonitorHeaderCost, MonitorHeaderEvent} from "../shared/models/InspectContainer";

@Injectable({
  providedIn: 'root'
})
export class MonitorService {



  constructor(private http: HttpClient) { }

  headers = new HttpHeaders();

  httpoptions: Object = {
    responseType: 'blob'
  }

  getMonitorPreview(params?: HttpParams) {
    return this.http.get(environment.apiUrl + 'oemcc_preview', { params }).pipe(
      map((response) => {
        let newContent = response;
        if (!!newContent['content']) {
          response['content'].map((content) => {
            if (!!content) {
              content['statusRow'] = {
                value: 'READY',
                errorType: '',
                errorList: [],
                errorAction: ''
              }
              Object.entries(content).forEach(entry => {
                if (entry[0] !== 'meta' && entry[0] !== 'statusRow' && entry[0] !== 'errors') {
                  content[entry[0]] = {
                    value: entry[1],
                    errorType: '',
                    errorList: [],
                    errorAction: ''
                  }
                }
              })
              if (!!content.errors) {
                content.errors.forEach((remark) => {
                  if (remark.errorStatus === 'ERROR') {
                    (content['statusRow']).value = 'ERROR'
                  } else if (remark.errorStatus === 'WARNING' && (content['statusRow']).value !== 'ERROR') {
                    (content['statusRow']).value = 'WARNING'
                  }

                  (content['statusRow']).errorAction = remark.errorAction;

                  (content[remark.errorColumn]).errorType = remark.errorStatus;
                  (content[remark.errorColumn]).errorAction = remark.errorAction;
                  (content[remark.errorColumn]).errorList.push(remark.errorNotice);
                  (content['statusRow']).errorList.push({ notice: remark.errorNotice, column: remark.errorColumn, action: remark.errorAction });
                });
              }
              //Object.entries(content);
            }

            return content;

          })
        }
        return newContent;
      }),
    );
  }

  getMonitor(params?: HttpParams) {
    if (!!params) {
      params.append('role', 'ADMIN')
    }
    return this.http.get(environment.apiUrl + 'oemcc_monitor', { params }).pipe(
      map((response) => {
        let newContent = response;
        if (!!newContent['content']) {
          response['content'].map((content) => {
            if (!!content) {
              content['statusRow'] = {
                value: 'READY',
                errorType: '',
                errorList: [],
                errorAction: ''
              }
              Object.entries(content).forEach(entry => {
                if (entry[0] !== 'meta' && entry[0] !== 'statusRow') {
                  content[entry[0]] = {
                    value: entry[1],
                    errorType: '',
                    errorList: [],
                    errorAction: ''
                  }
                }
              })
              if (!!content.meta && !!content.meta.remarks) {
                content.meta.remarks.forEach((remark) => {
                  if (remark.status === 'ERROR') {
                    (content['statusRow']).value = 'ERROR'
                  } else if (remark.status === 'WARNING' && (content['statusRow']).value !== 'ERROR') {
                    (content['statusRow']).value = 'WARNING'
                  }

                  (content['statusRow']).errorAction = remark.action;

                  (content[remark.column]).errorType = remark.status;
                  (content[remark.column]).errorAction = remark.action;
                  (content[remark.column]).errorList.push(remark.notice);
                  (content['statusRow']).errorList.push({ notice: remark.notice, column: remark.column, action: remark.action });
                });
              }
              //Object.entries(content);
            }

            return content;

          })
        }
        return newContent;
      }),
    );
  }


  getDuplicatedMonitor(params?: HttpParams) {
    if (!!params) {
      params.append('role', 'ADMIN')
    }
    return this.http.get(environment.apiUrl + 'oemcc_monitor/admin', { params }).pipe(
      map((response) => {
        let newContent = response;
        if (!!newContent['content']) {
          response['content'].map((content) => {
            if (!!content) {
              content['statusRow'] = {
                value: 'READY',
                errorType: '',
                errorList: [],
                errorAction: ''
              }
              Object.entries(content).forEach(entry => {
                if (entry[0] !== 'meta' && entry[0] !== 'statusRow') {
                  content[entry[0]] = {
                    value: entry[1],
                    errorType: '',
                    errorList: [],
                    errorAction: ''
                  }
                }
              })
              if (!!content.meta && !!content.meta.remarks) {
                content.meta.remarks.forEach((remark) => {
                  if (remark.status === 'ERROR') {
                    (content['statusRow']).value = 'ERROR'
                  } else if (remark.status === 'WARNING' && (content['statusRow']).value !== 'ERROR') {
                    (content['statusRow']).value = 'WARNING'
                  }

                  (content['statusRow']).errorAction = remark.action;

                  (content[remark.column]).errorType = remark.status;
                  (content[remark.column]).errorAction = remark.action;
                  (content[remark.column]).errorList.push(remark.notice);
                  (content['statusRow']).errorList.push({ notice: remark.notice, column: remark.column, action: remark.action });
                });
              }
              //Object.entries(content);
            }

            return content;

          })
        }
        return newContent;
      }),
    );
  }

  getHistory(params?: HttpParams) {
    return this.http.get(environment.apiUrl + 'oemcc_monitor/history', { params }).pipe(
      map((response) => {
        let newContent = response;
        if (!!newContent['content']) {
          response['content'].map((content) => {
            if (!!content) {
              content['statusRow'] = {
                value: 'READY',
                errorType: '',
                errorList: [],
                errorAction: ''
              }
              Object.entries(content).forEach(entry => {
                if (entry[0] !== 'meta' && entry[0] !== 'statusRow') {
                  content[entry[0]] = {
                    value: entry[1],
                    errorType: '',
                    errorList: [],
                    errorAction: ''
                  }
                }
              })
              if (!!content.meta && !!content.meta.remarks) {
                content.meta.remarks.forEach((remark) => {
                  if (remark.status === 'ERROR') {
                    (content['statusRow']).value = 'ERROR'
                  } else if (remark.status === 'WARNING' && (content['statusRow']).value !== 'ERROR') {
                    (content['statusRow']).value = 'WARNING'
                  }

                  (content['statusRow']).errorAction = remark.action;

                  (content[remark.column]).errorType = remark.status;
                  (content[remark.column]).errorAction = remark.action;
                  (content[remark.column]).errorList.push(remark.notice);
                  (content['statusRow']).errorList.push({ notice: remark.notice, column: remark.column, action: remark.action });
                });
              }
              //Object.entries(content);
            }

            return content;

          })
        }
        return newContent;
      }),
    );
  }

  mergePreviewToMonitor(idsToApprove: any[]) {
    /*
    records.map(record => {
      delete record.statusRow;
      delete record.errors;
      Object.entries(record).forEach(entry => {
        if (!!(entry[1]))
          record[entry[0]] = (entry[1])['value'];
        else
          delete record[entry[0]];
      })
      return record
    })*/
    return this.http.post<any>(environment.apiUrl + 'oemcc_preview/approve', idsToApprove, {});
  }

  exportExcel(records: any[]) {
    records.map(record => {
      delete record.statusRow;
      Object.entries(record).forEach(entry => {
        if (!!(entry[1]))
          record[entry[0]] = (entry[1])['value'];
        else
          delete record[entry[0]];
      })
      return record;
    })

    return this.http.post<any>(environment.apiUrl + 'oemcc_monitor/export', records, this.httpoptions);
  }

  exportHistoryCsv(params?: HttpParams) {
    const httpHeader: Object = {

      params: params,
      responseType: 'blob'
    }
    return this.http.get<any>(environment.apiUrl + 'oemcc_monitor/history/export/csv', httpHeader);
  }

  exportMonitorCsv(params?: HttpParams) {
    const httpHeader: Object = {

      params: params,
      responseType: 'blob'
    }
    return this.http.get<any>(environment.apiUrl + 'oemcc_monitor/export/csv', httpHeader);
  }

  importExcel(file: File) {
    const formData: FormData = new FormData();
    formData.append('monitorFile', file, file.name);
    return this.http.post<any>(environment.apiUrl + 'oemcc_monitor/import', formData, {}).pipe(
      map((response) => {
        let newContent = response;
        if (!!newContent) {
          response.map((content) => {
            if (!!content) {
              content['statusRow'] = {
                value: 'READY',
                errorType: '',
                errorList: [],
                errorAction: ''
              }
              Object.entries(content).forEach(entry => {
                if (entry[0] !== 'meta' && entry[0] !== 'statusRow') {
                  content[entry[0]] = {
                    value: entry[1],
                    errorType: '',
                    errorList: [],
                    errorAction: ''
                  }
                }
              })

              if (!!content.meta && !!content.meta.remarks) {
                content.meta.remarks.forEach((remark) => {
                  if (remark.status === 'ERROR') {
                    (content['statusRow']).value = 'ERROR'
                  } else if (remark.status === 'WARNING' && (content['statusRow']).value !== 'ERROR') {
                    (content['statusRow']).value = 'WARNING'
                  }

                  if ((content['statusRow']).errorAction === '') {
                    (content['statusRow']).errorAction = remark.action;
                  }

                  (content[remark.column]).errorType = remark.status;
                  (content[remark.column]).errorAction = remark.action;
                  (content[remark.column]).errorList.push(remark.notice);
                  (content['statusRow']).errorList.push({ notice: remark.notice, column: remark.column, action: remark.action });
                });
              }
              //Object.entries(content);
            }

            return content;

          })
        }
        return newContent;
      }),
    );
  }

  //MONITOR
  renderRow(editedRecord: any) {
    let records = _.cloneDeep(editedRecord)
    delete records.statusRow
    Object.entries(records).forEach(entry => {
      if (!!(entry[1]))
        records[entry[0]] = (entry[1])['value'];
      else
        delete records[entry[0]];
    });
    console.log(_.cloneDeep(records))
    return this.http.post<any>(environment.apiUrl + 'oemcc_monitor/render', records, {}).pipe(
      map((response) => {
        let newContent = response;
        if (!!newContent) {
          if (!!response) {
            response['statusRow'] = {
              value: 'READY',
              errorType: '',
              errorList: [],
              errorAction: ''
            }
            Object.entries(response).forEach(entry => {
              if (entry[0] !== 'meta' && entry[0] !== 'statusRow') {
                response[entry[0]] = {
                  value: entry[1],
                  errorType: '',
                  errorList: [],
                  errorAction: ''
                }
              }
            })
            if (!!response.meta && !!response.meta.remarks) {
              response.meta.remarks.forEach((remark) => {
                if (remark.status === 'ERROR') {
                  (response['statusRow']).value = 'ERROR'
                } else if (remark.status === 'WARNING' && (response['statusRow']).value !== 'ERROR') {
                  (response['statusRow']).value = 'WARNING'
                }

                (response['statusRow']).errorAction = remark.action;

                (response[remark.column]).errorType = remark.status;
                (response[remark.column]).errorAction = remark.action;
                (response[remark.column]).errorList.push(remark.notice);
                (response['statusRow']).errorList.push({ notice: remark.notice, column: remark.column, action: remark.action });
              });
            }
            //Object.entries(content);
          }
        }
        newContent['editing'] = editedRecord.editing
        return newContent;
      }),
    );
  }

  //PREVIEW
  renderRowPreview(editedRecord: any) {
    let records = _.cloneDeep(editedRecord)
    Object.entries(records).forEach(entry => {
      if (!!(entry[1]))
        records[entry[0]] = (entry[1])['value'];
      else
        delete records[entry[0]];

    });
    return this.http.post<any>(environment.apiUrl + 'oemcc_preview/render', records, {}).pipe(
      map((response) => {
        let newContent = response;
        if (!!newContent) {
          if (!!response) {
            response['statusRow'] = {
              value: 'READY',
              errorType: '',
              errorList: [],
              errorAction: ''
            }
            Object.entries(response).forEach(entry => {
              if (entry[0] !== 'meta' && entry[0] !== 'statusRow' && entry[0] !== 'errors' ) {
                response[entry[0]] = {
                  value: entry[1],
                  errorType: '',
                  errorList: [],
                  errorAction: ''
                }
              }
            })
            if (!!response.errors) {
              response.errors.forEach((remark) => {
                if (remark.errorStatus === 'ERROR') {
                  (response['statusRow']).value = 'ERROR'
                } else if (remark.errorStatus === 'WARNING' && (response['statusRow']).value !== 'ERROR') {
                  (response['statusRow']).value = 'WARNING'
                }

                (response['statusRow']).errorAction = remark.errorAction;

                (response[remark.errorColumn]).errorType = remark.errorStatus;
                (response[remark.errorColumn]).errorAction = remark.errorAction;
                (response[remark.errorColumn]).errorList.push(remark.errorNotice);
                (response['statusRow']).errorList.push({ notice: remark.errorNotice, column: remark.errorColumn, action: remark.errorAction });
              });
            }
            //Object.entries(content);
          }
        }
        newContent['editing'] = editedRecord.editing
        return newContent;
      }),
    );
  }

  saveMonitorRecord(records: any[]) {
    records.map(record => {
      delete record.statusRow;
      Object.entries(record).forEach(entry => {
        if (!!(entry[1]))
          record[entry[0]] = (entry[1])['value'];
        else
          delete record[entry[0]];
      })
      return record
    })
    return this.http.post<any>(environment.apiUrl + 'oemcc_monitor', records, {});
  }

  deleteRecordPreview(recordId: any) {
    return this.http.delete(environment.apiUrl + `oemcc_preview/${recordId}`);
  }

  getMonitorDetail(recordId: any) {
    return this.http.get(environment.apiUrl + 'oemcc_monitor/' + recordId).pipe(
      map((response) => {
        let newContent = response;
        if (!!newContent['content']) {
          response['content'].map((content) => {
            if (!!content) {
              content['statusRow'] = {
                value: 'READY',
                errorType: '',
                errorList: [],
                errorAction: ''
              }
              Object.entries(content).forEach(entry => {
                if (entry[0] !== 'meta' && entry[0] !== 'statusRow') {
                  content[entry[0]] = {
                    value: entry[1],
                    errorType: '',
                    errorList: [],
                    errorAction: ''
                  }
                }
              })
              if (!!content.meta && !!content.meta.remarks) {
                content.meta.remarks.forEach((remark) => {
                  if (remark.status === 'ERROR') {
                    (content['statusRow']).value = 'ERROR'
                  } else if (remark.status === 'WARNING' && (content['statusRow']).value !== 'ERROR') {
                    (content['statusRow']).value = 'WARNING'
                  }

                  (content['statusRow']).errorAction = remark.action;

                  (content[remark.column]).errorType = remark.status;
                  (content[remark.column]).errorAction = remark.action;
                  (content[remark.column]).errorList.push(remark.notice);
                  (content['statusRow']).errorList.push({ notice: remark.notice, column: remark.column, action: remark.action });
                });
              }
            }
            return content;
          })
        }
        return newContent;
      }),
    );
  }

  reopenRecords(recordIds: any) {
    return this.http.post<any>(environment.apiUrl + 'oemcc_monitor/reopen', recordIds, {});
  }

  openRecords(recordIds: any) {
    return this.http.post<any>(environment.apiUrl + 'oemcc_monitor/open', recordIds, {});
  }

  closeRecords(recordIds: any) {
    return this.http.post<any>(environment.apiUrl + 'oemcc_monitor/close', recordIds, {});
  }

  getAudit(recordId: string) {
    return this.http.get<any[]>(environment.apiUrl + 'oemcc_monitor/audit/' + recordId);
  }

  notifyRecords(recordIds: any) {
    return this.http.post<any>(environment.apiUrl + 'oemcc_monitor/notify', recordIds, {});
  }

  getDetailFile(container: string) {
    return this.http.get<any>(environment.apiUrl + 'oemcc_monitor/detail/find/' + container);
  }

  deleteDetailFile(fileId: String) {
    return this.http.get<any>(environment.apiUrl + 'oemcc_monitor/detail/delete/' + fileId);
  }

  downloadDetailFile(fileId: string, filename: string) {
    const httpHeader: Object = {
      responseType: 'blob'
    }
    return this.http.get<any>(environment.apiUrl + 'oemcc_monitor/detail/download/' + fileId, httpHeader).subscribe(blob => {
      saveAs(blob, filename);
    });
  }

  uploadDetailFile(id: string, containerNo: string, category: string, files: File[]) {
    const formData: FormData = new FormData();
    files.forEach(file => {
      formData.append('monitorFiles', file, file.name)
    });

    const params = new HttpParams().set('containerNo', containerNo).set("category", category);

    return this.http.post<any>(environment.apiUrl + 'oemcc_monitor/detail/upload/' + id, formData, { params })
  }

  getAttachmentCategories() {
    return this.http.get<string[]>(environment.apiUrl + 'oemcc_monitor/detail/categories', {});
  }

  getGroupedRecords(monitorIds: any, typology: string, id: string) {
    const params = new HttpParams().set('typology', typology).set('id', id);
    return this.http.post<any>(environment.apiUrl + 'oemcc_monitor/groups', monitorIds, { params });
  }
  getContactGroups(typology: string) {
    const params = new HttpParams().set('typology', typology);
    return this.http.get<any>(environment.apiUrl + 'oemcc_monitor/contact-groups', { params });
  }

  sendNotifySL(notify: NotificationSL, type: string) {
    const params = new HttpParams().set('type', type);
    return this.http.post<any>(environment.apiUrl + 'oemcc_monitor/notify', notify, { params });
  }

  sendNotify(notify: { [key: string]: NotificationSL }, type: string, id: string) {
    const params = new HttpParams().set('type', type).set('id', id);
    return this.http.post<any>(environment.apiUrl + 'oemcc_monitor/notify', notify, { params });
  }

  getActionsMenu(typeId?: string) {
    if (!!typeId) {
      const params = new HttpParams().set('typeId', typeId);
      return this.http.get<BtnAction[]>(environment.apiUrl + 'oemcc_monitor/label/actions', { params });
    }
    return this.http.get<BtnAction[]>(environment.apiUrl + 'oemcc_monitor/label/actions', {});
  }

  getEmailsForContactGroup(contactGeroupName: string) {
    return this.http.get<string[]>(environment.apiUrl + 'oemcc_monitor/contact-groups/emails/' + contactGeroupName, {})
  }

  getContainerList(ids: string) {
    return this.http.post<{ id: number, container: string }[]>(environment.apiUrl + 'oemcc_monitor/containers', ids, {})
  }

  uploadDetailFiles(objs: { id: number, container: string }[], category: string, file: File) {
    return objs.map(obj => this.uploadDetailFile('' + obj.id, obj.container, category, [file]))
  }

  sendReply(notify: NotificaitonReplyBody, typeId: number) {
    const params = new HttpParams().set('typeId', typeId);
    return this.http.post<any>(environment.apiUrl + 'oemcc_monitor/reply', notify, { params });
  }

  getTypology(typeId: string) {
    const params = new HttpParams().set('typeId', typeId);
    return this.http.get<string>(environment.apiUrl + 'oemcc_monitor/typology', { params });
  }

  editDetail(element: any, headerId: number) {

    Object.entries(element).forEach(entry => {
      if (!!(entry[1]))
        element[entry[0]] = (entry[1])['value'];
      else
        delete element[entry[0]];

    });

    return this.http.put<any>(environment.apiUrl + 'oemcc_monitor/' + headerId, element, {}).pipe(
      map((response) => {
        let newContent = response;
        if (!!(newContent['value'])['content']) {
          (response['value'])['content'].map((content) => {
            if (!!content) {
              content['statusRow'] = {
                value: 'READY',
                errorType: '',
                errorList: [],
                errorAction: ''
              }
              Object.entries(content).forEach(entry => {
                if (entry[0] !== 'meta' && entry[0] !== 'statusRow') {
                  content[entry[0]] = {
                    value: entry[1],
                    errorType: '',
                    errorList: [],
                    errorAction: ''
                  }
                }
              })
              if (!!content.meta && !!content.meta.remarks) {
                content.meta.remarks.forEach((remark) => {
                  if (remark.status === 'ERROR') {
                    (content['statusRow']).value = 'ERROR'
                  } else if (remark.status === 'WARNING' && (content['statusRow']).value !== 'ERROR') {
                    (content['statusRow']).value = 'WARNING'
                  }

                  (content['statusRow']).errorAction = remark.action;

                  (content[remark.column]).errorType = remark.status;
                  (content[remark.column]).errorAction = remark.action;
                  (content[remark.column]).errorList.push(remark.notice);
                  (content['statusRow']).errorList.push({ notice: remark.notice, column: remark.column, action: remark.action });
                });
              }
            }
            return content;
          })
        }
        if (!!(newContent['key'])) {
          (response['key'])['statusRow'] = {
            value: 'READY',
            errorType: '',
            errorList: [],
            errorAction: ''
          }
          Object.entries((response['key'])).forEach(entry => {
            if (entry[0] !== 'meta' && entry[0] !== 'statusRow') {
              (response['key'])[entry[0]] = {
                value: entry[1],
                errorType: '',
                errorList: [],
                errorAction: ''
              }
            }
          })
          if (!!(response['key']).meta && !!(response['key']).meta.remarks) {
            (response['key']).meta.remarks.forEach((remark) => {
              if (remark.status === 'ERROR') {
                ((response['key'])['statusRow']).value = 'ERROR'
              } else if (remark.status === 'WARNING' && ((response['key'])['statusRow']).value !== 'ERROR') {
                ((response['key'])['statusRow']).value = 'WARNING'
              }

              ((response['key'])['statusRow']).errorAction = remark.action;

              ((response['key'])[remark.column]).errorType = remark.status;
              ((response['key'])[remark.column]).errorAction = remark.action;
              ((response['key'])[remark.column]).errorList.push(remark.notice);
              ((response['key'])['statusRow']).errorList.push({ notice: remark.notice, column: remark.column, action: remark.action });
            });
          }
        }
        return newContent;
      }),
    );

  }

  deleteDetail(headerId: number, detailId: number) {
    const params = new HttpParams().set('detailId', detailId);
    return this.http.delete<any>(environment.apiUrl + 'oemcc_monitor/' + headerId, { params }).pipe(
      map((response) => {
        let newContent = response;
        if (!!(newContent['value'])['content']) {
          (response['value'])['content'].map((content) => {
            if (!!content) {
              content['statusRow'] = {
                value: 'READY',
                errorType: '',
                errorList: [],
                errorAction: ''
              }
              Object.entries(content).forEach(entry => {
                if (entry[0] !== 'meta' && entry[0] !== 'statusRow') {
                  content[entry[0]] = {
                    value: entry[1],
                    errorType: '',
                    errorList: [],
                    errorAction: ''
                  }
                }
              })
              if (!!content.meta && !!content.meta.remarks) {
                content.meta.remarks.forEach((remark) => {
                  if (remark.status === 'ERROR') {
                    (content['statusRow']).value = 'ERROR'
                  } else if (remark.status === 'WARNING' && (content['statusRow']).value !== 'ERROR') {
                    (content['statusRow']).value = 'WARNING'
                  }

                  (content['statusRow']).errorAction = remark.action;

                  (content[remark.column]).errorType = remark.status;
                  (content[remark.column]).errorAction = remark.action;
                  (content[remark.column]).errorList.push(remark.notice);
                  (content['statusRow']).errorList.push({ notice: remark.notice, column: remark.column, action: remark.action });
                });
              }
            }
            return content;
          })
        }
        if (!!(newContent['key'])) {
          (response['key'])['statusRow'] = {
            value: 'READY',
            errorType: '',
            errorList: [],
            errorAction: ''
          }
          Object.entries((response['key'])).forEach(entry => {
            if (entry[0] !== 'meta' && entry[0] !== 'statusRow') {
              (response['key'])[entry[0]] = {
                value: entry[1],
                errorType: '',
                errorList: [],
                errorAction: ''
              }
            }
          })
          if (!!(response['key']).meta && !!(response['key']).meta.remarks) {
            (response['key']).meta.remarks.forEach((remark) => {
              if (remark.status === 'ERROR') {
                ((response['key'])['statusRow']).value = 'ERROR'
              } else if (remark.status === 'WARNING' && ((response['key'])['statusRow']).value !== 'ERROR') {
                ((response['key'])['statusRow']).value = 'WARNING'
              }

              ((response['key'])['statusRow']).errorAction = remark.action;

              ((response['key'])[remark.column]).errorType = remark.status;
              ((response['key'])[remark.column]).errorAction = remark.action;
              ((response['key'])[remark.column]).errorList.push(remark.notice);
              ((response['key'])['statusRow']).errorList.push({ notice: remark.notice, column: remark.column, action: remark.action });
            });
          }
        }
        return newContent;
      }),
    );
  }

  newDetail(element: any, headerId: number) {

    Object.entries(element).forEach(entry => {
      if (!!(entry[1]))
        element[entry[0]] = (entry[1])['value'];
      else
        delete element[entry[0]];

    });

    return this.http.post<any>(environment.apiUrl + 'oemcc_monitor/' + headerId, element, {}).pipe(
      map((response) => {
        let newContent = response;
        if (!!(newContent['value'])['content']) {
          (response['value'])['content'].map((content) => {
            if (!!content) {
              content['statusRow'] = {
                value: 'READY',
                errorType: '',
                errorList: [],
                errorAction: ''
              }
              Object.entries(content).forEach(entry => {
                if (entry[0] !== 'meta' && entry[0] !== 'statusRow') {
                  content[entry[0]] = {
                    value: entry[1],
                    errorType: '',
                    errorList: [],
                    errorAction: ''
                  }
                }
              })
              if (!!content.meta && !!content.meta.remarks) {
                content.meta.remarks.forEach((remark) => {
                  if (remark.status === 'ERROR') {
                    (content['statusRow']).value = 'ERROR'
                  } else if (remark.status === 'WARNING' && (content['statusRow']).value !== 'ERROR') {
                    (content['statusRow']).value = 'WARNING'
                  }

                  (content['statusRow']).errorAction = remark.action;

                  (content[remark.column]).errorType = remark.status;
                  (content[remark.column]).errorAction = remark.action;
                  (content[remark.column]).errorList.push(remark.notice);
                  (content['statusRow']).errorList.push({ notice: remark.notice, column: remark.column, action: remark.action });
                });
              }
            }
            return content;
          })
        }
        if (!!(newContent['key'])) {
          (response['key'])['statusRow'] = {
            value: 'READY',
            errorType: '',
            errorList: [],
            errorAction: ''
          }
          Object.entries((response['key'])).forEach(entry => {
            if (entry[0] !== 'meta' && entry[0] !== 'statusRow') {
              (response['key'])[entry[0]] = {
                value: entry[1],
                errorType: '',
                errorList: [],
                errorAction: ''
              }
            }
          })
          if (!!(response['key']).meta && !!(response['key']).meta.remarks) {
            (response['key']).meta.remarks.forEach((remark) => {
              if (remark.status === 'ERROR') {
                ((response['key'])['statusRow']).value = 'ERROR'
              } else if (remark.status === 'WARNING' && ((response['key'])['statusRow']).value !== 'ERROR') {
                ((response['key'])['statusRow']).value = 'WARNING'
              }

              ((response['key'])['statusRow']).errorAction = remark.action;

              ((response['key'])[remark.column]).errorType = remark.status;
              ((response['key'])[remark.column]).errorAction = remark.action;
              ((response['key'])[remark.column]).errorList.push(remark.notice);
              ((response['key'])['statusRow']).errorList.push({ notice: remark.notice, column: remark.column, action: remark.action });
            });
          }
        }
        return newContent;
      }),
    );

  }

  getHeaderEventsByMonitorId(headerId: string){
    return this.http.get<MonitorHeaderEvent[]>(environment.apiUrl + 'oemcc_monitor/events/'+headerId, {})
  }

  getHeaderCostsByMonitorId(headerId: string){
    return this.http.get<MonitorHeaderCost[]>(environment.apiUrl + 'oemcc_monitor/costs/'+headerId, {})
  }


  getHeaderById(headerId: string){

    this.http.get<MonitorHeaderEvent[]>(environment.apiUrl + 'oemcc_monitor/events/'+headerId, {})


    return lastValueFrom(this.http.get(environment.apiUrl + 'oemcc_monitor/header/'+headerId, { }).pipe(
      map((response) => {
        let newContent = response;
        if (!!newContent['content']) {
          response['content'].map((content) => {
            if (!!content) {
              content['statusRow'] = {
                value: 'READY',
                errorType: '',
                errorList: [],
                errorAction: ''
              }
              Object.entries(content).forEach(entry => {
                if (entry[0] !== 'meta' && entry[0] !== 'statusRow') {
                  content[entry[0]] = {
                    value: entry[1],
                    errorType: '',
                    errorList: [],
                    errorAction: ''
                  }
                }
              })
              if (!!content.meta && !!content.meta.remarks) {
                content.meta.remarks.forEach((remark) => {
                  if (remark.status === 'ERROR') {
                    (content['statusRow']).value = 'ERROR'
                  } else if (remark.status === 'WARNING' && (content['statusRow']).value !== 'ERROR') {
                    (content['statusRow']).value = 'WARNING'
                  }

                  (content['statusRow']).errorAction = remark.action;

                  (content[remark.column]).errorType = remark.status;
                  (content[remark.column]).errorAction = remark.action;
                  (content[remark.column]).errorList.push(remark.notice);
                  (content['statusRow']).errorList.push({ notice: remark.notice, column: remark.column, action: remark.action });
                });
              }
              //Object.entries(content);
            }

            return content;

          })
        }
        return newContent;
      }),
    ))
  }

  updateMonitorHeader(headerId: string, header:any){
    return lastValueFrom(this.http.post(environment.apiUrl + 'oemcc_monitor/header/'+headerId, header, { }).pipe(
      map((response) => {
        let newContent = response;
        if (!!newContent['content']) {
          response['content'].map((content) => {
            if (!!content) {
              content['statusRow'] = {
                value: 'READY',
                errorType: '',
                errorList: [],
                errorAction: ''
              }
              Object.entries(content).forEach(entry => {
                if (entry[0] !== 'meta' && entry[0] !== 'statusRow') {
                  content[entry[0]] = {
                    value: entry[1],
                    errorType: '',
                    errorList: [],
                    errorAction: ''
                  }
                }
              })
              if (!!content.meta && !!content.meta.remarks) {
                content.meta.remarks.forEach((remark) => {
                  if (remark.status === 'ERROR') {
                    (content['statusRow']).value = 'ERROR'
                  } else if (remark.status === 'WARNING' && (content['statusRow']).value !== 'ERROR') {
                    (content['statusRow']).value = 'WARNING'
                  }

                  (content['statusRow']).errorAction = remark.action;

                  (content[remark.column]).errorType = remark.status;
                  (content[remark.column]).errorAction = remark.action;
                  (content[remark.column]).errorList.push(remark.notice);
                  (content['statusRow']).errorList.push({ notice: remark.notice, column: remark.column, action: remark.action });
                });
              }
              //Object.entries(content);
            }

            return content;

          })
        }
        return newContent;
      }),
    ))
  }

  deleteRecords(recordIds: any) {
    return this.http.post<any>(environment.apiUrl + 'oemcc_monitor/admin/delete', recordIds, {});
  }

  openDuplicateRecords(recordIds: any) {
    return this.http.post<any>(environment.apiUrl + 'oemcc_monitor/admin/open', recordIds, {});
  }



  getDuplicatedPreview(params?: HttpParams) {
    if (!!params) {
      params.append('role', 'ADMIN')
    }
    return this.http.get(environment.apiUrl + 'oemcc_preview/admin', { params }).pipe(
      map((response) => {
        let newContent = response;
        if (!!newContent['content']) {
          response['content'].map((content) => {
            if (!!content) {
              content['statusRow'] = {
                value: 'READY',
                errorType: '',
                errorList: [],
                errorAction: ''
              }
              Object.entries(content).forEach(entry => {
                if (entry[0] !== 'meta' && entry[0] !== 'statusRow' && entry[0] !== 'errors') {
                  content[entry[0]] = {
                    value: entry[1],
                    errorType: '',
                    errorList: [],
                    errorAction: ''
                  }
                }
              })
              if (!!content.errors) {
                content.errors.forEach((remark) => {
                  if (remark.errorStatus === 'ERROR') {
                    (content['statusRow']).value = 'ERROR'
                  } else if (remark.errorStatus === 'WARNING' && (content['statusRow']).value !== 'ERROR') {
                    (content['statusRow']).value = 'WARNING'
                  }

                  (content['statusRow']).errorAction = remark.errorAction;

                  (content[remark.errorColumn]).errorType = remark.errorStatus;
                  (content[remark.errorColumn]).errorAction = remark.errorAction;
                  (content[remark.errorColumn]).errorList.push(remark.errorNotice);
                  (content['statusRow']).errorList.push({ notice: remark.errorNotice, column: remark.errorColumn, action: remark.errorAction });
                });
              }
              //Object.entries(content);
            }

            return content;

          })
        }
        return newContent;
      }),
    );
  }


  deletePreviewRecords(recordIds: any) {
    return this.http.post<any>(environment.apiUrl + 'oemcc_preview/admin/delete', recordIds, {});
  }


  openPreviewRecords(recordIds: any) {
    return this.http.post<any>(environment.apiUrl + 'oemcc_preview/admin/open', recordIds, {});
  }


  getMonitorActions() {
    return this.http.get<ManualActionList>(environment.apiUrl + 'oemcc_manual_actions');
  }

  callManualAction(action: string, list: any[]) {
    let params = new HttpParams().set('action', action)
    return this.http.post<any>(environment.apiUrl + 'oemcc_manual_actions', list, {params: params});
  }
}

export interface NotificationSL {
  [key: string]: NotificationSLBody
}

export interface NotificaitonReplyBody {
  notificationBody: string,
  notificationCc: string,
  notificationTo: string,
  notificationSubject: string,
  messageId: string,
  conversationId: string,
  receivedDateTime: string,
  notificationTypology: string,
  notificationThreadId: number,
  notificationAttachments: {
    name: string,
    contentBytes: string,
    contentType: string,
    size: number
  }[],
  notificationThreadDesc: string
}

export interface NotificationSLBody {
  htmlBody: string,
  records: any[],
  sendCc: string[],
  sendTo: string[],
  mailSubject: string,
  files: any[]
}

export interface BtnAction {
  id: string,
  label: string,
  description: string,
  action: string
}


export type ManualActionList = ManualAction[]

export interface ManualAction {
  manualActionId: string
  manualActionName: string
  actionType: string
  workflowId: string
  eventId: any
  notificationId: any
  isMultiObjects: string
}


