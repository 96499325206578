import {Pipe, PipeTransform} from '@angular/core';
import {camelToSnakeCase, refactorName} from "../utils/generic";

@Pipe({
  name: 'rmDQS'
})
export class RemoveDoubleQuoteAndSlachPipe implements PipeTransform {

  transform(value: string | String, ...args: unknown[]): string {
    if (value == null){
      return '';
    }
    return value.replace(/\n/g, '\\n').replace(/"/g, '&quot;');
  }

}
