import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "lodash";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { AuthService } from "src/app/services/auth.service";
import { MonitorService } from "src/app/services/monitor.service";
import { DialogComponent } from "../dialog/dialog.component";
import { AdministrationService } from "../../../services/administration.service";
import {Header} from "../monitor-data-table/admin-data-table.component";
import {AlertType} from "../alert-old/alert-old.component";

@Component({
  selector: 'app-detail-table',
  templateUrl: './detail-table.component.html',
  styleUrls: ['./detail-table.component.scss']
})
export class DetailTableComponent implements OnInit {

  @ViewChild('alertMessageMonitor') alertMessageMonitor;

  @Input()
  rowElement: any;

  @Input()
  screen: string;

  @Output()
  onDetailChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onDetailLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  detailsDataSubject = new BehaviorSubject<any[]>([]);
  detailsDataObs = this.detailsDataSubject.asObservable();
  detailsDataSource = [];
  detailsDataLength = 0;

  permittedActions = [];

  detailHeadersSubject = new BehaviorSubject<Header[]>([]);
  detailHeaders = this.detailHeadersSubject.asObservable();

  iterableHeaders: Header[] = [];
  displayedColumns: string[] = [];

  dateReg = /[a-zA-Z]/g;

  oldElement: any;

  private timeOut = 500;

  constructor(private monitorService: MonitorService, private authService: AuthService, public dialog: MatDialog,
    private spinner: NgxSpinnerService, private administrationService: AdministrationService) {

    lastValueFrom(this.administrationService.getLoadingDelay()).then(res => {
      this.timeOut = +(res[0].value)
    })
  }

  ngOnInit(): void {
    this.authService.getAction().subscribe(actions => {
      this.permittedActions = actions['actions'];
    });

    this.authService.getHeader("oemcc_monitor_detail").subscribe(details => {
      let detailHeaders = [];
      details['detailModels'].forEach(detail => {
        detailHeaders.push({
          id: detail.order,
          field: detail.column,
          label: detail.label,
          editable: detail.editable == 'Y',
          editType: detail.type,
          selectOptions: detail.options,
          headerClass: detail.headerClass,
          actions: !!detail.actions ? detail.actions.split(';') : []
        });
        this.detailHeadersSubject.next(detailHeaders);
      });
      //console.log(detailHeaders);
    });

    this.detailHeaders.subscribe(headers => {
      this.displayedColumns = headers.map((header: Header) => header.field);
      this.iterableHeaders = headers;
    });

    this.refreshDetails(this.rowElement.id.value);
  }

  isActionPresent(action: string) {
    if (this.permittedActions.indexOf(action) > -1)
      return true;
    else
      return false;
  }

  newDetail() {
    this.detailsDataSource = [{
      id: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      unit: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      productType: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      supplierName: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      supplierCode: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      grossVolume: {
        value: 0,
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      grossWeight: {
        value: 0,
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      unitCount: {
        value: 0,
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      pnc: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      poNumber: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      invoiceNrSupplier: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      orderDate: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      transportOrderSent: {
        value: 'N',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      meta: {},
      newRow: true
    }, ...this.detailsDataSource];

    this.detailsDataSubject.next(this.detailsDataSource);
  }

  newDetailAlreadyExists() {
    return this.detailsDataSource.some((detail) => detail.newRow);
  }

  onClickCell(element: any, col: Header) {
    if (this.screen != 'monitor-su') {
      if (!element.newRow) {
        if (col.editable && element.editing !== col.field) {
          //Remove editing from any cell
          this.detailsDataObs.subscribe(table => table.filter((el) => !!el.editing).forEach((el) => el.editing = null));
          //Set this cell in editing
          element.editing = col.field;

          this.oldElement = _.cloneDeep(element);

          //this.onEditStart.emit({ column: col, element: element })
        }
      }
    }
  }

  onCellExit(element: any, col: Header) {
    if (!element.newRow) {
      this.onEditDetail({ record: element, oldRecord: this.oldElement, cel: col.field, editType: col.editType });
    }
  }

  onEditDetail(data: { record: any, oldRecord: any, cel: string, editType: string }) {

    if (!!data.record && !!data.oldRecord && data.record[data.cel].value === data.oldRecord[data.cel].value)
      return;

    let element;
    if (!!data.record) {
      delete data.record.editing;
      element = data.record;
    } else
      element = data;

    const { meta, ...newRecord } = data.record;

    this.monitorService.editDetail(newRecord, this.rowElement.id.value).subscribe(
      (res: any) => {

        //console.log(res['key'])
        this.onDetailChange.emit(res['key']);

        this.detailsDataSource = res['value']['content'];
        this.detailsDataSubject.next(this.detailsDataSource);
        this.detailsDataLength = res['value'].totalElements;

      },
      (err: any) => {
        this.refreshDetails(this.rowElement.id.value);
        /*if (err.status == 403)
          this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Error 403 Forbidden", "You don't have permission for this action.", 10);
        else
          this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Edit error", "An error occurred during editing.", 10);*/

      })
  }

  onAction(element: any, action: string) {
    this.onActionDetail({ element, action })
  }

  onActionDetail(row: any) {
    switch (row.action) {
      case "delete":
        const dialogRef = this.dialog.open(DialogComponent, {
          // width: '250px',
          data: {
            title: "Confirm deletion",
            content: `This action will delete the detail. Proceed?`,
            cancelButtonLabel: "Cancel",
            cancelButtonColor: "basic",
            confirmButtonLabel: "Delete",
            confirmButtonColor: "warn"
          }
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (!!res) {
            this.monitorService.deleteDetail(this.rowElement.id.value, row.element.id.value).subscribe(
              (res: any) => {
                this.detailsDataSource = res['value']['content'];
                this.detailsDataSubject.next(this.detailsDataSource);
                this.detailsDataLength = res['value'].totalElements;
              }
            ),
              (err: any) => {
                //this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Details loading error", "An error occurred while loading details.");
              }
          }
        });
        break;
      case "cancel":
        this.detailsDataSource = this.detailsDataSource.slice(1);
        this.detailsDataSubject.next(this.detailsDataSource);
        break;
      case "create":
        delete row.element.newRow;

        this.onNewDetail(row.element);
        break;
    }
  }

  onNewDetail(row: any) {

    const { meta, ...newRecord } = row;

    this.monitorService.newDetail(newRecord, this.rowElement.id.value).subscribe(
      (res: any) => {

        //console.log(res['key'])
        this.onDetailChange.emit(res['key']);

        this.detailsDataSource = res['value']['content'];
        this.detailsDataSubject.next(this.detailsDataSource);
        this.detailsDataLength = res['value'].totalElements;

      },
      (err: any) => {
        this.refreshDetails(this.rowElement.id.value);
        /*if (err.status == 403)
          this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Error 403 Forbidden", "You don't have permission for this action.", 10);
        else
          this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Edit error", "An error occurred during editing.", 10);*/

      })
  }

  refreshDetails(id: number) {
    //const loadingEvent = this.dialog.open(LoadingComponent);
    let isSpinning = true;
    setTimeout(() => {
      if (isSpinning) {
        this.spinner.show()
      }
    }, this.timeOut)

    this.monitorService.getMonitorDetail(id)
      .subscribe(
        (res: any) => {
          if (!!res && !!res.content) {
            this.detailsDataSource = [];
            this.detailsDataSource = this.hasSubArray(this.detailsDataSource, res.content);
            this.detailsDataSubject.next(this.detailsDataSource);
            this.detailsDataLength = res.totalElements;
            if (this.detailsDataLength > 0) {
              this.onDetailLoaded.emit(true);
            } else {
              this.onDetailLoaded.emit(false);
            }
          }
          //loadingEvent.close();
          this.spinner.hide();
          isSpinning = false;
        }),
      (err: any) => {
        this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Details loading error", "An error occurred while loading details.");
      }
  }

  dateModelChange(id: any, e: any) {
    this.checkDate((<HTMLInputElement>document.getElementById(id)).value);
    if (moment(e).isValid()) {
      return e;
    }
    else return (<HTMLInputElement>document.getElementById(id)).value;
  }

  checkDate(dateToCheck: any) {
    return (moment(dateToCheck, 'DD/MM/YYYY').isValid() && dateToCheck.length === 10 && !this.dateReg.test(dateToCheck));
  }

  checkDateById(id: string) {
    let dateToCheck
    if (!!(<HTMLInputElement>document.getElementById(id)))
      dateToCheck = (<HTMLInputElement>document.getElementById(id)).value;
    //console.log(dateToCheck)
    return moment(dateToCheck, 'DD/MM/YYYY').isValid() && dateToCheck.length === 10;
  }

  onDateCellExit(evt: any, element: any, col: any) {
    element[col.field].value = evt.target.value;
    if (!!element && typeof element[col.field].value === 'object') {
      element[col.field].value = moment(element[col.field].value, "DD/MM/YYYY").format('DD/MM/YYYY');
    }
    if (!this.checkDate(element[col.field].value) && element[col.field].value != '') {
      element[col.field].value = this.oldElement[col.field].value;
      return;
    }
    element.editing = null;
    this.onCellExit(element, col);
  }

  onDatepickerClose(evt: any, element: any, col: any,) {
    element[col.field].value = moment(evt, "DD/MM/YYYY").format('DD/MM/YYYY');
    if (!this.checkDate(element[col.field].value)) return;
    this.onCellExit(element, col);
  }

  inputExists(elementId) {
    if (!!(<HTMLInputElement>document.getElementById(elementId)) && !!(<HTMLInputElement>document.getElementById(elementId)).value) return true;
    else return false;
  }

  clearInput(id: string, element: any, col: any) {
    (<HTMLInputElement>document.getElementById(id)).value = '';
    element[col.field] = '';
    this.onEditDetail({ record: element, oldRecord: this.oldElement, cel: col.field, editType: col.editType });
    element.editing = null;
  }

  hasSubArray(master: any[], sub: any[]) {
    sub.forEach(elem => {
      const index = master.findIndex(e => e.id === elem.id);
      if (index !== -1) {
        master[index] = elem;
      } else {
        master.push(elem);
      }
    })
    return master;
  }

  onCellFocus($event: any) {
    if (!!$event && !!$event.target)
      $event.target.select()
  }

  onCheckClick(row: any, header: Header, field: string) {
    row[field].value = row[field].value == 'Y' ? 'N' : 'Y';

    this.onCellExit(row, header);
  }



}

