<mat-accordion *ngIf="!!filterController && !!filterController.filterModels && filterController.filterModels.length > 0"
               class="example-headers-align"
               multi>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <label
          class="filter-panel-header-title">{{ filterController.viewedFilter.length < 1 ? ("FILTER_LABEL" | translate) : (("FILTER_BY" | translate) + ":") }}</label><label
        class="filter-panel-header-title filter-panel-header-column-name">&nbsp; {{ filterController.viewedFilter.join('; ') }}</label>
      </mat-panel-title>
      <mat-panel-description>
        <label></label>
        <mat-icon (click)="openDndDialog($event)" matTooltip="{{ 'LABEL_FILTER_SETTINGS' | translate }}"
                  style="float: right; margin-right: 1rem;">settings
        </mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="row">
      <div class="column-filter">
        <div class="filter-panel-container">
          <ng-container *ngFor="let filterModel of filterController.filterModels; let i = index">
            <custom-filter (onEnterEmit)="applyFilter()" *ngIf="!filterModel.isHide"
                           [(filterModel)]="filterController.filterModels[i]"
                           [filterValues]="filterController.filterValues"></custom-filter>
          </ng-container>

        </div>
      </div>

      <div class="column-btn">
        <custom-btn (click)="applyFilter()" [color]="'primary'">
          {{ "FILTER_SEARCH" | translate }}
        </custom-btn>

        <custom-btn (click)="clearFilter()" [color]="'accent'">
          {{ "FILTER_CLEAR" | translate }}
        </custom-btn>

        <!--<custom-btn (click)="resetFilter()" [color]="'accent'">
          {{"FILTER_RESET" | translate}}
        </custom-btn>-->
      </div>
    </div>


  </mat-expansion-panel>
</mat-accordion>
