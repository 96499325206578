<div class="form-group">
  <!--<input class="form-control text-center" placeholder="dd/mm/yyyy" name="d2" #c2="ngModel" [(ngModel)]="value"
    (ngModelChange)="onDateChange()" ngbDatepicker #d2="ngbDatepicker" [placement]="'left'" />
  <i class="fa fa-calendar icon" (click)="d2.toggle()"></i>-->
  <div class="input-group">
    <input #d="ngbDatepicker" (focusout)="onFocusOut()" (ngModelChange)="onDateChange($event)" [(ngModel)]="value" [readonly]="disabled"
           class="form-control input-picker" name="dp" ngbDatepicker placeholder="dd/mm/yyyy"/>
    <button (click)="d.toggle()" [disabled]="disabled" class="btn btn-outline-secondary bi bi-calendar3 btn-picker"
            type="button"></button>
  </div>


  <!--<button class="btn btn-outline-secondary bi bi-calendar3" (click)="d2.toggle()" type="button"></button>-->
</div>
