
<div class="actions-container" sticky>
  <div class="btn-container" *ngIf="showMerge">
    <span class="bold">Select and...</span>
    <custom-btn [disabled]="this.selection.selected.length == 0 || !isActionPresent('preview.edit.approve')"
            class="action-button" mat-raised-button color="primary" matTooltip="Merge record to monitor"
            action-type="button"
            (click)="mergeToMonitor()">Approve
    </custom-btn>
  </div>
  <div class="btn-container" *ngIf="screen === 'monitor'">
    <span class="bold" *ngIf="isActionPresent('monitor.edit.close') || isActionPresent('monitor.export.excel')">Select
      and...</span>
    <!--<button [disabled]="this.selection.selected.length == 0 || !isActionPresent('monitor.edit.open') || !isActionPresent('monitor.edit.close')" class="action-button" mat-raised-button color="primary"
      matTooltip="Open record/s" action-type="button" (click)="openRecords()">Open</button>-->
    <custom-btn *ngIf="screen === 'monitor' && !isChanged && isActionPresent('monitor.edit.close')"
            [disabled]="this.selection.selected.length == 0 || !isActionPresent('monitor.edit.open') || !isActionPresent('monitor.edit.close')"
            class="action-button" mat-raised-button color="primary" matTooltip="Close record/s" action-type="button"
            (click)="closeRecords()">Close
    </custom-btn>

    <custom-btn *ngIf="screen === 'monitor' && (isActionPresent('monitor.notify'))" class="action-button" mat-raised-button
            color="primary" action-type="button"
            [disabled]="this.selection.selected.length == 0 || actionsBtn.length == 0"
            [matMenuTriggerFor]="notificatoinMenu">Notify
    </custom-btn>
    <mat-menu #notificatoinMenu="matMenu" xPosition="after">
      <div *ngFor="let btn of actionsBtn; let i= index">

        <button *ngIf="isActionPresent('monitor.notify.' + btn.id)" mat-menu-item
                (click)="notifyRecords(btn.id, btn.action, btn.label, btn.description)">{{btn.label}}</button>

      </div>

    </mat-menu>

    <custom-btn *ngIf="(extraActions ?? []).length > 0" class="action-button" mat-raised-button
            color="primary" action-type="button"
            [disabled]="this.selection.selected.length == 0 || actionsBtn.length == 0"
            [matMenuTriggerFor]="actionsMenu">Actions
    </custom-btn>
    <mat-menu #actionsMenu="matMenu" xPosition="after">
      <ng-container *ngFor="let btn of extraActions; let i= index">
        <div>
          <button *ngIf="isActionPresent('manual.actions.'+btn.manualActionId)" mat-menu-item
                  (click)="emitAction(btn.manualActionId)">{{btn.manualActionName}}</button>
        </div>
      </ng-container>

    </mat-menu>

    <button *ngIf="isActionPresent('monitor.export.excel')" class="action-button" mat-raised-button color="primary"
            matTooltip="Export selected records" action-type="button" (click)="onExport()"
            [disabled]="isChanged">Export
    </button>

  </div>

  <div *ngIf="showMerge" class="cb-container">
    <mat-checkbox name="info" class="cb-m" [(ngModel)]="checkboxes.info" (change)="emitCheck($event)">
      <mat-icon class="status-icon-info">check_circle_outline</mat-icon>
      <!--<label>{{(dataSource | async)?.filter(filterInfoCounter).length}}</label>-->
    </mat-checkbox>
    <mat-checkbox name="warning" class="cb-m" [(ngModel)]="checkboxes.warning" (change)="emitCheck($event)">
      <mat-icon class="status-icon-warning">error_outline</mat-icon>
      <!--<label>{{(dataSource | async)?.reduce(reduceWarningCounter, 0)}}</label>-->
    </mat-checkbox>
    <mat-checkbox name="error" class="cb-m" [(ngModel)]="checkboxes.error" (change)="emitCheck($event)">
      <mat-icon class="status-icon-error">report</mat-icon>
      <!--<label>{{(dataSource | async)?.reduce(reduceErrorCounter, 0)}}</label>-->
    </mat-checkbox>
  </div>

  <div class="actions-container">
    <custom-btn *ngIf="screen === 'monitor' && isActionPresent('monitor.import')" class="action-button" mat-raised-button
            color="primary" matTooltip="Import records" action-type="button" (click)="onImport()">Import
    </custom-btn>
    <span *ngIf="!(screen === 'monitor' && isActionPresent('monitor.import'))"></span>
    <custom-btn class="action-button" mat-raised-button color="primary" matTooltip="Clear sorting" action-type="button"
            (click)="resetSorting($event)" *ngIf="screen !== 'monitor' || (screen === 'monitor' && !isChanged)">Clear
      Sort
    </custom-btn>
  </div>
  <app-paginator class="right" [paginatorLoaderTitle]="'Loading batch: '" [page]="pageEvent.pageIndex"
                 [paginatorLoader]="50" [paginatorLoaderList]="[50, 100, 250]" [numElementsLoaded]="numElementsLoaded"
                 [numElementsSize]="length" (onPaginatorChange)="onPageChange($event)"
                 *ngIf="screen !== 'monitor' || (screen === 'monitor' && !isChanged)">
  </app-paginator>

  <!--<div class="paginator-container">

    <mat-paginator [length]="length" [pageSize]="pageEvent.pageSize" [disabled]="loadingTable"
      [pageIndex]="pageEvent.pageIndex" [pageSizeOptions]="logspaceFunction()" (page)="onPageChange($event)"
      aria-label="Select page">
    </mat-paginator>
  </div>-->
</div>

<div
  [ngClass]="{'plServiceItemsList-listContainer':!isChanged, 'plServiceItemsList-listContainer-import mt-16':isChanged}">
  <cdk-virtual-scroll-viewport [rowHeight]="48">
    <table mat-table [appVirtualDataSource]="trueDataSource" #table multiTemplateDataRows>

      <ng-container *ngFor="let col of displayedHeaders;" [matColumnDef]="col.field" class="table-column">

        <ng-template [ngIf]="col.field === 'select'">
          <th mat-header-cell *matHeaderCellDef>
            <span>
              <mat-checkbox (change)="masterToggle($event)" [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                <span *ngIf="!!selection.selected.length">{{selection.selected.length}} / {{numElementsLoaded}}</span>

              </mat-checkbox>
            </span>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)" [disabled]="isCheckboxDisabled(row)"
                          (keydown.Tab)="onKeyUpCell($event, row, col, null)">
            </mat-checkbox>
          </td>
        </ng-template>

        <ng-template [ngIf]="screen === 'preview' && col.field === 'status'">
          <th mat-header-cell *matHeaderCellDef>
            Status
          </th>
          <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="(row.statusRow).value === 'READY';else second">
              <span [matTooltip]="row.statusRow.errorList | statusTooltip:(displayedHeaders) | async"
                    matTooltipClass="line-broken-tooltip" tabindex="-1">
                <mat-icon class="status-icon-info">check_circle_outline</mat-icon>
              </span>
            </ng-container>
            <ng-template #second>
              <ng-container *ngIf="(row.statusRow).value === 'WARNING';else third">
                <span [matTooltip]="row.statusRow.errorList | statusTooltip:(displayedHeaders) | async"
                      matTooltipClass="line-broken-tooltip" tabindex="-1">
                  <mat-icon class="status-icon-warning">error_outline</mat-icon>
                </span>
              </ng-container>
            </ng-template>
            <ng-template #third>
              <span [matTooltip]="row.statusRow.errorList | statusTooltip:(displayedHeaders) | async"
                    matTooltipClass="line-broken-tooltip" tabindex="-1">
                <mat-icon class="status-icon-error">report</mat-icon>
              </span>
            </ng-template>
          </td>
        </ng-template>

        <ng-template [ngIf]="!!screen && screen === 'monitor' && col.field === 'status'">
          <th mat-header-cell *matHeaderCellDef>
            {{col.label}}
          </th>
          <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="!!row[col.field] && row[col.field].value === 'DELETE'; else new">
              <span [matTooltip]="row[col.field].value" tabindex="-1">
                <mat-icon class="status-icon-error">block</mat-icon>
              </span>
            </ng-container>
            <ng-template #new>
              <ng-container *ngIf="!!row[col.field] && row[col.field].value === 'NEW'; else open">
                <span [matTooltip]="row[col.field].value" tabindex="-1">
                  <mat-icon class="blue">note_add</mat-icon>
                </span>
              </ng-container>
            </ng-template>
            <ng-template #open>
              <ng-container *ngIf="!!row[col.field] && row[col.field].value === 'OPEN'; else closed">
                <span [matTooltip]="row[col.field].value" tabindex="-1">
                  <mat-icon class="status-icon-info">lock_open</mat-icon>
                </span>
              </ng-container>
            </ng-template>
            <ng-template #closed>
              <ng-container
                *ngIf="!!row[col.field] && (row[col.field].value == 'CLOSED' || row[col.field].value == null); else reopened">
                <span matTooltip="CLOSED" tabindex="-1">
                  <mat-icon class="status-icon-error">lock</mat-icon>
                </span>
              </ng-container>
            </ng-template>
            <ng-template #reopened>
              <ng-container *ngIf="!!row[col.field] && row[col.field].value == 'REOPENED'; else sent">
                <span [matTooltip]="!!row[col.field] ? row[col.field].value : ''" tabindex="-1">
                  <mat-icon class="status-icon-info">refresh</mat-icon>
                </span>
              </ng-container>
            </ng-template>
            <ng-template #sent>
              <ng-container *ngIf="!!row[col.field] && row[col.field].value == 'SENT'; else staged">
                <span [matTooltip]="!!row[col.field] ? row[col.field].value : ''" tabindex="-1">
                  <mat-icon class="status-icon-info">mark_email_read</mat-icon>
                </span>
              </ng-container>
            </ng-template>
            <ng-template #staged>
              <ng-container *ngIf="!!row[col.field] && row[col.field].value == 'STAGED'; else ready">
                <span [matTooltip]="!!row[col.field] ? row[col.field].value : ''" tabindex="-1">
                  <mat-icon class="status-icon-warning">inventory_2</mat-icon>
                </span>
              </ng-container>
            </ng-template>
            <ng-template #ready>
              <ng-container *ngIf="!!row[col.field] && row[col.field].value == 'READY'; else none">
                <span [matTooltip]="!!row[col.field] ? row[col.field].value : ''" tabindex="-1">
                  <mat-icon class="status-icon-info">pending</mat-icon>
                </span>
              </ng-container>
            </ng-template>
            <ng-template #none>
              <span [matTooltip]="!!row[col.field] ? row[col.field].value : ''" tabindex="-1">
                <mat-icon class="status-icon-error">info</mat-icon>
              </span>
            </ng-template>
          </td>
        </ng-template>

        <ng-template [ngIf]="col.field === 'detail'">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button mat-button (click)="onExpandClick(row)" tabindex="-1">
              <mat-icon *ngIf="!(row === expandedElement)">keyboard_arrow_right</mat-icon>
              <mat-icon *ngIf="(row === expandedElement)">keyboard_arrow_down</mat-icon>
            </button>
          </td>
        </ng-template>

        <ng-template [ngIf]="col.field === 'audit'">
          <th mat-header-cell *matHeaderCellDef class="center">{{col.label}}</th>
          <td mat-cell *matCellDef="let row" [matTooltip]="row | cellTooltip:col.field:col.label | async"
              matTooltipClass="line-broken-tooltip">
            <button mat-icon-button (click)="showAudit(row.id.value)" tabindex="-1">
              <mat-icon>history</mat-icon>
            </button>
          </td>
        </ng-template>

        <ng-template [ngIf]="col.field === 'containerDetail'">
          <th mat-header-cell *matHeaderCellDef class="center">{{col.label}}</th>
          <td mat-cell *matCellDef="let row">
            <button mat-button [matMenuTriggerFor]="containerDetailsActions" tabindex="-1">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #containerDetailsActions="matMenu">
              <button [disabled]="!isActionPresent('monitor.detail.view')" mat-menu-item
                      (click)="showDetail(row.id.value, row.container.value)" tabindex="-1">
                <mat-icon>manage_search</mat-icon>
                <span>Inspect container</span>
              </button>
              <button [disabled]="!isActionPresent('monitor.comunication.view')" mat-menu-item
                      (click)="openNotificationHistory(row.id.value, row.container.value)" tabindex="-1">
                <mat-icon>mark_email_unread</mat-icon>
                <span>Comunication tracker</span>
              </button>
            </mat-menu>

          </td>
        </ng-template>

        <ng-template
          [ngIf]="col.field !== 'select' && col.field !== 'status' && col.field !== 'audit' && col.field !== 'containerDetail' && col.field !== 'detail'">
          <th mat-header-cell *matHeaderCellDef class="pointer" [ngClass]="!!col.headerClass ? col.headerClass : ''"
              (click)="sortColumn(col.field)">
            <span tabindex="-1" *ngIf="!isChanged">
              {{col.label}}
              <mat-icon
                *ngIf="!!sortedColumns.get(col.field) && sortedColumns.get(col.field).type == 'asc'">
                arrow_upward</mat-icon>
              <mat-icon *ngIf="!!sortedColumns.get(col.field) && sortedColumns.get(col.field).type == 'desc'"
                        class="sorter-icon">arrow_downward</mat-icon><label class="sort-order"
                                                                            *ngIf="!!sortedColumns.get(col.field) && (sortedColumns.get(col.field).type == 'asc' || sortedColumns.get(col.field).type == 'desc')">{{sortedColumns.get(col.field).order}}</label>
            </span>
            <span tabindex="-1" *ngIf="isChanged">
              {{col.label}}</span>
          </th>

          <td mat-cell *matCellDef="let element" class="relative" (click)="onClickCell(element, col)"
              [class.editableCell]="(col.editable && !isAdding()) || !isChanged"
              [class.errorCell]="(element[col.field]?.value | validateRegex : col.regex)"
              [ngClass]="element | cellClass:col.field:(!!col.cellClass ? col.cellClass : ''):(col.editable || isChanged )| async"
              [matTooltip]="element | cellTooltip:col.field:col.label:((element[col.field]?.value | validateRegex : col.regex)?col.regexMessage:undefined) | async" matTooltipClass="line-broken-tooltip"
              [id]="'td-' + element.id.value+'-'+col.id" #tabCellClickable>

            <!-- [matTooltip]="getCellToolTip(element, col.field)" matTooltipClass="line-broken-tooltip" -->

            <!-- STANDARD VIEW -->
            <div *ngIf="(!col.editable || (col.editable && element.editing !== col.field))"
                 [ngClass]="!!col.cellTextClass ? col.cellTextClass : ''">
              <!-- NORMAL CELL -->
              <!--<span class="mat-error-element">{{element | cellTooltip:col.field:col.label | async}}</span>-->
              <span
                *ngIf="col.field !== 'actions' && col.editType !== 'datePicker' && !element.newRow && col.field !== 'container' && col.editType !== 'check' && !(selectableColumns.includes(col.field))">
                {{element[col.field] != null ? element[col.field].value : null}}
              </span>

              <span
                *ngIf="col.field !== 'actions' && col.editType !== 'datePicker' && !element.newRow && col.field !== 'container' && col.editType !== 'check' && (selectableColumns.includes(col.field))">
                {{element[col.field] != null ? (element[col.field].value | valueFromArrayOrDefault : (col.field.includes('terminal') ? terminalOptions : (col.field.includes('declarant') ? declarantOptions : ( col.field.includes('containerContentType') ? containerContentTypeOptions :destinationsOptions ))) : 'value' : 'label') : null}}
              </span>




              <span
                *ngIf="!element.newRow && col.editType && col.editType === 'datePicker' && typeof(element[col.field].value) !== 'object' "
                [ngClass]="col.field == 'portEtaActual' ? 'bold' : ''">
                {{element[col.field].value}}
              </span>

              <span
                *ngIf="!element.newRow && col.editType && col.editType === 'datePicker' && typeof(element[col.field].value) === 'object'"
                [ngClass]="hasError(element.id.value, col.id) ? 'cell-error' : ''">
                {{element[col.field].value | date: 'dd/MM/yyyy'}}
              </span>

              <span *ngIf="!element.newRow && col.field === 'container'" class="bold"
                    [class.errorText]="!checkContainer(element[col.field].value)"
                    [matTooltip]="!checkContainer(element[col.field].value) && screen === 'monitor' ? 'Format must be 4 capital letters and 7 numbers' : null">
                {{element[col.field].value}}
              </span>

              <div class="center"
                   *ngIf="!element.newRow && col.editType && col.editType === 'check' && col.field === 'transportOrderSent' && !!(element[col.field]) && !!(element[col.field].value)">
                <span *ngFor="let checkBox of (element[col.field].value).split(',')">
                  <mat-icon *ngIf="checkBox == 'Y'">check_box</mat-icon>
                  <mat-icon *ngIf="checkBox !== 'Y'">check_box_outline_blank</mat-icon>
                </span>
              </div>

              <div class="center"
                   *ngIf="!element.newRow && col.editType && col.editType === 'check' && !(!!col.isAggregated) && col.field !== 'transportOrderSent'">
                <span (click)="onCheckClick(element, col, col.field)">
                  <mat-icon *ngIf="!!(element[col.field].value) && element[col.field].value == 'Y'">check_box</mat-icon>
                  <mat-icon *ngIf="!(!!(element[col.field].value)) || element[col.field].value !== 'Y'">
                    check_box_outline_blank</mat-icon>
                </span>
              </div>

              <!-- ACTIONS CELL (EDITING) -->
              <span *ngIf=" col.field==='actions' && !element.newRow">
                <ng-template *ngFor="let action of col.actions"
                             [ngIf]="action !=='delete' || (action === 'delete' && isDuplicated(element)) || (action==='delete' && !isFilter)">
                  <button mat-icon-button color="primary" class="table-button"
                          [disabled]="!isActionPresent(screen+'.delete')" (click)="onAction(element, action)">
                    <mat-icon>{{action}}</mat-icon>
                  </button>
                </ng-template>
              </span>

              <!-- ACTIONS CELL (NEW ROW) -->
              <div *ngIf="col.field === 'actions' && element.newRow" [ngStyle]="{ display: 'flex' }">
                <button mat-icon-button color="primary" class="table-button" (click)="onAction(element, 'create')">
                  <mat-icon>check</mat-icon>
                </button>
                <button mat-icon-button color="primary" class="table-button" (click)="onAction(element, 'cancelNew')">
                  <mat-icon>clear</mat-icon>
                </button>
              </div>
            </div>

            <!-- EDITING VIEW -->
            <div *ngIf="col.editable && element.editing === col.field && !element.newRow"
                 [id]="'div-'+element.id.value+'-'+col.id">

              <!-- STANDARD INPUT -->
              <mat-form-field *ngIf="(!col.editType || col.editType === 'input') && col.field !== 'container'"
                              (click)="$event.stopPropagation()" class="full-width"
                              (keydown.Tab)="onKeyUpCell($event, element, col, null)"
                              [id]="'field-'+element.id.value+'-'+col.id">
                <input [id]="element.id.value+'-'+col.id" matInput [(ngModel)]="element[col.field].value"
                       (ngModelChange)="validateCell($event, element, col)" (focusout)="onCellExit(element, col)">
                <mat-error *ngIf="!!element.error">
                  {{element.error}}
                </mat-error>
              </mat-form-field>

              <!-- NUMERIC INPUT -->
              <mat-form-field *ngIf="col.editType === 'number'" (click)="$event.stopPropagation()" class="full-width"
                              (keydown.Tab)="onKeyUpCell($event, element, col, null)"
                              [id]="'field-'+element.id.value+'-'+col.id">
                <input type="number" [id]="element.id.value+'-'+col.id" matInput [(ngModel)]="element[col.field].value"
                       (focusout)="onCellExit(element, col)">
              </mat-form-field>

              <!-- CONTAINER INPUT -->
              <mat-form-field *ngIf="col.field === 'container'"
                              [class.errorText]="!checkContainer(element[col.field].value)"
                              (click)="$event.stopPropagation()"
                              class="full-width" (keydown.Tab)="onKeyUpCell($event, element, col, null)"
                              [id]="'field-'+element.id.value+'-'+col.id">
                <input [id]="element.id.value+'-'+col.id" matInput [(ngModel)]="element[col.field].value"
                       (ngModelChange)="validateCell($event, element, col)"
                       [matTooltip]="!checkContainer(element[col.field].value) ? 'Format must be 4 capital letters and 7 numbers' : null"
                       (focusout)="onCellExit(element, col)">
              </mat-form-field>

              <!-- SELECT INPUT -->
              <mat-form-field *ngIf="col.editType && col.editType === 'select'" (click)="$event.stopPropagation()"
                              [id]="'field-'+element.id.value+'-'+col.id">
                <mat-select [id]="element.id.value+'-'+col.id" [(ngModel)]="element[col.field].value"
                            (selectionChange)="onCellExit(element, col)"
                            (keydown.Tab)="onKeyUpCell($event, element, col, null)">
                  <mat-option *ngIf="col.field !== 'containerContentType'" [value]="null"></mat-option>
                  <mat-option *ngFor="let option of col.selectOptions ?? []" [value]="option.label">{{option.label}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!!element.error">
                  {{element.error}}
                </mat-error>
              </mat-form-field>

              <!-- SELECT INPUT TO MONITOR AND PREVIEW -->
              <div *ngIf="col.editType && col.editType === 'selectMP'" (click)="$event.stopPropagation()"
                   (keydown.Tab)="onKeyUpCell($event, element, col, null)" [id]="'field-'+element.id.value+'-'+col.id">
                <autocomplete [selectedOptions]="col.selectOptions ?? []" [element]="element" [column]="col"
                              [identifier]="element.id.value+'-'+col.id" (focusout)="onCellExit(element, col)">
                </autocomplete>
                <!--<mat-select [id]="element.id.value+'-'+col.id" [(ngModel)]="element[col.field].value"
                  (selectionChange)="onCellExit(element, col)"
                  (keydown.Tab)="onKeyUpCell($event, element, col, element.id.value+'-'+col.id)" [autofocus]="true"
                  #rdcSelect (focus)="rdcSelect.open()">
                  <mat-option *ngFor="let option of col.selectOptions ?? []" [value]="option.label">{{option.label}}
                  </mat-option>
                </mat-select>-->
              </div>

              <!-- DATEPICKER INPUT -->
              <div *ngIf="col.editType && col.editType === 'datePicker'" (click)="$event.stopPropagation(); "
                   [id]="'field-'+element.id.value+'-'+col.id">
<!--                commentato utilizzo nebular -->
<!--                <input class="picker" [nbDatepicker]="datepicker" [id]="element.id.value+'-'+col.id"-->
<!--                       [ngModel]="element[col.field].value | stringToDate | async"-->
<!--                       (ngModelChange)="dateModelChange(element.id.value+'-'+col.id, $event)"-->
<!--                       [class.errorText]="!checkDateById(element.id.value+'-'+col.id)"-->
<!--                       [matTooltip]="!checkDateById(element.id.value+'-'+col.id) ? 'Format must be DD/MM/YYYY' : ''"-->
<!--                       (keydown.Tab)="onKeyUpCell($event, element, col, null)"-->
<!--                       (change)="onDateCellExit($event, element, col)" #dateInput>-->
                <span class="t-1" *ngIf="inputExists(element.id.value+'-'+col.id)" nbButton
                      (click)="clearInput(element.id.value+'-'+col.id, element, col);">
                  <mat-icon>event_busy</mat-icon>
                </span>
<!--                commentato utilizzo nebular-->
<!--                <nb-datepicker #datepicker (dateChange)="onDatepickerClose($event, element, col);" format="dd/MM/yyyy">-->
<!--                </nb-datepicker>-->
              </div>

              <!-- TIMEPICKER INPUT -->
              <mat-form-field *ngIf="col.editType && col.editType === 'time'" class="picker"
                              (click)="$event.stopPropagation()" [id]="'field-'+element.id.value+'-'+col.id">
                <div class="flex">
                  <input matInput type="time" [id]="element.id.value+'-'+col.id" [(ngModel)]="element[col.field].value"
                         (focusout)="onCellExit(element, col)" (keydown.Tab)="onKeyUpCell($event, element, col, null)">
                  <span class="cancel" *ngIf="inputExists(element.id.value+'-'+col.id) "
                        (click)="clearInput(element.id.value+'-'+col.id, element, col);">
                    <mat-icon>cancel</mat-icon>
                  </span>
                </div>
              </mat-form-field>

            </div>

            <!-- NEW ROW VIEW -->
            <div *ngIf="element.newRow && col.field !== 'id' && col.field !== 'actions'">
              <!-- STANDARD INPUT -->
              <mat-form-field *ngIf="!col.editType || col.editType === 'input'" (click)="$event.stopPropagation()"
                              class="full-width">
                <input [id]="element.id.value+'-'+col.id" matInput [(ngModel)]="element[col.field].value"
                       (ngModelChange)="validateCell($event, element, col)"
                       (keyup)="onKeyUpCell($event, element, col, null)"
                       [autofocus]="true" (focus)="onCellFocus($event)" (focusout)="onCellExit(element, col)">
                <mat-error *ngIf="!!element.error">
                  {{element.error}}
                </mat-error>
              </mat-form-field>

              <!-- SELECT INPUT -->
              <mat-form-field *ngIf="col.editType && col.editType === 'select'" (click)="$event.stopPropagation()">
                <mat-select [id]="element.id.value+'-'+col.id" [(ngModel)]="element[col.field].value">
                  <mat-option *ngFor="let option of col.selectOptions ?? []" [value]="option.value">{{option.label}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!!element.error">
                  {{element.error}}
                </mat-error>
              </mat-form-field>

              <!-- SELECT MP INPUT -->
              <mat-form-field *ngIf="col.editType && col.editType === 'selectMP'">
                <mat-select [id]="element.id.value+'-'+col.id" [(ngModel)]="element[col.field].value">
                  <mat-option *ngFor="let option of col.selectOptions ?? []" [value]="option.value">{{option.label}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!!element.error">
                  {{element.error}}
                </mat-error>
              </mat-form-field>
            </div>

            <div *ngIf="hasError(element.id, col.id)" class="message-error">
              {{getErrorMessage(element.id, col.id)}}
            </div>
          </td>
        </ng-template>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
          <div class="detailTable-container" [@detailExpand]="row === expandedElement ? 'expanded' : 'collapsed'">

            <app-detail-table *ngIf="row === expandedElement" [rowElement]="expandedElement"
                              (onDetailChange)="onDetailChange($event)">
            </app-detail-table>

          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" [style.height.px]="48"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === row" [class.selectedRow]="selection.isSelected(row)"></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
  </cdk-virtual-scroll-viewport>

</div>
