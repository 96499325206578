import {Component, Inject, Input, OnDestroy, OnInit} from "@angular/core";
import {TableControllerService} from "../../../services/table-controller.service";
import {FilterControllerService} from "../../../services/filter-controller.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Subject, takeUntil} from "rxjs";
import {GeneralService} from "../../../services/crud/general.service";
import {camelToSnakeCase} from "../../../utils/generic";
import {AlertService} from "../alert/alert.service";
import {ALERT_TYPE} from "../alert/alert.enumerate";


@Component({
  selector: "import-preview",
  templateUrl: "./import-preview.component.html",
  styleUrls: ["./import-preview.component.scss"],
  host: {'class': 'h-100'},
  providers: [TableControllerService, FilterControllerService],
})
export class ImportPreviewComponent implements OnDestroy, OnInit
{
  private _interactionCycle: Subject<void> = new Subject<void>();

  layout: any;
  apiParent: string;
  apiUrl: string;

  isSaving = false;

  columns: any[];
  rows: any[];

  constructor(
    // retrieve dialog input values
    @Inject(MAT_DIALOG_DATA) public data: any,
    private generalDataService: GeneralService,
    public alertService: AlertService,
  )
  {
    this.layout = this.data['layout'];
    this.apiParent = this.data['apiParent'];
    this.apiUrl = this.data['apiUrl'];
  }

  ngOnDestroy(): void
  {
  }

  ngOnInit(): void
  {
    this.columns = this.layout.view.detailModels;
    this.rows = this.layout.importRows;
  }

  getCellInfo(key:string, row: any) {
    const remarks: any[] = row.meta.remarks;

    const STATUS_INFO = "INFO";
    const STATUS_WARNING = "WARNING";
    const STATUS_ERROR = "ERROR";

    let isRemarked: boolean = false;
    let tooltips: string[] = [];
    let status: string;

    const errorPriorityMap = {
      [STATUS_INFO] : 0,
      [STATUS_WARNING] : 1,
      [STATUS_ERROR] : 2,
    }

    const errorClassMap = {
      [STATUS_INFO] : '',
      [STATUS_WARNING] : 'warning',
      [STATUS_ERROR] : 'error',
    }

    remarks.forEach(remark => {
      if (key.toLowerCase() == camelToSnakeCase(remark.column).toLowerCase()){
        isRemarked = true;
        tooltips.push(remark.action + ' - ' + remark.notice);
        if (errorPriorityMap[remark.status] != null && errorPriorityMap[remark.status] > (errorPriorityMap[status] ?? 0)) {
          status = remark.status
        }
      }
    });

    return {
      isRemarked : isRemarked,
      tooltips : tooltips.length == 0 ? row[key] : tooltips.join(', '),
      status : status,
      class : errorClassMap[status] ?? null
    };

  }

  save(){
    this.isSaving = true;
    this.generalDataService.postData(
      this.apiParent + "/" + this.apiUrl + "/preview",
      this.rows,
      // new HttpParams().set('new', (committedRow.newRow ?? false))
      null
    ).pipe(takeUntil(this._interactionCycle)).subscribe({
      next: (res) =>
      {
        this.isSaving = false;

        const success = 200 == res.iResponseCode;
        if (success) {
          const oContent = res.oContent;
          let messages = [];
          messages.push("Updated detail: " + oContent.detail);
          messages.push("New monitor: " + oContent.new);
          messages.push("Updated monitor: " + oContent.updated);
          this.alertService.add({
            type: ALERT_TYPE.SUCCESS,
            message: messages.join(", "),
            timeout: 5000,
            selfClose: null,
          });
        } else {
          this.alertService.add({
            type: ALERT_TYPE.DANGER,
            message: "UPLOADING_ERROR",
            timeout: 10000,
            selfClose: null,
          });
        }
      },
      error: () => {
        this.isSaving = false;
        this.alertService.add({
          type: ALERT_TYPE.DANGER,
          message: "UPLOADING_ERROR",
          timeout: 10000,
          selfClose: null,
        });
      }
    });
  }

}
