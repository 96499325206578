<app-action-bar>
  <label class="page-title" page-title><label class="page-subtitle">Master Data - </label> Units</label>
  <button mat-flat-button action-type="button" class="action-button"
    (click)="refreshUnits(0, (pageEvent.pageIndex+1)*pageEvent.loader, sortedColumns)" color="primary">Refresh</button>
  <button *ngIf="isActionPresent('unit.insert')" mat-flat-button action-type="button" class="action-button"
    (click)="newUnit()" [disabled]="newUnitAlreadyExists()" color="primary">New Unit</button>
</app-action-bar>

<div class="alert-container">
  <alert-old-component #alertMessageMonitor></alert-old-component>
</div>

<div class="datatable-container ph-16">
  <div class="datatable-content">
    <app-data-table class="datatable-content" [headers]="unitsHeaders" [length]="unitsLength" [loadingTable]="loading"
      [dataSource]="unitsDataSourceObservable" [numElementsLoaded]="(unitsDataSourceObservable | async)?.length"
      (onSortChange)="onSortChange($event)" [sortedColumns]="sortedColumns"
      (onPaginationChange)="onPageChange($event, 3)" (onEditCell)="onEditUnit($event, true)"
      (onActionClick)="onActionUnit($event)" [screen]="screen" #childDataTable>
    </app-data-table>
  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white; font-size: 1.5rem" > Loading... </p></ngx-spinner>
