import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {map, Observable, of} from 'rxjs';
import {startWith} from 'rxjs/operators';
import {MatFormFieldControl} from '@angular/material/form-field';
import {IMultiSelectOption} from "../custom-multiselect/types";

@Component({
  selector: 'custom-autocomplete',
  templateUrl: './custom-autocomplete.component.html',
  styleUrls: ['./custom-autocomplete.component.scss'],
  /*providers: [
    { provide: MatFormFieldControl, useExisting: CustomAutocompleteComponent },
  ],*/
})
export class CustomAutocompleteComponent implements OnInit {

  myControl = new FormControl('');
  @Input()
  options: any[] = [];
  @Input()
  placeholder: String = '';
  @Input()
  required = false;

  @Output()
  optionChange: EventEmitter<String> = new EventEmitter();

  filteredOptions!: Observable<any[]>;

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options?.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  onOptionChange(option: any) {
    this.optionChange.emit(option.id);
  }
}
