import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
})
export class ActionBarComponent implements OnInit {
  @Input()
  type: String = '';

  constructor() {
  }

  ngOnInit(): void {
  }
}
