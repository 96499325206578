<div class="custom-select-container">
	<!--<mat-icon class="error-icon" *ngIf="!haveAction(defaultValue) && localValue" matTooltip="{{'LABEL_ERROR_404' | translate}}">error</mat-icon>
	<mat-icon class="warning-icon" *ngIf="isExpiredAction(defaultValue) && localValue" matTooltip="{{'LABEL_ERROR_EXPIRED' | translate}}">warning</mat-icon>-->

	<mat-select #selectElement
				class="form-control filter-options custom-select"
				[ngClass]="extraClass"
				(selectionChange)="oncSelectionChange($event)"
				(openedChange)="openedChange($event)"
				[(ngModel)]="value"
				[matTooltip]="(( options ?? []) | getValueOnCondition:'id':'name':value:value | async) ?? ''"
				multiple
				[disabled]="disabled?? false"
	>
		<ng-container *ngIf="searchable">
			<div class="search-container position-sticky top-0">
				<div class="input-group">
					<input
						#searchInput
						class="form-control"
						[(ngModel)]="searchValue"
						(ngModelChange)="onSearchValueChange($event)"
						placeholder="Search any Id"/>
					<button type="button"
							class="btn btn-outline-secondary bi bi-trash-fill btn-picker"
							[disabled]="!searchValue"
							(click)="onSearchValueChange('')"
					>
					</button>
				</div>
			</div>
		</ng-container>

    <cdk-virtual-scroll-viewport itemSize="25" class="virtual-scroll" [rowHeight]="48">
      <mat-option *ngFor="let option of filterOptions" [value]="option.id">
        {{ option.name }}
      </mat-option>
    </cdk-virtual-scroll-viewport>

		<div *ngIf="filterOptions?.length === 0"
			 class="empty-message w-100 text-center fw-bold mb-2">
			No result found
		</div>

	</mat-select>


</div>
