<app-action-bar>
  <label class="page-title" page-title><label class="page-subtitle">OEMCC - </label> Preview</label>
</app-action-bar>
<div class="alert-container">
  <alert-old-component #alertMessagePreview></alert-old-component>
</div>
<div class="datatable-container ph-16">
  <div class="datatable-content">
    <app-filter [filterObj]="filterObjPreview" (applyFilterEv)="onApplyFilter($event, 0)"></app-filter>
    <div class="datatable-container">
      <app-data-table class="datatable-content" *ngIf="(headers | async)?.length > 0" [length]="dataLength" [headers]="headers" [loadingTable]="loading"
        [checkboxes]="checkboxesStatusFilter" [dataSource]="dataSourceObservable"
        [dataSourceObservable]="dataSourceObservable" [pageEvent]="pageEvent"
                      [destinationsOptions]="destinationsOptions"
                      [rdcDestinationsOptions]="rdcDestinationsOptions"
                      [containerContentTypeOptions]="containerContentTypeOptions"
                      [declarantOptions]="declarantOptions"
                      [terminalOptions]="terminalOptions"
        [numElementsLoaded]="(dataSourceObservable | async)?.length" (onPaginationChange)="onPageChange($event)"
        (onSortChange)="onSortChange($event)" [sortedColumns]="sortedColumnsPreview"
        (onMergeClick)="onMergeClick($event, 0)" [showMerge]="true" (onEditCell)="onEditCell($event)"
        (onEditStart)="onDestinationEditStart($event)" [screen]="screen" (onCheckboxClick)="onCheckboxClick($event)"
        (onActionClick)="onActionPreview($event)" [editingRowColumn]="editingRowColumn" #childDataTablePreview>
      </app-data-table>
    </div>

  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white; font-size: 1.5rem" > Loading... </p></ngx-spinner>
