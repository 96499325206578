import {Pipe, PipeTransform} from '@angular/core';
import {camelToSnakeCase, refactorName} from "../utils/generic";

@Pipe({
  name: 'cml2snk'
})
export class Camel2SnakePipe implements PipeTransform {

  transform(value: string | String, ...args: unknown[]): string {
    return camelToSnakeCase(value);
  }

}
