import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";

import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {environment} from "../environments/environment";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {HIGHLIGHT_OPTIONS, HighlightModule, HighlightOptions} from "ngx-highlightjs";
import {TestComponentComponent} from "./test-component/test-component.component";
import {ObservableRequestComponent} from "./components/observable-request/observable-request.component";
import {PromiseRequestComponent} from "./components/promise-request/promise-request.component";

import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DndDirective} from "./directives/dnd.directive";

import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatButtonModule} from "@angular/material/button";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";
import {MatInputModule} from "@angular/material/input";
import {MatTooltipModule} from "@angular/material/tooltip";

import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {TopbarComponent} from "./components/shared/topbar/topbar.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AuthService} from "./services/auth.service";
import {NotFoundComponent} from "./components/pages/not-found/not-found.component";
import {DynamicPageComponent} from "./components/pages/dynamic-page/dynamic-page.component";
import {MenuListItemComponent} from "./components/shared/menu-list-item/menu-list-item.component";
import {AuthInterceptorService} from "./services/auth.interceptor.service";
import {HomeComponent} from "./components/pages/home/home.component";
import {NavService} from "./services/nav.service";
import {CustomBtnComponent} from "./components/shared/custom-btn/custom-btn.component";
import {CustomTextFieldComponent} from "./components/shared/custom-text-field/custom-text-field.component";
import {ActionBarComponent} from "./components/shared/action-bar/action-bar.component";
import {AlertComponent} from "./components/shared/alert/alert.component";
import {LoadingComponent} from "./components/shared/loading/loading.component";
import {EmailPreviewComponent} from "./components/shared/email-preview/email-preview.component";
import {VirtualScrollComponent} from "./components/shared/virtual-scroll/virtual-scroll.component";
import {UserSettingsComponent} from "./components/shared/user-settings/user-settings.component";
import {CheckboxFilterComponent} from "./components/shared/checkbox-filter/checkbox-filter.component";
import {BlankComponent} from "./components/shared/blank/blank.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {CustomCheckboxComponent} from "./components/shared/custom-checkbox/custom-checkbox.component";
import {CustomFilterComponent} from "./components/shared/filter/custom-filter/custom-filter.component";
import {MatSelectModule} from "@angular/material/select";
import {CustomDatepickerComponent} from "./components/shared/custom-datepicker/custom-datepicker.component";
import {CustomSelectComponent} from "./components/shared/custom-select/custom-select.component";
//import { CustomMultiselectComponent } from './components/shared/custom-multiselect/custom-multiselect.component';
import {CustomMultiselectModule} from "./components/shared/custom-multiselect/custom-multiselect.module";
import {FilterPanelComponent} from "./components/shared/filter/filter-panel/filter-panel.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatExpansionModule} from "@angular/material/expansion";
import {FilterSettingsComponent} from "./components/shared/filter/filter-settings/filter-settings.component";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {
  CustomTablePaginatorComponent
} from "./components/shared/custom-table-paginator/custom-table-paginator.component";
import {RefactorNamePipe} from "./pipes/refactor-name.pipe";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {CustomAutocompleteComponent} from "./components/shared/custom-autocomplete/custom-autocomplete.component";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {GeneralComponent} from "./components/shared/template/general/general.component";
import {DndComponent} from "./components/shared/dnd/dnd.component";
import {DialogComponent} from "./components/shared/dialog/dialog.component";
import {HaveActionPipe} from "./pipes/have-action.pipe";
import {CustomSelectValuesComponent} from "./components/shared/custom-select-values/custom-select-values.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {DocumentsComponent} from "./components/pages/documents/documents.component";
import {BackNavigationDirective} from "./directives/utils/back-navigation.directive";
import {GetListTooltipPipe} from "./pipes/get-list-tooltip.pipe";
import {GetValueOnConditionPipe} from "./pipes/get-value-on-condition.pipe";
import {CompareEditingPipe} from "./pipes/compare-editing.pipe";
import {HttpLoggerInterceptor} from "./services/logger.http.interceptor.service";
import {AppInfoComponent} from "./components/shared/dialog/app-info/app-info.component";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {GetOptionsPipe} from "./pipes/get-options.pipe";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {Observable} from "rxjs";
import {GeneralService} from "./services/crud/general.service";
import {LanguageComponent} from "./components/shared/template/language/language.component";
import {Camel2SnakePipe} from "./pipes/camel2snake.pipe";
import {AddLanguageComponent} from "./components/shared/template/language/add-language/add-language.component";
import {RemoveDoubleQuotePipe} from "./pipes/removeDoubleQuote.pipe";
import {RemoveDoubleQuoteAndSlachPipe} from "./pipes/removeDoubleQuoteAndSlash.pipe";
import {AddTranslationComponent} from "./components/shared/template/language/add-translation/add-translation.component";
import {HaveOneActionPipe} from "./pipes/have-one-action.pipe";
import {GetValueOnConditionImpurePipe} from "./pipes/get-value-on-condition-impure.pipe";
import {StringArrayTranslationPipe} from "./pipes/string-array-translation.pipe";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {CheckForUpdateService} from "./services/check-for-update.service";
import {CdkHeightCalculatorPipe} from "./pipes/cdk-height-calculator.pipe";
import {BadRequestComponent} from "./components/pages/bad-request/bad-request.component";
import {CustomIconBtnComponent} from "./components/shared/custom-icon-btn/custom-icon-btn.component";
import {HttpSpinnerInterceptorService} from "./services/http-spinner.interceptor.service";
import {FunctionsComponent} from "./components/pages/functions/functions.component";
import {GetDescriptionFromOptionValuesPipe} from "./pipes/get-description-from-option-values.pipe";
import {GetListTooltipV2ClassicPipe} from "./pipes/get-list-tooltip-v2-classic.pipe";
import {ngxLoadingAnimationTypes, NgxLoadingModule} from "ngx-loading";
import {TitleCasePipe} from "@angular/common";
import {DialogDownloadComponent} from './components/shared/dialog-download/dialog-download.component';
import {SaveConfirmComponent} from "./components/shared/dialog/save-confirm/save-confirm.component";
import {SafeUrlPipe} from "./pipes/safe-url.pipe";
import {SortArrayPipe} from "./pipes/sort-array.pipe";
import {MonitorComponent} from "./pages/monitor/monitor/monitor.component";
import {AutoFocusDirective} from "./shared/directives/auto-focus.directive";
import {ResizableDirective} from "./shared/directives/resizable.directive";
import {CellClassPipe} from "./shared/pipe/cell-class.pipe";
import {CellTooltipPipe} from "./shared/pipe/cell-tooltip.pipe";
import {StatusTooltipPipe} from "./shared/pipe/status-tooltip.pipe";
import {TranslateYPipe} from "./shared/pipe/translate-y.pipe";
import {VirtualTableDirective} from "./shared/directives/virtual-table.directive";
import {
  FixedRowSizeTableVirtualScrollStrategyDirective
} from "./shared/directives/fixed-row-size-table-virtual-scroll-strategy.directive";
import {CdkDetailRowDirective} from "./shared/directives/cdk-detail-row.directive";
import {StringToDatePipe} from "./shared/pipe/string-to-date.pipe";
import {StringToDateNoObsPipe} from "./shared/pipe/string-to-date-no-obs.pipe";
import {GetElementFromDatasourcePipe} from "./shared/pipe/get-element-from-datasource.pipe";
import {FileTypeTransfomPipe} from "./shared/pipe/file-type-transfom.pipe";
import {ContainStatusPipe} from "./shared/pipe/contain-status.pipe";
import {ValidateRegexPipe} from "./shared/pipe/validate-regex.pipe";
import {GetElementFromArrayPipe} from "./shared/pipe/get-value-from-array.pipe";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatTabsModule} from "@angular/material/tabs";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatDividerModule} from "@angular/material/divider";
import {MatStepperModule} from "@angular/material/stepper";
import {MatRadioModule} from "@angular/material/radio";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {MatGridListModule} from "@angular/material/grid-list";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {NgxSpinnerModule} from "ngx-spinner";
import {NgSelectModule} from "@ng-select/ng-select";
//import {NbDatepickerModule, NbLayoutModule, NbThemeModule} from "@nebular/theme";
import {DataTableComponent} from "./components/shared/data-table/data-table.component";
import {PaginatorComponent} from "./components/shared/paginator/paginator.component";
import {FinalDestinationComponent} from "./components/shared/final-destination/final-destination.component";
import {StaticDataTableComponent} from "./components/shared/static-data-table/static-data-table.component";
import {MonitorHistoryComponent} from "./pages/monitor/monitor-history/monitor-history.component";
import {
  TableVirtualScrollViewportComponent
} from "./components/shared/table-virtual-scroll-viewport/table-virtual-scroll-viewport.component";
import {DetailTableComponent} from "./components/shared/detail-table/detail-table.component";
import {AuditDialogComponent} from "./components/shared/audit-dialog/audit-dialog.component";
import {NewDelegationComponent} from "./components/shared/new-delegation/new-delegation.component";
import {HomepageComponent} from "./components/shared/homepage/homepage.component";
import {StaticLoadingComponent} from "./components/shared/static-loading/static-loading.component";
import {AdministrationPanelComponent} from "./components/shared/administration-panel/administration-panel.component";
import {AdminDataTableComponent} from "./components/shared/monitor-data-table/admin-data-table.component";
import {ContainerDetailComponent} from "./components/shared/container-detail/container-detail.component";
import {ContactGroupDialogComponent} from "./components/shared/contact-group-dialog/contact-group-dialog.component";
import {ComunicationTrackerComponent} from "./components/shared/comunication-tracker/comunication-tracker.component";
import {AutocompleteComponent} from "./components/shared/autocomplete/autocomplete.component";
import {AttachfiletoComponent} from "./components/shared/attachfileto/attachfileto.component";
import {ReplyMailComponent} from "./components/shared/reply-mail/reply-mail.component";
import {FilterComponent} from "./components/shared/filter/filter/filter.component";
import {DndOldComponent} from "./components/shared/dnd-old/dnd-old.component";
import {PreviewComponent} from "./pages/monitor/preview/preview.component";
import {MonitorSUComponent} from "./pages/monitor/monitor-SU/monitor-su.component";
import {PreviewSUComponent} from "./pages/monitor/preview-SU/preview-su.component";
import {ImportedComponent} from "./pages/imported/imported.component";
import {HelpComponent} from "./pages/help/help.component";
import {DestinationsComponent} from "./pages/master-data/destinations/destinations.component";
import {RawDestinationsComponent} from "./pages/master-data/raw-destinations/raw-destinations.component";
import {SubproductLinesComponent} from "./pages/master-data/subproduct-lines/subproduct-lines.component";
import {UnitsComponent} from "./pages/master-data/units/units.component";
import {AdministrationComponent} from "./pages/administration/administration.component";
import {AlertOldComponent} from "./components/shared/alert-old/alert-old.component";
import {DndOldDirective} from "./components/shared/dnd-old/dnd-old.directive";
import {AppDataTableComponent} from "./components/shared/app-data-table/app-data-table.component";
import {AppActionBarComponent} from "./components/shared/app-action-bar/app-action-bar.component";
import {MultiSelectComponent} from "./components/shared/multi-select/multi-select.component";
import {MonitorDetailComponent} from "./components/shared/monitor-detail-component/monitor-detail.component";
import {DetailDataTableComponent} from "./components/shared/detail-data-table/detail-data-table.component";
import {MonitorHeaderComponent} from "./components/shared/monitor-header/monitor-header.component";
import {
  MonitorDetailAttachmentComponent
} from "./components/shared/monitor-detail-component/monitor-detail-attachments-component/monitor-detail-attachment.component";
import {
  MonitorNotificationComponent
} from "./components/shared/monitor-notification-component/monitor-notification.component";
import {
  MonitorNotificationRowComponent
} from "./components/shared/monitor-notification-component/monitor-notification-row-component/monitor-notification-row.component";
import {ImportPreviewComponent} from "./components/shared/import-preview/import-preview.component";
import {CustomTimepickerComponent} from "./components/shared/custom-timepicker/custom-timepicker.component";


const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.stsAuthority,
      redirectUri: `${window.location.origin}/oem`,
      postLogoutRedirectUri: `${window.location.origin}/oem`,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false,
      },
      iframeHashTimeout: 9000,
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  console.log(environment.clientScope.toLowerCase().split(" "));
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.clientScope.toLowerCase().split(" "),
    },
    loginFailedRoute: "/",
  };
}

export function httpTranslateLoader(http: HttpClient, service: GeneralService) {
  return new TranslationLoader(http, service);
}

export class TranslationLoader implements TranslateLoader {
  constructor(private http: HttpClient, private httpService: GeneralService) {
  }

  getTranslation(lang: string): Observable<any> {

    return new Observable<any>((observer) => {
      this.httpService.getLanguage(lang).subscribe(res => {
        observer.next(res);
        observer.complete();
      })
    })

    //return ;
  }
}

@NgModule({
  declarations: [
    // custom components
    AppComponent,
    MonitorComponent,
    ImportedComponent,
    TopbarComponent,
    DataTableComponent,
    AppDataTableComponent,
    AutoFocusDirective,
    DialogComponent,
    FilterComponent,
    AlertComponent,
    AlertOldComponent,
    ActionBarComponent,
    AppActionBarComponent,
    DndComponent,
    DndOldComponent,
    DndDirective,
    DndOldDirective,
    HelpComponent,
    MenuListItemComponent,
    PreviewComponent,
    DestinationsComponent,
    RawDestinationsComponent,
    SubproductLinesComponent,
    UnitsComponent,
    LoadingComponent,
    PaginatorComponent,
    FinalDestinationComponent,
    StaticDataTableComponent,
    MonitorHistoryComponent,
    ResizableDirective,
    CellClassPipe,
    CellTooltipPipe,
    StatusTooltipPipe,
    TranslateYPipe,
    VirtualTableDirective,
    FixedRowSizeTableVirtualScrollStrategyDirective,
    TableVirtualScrollViewportComponent,
    CdkDetailRowDirective,
    DetailTableComponent,
    StringToDatePipe,
    StringToDateNoObsPipe,
    AuditDialogComponent,
    GetElementFromDatasourcePipe,
    UserSettingsComponent,
    NewDelegationComponent,
    HomepageComponent,
    StaticLoadingComponent,
    AdministrationComponent,
    AdministrationPanelComponent,
    AdminDataTableComponent,
    ContainerDetailComponent,
    FileTypeTransfomPipe,
    ContactGroupDialogComponent,
    ComunicationTrackerComponent,
    AutocompleteComponent,
    AttachfiletoComponent,
    ReplyMailComponent,
    MonitorSUComponent,
    PreviewSUComponent,
    ContainStatusPipe,
    ValidateRegexPipe,
    GetElementFromArrayPipe,
    TestComponentComponent,
    ObservableRequestComponent,
    PromiseRequestComponent,
    NotFoundComponent,
    DynamicPageComponent,
    HomeComponent,
    CustomBtnComponent,
    CustomTextFieldComponent,
    EmailPreviewComponent,
    VirtualScrollComponent,
    CheckboxFilterComponent,
    BlankComponent,
    CustomCheckboxComponent,
    CustomFilterComponent,
    CustomDatepickerComponent,
    CustomSelectComponent,
    FilterPanelComponent,
    FilterSettingsComponent,
    CustomTablePaginatorComponent,
    RefactorNamePipe,
    CustomAutocompleteComponent,
    GeneralComponent,
    HaveActionPipe,
    CustomSelectValuesComponent,
    DocumentsComponent,
    BackNavigationDirective,
    GetListTooltipPipe,
    GetValueOnConditionPipe,
    CompareEditingPipe,
    AppInfoComponent,
    GetOptionsPipe,
    LanguageComponent,
    Camel2SnakePipe,
    AddLanguageComponent,
    RemoveDoubleQuotePipe,
    RemoveDoubleQuoteAndSlachPipe,
    AddTranslationComponent,
    HaveOneActionPipe,
    GetValueOnConditionImpurePipe,
    StringArrayTranslationPipe,
    CdkHeightCalculatorPipe,
    BadRequestComponent,
    CustomIconBtnComponent,
    FunctionsComponent,
    GetDescriptionFromOptionValuesPipe,
    GetListTooltipV2ClassicPipe,
    DialogDownloadComponent,
    SaveConfirmComponent,
    SafeUrlPipe,
    SortArrayPipe,
    MultiSelectComponent,
    MonitorDetailComponent,
    DetailDataTableComponent,
    MonitorHeaderComponent,
    MonitorDetailAttachmentComponent,
    MonitorNotificationComponent,
    MonitorNotificationRowComponent,
    ImportPreviewComponent,
    CustomTimepickerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatInputModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatTooltipModule,
    HighlightModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MsalModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.cubeGrid,
      backdropBackgroundColour: "rgba(0,0,0,0.9)",
      fullScreenBackdrop: true,
      primaryColour: "#ffffff",
      secondaryColour: "#ffffff",
      tertiaryColour: "#ffffff",
    }),
    MatSelectModule,
    CustomMultiselectModule,
    DragDropModule,
    MatExpansionModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient, GeneralService],
      },
    }),
    ScrollingModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatTabsModule,
    MatProgressBarModule,
    MatDividerModule,
    MatStepperModule,
    MatRadioModule,
    MatMomentDateModule,
    MatGridListModule,
    // NbDatepickerModule.forRoot(),
    // NbDatepickerModule,
    // NbThemeModule.forRoot({name: 'default'}),
    // NbLayoutModule,
    // NbEvaIconsModule,
    //NbDateFnsDateModule,
    AngularEditorModule,
    NgxSpinnerModule,
    NgSelectModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLoggerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpSpinnerInterceptorService,
      multi: true,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AuthService,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: <HighlightOptions>{
        lineNumbers: true,
        coreLibraryLoader: () => import("highlight.js/lib/core"),
        lineNumbersLoader: () => import("highlightjs-line-numbers.js"),
        themePath: "node_modules/highlight.js/styles/github.css",
        languages: {
          typescript: () => import("highlight.js/lib/languages/typescript"),
          css: () => import("highlight.js/lib/languages/scss"),
          xml: () => import("highlight.js/lib/languages/xml"),
        },
      },
    },
    {provide: MAT_DIALOG_DATA, useValue: {}},
    {provide: MatDialogRef, useValue: {}},
    NavService,
    CheckForUpdateService,
    GetDescriptionFromOptionValuesPipe,
    Camel2SnakePipe,
    GetListTooltipV2ClassicPipe,
    TitleCasePipe
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
}
