import {Pipe, PipeTransform} from '@angular/core';
import {Observable, of} from "rxjs";

@Pipe({
  name: 'getValueOnConditionImpure',
  pure: false
})
export class GetValueOnConditionImpurePipe implements PipeTransform {

  transform(arrayToCheck: any[], propertyToCompare: string, propertyToShow: string, propertyValue: any, defaultValue: string): string {
    try {
      return (arrayToCheck.filter(elem => elem[propertyToCompare] == propertyValue))
        [0][propertyToShow];
    } catch (e) {
      return defaultValue;
    }

  }

}
