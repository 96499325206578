import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-dnd',
  templateUrl: './dnd.component.html',
  styleUrls: ['./dnd.component.scss'],
})
export class DndComponent implements OnInit {
  public files: any = [];
  public dialog: { [key: string]: any };

  //'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  ANY_FILE = '*/*'
  IMAGE_FILE = 'image/* image/webp image/avif image/bmp image/gif image/vnd.microsoft.icon image/jpeg image/png image/svg+xml image/tiff'
  acceptFileType = this.ANY_FILE;

  constructor(
    public dialogRef: MatDialogRef<DndComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { [key: string]: any }
  ) {
    this.dialog = data;
    if (!!data['type']) {
      if (data['type'] == 'any') {
        this.acceptFileType = '*/*';
      } else if (data['type'] == 'image') {
        this.acceptFileType = 'image/* image/webp image/avif image/bmp image/gif image/vnd.microsoft.icon image/jpeg image/png image/svg+xml image/tiff';
      }
    }
    if (!!data['files']) {
      this.files = data['files']
    }
  }

  ngOnInit(): void {
  }

  /**
   * on file drop handler
   */
  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(evt: any) {
    const files = evt.target.files;
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    const accepted_file = this.acceptFileType.split(' ');
    for (const item of files) {
      if (this.acceptFileType !== '*/*' && accepted_file.includes(item.type)) {
        item.progress = 0;
        this.files.push(item);
      } else if (this.acceptFileType == '*/*') {
        item.progress = 0;
        this.files.push(item);
      }
    }
  }

  /**z
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: any, decimals: any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
