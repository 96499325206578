import { EventEmitter, Injectable, Output } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of } from "rxjs";
import { SelectionModel } from '@angular/cdk/collections';
import { PageEventType } from '../components/shared/custom-table-paginator/custom-table-paginator.component';
import { IMultiSelectOption } from '../components/shared/custom-multiselect/types';
import { cloneDeep } from 'lodash';

@Injectable()
export class TableControllerService {

  dataSet: any[] = [];
  deletedRows: any[] = [];

  row: any;
  rowNumber: number = -1;
  selectedRow: any[] = [];
  elemLoaded: Observable<number> = of(this.dataSet?.length);

  selection = new SelectionModel<any>(true, []);

  numElementsLoaded: number = 0;
  numElementsSize: number = 0;

  public _filterValues: BehaviorSubject<any> = new BehaviorSubject<any>({})

  public filterValues = this._filterValues.asObservable();

  @Output()
  onPaginationChange: EventEmitter<PageEventType> =
    new EventEmitter<PageEventType>();

  selectableValuesMap: Map<string, IMultiSelectOption[]> = new Map<
    string,
    IMultiSelectOption[]
  >();

  @Output()
  onSortChange: EventEmitter<{
    sorting: Map<string, { order: number; type: 'asc' | 'desc' }>;
    pageEvent: PageEventType;
  }> = new EventEmitter<{
    sorting: Map<string, { order: number; type: 'asc' | 'desc' }>;
    pageEvent: PageEventType;
  }>();

  sortedColumns: Map<string, { order: number; type: 'asc' | 'desc' }> =
    new Map();


  private errors: Map<number, Map<string, boolean>> = new Map<number, Map<string, boolean>>()

  public validateActions: Map<string, Function> = new Map<string, Function>()

  public columnWeight: {} = {};

  public columnSize: {} = {};


  constructor() {}

  onAddClick(newRowObj: any) {
    newRowObj.newRow = true;

    newRowObj = this.customizeAfterAddClick(newRowObj);

    if (!!this.dataSet && this.dataSet.length > 0) {
      this.dataSet = [_.cloneDeep(newRowObj), ...this.dataSet];
      this.row = this.dataSet[this.rowNumber + 1]
    } else {
      this.dataSet = [_.cloneDeep(newRowObj)];
    }

    return this.dataSet;
  }

  customizeAfterAddClick(newRowObj:any){
    return newRowObj;
  }

  editRow(index: number) {
    ////(this.dataSet![index]);
    this.dataSet[index].backup = _.cloneDeep(this.dataSet[index]);
    this.dataSet[index].editing = true;
  }

  handleInnerGridVisibility(index: number) {
    let isVisible = this.dataSet[index].isInnerTableVisible ?? false;
    this.dataSet[index].isInnerTableVisible = !isVisible;
  }

  exitRow(index: number) {
    if (!!this.dataSet[index].newRow) {
      this.dataSet.splice(index, 1);
      //delete this.dataSet[index].newRow;
      this.dataSet[index];
    } else {
      this.dataSet[index] = this.dataSet[index].backup;
    }
  }

  saveRow(index: number) {
    delete this.dataSet[index]['newRow'];
    this.dataSet[index].checked = true;

    return this.dataSet;
  }

  commitEdit(index: number) {
    delete this.dataSet[index]['editing'];
  }

  commitEditDetail(index: number) {
    this.rowNumber = index;
    if (!!this.dataSet) this.row = this.dataSet[index];
  }

  saveAll(rows: any) {
    ////('rows to save:', rows);
    ////('rows to delete:', this.deletedRows);
    this.deletedRows = [];
  }

  deleteRow(index: number, rollback: boolean = false) {
    this.rowNumber = index;
    if(rollback){
      delete this.dataSet[index].deleted;
      this.deletedRows = this.dataSet.filter(elem => elem.deleted);
    }

    if (this.dataSet[index].newRow == undefined && !rollback) {
      this.deletedRows.push(this.dataSet[index]);
      console.log(this.deletedRows)
      //this.dataSet.splice(index, 1);
      ////('existing row deleted.');
    } else {
      //this.dataSet!.splice(index, 1);
      ////('new row deleted.');
    }
    return this.dataSet;
  }

  // ITOEM-5 added restart table action
  reloadRow(index: number, rollback: boolean = false) {
    this.rowNumber = index;
    return this.dataSet;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = !!this.selection ? this.selection.selected.length : 0;
    const numRows = !!this.dataSet ? this.dataSet.length : 0;
    return !!this.dataSet ? numSelected === numRows : false;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSet);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  inError() {
    //console.log(this.errors)
    return [...this.errors.values()].some((value) => {
      return [...value.values()].some((value) => value)
    })
  }

  getErrors(i: number, columnName: any) {
    if(this.errors.has(i)){
      if(this.errors.get(i)!.has(columnName)){
        return this.errors.get(i)!.get(columnName);
      }else{
        this.errors.get(i)!.set(columnName, false);
        return this.errors.get(i)!.get(columnName);
      }
    }else{
      this.errors.set(i, (new Map<string, boolean>()).set(columnName, false));
      return this.errors.get(i)!.get(columnName);
    }
  }

  deleteErrors(i: number) {
    if(this.errors.has(i)){
      this.errors.delete(i);
    }
  }

  setErrors($event: boolean, i: number, columnName: any, isDisabled: boolean = false) {
    if(!isDisabled)
      this.errors.get(i)?.set(columnName, $event);
  }

  public invokeFunction(key: string, ...args: any[]): boolean {
    const func = this.validateActions.get(key);
    if (func) {
      return func(...args);
    }
    return true;
  }

  calculateColumnWidth() {

  }
}
