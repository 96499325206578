import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {TableControllerService} from "../../../services/table-controller.service";
import {FilterControllerService} from "../../../services/filter-controller.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {FILTER_TYPE} from "../filter/custom-filter/filter.interface";
import {GeneralService} from "../../../services/crud/general.service";
import {HttpParams} from "@angular/common/http";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: "monitor-header",
  templateUrl: "./monitor-header.component.html",
  styleUrls: ["./monitor-header.component.scss"],
  host: {'class': 'h-100'},
  providers: [TableControllerService, FilterControllerService],
})
export class MonitorHeaderComponent implements OnDestroy, OnInit
{
  private _interactionCycle: Subject<void> = new Subject<void>();

  apiParent: string = 'monitor';
  apiUrl: string = 'monitor-detail/monitor-header';
  monitorId: string;

  isLoadingDetail = false;

  fields: Field[] = [
    {
      id: 'label',
      label: 'LABEL_LABEL',
      type: 'NVAR',
      option: [],
      isNumber: false
    },{
      id: 'swb',
      label: 'LABEL_SWB_NO',
      type: 'NVAR',
      option: [],
      isNumber: false
    },{
      id: 'shippingLine',
      label: 'LABEL_SHIPPING_LINE',
      type: 'NVAR',
      option: [],
      isNumber: false
    },{
      id: 'vessel',
      label: 'LABEL_VESSEL',
      type: 'NVAR',
      option: [],
      isNumber: false
    },{
      id: 'portOfLoading',
      label: 'LABEL_PORT_OF_LOADING',
      type: '',
      option: [],
      isNumber: false
    },{
      id: 'portOfLoadingEtd',
      label: 'LABEL_PORT_LOADING_ETD',
      type: 'DATE',
      option: [],
      isNumber: false
    },{
      id: 'portOfDischarge',
      label: 'LABEL_PORT_OF_DISCHARGE',
      type: 'NVAR',
      option: [],
      isNumber: false
    },{
      id: 'portEta',
      label: 'LABEL_ETA_PORT',
      type: 'DATE',
      option: [],
      isNumber: false
    },{
      id: 'portEtaActual',
      label: 'LABEL_ACTUAL_ETA_PORT',
      type: 'DATE',
      option: [],
      isNumber: false
    },{
      id: 'size',
      label: 'LABEL_SIZE',
      type: 'NVAR',
      option: [],
      isNumber: false
    },{
      id: 'container',
      label: 'LABEL_CONTAINER',
      type: 'NVAR',
      option: [],
      isNumber: false
    },
    {
      id: 'destinationId',
      label: 'LABEL_RDC_NAME',
      type: 'LIST',
      option: [],
      isNumber: false
    },
    {
      id: 'possibleDeliveryDate',
      label: 'LABEL_POSSIBLE_DELIVERY_DATE',
      type: 'DATE',
      option: [],
      isNumber: false
    },{
      id: 'transportMode',
      label: 'LABEL_TRANSPORT_MODE',
      type: 'NVAR',
      option: [],
      isNumber: false
    },{
      id: 'actualSlotDate',
      label: 'LABEL_ACTUAL_SLOT_DATE',
      type: 'DATE',
      option: [],
      isNumber: false
    },{
      id: 'actualSlotTime',
      label: 'LABEL_ACTUAL_SLOT_TIME',
      type: 'TIME',
      option: [],
      isNumber: false
    },{
      id: 'portStorageReasonCode',
      label: 'LABEL_PORT_STORAGE_REASON_CODE',
      type: 'NVAR',
      option: [],
      isNumber: false
    },{
      id: 'proposedWarehouseDeliveryDate',
      label: 'LABEL_PROPOSE_WH_DELIVERY_DATE',
      type: 'DATE',
      option: [],
      isNumber: false
    },{
      id: 'remarks',
      label: 'LABEL_REMARKS',
      type: 'NVAR',
      option: [],
      isNumber: false
    },{
      id: 'extraCostEur',
      label: 'LABEL_EXTRA_COST_EUR',
      type: 'NVAR',
      option: [],
      isNumber: true
    },{
      id: 'extraCostReason',
      label: 'LABEL_EXTRA_COST_REASON_CODE',
      type: 'NVAR',
      option: [],
      isNumber: false
    },{
      id: 'containerContentType',
      label: 'LABEL_CONTAINER_CONTENT_TYPE',
      type: 'LIST',
      option: [],
      isNumber: false
    },{
      id: 'docs',
      label: 'LABEL_DOCS',
      type: 'LIST',
      option: [],
      isNumber: false
    },{
      id: 'aebReference',
      label: 'LABEL_AEB_REFERENCE',
      type: 'Container Content Type',
      option: [],
      isNumber: true
    },{
      id: 'terminal',
      label: 'LABEL_TERMINAL',
      type: 'LIST',
      option: [],
      isNumber: false
    },{
      id: 'secondaryCarrier',
      label: 'LABEL_SECONDARY_CARRIER',
      type: 'NVAR',
      option: [],
      isNumber: false
    },{
      id: 'inlandPortDate',
      label: 'LABEL_INLAND_PORT_DATE',
      type: 'DATE',
      option: [],
      isNumber: false
    },{
      id: 'declarant',
      label: 'LABEL_DECLARANT',
      type: 'LIST',
      option: [],
      isNumber: false
    },{
      id: 'atbNumber',
      label: 'LABEL_ATB_NUMBER',
      type: 'NVAR',
      option: [],
      isNumber: false
    },{
      id: 'discharged',
      label: 'LABEL_DISCHARGE',
      type: 'LIST',
      option: [],
      isNumber: false
    },
    // {
    //   id: '',
    //   label: 'VA',
    //   type: 'NVAR',
    //   option: [],
//      isNumber: false
    // },
    {
      id: 'remarkInvoice',
      label: 'LABEL_REMARK_INVOICE',
      type: 'NVAR',
      option: [],
      isNumber: false
    },{
      id: 'managedBy',
      label: 'LABEL_MANAGED_BY',
      type: 'NVAR',
      option: [],
      isNumber: false
    },
  ];

  header: any = {};

  constructor(
    // retrieve dialog input values
    @Inject(MAT_DIALOG_DATA) public data: any,
    private generalDataService: GeneralService
  )
  {
    this.monitorId= this.data['monitorId'];
  }

  ngOnDestroy(): void
  {

  }

  ngOnInit(): void
  {
    // retrieve detail information
    this.getMonitorHeader();

    // retrieve option value
    let params = new HttpParams();
    const optionUrl = '/monitor-detail/options'
    for (let field of this.fields) {
      if (field.type == "LIST") {
        params = new HttpParams();
        params = params.append("optionId", field.id)
        this.generalDataService.getData(this.apiParent + optionUrl, "excel", params).pipe(
          takeUntil(this._interactionCycle)
        ).subscribe({
          next: (res: any) => {
            field.option = res.oContent;
          },
          error: (res) => {

          }
        });
      }
    }
  }

  getMonitorHeader() {
    this.isLoadingDetail = true;
    let params = new HttpParams();
    params = params.append("monitorId", this.monitorId)
    this.generalDataService.getData(this.apiParent + "/" + this.apiUrl, null, params).pipe(
      takeUntil(this._interactionCycle)
    ).subscribe({
      next: (res: any) => {
        this.header = res.oContent;
        this.isLoadingDetail = false;
      },
      error: (res) => {
        this.isLoadingDetail = false;
      }
    });
  }

  updateRecord(): void {
    this.isLoadingDetail = true;
    let params = new HttpParams();
    params = params.append("monitorHeader", this.header)
    this.generalDataService.postData(this.apiParent + "/" + this.apiUrl, [this.header], params).pipe(
      takeUntil(this._interactionCycle)
    ).subscribe({
      next: (res: any) => {
        this.header = res.oContent;
        this.isLoadingDetail = false;
      },
      error: (res) => {
        this.isLoadingDetail = false;
      }
    });
  }

  protected readonly filterType = FILTER_TYPE;
}

export interface Field {
  id: string; // Identificativo univoco del campo
  label: string; // Etichetta da visualizzare
  type: string; // Tipo del campo
  option: any[]; // Array di opzioni (puoi sostituire `any` con un tipo specifico se necessario)
  isNumber: boolean // mi dice se il campo è numerico
}
