import {Component, OnInit} from '@angular/core';
import {AlertService} from '../../shared/alert/alert.service';
import {ALERT_TYPE} from '../../shared/alert/alert.enumerate';
import {LayoutServiceService} from '../../../services/layout.service';
import {MatDialog} from '@angular/material/dialog';
import {DndComponent} from "../../shared/dnd/dnd.component";
import {asyncScheduler, from, lastValueFrom, Observable, throttleTime} from "rxjs";
import {GeneralService} from "../../../services/crud/general.service";
import {loadAsync} from "jszip";
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  apiParent: string = 'primary';

  apiUrl: string = 'damages/detail';

  pictureMaxSize: number = 500;

  usermail: Observable<string>;

  validUser: string[] = ['ramadio@thisisjustademo.it', 'marco.zanatta@electrolux.com', 'roberto.amadio@electrolux.com']

  isDeletingProblem: boolean = false;

  problemList: string = '';

  loadedDoc: number = 0;

  elemLoad: number = 0;

  maxElemloaded: number = 0;

  constructor(
    public alertService: AlertService,
    public layoutService: LayoutServiceService,
    public dialog: MatDialog,
    private generalDataService: GeneralService,
    private authService: AuthService
  ) {
    this.usermail = this.authService.emailObs
  }

  ngOnInit(): void {
    localStorage.setItem('changes', 'false')

  }


  addAlert() {
    this.alertService.add({
      type: ALERT_TYPE.PRIMARY,
      message: 'Messaggio di errore',
      timeout: 2000,
      selfClose: null,
    });
  }

  onChange(event: any): void {
    this.alertService.add({
      type: ALERT_TYPE.PRIMARY,
      message: 'You have selected: ' + event,
      timeout: 1000,
      selfClose: null,
    });
  }

  getMimeTypeFromBlob(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const arrayBuffer = reader.result as ArrayBuffer;
        const bytes = new Uint8Array(arrayBuffer);
        const mimeType = this.getMimeTypeFromByteArray(bytes);
        resolve(mimeType);
      };

      reader.onerror = () => {
        reject('Error reading Blob content');
      };

      reader.readAsArrayBuffer(blob);
    });
  }

  getMimeTypeFromByteArray(byteArray: Uint8Array): string {
    const header = byteArray.slice(0, 4);
    let mimeType = '';

    switch (header.join()) {
      case '255,216,255,224':
      case '255,216,255,225':
        mimeType = 'image/jpeg';
        break;
      case '137,80,78,71':
        mimeType = 'image/png';
        break;
      case '71,73,70,56,57,97':
      case '71,73,70,56,55,97':
        mimeType = 'image/gif';
        break;
      case '66,77':
        mimeType = 'image/bmp';
        break;
      case '73,73,42,0':
      case '77,77,0,42':
        mimeType = 'image/tiff';
        break;
      case '255,216,255,219':
        mimeType = 'image/rgb';
        break;
      case '255,216,255,196':
        mimeType = 'image/jpeg';
        break;
      default:
        mimeType = 'application/octet-stream';
    }

    return mimeType;
  }


  uploadDoc() {
    const MAX_RETRY_ATTEMPTS = 2;
    const RETRY_DELAY_MS = 100;
    const dialogEvent = this.dialog.open(DndComponent, {
      data: {
        title: 'UPLOADING',
        code: 'Upload file: ',
        message: 'Are you sure you want to upload this file?',
        btn: 'Upload',
        type: 'any',
      },
    });
    dialogEvent.afterClosed().subscribe(async (dndResponse) => {
      if (!!dndResponse && dndResponse.send) {
        //this.fileToSend = dndResponse.files;
        this.generalDataService.getDetail('primary/problems/detail/pictures/info').subscribe({
          next: (res) => {
            this.pictureMaxSize = +res['oContent']
            this.alertService.add({
              type: ALERT_TYPE.INFO,
              message:
                'To comply with the requirements all uploaded images will be compressed to a size of ' + this.pictureMaxSize + ' KB',
              timeout: 5000,
              selfClose: null,
            });
          },
          error: (err) => {
            this.alertService.add({
              type: ALERT_TYPE.INFO,
              message:
                'To comply with the requirements all uploaded images will be compressed to a size of ' + this.pictureMaxSize + ' KB',
              timeout: 5000,
              selfClose: null,
            });
          }
        })

        const zip = await loadAsync(dndResponse.files[0])
        const filesInZip = zip.files;
        let saveFile = true;
        this.loadedDoc = 0;
        this.maxElemloaded = Object.entries(filesInZip).length;
        const files = Object.entries(filesInZip);
        const batchSize = 10; // Set the desired batch size
        const fileEntries = files;
        const numBatches = Math.ceil(fileEntries.length / batchSize);
        let processedBatches = 0;

        const retryRequest = async (url, damageId, zippedFile, fileType, retryCount = 0) => {
          try {
            await lastValueFrom(
              this.generalDataService.uploadDocZipped(url, damageId, zippedFile, fileType)
            );
          } catch (error) {
            if (retryCount < MAX_RETRY_ATTEMPTS) {
              await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY_MS));
              await retryRequest(url, damageId, zippedFile, fileType, retryCount + 1);
            } else {
              // Handle permanent failure
              console.error("Failed to upload file after multiple retries:", error);
            }
          }
        };

        for (let batchIndex = 0; batchIndex < numBatches; batchIndex++) {
          const batch = fileEntries.slice(
            batchIndex * batchSize,
            (batchIndex + 1) * batchSize
          );

          const promises = batch.map(async (entry) => {
            const [key, value] = entry;
            const fileInZip = value;
            const fileDataBlob = await fileInZip.async("blob");
            const fileType = await this.getMimeTypeFromBlob(fileDataBlob);
            const zippedFile = new File([fileDataBlob], fileInZip.name);
            const damageId = zippedFile.name.split("_")[0];
            await retryRequest(
              this.apiParent + "/" + this.apiUrl + "/docs",
              damageId,
              zippedFile,
              fileType
            );
          });

          await Promise.all(promises);
          processedBatches++;
          this.loadedDoc = Math.ceil((processedBatches / numBatches) * 100);
        }
        /*const zip = await loadAsync(dndResponse.files[0])
        const filesInZip = zip.files;

        let saveFile = true;

        this.loadedDoc = 0;
        this.maxElemloaded = Object.entries(filesInZip).length;
        const files = Object.entries(filesInZip);
        const batchSize = 10; // Set the desired batch size
        const fileEntries = files;
        const numBatches = Math.ceil(fileEntries.length / batchSize);
        let processedBatches = 0;

        for (let batchIndex = 0; batchIndex < numBatches; batchIndex++) {
          const batch = fileEntries.slice(
            batchIndex * batchSize,
            (batchIndex + 1) * batchSize
          );

          const promises = batch.map(async (entry) => {
            const [key, value] = entry;
            const fileInZip = value;
            const fileDataBlob = await fileInZip.async("blob");
            const fileType = await this.getMimeTypeFromBlob(fileDataBlob);
            const zippedFile = new File([fileDataBlob], fileInZip.name);
            console.log(fileType);
            const damageId = zippedFile.name.split("_")[0];
            await lastValueFrom(this.generalDataService.uploadDocZipped(
              this.apiParent + "/" + this.apiUrl + "/docs",
              damageId,
              zippedFile,
              fileType
            ));
          });

          await Promise.all(promises);
          processedBatches++;
          this.loadedDoc = Math.ceil((processedBatches / numBatches) * 100);
        }*/
        /*
        from(files)
          .pipe(throttleTime(200, asyncScheduler, { leading: true, trailing: true }))
          .subscribe(async ([key, value]) => {
            const fileInZip = value;
            const fileDataBlob = await fileInZip.async("blob");
            const fileType = await this.getMimeTypeFromBlob(fileDataBlob);
            const zippedFile = new File([fileDataBlob], fileInZip.name);
            const damageId = zippedFile.name.split("_")[0];
            await lastValueFrom(this.generalDataService.uploadDocZipped(
              this.apiParent + "/" + this.apiUrl + "/docs",
              damageId,
              zippedFile,
              fileType
            ));
            this.loadedDoc = Math.ceil(((++this.elemLoad) / this.maxElemloaded) * 100);
          });
        */

        /*Object.entries(filesInZip).forEach(entry => {
          const [key, value] = entry;
          const fileInZip = value;
          fileInZip.async("blob").then(async (fileDataBlob) => {
            const fileType = await this.getMimeTypeFromBlob(fileDataBlob);
            let zippedFile = new File([fileDataBlob], fileInZip.name);
            console.log(fileType);

            let damageId = zippedFile.name.split("_")[0];
            lastValueFrom(
              this.generalDataService.uploadDocZipped(
                this.apiParent + '/' + this.apiUrl + '/docs',
                damageId,
                zippedFile,
                fileType
              )
            )
            }).finally(() => {
              this.loadedDoc = Math.ceil(((++this.elemLoad) / this.maxElemloaded) * 100);
            })
          });
        })*/
      }
    });
  }

  checkValidUser = (user) => {
    return this.validUser.some(u => user.includes(u));
  }

  deleteProblem() {
    if (this.isDeletingProblem) {
      this.generalDataService.delete(
        'util/problem',
        this.problemList.split(',')
      ).subscribe(res => console.log(res))
    } else {
      this.problemList = '';
      this.isDeletingProblem = true;
    }
  }
}
