<div>
  <div *ngIf="isLoading && (tableController == null || tableController.dataSet.length < 1)" class="loading-panel">
    {{ 'LABEL_LOADING' | translate }}
  </div>

  <div *ngIf="!isLoading">
    <mat-panel-title *ngIf="titlePage!=null" class="title">
      {{ titlePage | translate }}
    </mat-panel-title>
    <ng-container *ngIf="layout != null">
      <ng-container *ngFor="let sectionLayout of layout['sectionLayouts']; let i = index">

  <!--      DISABLE THIS COMMENT IF YOU WANT TO MANGE FILTER      -->
  <!--      <div class="shadow p-3 mb-3 mt-1 bg-white rounded"-->
  <!--           *ngIf="sectionLayout['id'] == 'LYO_SECFILT' && !!(sectionLayout['dataset'])[0] && !!(sectionLayout['dataset'])[0].filterset && !!filterController.filterModels">-->
  <!--        <filter-panel (onFilterClick)="onFilterClick($event)" [filterController]="filterController"-->
  <!--                      [pageReference]="pageId" (onResetFilterClick)="resetFilter($event)"></filter-panel>-->
  <!--      </div>-->
        <data-table
          [ngStyle]="{ 'margin-left': isInner ? '10px' : '0' }"
          *ngIf="sectionLayout['id'] == 'LYO_SECTABLE'"
          [datasets]="(sectionLayout['dataset'])[0]" [actionsets]="(sectionLayout['actionset'])[0]"
          (onActionEmit)="actionCatcher($event)" [tableController]="tableController" (onResetSort)="resetSort()"
          [numElementsLoaded]="tableController.numElementsLoaded" [length]="tableController.numElementsSize"
          [disabledColumns]="disabledColumns"
          class="flex-grow-1 d-flex flex-column">
        </data-table>
      </ng-container>
    </ng-container>
  </div>
</div>
