import {Injectable} from "@angular/core";
import {FILTER_TYPE, FilterInterface} from "../components/shared/filter/custom-filter/filter.interface";
import {TranslateService} from "@ngx-translate/core";
import {BehaviorSubject, lastValueFrom, take} from "rxjs";
import {Camel2SnakePipe} from "../pipes/camel2snake.pipe";
import {GetListTooltipV2ClassicPipe} from "../pipes/get-list-tooltip-v2-classic.pipe";

@Injectable()
export class FilterControllerService {
  filterModels: FilterInterface[] = [];

  viewedFilter: String[] = [];

  public _filterValues: BehaviorSubject<any> = new BehaviorSubject<any>({})

  public filterValues = this._filterValues.asObservable();

  constructor(private translate: TranslateService, private listTranslate: GetListTooltipV2ClassicPipe, private c2s: Camel2SnakePipe) {
  }

  //USE THE FOLLOWING FUNCTION TO TRANSLATE THE ID OF MULTISLECTION FILTER
  /*refreshViewedFilter() {
    this.viewedFilter =
      (this.filterModels
        .filter((model) => !model.isHide)
        .filter((filter) => {
          return (!!filter.firstValue && filter.firstValue.length > 0) || !filter.filterSelectedOption.haveFirstParam;
        })
        .map(
          (filter) => filter.filterSelectedOption.haveFirstParam ?
            "" +
            this.translate.instant(filter.columnLabel) + ' \"' + filter.filterSelectedOption.name + '\" ' +
            // filter.columnLabel+ ' \"' +filter.filterSelectedOption.name + '\" '+
            ": [" +
            (filter.filterType == FILTER_TYPE.MULTI_SELECTION ? filter.firstValue.map(value => this.listTranslate.transform(this._filterValues.value, this.c2s.transform(filter.columnName), value, value) ).join(",") : filter.firstValue) +
            "]" +
            (filter.filterSelectedOption.haveSecondParam
              ? " and [" +
              (filter.filterType == FILTER_TYPE.MULTI_SELECTION ? filter.secondValue.map(value => this.listTranslate.transform(this._filterValues.value, this.c2s.transform(filter.columnName), value, value) ).join(",") : filter.secondValue) +
              "]"
              : "") : "" +
            this.translate.instant(filter.columnLabel) + ' \"' + filter.filterSelectedOption.name + '\" '
        )) ?? [];
  }*/

  //USE THE FOLLOGING FUNCTION TO SHOW ONLY THE ID
  refreshViewedFilter() {
    this.viewedFilter =
      (this.filterModels
        .filter((model) => !model.isHide)
        .filter((filter) => {
          return (!!filter.firstValue && filter.firstValue.length > 0) || !filter.filterSelectedOption.haveFirstParam;
        })
        .map(
          (filter) => filter.filterSelectedOption.haveFirstParam ?
            "" +
            this.translate.instant(filter.columnLabel) + ' \"' + filter.filterSelectedOption.name + '\" ' +
            // filter.columnLabel+ ' \"' +filter.filterSelectedOption.name + '\" '+
            ": [" +
            (filter.filterType == FILTER_TYPE.MULTI_SELECTION ? filter.firstValue.join(",") : filter.firstValue) +
            "]" +
            (filter.filterSelectedOption.haveSecondParam
              ? " and [" +
              (filter.filterType == FILTER_TYPE.MULTI_SELECTION ? filter.secondValue.join(",") : filter.secondValue) +
              "]"
              : "") : "" +
            this.translate.instant(filter.columnLabel) + ' \"' + filter.filterSelectedOption.name + '\" '
        )) ?? [];
  }
}
