import {Injectable, Optional, SkipSelf} from "@angular/core";
import {cloneDeep} from "lodash";

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  enable: boolean = false;

  constructor(@Optional() @SkipSelf() sharedService?: MessagingService) {
    if (sharedService) {
      throw new Error('MessagingSerivce is already loaded')
    }
  }

  add(instant: number, httpMethod: string, httpStauts: string, httpUrlWithParma: string, message: string, obj: any) {
    if (!this.enable) {
      return;
    }
    const log = {
      httpMethod: httpMethod,
      httpStauts: httpStauts,
      httpUrlWithParma: httpUrlWithParma,
      message: message,
      http: obj
    }
    const httpLog = sessionStorage.getItem('httpLog');
    if (!!httpLog) {
      let logs = JSON.parse(httpLog);
      logs['' + instant] = cloneDeep(log);
      sessionStorage.setItem('httpLog', JSON.stringify(logs));
    } else {
      let logs = {};
      logs['' + instant] = cloneDeep(log);
      sessionStorage.setItem('httpLog', JSON.stringify(logs));
    }
  }
}
