import { trigger, state, style, transition, animate } from '@angular/animations';
import { ArrayDataSource, SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { Observable, BehaviorSubject, Subscription, of, map, lastValueFrom } from 'rxjs';
import { PageEventType } from '../paginator/paginator.component';
//import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import {ManualActionList, MonitorService} from 'src/app/services/monitor.service';
import { AuditDialogComponent } from '../audit-dialog/audit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ContactGroupDialogComponent } from '../contact-group-dialog/contact-group-dialog.component';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-data-table',
  templateUrl: './app-data-table.component.html',
  styleUrls: ['./app-data-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AppDataTableComponent implements OnInit, OnDestroy {

  //@ViewChild(CdkVirtualScrollViewport, { static: true }) viewPort: CdkVirtualScrollViewport;

  ITEM_SIZE = 48;
  offset: number = 0;
  offsetObs: Observable<number>;
  offsetChange = new BehaviorSubject(0);

  @Input()
  length: number = 0

  @Input() isFilter: boolean = true;

  @Input()
  dataSource: Observable<any>;


  trueDataSource: ArrayDataSource<any>;

  dataTable: MatTableDataSource<{ [key: string]: any }> = new MatTableDataSource<{ [key: string]: any }>([]);

  @Input()
  headers: Observable<Header[]>;
  @Input()
  loadingTable: boolean = false;
  @Input()
  showMerge: boolean = false;
  @Input()
  screen: string;
  @Input()
  isChanged: boolean;

  @ViewChild(MatSort) sort: MatSort;

  @Output()
  onCheckboxClick: EventEmitter<{ info: boolean, warning: boolean, error: boolean }> = new EventEmitter<{ info: boolean, warning: boolean, error: boolean }>();
  @Output()
  onEditStart: EventEmitter<{ column: Header, element: any }> = new EventEmitter<{ column: Header, element: any }>();
  @Output()
  onEditCell: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditFinalDestinationCell: EventEmitter<{ col: any, element: any }> = new EventEmitter<{ col: any, element: any }>();
  @Output()
  onMergeClick: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output()
  onSortChange: EventEmitter<{ sorting: Map<string, { order: number, type: 'asc' | 'desc' }>; pageEvent: PageEventType }> = new EventEmitter<{ sorting: Map<string, { order: number, type: 'asc' | 'desc' }>; pageEvent: PageEventType }>();
  @Output()
  onPaginationChange: EventEmitter<PageEventType> = new EventEmitter<PageEventType>();
  @Output()
  onActionClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onExpandEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onOpenRecordsClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onCloseRecordsClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onShowAuditClick: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onNotifyRecordsClick: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onManualActionClick: EventEmitter<{action: string, list: any[]}> = new EventEmitter<{action: string, list: any[]}>();

  @Output()
  onExportClick: EventEmitter<SelectionModel<any>> = new EventEmitter<SelectionModel<any>>();
  @Output()
  onImportClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onDetailChangeEmit: EventEmitter<any> = new EventEmitter<any>();

  displayedColumns: string[] = [];
  errors: CellError[] = [];
  @Input()
  pageEvent: PageEventType = { pageIndex: 0, previousPageIndex: 0, loader: 50, size: 50 };

  oldElement: any;

  @Input()
  numElementsLoaded: number = 0;

  @Input()
  numElementsSize: number = 0;

  selectableColumns: string[] = ['destRailLocationId','portOfDischargeId','portOfLoadingId','destinationId', 'finalDestination', 'terminal', 'declarant', 'containerContentType', 'rdcName']

  @Input() public set dataSourceObservable(d: Observable<any[]>) {
    if (d !== null) {
      this.dataSource = d;
      d.subscribe(table => {
        this.dataTable.data = table;
        this.trueDataSource = new ArrayDataSource(table);
        //this.dataSourceTable.matTableDataSource.data = table;
        //this.viewPort.checkViewportSize();
      })
      /*d.subscribe((res: any[]) => {
        this.dataSource = res;
      });*/
    }
  }

  expandedElement: any | null;

  displayedHeaders: Header[] = [];

  @Input()
  sortedColumns: Map<string, { order: number, type: 'asc' | 'desc' }> = new Map();

  selection = new SelectionModel<any>(true, []);

  sortingList: Array<{ column: String; isAsc: boolean }> = [];

  isEnded: boolean = false;

  @Input()
  checkboxes: { info: boolean, warning: boolean, error: boolean };

  dateReg = /[a-zA-Z]/g;

  permittedActions: String[] = [];

  subscriptionArray: Subscription[] = [];

  isTabKeyPressed: boolean = false;

  actionsBtn: any[] = [];

  @Input()
  editingRowColumn: { row: any, column: string };


  @ViewChild('rdcSelect') rdcSelect: ElementRef<HTMLElement>;

  selectedItems: { [key: string]: boolean } = {};

  @Input()
  destinationsOptions:any[] = [];

  @Input()
  rdcDestinationsOptions:any[] = [];

  @Input()
  containerContentTypeOptions:any[] = [];

  @Input()
  declarantOptions:any[] = [];

  @Input()
  terminalOptions:any[] = [];


  @Input()
  extraActions?: ManualActionList;


  constructor(/*private datePipe: DatePipe,*/ private monitorService: MonitorService,
    public dialog: MatDialog,
    public authService: AuthService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private zone: NgZone,) {
    this.pageEvent = { pageIndex: 0, previousPageIndex: 0, loader: 50, size: 50 };
    //this.dataSourceTable = new DataTableSource();
  }


  ngAfterViewChecked() { this.changeDetector.detectChanges(); }

  ngOnDestroy(): void {
    this.subscriptionArray.forEach(sub => sub.unsubscribe());
  }

  ngOnInit(): void {

    this.headers.subscribe(headers => {
      this.displayedColumns = headers.map((header: Header) => header.field);
      this.displayedHeaders = headers;
    });

    this.subscriptionArray.push(this.authService.getAction().subscribe(actions => {
      this.permittedActions = actions['actions'];
      this.subscriptionArray.forEach(sub => sub.unsubscribe());
    }));

    this.monitorService.getActionsMenu().subscribe(res => {
      this.actionsBtn = res.map(el => {
        return {
          id: el['notificationId'],
          label: el['notificationLabel'],
          description: el['notificationDescription'],
          action: el['notificationTypology']
        }
      });
    });



    /*this.dataSourceTable.attach(this.viewPort);
    this.viewPort.scrolledIndexChange
      .pipe(
        map(() => this.viewPort.getOffsetToRenderedContentStart() * -1),
        distinctUntilChanged(),
      )
      .subscribe(offset => {
        this.offset = offset
      });

    this.viewPort.renderedRangeStream.subscribe(range => {
      this.offset = range.start * (-this.ITEM_SIZE);
    });*/

    /*this.offsetObs = this.viewPort.renderedRangeStream.pipe(
      map(() => -(this.viewPort.getOffsetToRenderedContentStart()+56))
    );*/

    /*this.viewPort.elementScrolled().subscribe((ev:any) => {
      const start = Math.floor(ev.currentTarget.scrollTop / this.ITEM_SIZE);
      this.offset = this.ITEM_SIZE * (start);
      this.viewPort.setRenderedContentOffset(this.offset);
      this.offsetChange.next(this.offset);
    })*/
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.selection.clear();

    if (changes['dataSource']) {

      this.dataSource = changes['dataSource'].currentValue
      changes['dataSource'].currentValue.subscribe(table => {
        !!table ? this.dataTable.data = table : this.dataTable.data = [];
        !!table ? this.trueDataSource = new ArrayDataSource(table) : this.trueDataSource = new ArrayDataSource([]);
        //!!table ? this.dataSourceTable.matTableDataSource.data = table : this.dataSourceTable.matTableDataSource.data = []
      })
    }

    if (changes['editingRowColumn'] && !!this.editingRowColumn && !!this.editingRowColumn.column && !!this.editingRowColumn.row) {
      console.log('edit')
      let h: Header = this.displayedHeaders.find(h => h.field == this.editingRowColumn.column)
      let currentCellIndex = h.id
      this.oldElement = _.cloneDeep(this.editingRowColumn.row);
      for (let i = currentCellIndex; i < this.displayedHeaders.length; i++) {
        if (this.displayedHeaders[i].editable) {
          this.editingRowColumn.row.editing = this.displayedHeaders[i].field;
          if (this.displayedHeaders[i].editType == 'selectMP' || this.displayedHeaders[i].editType == 'select') {
            this.onEditStart.emit({ column: this.displayedHeaders[i], element: this.editingRowColumn.row });
          }
          break;
        }
      }

      /*document.getElementById('div-'+this.editingRowColumn.row.id.value+'-'+h.id).focus()// <<<---using ()=> syntax
      document.getElementById('div-'+this.editingRowColumn.row.id.value+'-'+h.id).click()
      document.getElementById('field-'+this.editingRowColumn.row.id.value+'-'+h.id).focus()
      document.getElementById('field-'+this.editingRowColumn.row.id.value+'-'+h.id).click()
      document.getElementById(''+this.editingRowColumn.row.id.value+'-'+h.id).focus()
      document.getElementById(''+this.editingRowColumn.row.id.value+'-'+h.id).click()*/


      setTimeout(() => {
        document.getElementById('div-' + this.editingRowColumn.row.id.value + '-' + h.id).focus()// <<<---using ()=> syntax
        document.getElementById('div-' + this.editingRowColumn.row.id.value + '-' + h.id).click()
        document.getElementById('field-' + this.editingRowColumn.row.id.value + '-' + h.id).focus()
        document.getElementById('field-' + this.editingRowColumn.row.id.value + '-' + h.id).click()
        document.getElementById('' + this.editingRowColumn.row.id.value + '-' + h.id).focus()
        document.getElementById('' + this.editingRowColumn.row.id.value + '-' + h.id).click()
      }, 0);

      //this.onClickCell(this.editingRowColumn.row, h)

    }
  }

  onPageChange(pageEvent: any) {
    //this.pageEvent = pageEvent;
    this.onPaginationChange.emit(pageEvent)
  }

  onAction(element: any, action: string) {
    this.onActionClick.emit({ element, action })
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      //sort direction
    }
  }

  onClickCell(element: any, col: Header) {

    if (!this.isAdding() && !element.newRow) {
      if (col.editable && element.editing !== col.field) {
        //Remove editing from any cell
        this.dataSource.subscribe(table => table.filter((el) => !!el.editing).forEach((el) => el.editing = null));
        //Set this cell in editing
        element.editing = col.field;

        this.oldElement = _.cloneDeep(element);

        this.onEditStart.emit({ column: col, element: element })
        console.log(col, element)
      }
    }
  }

  onCellFocus($event: any) {
    if (!!$event && !!$event.target)
      $event.target.select()
  }

  checkContainer(container: string) {
    const regex = new RegExp('^[A-Z]{4}\\d{7}$');
    if (regex.test(container))
      return true;
    else
      return false;
  }

  onKeyUpCell(e: any, element: any, col: Header, id: string) {
    //On enter stops editing the cell
    /*if (e.key === 'Enter' || e.keyCode === 13) {
      if (col.field === 'container') {
        if (!this.checkContainer(element[col.field].value)) return;
      }
      element.editing = null;
      this.onCellExit(element, col);
    } else*/
    if (e.key == 'Tab') {
      this.isTabKeyPressed = true;
      let currentCellIndex;
      this.headers.subscribe(headers => {
        //currentCellIndex = headers.findIndex(column => column.field === col.field);
        currentCellIndex = col.id
        if (col.id == 0) {
          this.oldElement = _.cloneDeep(element);
        }
        for (let i = currentCellIndex; i < headers.length; i++) {
          if (i !== headers.length - 1 && headers[i + 1].editable) {
            element.editing = headers[i + 1].field;
            if (headers[i + 1].editType == 'selectMP' || headers[i + 1].editType == 'select') {
              this.onEditStart.emit({ column: headers[i + 1], element: element });
            }
            break;
          }
        }
      })
    }
  }

  checkDate(dateToCheck: any) {
    return (moment(dateToCheck, 'DD/MM/YYYY').isValid() && dateToCheck.length === 10 && !this.dateReg.test(dateToCheck));
  }

  checkDateById(id: string) {
    let dateToCheck
    if (!!(<HTMLInputElement>document.getElementById(id)))
      dateToCheck = (<HTMLInputElement>document.getElementById(id)).value;
    //console.log(dateToCheck)
    return moment(dateToCheck, 'DD/MM/YYYY').isValid() && dateToCheck.length === 10;
  }

  dateModelChange(id: string, e: any) {
    this.checkDate((<HTMLInputElement>document.getElementById(id)).value);
    if (moment(e).isValid()) {
      return e;
    }
    else return (<HTMLInputElement>document.getElementById(id)).value;
  }

  validateCell(e: any, element: any, col: Header): boolean {
    let error: any = null
    if (col.validation) {
      if (col.validation.required && !e)
        error = "Field required"
      else if (col.validation.maxChar !== undefined && e.length > col.validation.maxChar)
        error = "Max characters " + col.validation.maxChar
      else if (col.validation.minChar !== undefined && e.length < col.validation.minChar)
        error = "Min characters " + col.validation.minChar
      else
        error = null
    } else
      error = null

    let cellError = this.errors.find((err) => err.elementId === element.id && err.headerId === col.id)
    if (!!cellError)
      cellError.message = error
    else if (error !== null)
      this.errors.push({ elementId: element.id, headerId: col.id, message: error })

    return !error;
  }

  onCellExit(element: any, col: Header) {
    if (col.field === 'container') {
      if (!this.checkContainer(element[col.field].value)) {
        element[col.field].value = this.oldElement[col.field].value;
        return;
      }
    }
    if (!element.newRow) {
      this.selection.clear();
      this.onEditCell.emit({ record: element, oldRecord: this.oldElement, cel: col.field, editType: col.editType, isTabKeyPressed: this.isTabKeyPressed });
      //element.editing = undefined;
      this.isTabKeyPressed = false;
    }
  }

  onSelectionCellExit(event: any) {
    let element: any = event.element
    let col: Header = event.col
    if (col.field === 'container') {
      if (!this.checkContainer(element[col.field].value)) {
        element[col.field].value = this.oldElement[col.field].value;
        return;
      }
    }
    if (!(!!element.newRow)) {
      this.selection.clear();
      this.onEditCell.emit({ record: element, oldRecord: this.oldElement, cel: col.field, editType: col.editType, isTabKeyPressed: this.isTabKeyPressed });
      //element.editing = undefined;
      this.isTabKeyPressed = false;
    }
  }

  onDateCellExit(evt: any, element: any, col: any) {
    element[col.field].value = evt.target.value;
    if (!!element && typeof element[col.field].value === 'object') {
      element[col.field].value = moment(element[col.field].value, "DD/MM/YYYY").format('DD/MM/YYYY');
    }
    if (!this.checkDate(element[col.field].value) && element[col.field].value != '') {
      element[col.field].value = this.oldElement[col.field].value;
      return;
    }
    element.editing = null;
    this.onCellExit(element, col);
  }

  onDatepickerClose(evt: any, element: any, col: any,) {
    element[col.field].value = moment(evt, "DD/MM/YYYY").format('DD/MM/YYYY');
    if (!this.checkDate(element[col.field].value)) return;
    this.onCellExit(element, col);
  }

  hasError(elementId: number, colId: number) {
    return this.errors.find((err) => err.elementId === elementId && err.headerId === colId)
  }

  getErrorMessage(elementId: number, colId: number) {
    return this.errors.find((err) => err.elementId === elementId && err.headerId === colId)?.message
  }

  isAdding() {
    let isAdding = false;
    this.dataSource.subscribe(table => {
      table.some((item) => item.newRow) ?? false;
    })
    return isAdding;
  }

  checkCellError(errors: any[], field: string) {
    let classes: any = {};
    let errorType = undefined;

    errors?.some((error: any) => {
      if (error?.property === field) {
        classes.errorCell = true;
        return true;
      }
      return false;
    })
    return classes;
  }

  addBorder(element, field) {

    if (!!element.meta) {
      if (!!element.meta.remarks) {
        if ((!element.meta.remarks.every((remark) => (remark.column !== field) || remark.status !== 'ERROR') && !(field == 'destinationCid' || field == 'destinationCountry')))
          return 'error'
        else if (element.meta.remarks.every((remark) => remark.column === field && remark.status !== 'ERROR') && element.meta.remarks.every((remark) => remark.column === field && remark.status === 'INFO' && remark.action === 'AUTOCALC'))
          return 'info'
        else if (element.meta.remarks.every((remark) => remark.column === field && remark.status !== 'ERROR') && element.meta.remarks.every((remark) => remark.column === field && remark.status === 'WARNING' && remark.action === 'COMPARE'))
          return 'compare'
        else if (element.meta.remarks.every((remark) => remark.column === field && remark.status !== 'ERROR') && element.meta.remarks.some((remark) => remark.column === field && remark.status === 'WARNING'))
          return 'warning'
      }
    }
    return 'null';
  }

  addBorderRow(element, field) {
    if (!!element.meta) {
      if (!!element.meta.remarks) {
        if (element.meta.remarks.some((remark) => remark.column !== field && remark.action === 'COMPARE'))
          return 'compareRow'
        if (element.meta.remarks.some((remark) => remark.column !== field && remark.status === 'WARNING' && remark.action === 'FIND'))
          return 'newRow'
      }
    }
    return 'null';
  }

  logspaceFunction() {
    //return logspace(25, this.length, 2);
    return [50, 100, 250];
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    let numRows = -1;
    this.dataSource.subscribe(table => numRows = table.length);
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(evt: any) {
    if (evt.checked) {
      this.dataSource.subscribe(table => {
        this.selection.select(...table);
      })
      //this.selection.select(...this.dataSource);
      return;
    } else {
      this.selection.clear();
      return;
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row}`;
  }

  isCheckboxDisabled(row: any) {
    return false;
  }

  removeCheckBox(evt: any, rowId: any) {
    if (evt.checked) {
      this.selectedItems[rowId] = true;
    } else {
      delete this.selectedItems[rowId];
    }

    console.log(this.selectedItems)

  }

  sortColumn(name: string) {
    if (this.isChanged) {
      return;
    }
    let poolMap: Map<string, { order: number, type: 'asc' | 'desc' }> = new Map();
    this.sortedColumns.forEach((value, key, map) => {
      poolMap.set(key, value);
    });
    if (!!this.sortedColumns.get(name) && (this.sortedColumns.get(name).type == 'asc' || this.sortedColumns.get(name).type == 'desc')) {
      poolMap.delete(name);
      if (this.sortedColumns.get(name).type == 'asc') {
        const order = this.sortedColumns.get(name).order;
        this.sortedColumns = new Map();
        this.sortedColumns.set(name, { order: order, type: 'desc' });
        poolMap.forEach((value, key, map) => {
          this.sortedColumns.set(key, value);
        })
      } else {
        const order = this.sortedColumns.get(name).order;
        this.sortedColumns.delete(name);
        this.sortedColumns.forEach((value, key, map) => {
          this.sortedColumns.set(key, { order: value.order > order ? value.order - 1 : value.order, type: value.type });
        })
      }
    } else {
      if (!(!!this.sortedColumns.get(name))) {
        let maxIndex = 0;
        this.sortedColumns.forEach((value, key, map) => {
          maxIndex = maxIndex < value.order ? value.order : maxIndex;
        })
        this.sortedColumns.set(name, { order: maxIndex + 1, type: 'asc' });
      }
    }

    this.onSortChange.emit({ sorting: this.sortedColumns, pageEvent: this.pageEvent });
  }

  resetSorting(event) {
    event.stopPropagation();

    this.sortedColumns = new Map();
    this.onSortChange.emit({ sorting: this.sortedColumns, pageEvent: this.pageEvent });
  }

  mergeToMonitor() {
    let selected = new Array();
    this.selection.selected.forEach((el) => {
      selected.push(Object.assign({}, { value: el.id.value, status: el.statusRow.value }));
    });
    this.onMergeClick.emit(selected);
    this.selection = new SelectionModel<Element>(true, []);
    this.selection.clear();
  }

  emitCheck(evt: any) {
    this.onCheckboxClick.emit(this.checkboxes)
  }

  getCellToolTip(element: any, field: any) {

    if (field !== 'actions' && field !== 'destinationCountry' && field !== 'destinationCid' && field !== 'audit') {
      if (element[field].errorType === 'WARNING' && (field == 'portOfLoadingEtd' || field == 'portEta' || field == 'portEtaActual')) {
        return (+ " has been self-calculated");
      }
      if (element[field].errorType === 'ERROR' || ((element[field].errorType === 'WARNING' || element[field].errorType === 'INFO') && (field == 'container'))) {
        element[field].errorList = element[field].errorList.map(el => {
          return el.replace(field, name)
        })
        return (element[field].errorList);
      }
    }
    return "Click to edit";
  }

  typeof(x: any) { return typeof x; }

  checkInfo = (value) => !!value && (value.status == 'INFO');
  checkWarning = (value) => !!value && (value.status != 'ERROR');
  someWarning = (value) => !!value && (value.status == 'WARNING');
  checkError = (value) => !!value && (value.status == 'ERROR');
  someError = (value) => !!value && (value.status == 'ERROR');

  filterInfoCounter = (obj) => !(!!obj.meta) || !(!!obj.meta.remarks) || obj.meta.remarks.every(remark => remark.status == 'INFO')

  reduceInfoCounter = (counter, obj) => {
    if (!(!!obj.meta) || !(!!obj.meta.remarks) || obj.meta.remarks.some(remark => remark.status == 'INFO')) counter += 1
    return counter;
  }

  reduceWarningCounter = (counter, obj) => {
    if (!!(obj.meta) && !!(obj.meta.remarks) && obj.meta.remarks.some(remark => remark.status == 'WARNING')) counter += 1
    return counter;
  }

  reduceErrorCounter = (counter, obj) => {
    if (!!(obj.meta) && !!(obj.meta.remarks) && obj.meta.remarks.some(remark => remark.status == 'ERROR')) counter += 1
    return counter;
  }

  dateFormatter(stringDate: any) {
    if (!!stringDate && typeof stringDate === 'string' && stringDate.includes('-')) {
      stringDate = stringDate.substring(8, 10) + "/" + stringDate.substring(5, 7) + "/" + stringDate.substring(0, 4);
    }

    return stringDate;
  }

  isDuplicated(element: any) {
    if (!!element && !!element.meta && !!element.meta.remarks) {
      const remarks = element.meta.remarks;
      if (!!remarks.find(remark => remark.column === 'container')) {
        return true;
      }
    }
    return false;
  }

  dateInit(date: any, id: any) {
    if (!!date) {
      date = date.toString();
      id = id.toString();
      if (date.includes('/')) {
        date = date.substring(0, 2) + '/' + date.substring(3, 5) + '/' + date.substring(6, 10);
        if ((<HTMLInputElement>document.getElementById(id)).value != null) {
          (<HTMLInputElement>document.getElementById(id)).value = date;
        }
      }
    }
  }

  dateToString(date: any) {
    //return this.datePipe.transform(new Date(date), 'dd/MM/yyyy ');
  }

  onExpandClick(row: any) {
    this.expandedElement = this.expandedElement === row ? null : row;

    this.onExpandEmitter.emit(row.id.value);
  }

  openRecords() {
    const ids = this.selection.selected.map(el => el.id.value);

    this.onOpenRecordsClick.emit(ids);
    this.selection.clear();
  }

  closeRecords() {
    const ids = this.selection.selected.map(el => el.id.value);

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "Confirm closing",
        content: `This action will close all selected records, proceed?`,
        cancelButtonLabel: "Cancel",
        cancelButtonColor: "basic",
        confirmButtonLabel: "Close",
        confirmButtonColor: "warn"
      }
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (!!res) {
        this.onCloseRecordsClick.emit(ids);
      }
    });

    this.selection.clear();
  }

  notifyRecords(id?: string, action?: string, label?: string, description?: string) {
    if (!!id) {
      const ids = this.selection.selected.map(el => el.id.value);

      const dialog = this.dialog.open(ContactGroupDialogComponent, {
        width: '65vw',
        data: {
          title: label + ' - ' + description,
          ids: ids,
          typology: action,
          notificationId: id,
          closeButtonLabel: "Close",
          confirmButtonLabel: "Send"
        }
      });

      dialog.afterClosed().subscribe(res => {
        if (!!res && res)
          this.onNotifyRecordsClick.emit(res);
      });

      this.selection.clear();
    }


  }

  emitAction(id?: string) {
    if (!!id) {
      const ids = this.selection.selected.map(el => el.id.value);

      this.onManualActionClick.emit({action: id, list: ids});

      this.selection.clear();
    }


  }

  clearInput(id: string, element: any, col: any) {
    (<HTMLInputElement>document.getElementById(id)).value = '';
    element[col.field] = '';
    this.onEditCell.emit({ record: element, oldRecord: this.oldElement, cel: col.field, editType: col.editType });
    element.editing = null;
  }

  showAudit(recordId: string) {
    this.dialog.open(AuditDialogComponent, {
      data: {
        title: "Audit details",
        info: recordId,
        closeButtonLabel: "Close",
      }
    });
  }

  showDetail(id: string, container: string) {
    this.zone.run(() => {
      this.router.navigate(['/monitor/container'], { queryParams: { id: id, number: container } });
    });
  }

  inputExists(elementId) {
    if (!!(<HTMLInputElement>document.getElementById(elementId)) && !!(<HTMLInputElement>document.getElementById(elementId)).value) return true;
    else return false;
  }

  isActionPresent(action: string) {
    if (this.permittedActions.indexOf(action) > -1)
      return true;
    else
      return false;
  }

  onExport() {
    this.onExportClick.emit(this.selection);
  }

  onImport() {
    this.onImportClick.emit();
  }

  openNotificationHistory(id: string, container: string) {
    this.zone.run(() => {
      this.router.navigate(['/notification/history'], { queryParams: { id: id, number: container } });
    });
  }


  onDetailChange(row: any) {
    this.onDetailChangeEmit.emit(row);
    this.expandedElement = row;
  }

  onCheckClick(row: any, header: Header, field: string) {
    row[field].value = row[field].value == 'Y' ? 'N' : 'Y';

    this.onCellExit(row, header);
  }

  detectStatus(checkBox: 'indeterminate' | 'checked') {
    if (checkBox == 'indeterminate') {
      return Object.getOwnPropertyNames(this.selectedItems).length > 0 && Object.getOwnPropertyNames(this.selectedItems).length < this.numElementsLoaded
    }
    if (checkBox == 'checked') {
      return Object.getOwnPropertyNames(this.selectedItems).length == this.numElementsLoaded
    }
    return false;
  }


}

export interface Header {
  id: number,
  label: string,
  field: string,
  editable?: boolean,
  actions?: any[],
  validation?: CellValidation,
  cellTextClass?: string,
  cellClass?: string,
  headerClass?: string,
  editType?: string,
  selectOptions?: any[],
  fix?: boolean,
  isAggregated?: boolean,
  regex?: string,
  regexMessage?: string
}

export interface CellValidation {
  required?: boolean;
  type?: "text" | "number" | "date"
  maxChar?: number;
  minChar?: number;
}

export interface CellError {
  elementId: number,
  headerId: number,
  message?: string,
}

