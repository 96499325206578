<div [ngClass]="['container', isLoading ? 'loading' : '']">

  <mat-panel-title class="title">
    {{ 'TITLE_COMMUNICATION_TRACKER' | translate }} - {{ 'LABEL_CONTAINER' | translate }}: {{monitorId}} {{container}}
  </mat-panel-title>

  <ng-container *ngIf="notifications && notifications.length > 0; else noData">
    <ng-container *ngFor="let notification of notifications" style="width: 100%">
      <monitor-notification-row style="width: 100%"
                                [actionsets]="actionsets"
                                [notification]="notification"
                                [apiParent]="apiParent"
                                [apiUrl]="apiUrl">
      </monitor-notification-row>
    </ng-container>
  </ng-container>

  <ng-template #noData>
    <div *ngIf="!isLoading;" class="no-data-panel">
      <div class="no-data-icon-container">
        <mat-icon class="no-data-icon" color="primary">info</mat-icon>
      </div>
      <p>{{'LABEL_NO_DATA_FOUND' | translate}}</p>
    </div>
  </ng-template>

  <div mat-dialog-actions align="end">
    <button mat-button color="primary" [mat-dialog-close]="false">{{"ACT_CLOSE" | translate}}</button>
  </div>
</div>
