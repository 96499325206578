<div class="alert-container">
  <alert-old-component #alertMessageMonitor></alert-old-component>
</div>

<mat-expansion-panel fxFlex [expanded]=expanded>
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{title}}
    </mat-panel-title>
  </mat-expansion-panel-header>

  <app-action-bar>
    <button *ngIf="isActionPresent(screen + '.insert') && screen != 'monitor.inv'" class="action-button"
      action-type="button" mat-raised-button color="primary" matTooltip="{{'Add new '+ title}}" (click)="create()">
      New {{title}}
    </button>
  </app-action-bar>

  <div class=" mt-16 ph-16">
    <app-filter [filterObj]="filter" (applyFilterEv)="onApplyFilter($event)" *ngIf="screen != 'monitor.inv'">
    </app-filter>
    <admin-data-table [headers]="headers" [length]="length" [numElementsLoaded]="(dataSourceObservable | async)?.length"
      [dataSource]="dataSourceObservable" (onPaginationChange)="onPageChange($event)"
      (onEditCell)="onEdit($event, true)" (onEditStart)="onEditStart($event)" (onActionClick)="onAction($event)"
      [screen]="screen" (onSortChange)="onSortChange($event)" #childDataTable>
    </admin-data-table>
  </div>

</mat-expansion-panel>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white; font-size: 1.5rem" > Loading... </p></ngx-spinner>
