<div class="form-group" *ngIf="regexps.length <= 0">
  <div class="input-group">
    <label for="input-text" *ngIf="!!label">{{label}}</label>
    <input id="input-text" [style.width.px]="mywidth ? mywidth : 208" class="form-control input-text" disabled="{{disabled}}" type="{{isNumber?'number':'text'}}" min="{{min}}"
           placeholder="{{placeholder}}" [(ngModel)]="value" (ngModelChange)="onValueChange($event)" 
           >
  </div>
</div>

<div class="form-group" *ngIf="regexps.length > 0">
  <div class="input-group">
    <label for="custom-input" *ngIf="!!label">{{label}}</label>
    <input id="custom-input" [style.width.px]="mywidth ? mywidth : 208"  class="form-control input-text custom-input" disabled="{{disabled}}" type="{{isNumber?'number':'text'}}"
           placeholder="{{placeholder}}" [(ngModel)]="value" (ngModelChange)="onValueChange($event)"
           (input)="onInputChange($event)" pattern="{{pattern}}" maxlength="{{maxlength}}" required="{{isMandatory}}" #customInput>
  </div>
</div>