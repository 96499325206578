import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IMultiSelectOption} from "../../../custom-multiselect/types";
import {List} from "lodash";

@Component({
  selector: 'add-language',
  templateUrl: './add-language.component.html',
  styleUrls: ['./add-language.component.scss']
})
export class AddLanguageComponent implements OnInit {

  languageName: string = ''
  optionList: any[] = [
    {
      id: null,
      name: ''
    }
  ]
  selectedOption = null

  constructor(
    public dialogRef: MatDialogRef<AddLanguageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.optionList = this.data.availableLanguages.map((langModel) => ({id: langModel.id, name: langModel.id}))
  }

  onCancelClick() {
    this.dialogRef.close(false)
  }

  onSaveClick() {
    if (!!this.languageName) {
      this.dialogRef.close({
        name: this.languageName,
        selected: this.selectedOption,
        select: this.data.availableLanguages.find(language => language.id == this.selectedOption)?.content
      })
    }
  }
}
