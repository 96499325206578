import {IMultiSelectOption} from "../../custom-multiselect/types";

export interface FilterOption {
  id: string;
  name: string;
  haveSecondParam: boolean;
}

export enum FILTER_TYPE {
  TEXT = 'FLT_STRING',
  NUMBER = 'FLT_NUMBER',
  DATE = 'FLT_DATE',
  SELECTION = 'FLT_SELECTION',
  MULTI_SELECTION = 'FLT_MULTI_SELECTION',
  STATUS = 'FLT_STATUS'
}

export function getKeyByValue(value: string) {
  const indexOfS = Object.values(FILTER_TYPE).indexOf(value as unknown as FILTER_TYPE);

  return Object.keys(FILTER_TYPE)[indexOfS];
}

export interface FilterValue {
  value: any;
  label: String;
}

export interface FilterInterface {

  lFilterOptions: IMultiSelectOption[];
  filterId: string;
  filterValueSearch: string;
  filterType: FILTER_TYPE;
  columnLabel: string;
  columnName: string,
  selectedOption: string;
  filterSelectedOption: IMultiSelectOption;
  filterValues: IMultiSelectOption[];
  firstValue: any;
  secondValue: any;
  isHide: Boolean;
  isResultsetLinked: boolean;

}
