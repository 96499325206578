import {Pipe, PipeTransform} from '@angular/core';
import {camelToSnakeCase, refactorName} from "../utils/generic";

@Pipe({
  name: 'rmDQ'
})
export class RemoveDoubleQuotePipe implements PipeTransform {

  transform(value: string | String, ...args: unknown[]): string {
    return value.replace(/"/g, "&quot;");
  }

}
