import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, lastValueFrom, Observable, of, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { groupBy, replacer, reviver, splitFilter } from 'src/app/shared/utils/generic';
import {Header} from "../../../components/shared/monitor-data-table/admin-data-table.component";
import {PageEventType} from "../../../components/shared/paginator/paginator.component";
import {FILTER_OBJ} from "../../../components/shared/filter/filter/filter.component";
import {AlertType} from "../../../components/shared/alert-old/alert-old.component";
import {DialogComponent} from "../../../components/shared/dialog/dialog.component";

@Component({
  selector: 'app-raw-destinations',
  templateUrl: './raw-destinations.component.html',
  styleUrls: ['./raw-destinations.component.scss']
})
export class RawDestinationsComponent implements OnInit {

  @ViewChild('childDataTable') childDataTable;
  @ViewChild('alertMessageMonitor') alertMessageMonitor;

  rawDestinationsHeaders: Observable<Header[]> = of([
    { id: 0, field: 'id', label: 'ID', editable: false, headerClass: "w-1-20", cellTextClass: "bold" },
    { id: 1, field: 'destination', label: 'Destination', editable: true, headerClass: "w-9-20" },
    { id: 1, field: 'cidCode', label: 'Destination CID', editable: true },
    { id: 2, field: 'destinationId', label: 'Destination ID', editable: true, headerClass: "w-10-20", editType: "selectMP", selectOptions: [] },
    { id: 3, field: 'actions', label: 'Actions', editable: false, headerClass: "w-1-20", actions: ["delete_forever"] },
  ]);

  rawDestinationsDataSourceSubject = new BehaviorSubject<any[]>([]);
  rawDestinationsDataSourceObservable = this.rawDestinationsDataSourceSubject.asObservable();
  rawDestinationsDataSource: any[] = [];
  rawDestinationsLength = 0;

  destinationsOptions: any[] = [];
  unitsOptions: any[] = [];

  loading: boolean = false;
  pageEvent: PageEventType = { pageIndex: 0, previousPageIndex: 0, loader: 50, size: 50 };
  sortedColumns: Map<string, { order: number, type: 'asc' | 'desc' }>;
  filterValues: Array<string>;
  filterObjs: FILTER_OBJ[] = [
    {
      name: 'Destination',
      columnProp: 'destination',
      filterType: 'string',
      value: '',
      modelValue: ''

    }
  ]

  subscriptionArray: Subscription[] = [];

  actions: string[] = [];

  screen: string = 'rawDestination'

  constructor(
    private masterDataService: MasterDataService,
    public dialog: MatDialog,
    private authService: AuthService,
    private spinner: NgxSpinnerService
  ) {
    this.sortedColumns = new Map<string, { order: number, type: 'asc' | 'desc' }>([['destination', { order: 1, type: 'asc' }]]);
    this.filterValues = new Array<string>();
  }

  ngOnInit(): void {
    this.sortedColumns = new Map<string, { order: number, type: 'asc' | 'desc' }>([['destination', { order: 1, type: 'asc' }]]);
    this.filterValues = new Array<string>();

    this.getAllDestinationsOptions().finally(() => {

      this.loading = false;
      const backupFilter = JSON.parse(sessionStorage.getItem('backup.filter.rawdestinations'))

      if (!!backupFilter) {
        this.filterValues = backupFilter;
        let backupArray = backupFilter.map(item => {
          return item.split("&");
        }).flat().map(fItem => {
          return splitFilter(fItem)
        })
        backupArray = groupBy(backupArray, 'key', 'value');

        this.filterObjs.forEach((filter) => {
          if (!!filter.filterType && filter.filterType == 'multipleSelect') {
            if (!!backupArray['' + filter.columnProp] && backupArray['' + filter.columnProp].length > 0) {
              filter.modelValue = filter.value.filter(e => !(backupArray['' + filter.columnProp].some(el => el['value'] == e.value))).map(e => e.value)
            }
          } else {
            if (!!backupArray['' + filter.columnProp] && backupArray['' + filter.columnProp].length > 0) {
              filter.value = (backupArray['' + filter.columnProp])[0].value;
              filter.modelValue = (backupArray['' + filter.columnProp])[0].value;
            }
          }
        })
      }
      const backupSorting = JSON.parse(sessionStorage.getItem('backup.sorting.rawdestinations'), reviver);
      if (!!backupSorting) {
        this.sortedColumns = backupSorting;
      }

      this.getRawDestinations(0, this.pageEvent.loader, this.sortedColumns, this.filterValues);

    })

    this.subscriptionArray.push(
      this.authService.getAction().subscribe(actions => {
        this.actions = actions['actions'];
        this.subscriptionArray.forEach(sub => sub.unsubscribe());
      }, err => {
        //this.router.navigate(['/'],);
      }))
  }

  onPageChange(pageEvent: any): void {
    this.pageEvent = pageEvent;

    this.getRawDestinations(pageEvent.pageIndex, pageEvent.loader, this.sortedColumns, this.filterValues)
  }

  onSortChange(pageSorting: { sorting: Map<string, { order: number, type: 'asc' | 'desc' }>; pageEvent: PageEventType }): void {
    this.sortedColumns = pageSorting.sorting;
    sessionStorage.setItem('backup.sorting.rawdestinations', JSON.stringify(pageSorting.sorting, replacer));
    this.refreshRawDestinations(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, pageSorting.sorting, this.filterValues);
  }

  onApplyFilter(filters: Array<string>, tab: number): void {
    this.filterValues = filters
    sessionStorage.setItem('backup.filter.rawdestinations', JSON.stringify(filters));
    this.refreshRawDestinations(0, this.pageEvent.loader, this.sortedColumns, filters);
  }

  getAllDestinationsOptions() {
    this.loading = true;
    return lastValueFrom(this.masterDataService.getListDestinations()).then(res => {
      if (!!res && !!res) {
        this.destinationsOptions = res.map((destination: any) => ({ value: destination.id.value, label: `${destination.rdcName.value}` })).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));

      }
    })
  }

  hasSubArray(master: any[], sub: any[]) {
    /*if (sub.every((i => v => i = master.indexOf(v, i) + 1)(0))) {
      return master;
    }
    return master.concat(sub);*/
    sub.forEach(elem => {
      const index = master.findIndex(e => e.id === elem.id);
      if (index !== -1) {
        master[index] = elem;
      } else {
        master.push(elem);
      }
    })
    return master;

  }

  getRawDestinations(page: number, size: number, sorting: Map<string, { order: number, type: 'asc' | 'desc' }>, filters: Array<string>) {
    this.loading = true;
    //const loadingEvent = this.dialog.open(LoadingComponent);
    this.spinner.show();
    let params = new HttpParams().set('page', page).set('size', size);
    new Map([...sorting.entries()].sort((a, b) => a[1].order - b[1].order)).forEach((value, key, map) => {
      if (!(!!params.get('sort')))
        params = params.set('sort', key + (value.type == 'desc' ? ',desc' : ''))
      else
        params = params.append('sort', key + (value.type == 'desc' ? ',desc' : ''))
    })
    filters.forEach(filter => {
      if (!(!!params.get('search')))
        params = params.set('search', filter.replace(',', '§'));
      else
        params = params.append('search', filter.replace(',', '§'));
    });
    this.masterDataService.getRawDestinations(params)
      .subscribe(
        (res: any) => {
          if (!!res && !!res.content) {
            //this.rawDestinationsDataSource = res.content;
            this.rawDestinationsLength = res.totalElements;
            this.rawDestinationsDataSource = this.hasSubArray(this.rawDestinationsDataSource, res.content).map(e => {
              this.destinationsOptions.forEach(elem => {
                if (elem.value === e.destinationId.value) {
                  e.destinationId.value = elem.label
                }
              })
              return e;
            })
            /*res.content.map(row => {
              const rD = this.rawDestinationsDataSource.find(elem => elem.id === row.id)
              if(!!rD){
                return rD
              }
              this.destinationsOptions.forEach(elem => {
                if(elem.value === row.destinationId){
                  row.destinationId = elem.label
                }
              })
              return row;
            })*/

            this.rawDestinationsDataSourceSubject.next(this.rawDestinationsDataSource);
          }
          //loadingEvent.close();
this.spinner.hide();
        },
        (err: any) => {
          //loadingEvent.close();
this.spinner.hide();
          this.loading = false;
        },
        () => this.loading = false)
  }

  refreshRawDestinations(page: number, size: number, sorting: Map<string, { order: number, type: 'asc' | 'desc' }>, filters: Array<string>) {
    this.loading = true;
    //const loadingEvent = this.dialog.open(LoadingComponent);
    this.spinner.show();
    let params = new HttpParams().set('page', page).set('size', size);
    new Map([...sorting.entries()].sort((a, b) => a[1].order - b[1].order)).forEach((value, key, map) => {
      if (!(!!params.get('sort')))
        params = params.set('sort', key + (value.type == 'desc' ? ',desc' : ''))
      else
        params = params.append('sort', key + (value.type == 'desc' ? ',desc' : ''))
    })
    filters.forEach(filter => {
      if (!(!!params.get('search')))
        params = params.set('search', filter.replace(',', '§'));
      else
        params = params.append('search', filter.replace(',', '§'));
    });
    this.masterDataService.getRawDestinations(params)
      .subscribe(
        (res: any) => {
          if (!!res && !!res.content) {
            //this.rawDestinationsDataSource = res.content;
            this.rawDestinationsDataSource = []
            this.rawDestinationsLength = res.totalElements;
            this.rawDestinationsDataSource = this.hasSubArray(this.rawDestinationsDataSource, res.content).map(e => {
              this.destinationsOptions.forEach(elem => {
                if (elem.value === e.destinationId.value) {
                  e.destinationId.value = elem.label
                }
              })
              return e;
            })
            /*res.content.map(row => {
              const rD = this.rawDestinationsDataSource.find(elem => elem.id === row.id)
              if(!!rD){
                return rD
              }
              this.destinationsOptions.forEach(elem => {
                if(elem.value === row.destinationId){
                  row.destinationId = elem.label
                }
              })
              return row;
            })*/
            this.rawDestinationsDataSourceSubject.next(this.rawDestinationsDataSource);
          }
          //loadingEvent.close();
this.spinner.hide();
        },
        (err: any) => {
          //loadingEvent.close();
this.spinner.hide();
          this.loading = false;
        },
        () => this.loading = false)
  }

  onRawDestinationEditStart(obj: { column: Header, element: any }) {
    if (obj.column.field === "destinationId") {
      obj.column.selectOptions = this.destinationsOptions;
    }
  }

  onEditRawDestination(data: any, reload = false) {
    if (!!data.record && !!data.oldRecord && data.record[data.cel].value === data.oldRecord[data.cel].value) return;

    let element;
    if (!!data.record) {
      delete data.record.editing;
      element = data.record;
    } else
      element = data;

    this.loading = reload;
    this.destinationsOptions.forEach(elem => {
      if (elem.label === element.destinationId.value) {
        element.destinationId.value = elem.value
      }
    })
    this.masterDataService.editRawDestination(element)
      .subscribe(
        (res: any) => {
          if (reload) {
            this.refreshRawDestinations(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, this.sortedColumns, this.filterValues);
          }

          //this.rawDestinationsDataSourceSubject.next(this.rawDestinationsDataSource)

        },
        (err: any) => {
          this.refreshRawDestinations(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, this.sortedColumns, this.filterValues);
          if (err.status == 403)
            this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Error 403 Forbidden", "You don't have permission for this action.", 10);
          else
            this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Edit error", "An error occurred during editing.", 10);
          this.loading = false;
        },
        () => {
          this.loading = false;
        })
  }

  onNewRawDestination(data: any, reload = false) {
    delete data.editing;

    this.loading = reload;
    this.masterDataService.newRawDestination(data)
      .subscribe(
        (res: any) => {
          if (reload) {
            this.refreshRawDestinations(this.pageEvent.pageIndex, this.pageEvent.loader, this.sortedColumns, this.filterValues);
          }

        },
        (err: any) => {
          this.loading = false;
        },
        () => this.loading = false)
  }

  onActionRawDestination(row: any) {
    switch (row.action) {
      case "delete_forever":
        const dialogRef = this.dialog.open(DialogComponent, {
          // width: '250px',
          data: {
            title: "Confirm deletion",
            content: `This action will delete the raw destination "${row.element.destination.value}". Proceed?`,
            cancelButtonLabel: "Cancel",
            cancelButtonColor: "basic",
            confirmButtonLabel: "Delete",
            confirmButtonColor: "warn"
          }
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (!!res) {
            this.masterDataService.deleteRawDestination(row.element.id.value)
              .subscribe(
                (res: any) => {
                  this.refreshRawDestinations(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, this.sortedColumns, this.filterValues);

                },
                (err: any) => {
                  this.loading = false;
                },
                () => this.loading = false)
          }
        });

        break
      case "cancelNew":
        this.rawDestinationsDataSource = this.rawDestinationsDataSource.slice(1);
        this.rawDestinationsDataSourceSubject.next(this.rawDestinationsDataSource);
        break;
      case "create":
        delete row.element.newRow;
        let element = row.element;
        this.destinationsOptions.forEach(elem => {
          if (elem.label === element.destinationId) {
            element.destinationId = elem.value
          }
        })

        this.onEditRawDestination(element, true);

        break;
    }
  }

  newRawDestination() {
    this.rawDestinationsDataSource = [{
      id: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      destination: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      cidCode: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      destinationId: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      newRow: {
        value: true,
        errorType: '',
        errorList: [],
        errorAction: ''
      }
    }, ...this.rawDestinationsDataSource];

    this.rawDestinationsDataSourceSubject.next(this.rawDestinationsDataSource);

    this.rawDestinationsHeaders.subscribe(rawDestinationsHeaders => {
      rawDestinationsHeaders.some((header) => {
        if (header.field === "destinationId") {
          header.selectOptions = this.destinationsOptions;
          return true;
        }
        return false;
      })
    })

  }


  newRawDestinationAlreadyExists() {
    return this.rawDestinationsDataSource.some((rawDestination) => !!rawDestination.newRow)
  }

  isActionPresent(action: string) {
    if (this.actions.indexOf(action) > -1)
      return true;
    else
      return false
  }
}

