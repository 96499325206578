<app-action-bar>
  <label class="page-title" page-title><label class="page-subtitle">OEMCC - </label> Preview ADM</label>
  <!--<button *ngIf="isActionPresent('monitor.import.save') && isActionPresent('monitor.import')" class="action-button"
    mat-raised-button color="primary" matTooltip="Save imported records" action-type="button" (click)="onSaveClick()"
    [disabled]="!isChanged || importError">Save All</button>
  <button *ngIf="isActionPresent('monitor.import')" class="action-button" mat-raised-button color="primary"
    matTooltip="Cancel imported records" action-type="button" (click)="onCancelClick()"
    [disabled]="!isChanged">Cancel</button>

    <button *ngIf="isActionPresent('monitor.import')" class="action-button" mat-raised-button color="primary" matTooltip="Import records" action-type="button"
    (click)="onImportClick()">Import</button>

  <button *ngIf="isActionPresent('monitor.export.csv')" class="action-button" mat-raised-button color="primary"
    matTooltip="Export monitor in CSV file" action-type="button" (click)="onExportCsvClick()"
    [disabled]="isChanged">Export CSV</button>-->

</app-action-bar>

<div class="alert-container">
  <alert-old-component #alertMessageMonitor></alert-old-component>
</div>

<div class="datatable-container ph-16">
  <div class="datatable-content">
    <app-filter *ngIf="!isChanged" [filterObj]="filterObjMonitor" (applyFilterEv)="onApplyFilter($event)">
    </app-filter>
    <div class="datatable-container">
      <app-static-data-table class="datatable-content" [headers]="monitorHeaders" [length]="monitorLength" [loadingTable]="loading"
                             [pageEvent]="pageEvent" [dataSource]="monitorDataSourceObservable"
                             [numElementsLoaded]="(monitorDataSourceObservable | async)?.length"
                             [dataSourceObservable]="monitorDataSourceObservable" (onPaginationChange)="onPageChange($event)"
                             (onSortChange)="onSortChange($event)" [sortedColumns]="sortedColumnsMonitor" [showMerge]="false"
                             (onDeleteRecordClick)="onDeleteRecordClick($event)" (searchForDuplicate)="searchForDuplicate($event)" (onReopenRecordsClick)="onReopenRecordsClick($event)" (searchForDeleting)="searchForDeleting($event)"
                             [screen]="'preview-su'"
                             #childDataTableMonitor>
      </app-static-data-table>
    </div>

  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white; font-size: 1.5rem" > Loading... </p></ngx-spinner>


<!--<div class="mt-16 ph-16" *ngIf="isChanged">

  <app-data-table [isFilter]="!isChanged" [headers]="monitorHeadersImport"
    [length]="(monitorImportDataSourceObservable | async)?.length" [loadingTable]="loading" [pageEvent]="pageEvent"
    [dataSource]="monitorImportDataSourceObservable"
    [numElementsLoaded]="(monitorImportDataSourceObservable | async)?.length"
    [dataSourceObservable]="monitorImportDataSourceObservable" (onPaginationChange)="onPageChange($event)"
    (onSortChange)="onSortChange($event)" [sortedColumns]="sortedColumnsMonitor" [showMerge]="false"
    (onActionClick)="onActionMonitor($event)" (onCloseRecordsClick)="onCloseRecordsClick($event)" [screen]="'import'"
    #childDataTableMonitorImport>
  </app-data-table>
</div>-->
