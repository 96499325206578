import {Component, Input} from '@angular/core';
import {AlertService} from "./alert.service";


@Component({
  selector: 'custom-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  @Input()
  containerClass: string = 'alert-container';

  constructor(public alertService: AlertService) {
  }


}
