<h1 class="fs-1 fw-bold text-left mt-3">{{ 'LABEL_DOCUMENTS' | translate }}</h1>
<ng-container *ngIf="!!layout && !!layout['sectionLayouts']">
  <div *ngFor="let sectionLayout of layout['sectionLayouts']; let i = index" class="mt-lg-5 w-100">
    <!-- && !!(sectionLayout['dataset'])[0] && !!(sectionLayout['dataset'])[0].datas -->
    <div *ngIf="sectionLayout['id'] == 'LYO_DOC_LIST' " class="detail-container p-3 mb-5 w-100">
      <ng-container
        *ngIf="!!sectionLayout.actionset[0] &&  ('ACT_DOC_NEW' | haveAction : sectionLayout.actionset[0].actions | async)">
        <div *ngIf="isNewSection" class="row align-items-center">
          <div class="col-4">
            <div class="row align-items-center">
              <div class="col-3"><label class="fs-6 fw-bold filter-title">{{ 'LABEL_SECTION_NAME' | translate }}</label>
              </div>
              <div class="col-9">
                <custom-text-field [(value)]="newSection.SECTION" [appearance]="'fill'"
                                   [placeholder]="('LABEL_SECTION_NAME' | translate)">
                </custom-text-field>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row align-items-center">
              <div class="col-2">
                <custom-btn (click)="onAddSection(true)" color="accent">{{ 'ACT_CONFIRM' | translate }}</custom-btn>
              </div>
              <div class="col-2">
                <custom-btn (click)="onAddSection(false)">{{ 'ACT_CANCEL' | translate }}</custom-btn>
              </div>
            </div>
          </div>
          <div class="col-4"></div>
        </div>
        <div *ngIf="!isNewSection" class="row">
          <div class="col-3">
            <custom-btn (click)="onAddSection()" color="accent">{{ 'LABEL_SECTION_ADD' | translate }}</custom-btn>
          </div>
          <div class="col-9"></div>
        </div>
      </ng-container>


      <ng-container *ngFor="let archive of archives">
        <div class="row align-items-center mt-4 w-100 mb-2">
          <div *ngIf="!!sectionLayout.actionset[0] &&  (('ACT_DOC_NEW' | haveAction : sectionLayout.actionset[0].actions | async) || ('ACT_DOC_UPLOAD' | haveAction : sectionLayout.actionset[0].actions | async) || ('ACT_DOC_DELETE' | haveAction : sectionLayout.actionset[0].actions | async))"
               class="col-1">
            <div class="row align-items-center">
              <div ngbDropdown>
                <custom-btn color="primary" id="dropdownBasic1" ngbDropdownToggle>
                  {{ 'LABEL_ACTIONS' | translate }}
                </custom-btn>
                <div aria-labelledby="dropdownBasic1" ngbDropdownMenu>
                  <button (click)="deleteArchive(archive.DOCUMENTS_ID)" *ngIf="('ACT_DOC_DELETE' | haveAction : sectionLayout.actionset[0].actions | async)"
                          ngbDropdownItem>{{ 'ACT_DELETE' | translate }}
                  </button>
                  <button (click)="renameSection(archive)" *ngIf="('ACT_DOC_NEW' | haveAction : sectionLayout.actionset[0].actions | async)"
                          matTooltip="D"
                          ngbDropdownItem>{{ 'ACT_RENAME' | translate }}
                  </button>
                  <button (click)="uploadDoc(archive.DOCUMENTS_ID)" *ngIf="('ACT_DOC_UPLOAD' | haveAction : sectionLayout.actionset[0].actions | async)"
                          ngbDropdownItem>{{ 'ACT_UPLOAD' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="row align-items-center">
              <ng-container *ngIf="!!section && section.DOCUMENTS_ID == archive.DOCUMENTS_ID else default">
                <div class="col-4">
                  <custom-text-field [(value)]="section.SECTION" [appearance]="'fill'"
                                     [placeholder]="('LABEL_SECTION_NAME' | translate)">
                  </custom-text-field>
                </div>
                <div class="col-8">
                  <div class="row align-items-center">
                    <div class="col-2">
                      <custom-btn (click)="saveSection(section)" color="accent">{{ 'ACT_CONFIRM' | translate }}
                      </custom-btn>
                    </div>
                    <div class="col-2">
                      <custom-btn (click)="renameSection(null)">{{ 'ACT_CANCEL' | translate }}</custom-btn>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-template #default>
                <label class="fs-4 fw-bold section-title">{{ archive.SECTION }}</label>
              </ng-template>
            </div>
          </div>
          <div class="col-auto"></div>
        </div>
        <div *ngFor="let document of archive['files']"
             class="row pt-1 pb-1 w-100 pl-5 rounded align-items-center document-row">
          <div class="col-2">
            <div class="row align-items-center">
              <ng-container
                *ngIf="!!sectionLayout.actionset[0] && ('ACT_DOC_DELETE' | haveAction : sectionLayout.actionset[0].actions | async)">
                <div class="col-2">
                  <div class="row">
                    <span (click)="deleteDoc(document ['id'], document)" class="custom-icon-btn"
                          matTooltip="{{'LABEL_DELETE' | translate}}">
                      <mat-icon>delete</mat-icon>
                    </span>
                  </div>
                </div>
              </ng-container>

              <div class="col-2">
                <span (click)="downloadAttachment(document['url'], document['name'])" class="custom-icon-btn"
                      matTooltip="{{'LABEL_DOWNLOAD' | translate}}">
                      <mat-icon>cloud_download</mat-icon>
                    </span>

              </div>
            </div>
          </div>
          <div class="col-5">
            <label class="fs-6 fw-normal section-title">{{ document['name'] }}</label>
          </div>
          <div class="col-auto"></div>

        </div>
      </ng-container>
      <!--<ng-container *ngFor="let data of (sectionLayout['dataset'])[0].datas">
        <ng-container
          *ngIf="data.columnName != 'SHIPMENT_ID' && data.columnName != 'DELIVERY_TYPE_ID' && data.columnName != 'DELIVERY_MODE_ID' && data.columnName != 'CARRIER_ID' && data.columnName != 'WAGON' && data.columnName != 'TRUCK_PLATE' && data.columnName != 'OTM_SHIPMENT_ID' && data.columnName != 'CONTAINER' && data.columnName != 'TRAILER_PLATE' && data.columnName != 'REMARKS'">
          <ng-container *ngIf="data.type != 'DTY_LIST' else listBlock">
            <ng-container *ngIf="data.type == 'DTY_DATE' else listBlock">
              <div class="detail-content">
                <label class="fs-6 fw-bold filter-title">{{data.columnLabel}}</label>
                <custom-datepicker *ngIf="data.type == 'DTY_DATE'" [disabled]="!data.editable"
                                   [(value)]="detail[data.columnName]">
                </custom-datepicker>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #listBlock>
            <ng-container *ngIf="data.type == 'DTY_LIST' && !!data.optionValues else defaultBlock">

              <div class="detail-content ">
                <label class="fs-6 fw-bold filter-title">{{data.columnLabel}}</label>

                <div class="select-wrapper">
                  <custom-select [values]="data.optionValues" [disabled]="!data.editable"
                                 [(defaultValue)]="detail[data.columnName]" [extraClass]="'likeText'">
                  </custom-select>
                </div>

              </div>
            </ng-container>
            <ng-template #defaultBlock>
              <div class="detail-content">
                <label class="fs-6 fw-bold filter-title">{{data.columnLabel}}</label>
                <custom-text-field [appearance]="'fill'" [placeholder]="data.columnLabel" [disabled]="!data.editable"
                                   [(value)]="detail[data.columnName]"></custom-text-field>
              </div>
            </ng-template>
          </ng-template>
        </ng-container>
      </ng-container>-->
    </div>
  </div>
</ng-container>
