import {Component, OnDestroy, OnInit} from "@angular/core";
import {HttpParams} from "@angular/common/http";
import {TableControllerService} from "../../../../services/table-controller.service";
import {ActivatedRoute, Router} from "@angular/router";
import {BehaviorSubject, filter, firstValueFrom, forkJoin, Observable, Subject, Subscription, takeUntil} from "rxjs";
import {map, tap} from "rxjs/operators";
import {GeneralService} from "../../../../services/crud/general.service";
import {FilterControllerService} from "../../../../services/filter-controller.service";
import * as saveAs from "file-saver";
import {MatDialog} from "@angular/material/dialog";
import {DialogComponent} from "../../dialog/dialog.component";
import {
  get_tex_size,
  hasSubArray,
  refactorName,
  replacer,
  reviver,
  transformIdToValueFromList
} from "../../../../utils/generic";
import {PageEventType} from "../../custom-table-paginator/custom-table-paginator.component";
import {ActionSet, Data, DataSet, Layout, SectionLayout} from "../../../../models/layout";
import {SpinnerService} from "../../../../services/spinner.service";
import {LayoutServiceService} from "../../../../services/layout.service";
import {ALERT_TYPE} from "../../alert/alert.enumerate";
import {AlertService} from "../../alert/alert.service";
import {NavigationService} from "../../../../services/utils/navigation.service";
import {DndComponent} from "../../dnd/dnd.component";
import {FILTER_TYPE, FilterInterface} from "../../filter/custom-filter/filter.interface";
import {IMultiSelectOption} from "../../custom-multiselect/types";
import {cloneDeep, first} from "lodash";
import {TranslateService} from "@ngx-translate/core";
import {TitleCasePipe} from "@angular/common";
import {Document} from "src/app/components/pages/documents/documents.component";
import {SaveConfirmComponent} from "../../dialog/save-confirm/save-confirm.component";
import {AddLanguageComponent} from "../language/add-language/add-language.component";
import {AuditDialogComponent} from "../../audit-dialog/audit-dialog.component";
import {ContactGroupDialogComponent} from "../../contact-group-dialog/contact-group-dialog.component";
import {MonitorService} from "../../../../services/monitor.service";
import {ImportPreviewComponent} from "../../import-preview/import-preview.component";

@Component({
	selector: "layout-general",
	templateUrl: "./general.component.html",
	styleUrls: ["./general.component.scss"],
	providers: [TableControllerService, FilterControllerService],
	host: {'class': 'h-100'}
})
export class GeneralComponent implements OnDestroy, OnInit
{
	private searchSubscription?: Subscription;

	private _interactionCycle: Subject<void> = new Subject<void>();
	private _pageCycle: Subject<void> = new Subject<void>();

	private _routeUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');

	private url$: Observable<string> = this.route.url.pipe(map((segments) => segments.join("/"))).pipe(
		map((url) =>
		{
			this.spinner.hideAll();
			this.apiUrl = url;
			this.route.parent?.url.pipe(map((segments) => segments.join("/"))).subscribe((finalParentUrl) =>
			{
				this.apiParent = finalParentUrl;
			});
			return url;
		}),
		filter(url => url !== this._routeUrl.value),
		tap(url =>
		{
			this._routeUrl.next(url);
			this.tableController.dataSet = [];

			this._interactionCycle.next()
			console.log('loading for:', url)
			this.getQueryParam();
		}),
		takeUntil(this._pageCycle)
	)

	constructor(
		private spinner: SpinnerService,
		public tableController: TableControllerService,
		public filterController: FilterControllerService,
		private route: ActivatedRoute,
		private router: Router,
		private generalDataService: GeneralService,
		public dialog: MatDialog,
		public layoutService: LayoutServiceService,
		public alertService: AlertService,
		private navigation: NavigationService,
		private translateService: TranslateService,
		private titlePageTranslate: TitleCasePipe,
    private monitorService: MonitorService,
	)
	{
		this.spinner.hideAll();


		this.tableController.onPaginationChange.subscribe((event) =>
		{
			this.savedPaginator = event;
			this.onSearch(event.pageIndex, event.loader, this.sortedColumns, this.savedFilter);
		});

		this.tableController.onSortChange.subscribe((event) =>
		{
			this.sortedColumns = event.sorting;
			sessionStorage.setItem("sort_" + this.pageId, JSON.stringify(event.sorting, replacer));
			this.onSearch(0, this.tableController.dataSet.length, event.sorting, this.savedFilter, this.dts, true);
		});

		this.tableController.validateActions.set("ACT_WITHDRAW", (row) =>
		{
			return !!row["STATUS"] && row["STATUS"] != "CANCELLED";
		});

		this.tableController.validateActions.set("ACT_DELETE", (row) =>
		{
			return !(!!row["STATUS"] && row["STATUS"] != "CANCELLED");
		});

		this.tableController.validateActions.set("ACT_EDIT_DAM", (row) =>
		{
			return !!row["STATUS"] && row["STATUS"] != "CANCELLED";
		});

		this.tableController.validateActions.set("ACT_CREATE_SCORECARD_DAM", (row) =>
		{
			return !!row["STATUS"] && (row.STATUS == 'OPEN' || row.STATUS == 'REOPEN' || row.STATUS == 'MODIFIED')
		});

		this.tableController.validateActions.set("ACT_EDIT_SCR", (row) =>
		{
			return false;
		});


		tableController.customizeAfterAddClick = (newRowObj: any): any =>
		{
			//newRowObj[newRowObj.obackupDTO]=null
			delete newRowObj.lDocuments;
			delete newRowObj.backup;
			return newRowObj
		}
		//setto nill e return  constr altrimneti delete, + problem detail componetn
		//per il mom
	}

	ngOnDestroy(): void
	{
		if (!!this.searchSubscription)
		{
			this.searchSubscription.unsubscribe();
		}
		console.log('destroying page');
		this._pageCycle.next();
	}

	ngOnInit(): void
	{
		console.log('building page')
		this.url$.subscribe();

	}

	_LayoutSubject: BehaviorSubject<Layout | null> = new BehaviorSubject<Layout | null>(null);
	_LayoutObservable = this._LayoutSubject.asObservable();

	layout: Layout | undefined;
	viewOnly: boolean = false
	pageActionset: ActionSet[] = [];
	pageDataset: DataSet[] = [];

	dataSet: any[] = [];

	apiUrl: string = "";

	apiParent: string = "";

	savedFilter: any = [];

	titlePage: string = "";
	pageId: string = "";

  //ITOEM-05: added logic to display notification option button
  notificationOption: any[];
  //ITOEM-05: added logic to display notification option button
  manualOption: any[];

	attachments: Document[] = [];


	savedParams: HttpParams = new HttpParams();

	savedPaginator: PageEventType = {
		pageIndex: 0,
		previousPageIndex: 0,
		loader: 50,
		size: 0
	};

	sortedColumns: Map<string, { order: number; type: "asc" | "desc" }> = new Map();

	toDamageDetail = {};

	disabledColumns: string[] = ["INSERT_USER", "INSERT_DATE", "UPDATE_USER", "UPDATE_DATE"];

	dts: string = "";

	ats: string = "";

	visibleItems!: number;

	checkStringOrNumberValidity = (value: any | null) =>
	{
		console.log(value, value !== undefined && value !== null)
		return value !== undefined && value !== null
	}

	refreshData(
		srtdClmns: Map<
			string,
			{
				order: number;
				type: "asc" | "desc";
			}
		>                        = new Map(),
		newFilter: Array<string> = new Array<string>()
	)
	{
		this.onSearch(0, this.savedPaginator.loader, srtdClmns, newFilter, this.dts, true);
	}

	getQueryParam()
	{
		this.route.queryParams.pipe(
			takeUntil(this._interactionCycle),
			map((params) =>
			{
				this.pageId = params["id"] ?? [];
				this.titlePage = params["title"] ?? "";

				const sorted = sessionStorage.getItem("sort_" + this.pageId);
				if (sorted)
				{
					this.tableController.sortedColumns = JSON.parse(sorted, reviver);
				}
				else
				{
					this.tableController.sortedColumns = new Map<string, { order: number; type: "asc" | "desc" }>();
				}

				const flt = sessionStorage.getItem("FLT_" + this.pageId);
				if (flt)
				{
					this.filterController.filterModels = JSON.parse(flt, reviver)
					this.onFilterClick(null, false);
				}

				this.getPageLayout(this.pageId);

        //ITOEM-05: added logic for notification button
        this.notificationOption = [];
        if (this.pageId != null && this.pageId == 'PAG_MONITOR') {
          this.generalDataService.getNotificationActions(this.apiParent + "/" + this.apiUrl, null).pipe(
            takeUntil(this._interactionCycle)
          ).subscribe({
            next: (notificationOption) => {
              this.notificationOption = notificationOption;
            },
            error: (err) => {
              this.notificationOption = [];
            }
          });
        }

        //ITOEM-05: added logic for manual action
        this.manualOption = [];
        if (this.pageId != null && this.pageId == 'PAG_MONITOR') {
          this.generalDataService.getManualActions(this.apiParent + "/" + this.apiUrl, null).pipe(
            takeUntil(this._interactionCycle)
          ).subscribe({
            next: (manualOption) => {
              this.manualOption = manualOption;
            },
            error: (err) => {
              this.manualOption = [];
            }
          });
        }

				return params;
			})
		).subscribe({
			next: (params) =>
			{
			},
			error: (err) =>
			{
				//(err)
				this.alertService.add({
					type: ALERT_TYPE.DANGER,
					message: "Some error occurred on layout retrive",
					timeout: 5000,
					selfClose: null
				});
			}
		});
	}

	onFilterClick(event: any, isSearching: boolean = true)
	{
		const newFilter: any = [];

		sessionStorage.setItem("FLT_" + this.pageId, JSON.stringify(this.filterController.filterModels, replacer));
		const _filterList = this.filterController.filterModels.filter((model) => !model.isHide);
		//(_filterList);
		_filterList.forEach((filter) =>
		{
			console.log(filter)
			if (filter.filterSelectedOption.haveSecondParam && filter.filterSelectedOption.id == "_-d_")
			{
				if (this.checkStringOrNumberValidity(filter.firstValue)) newFilter.push(filter.columnName + "_>d_" + filter.firstValue);
				if (this.checkStringOrNumberValidity(filter.secondValue)) newFilter.push(filter.columnName + "_<d_" + filter.secondValue);
			}
			else
				if (filter.filterSelectedOption.haveSecondParam && filter.filterSelectedOption.id == "_-_")
				{
					if (this.checkStringOrNumberValidity(filter.firstValue)) newFilter.push(filter.columnName + "_>_" + filter.firstValue);
					if (this.checkStringOrNumberValidity(filter.secondValue)) newFilter.push(filter.columnName + "_<_" + filter.secondValue);
				}
				else
				{
					if (filter.filterSelectedOption.haveFirstParam)
					{
						if (this.checkStringOrNumberValidity(filter.firstValue) && filter.firstValue.length > 0)
						{
							if (filter.filterType == FILTER_TYPE.MULTI_SELECTION)
							{
								newFilter.push(filter.columnName + filter.filterSelectedOption.id + filter.firstValue.join("§"));
							}
              else
                // OEMCC-5: logic to handle IN clause for FILTER_TYPE.TEXT
                if (filter.filterType == FILTER_TYPE.TEXT && filter.filterSelectedOption.id == "_SAME_")
                {
                  let filterValue = filter.firstValue;
                  let filterOption = filterValue.split(",");
                  newFilter.push(filter.columnName + filter.filterSelectedOption.id + filterOption.join("§"));
                }
							else
							{
								newFilter.push(filter.columnName + filter.filterSelectedOption.id + filter.firstValue);
							}
						}
					}
					else
					{
						filter.firstValue = []
						newFilter.push(filter.columnName + filter.filterSelectedOption.id + "#");
					}
				}
		});
		this.savedFilter = newFilter;
		this.filterController.refreshViewedFilter();

		if (isSearching)
		{
			this.tableController.dataSet = [];
			this.onSearch(0, this.savedPaginator.loader, this.sortedColumns, newFilter);
		}

	}

	onCancelClick()
	{
		this.tableController.deletedRows = [];
		this.tableController.selection.clear();
		this.tableController.dataSet = [];
		this.onSearch(0, this.savedPaginator.loader, this.sortedColumns, this.savedFilter);
	}

	/*onSaveClick(event) {
	  event.stopPropagation();

	  this.spinner.show();
	  if (!!this.tableController.deletedRows && this.tableController.deletedRows.length > 0) {
		lastValueFrom(
		  this.generalDataService.deleteData(this.apiParent + "/" + this.apiUrl, this.tableController.deletedRows)
		)
		  .then((res) => {
			this.tableController.deletedRows = [];
			this.spinner.hide();
		  })
		  .finally(() => {
			this.spinner.show();
			lastValueFrom(
			  this.generalDataService.postData(
				this.apiParent + "/" + this.apiUrl,
				this.tableController.dataSet
				  .filter((row) => row.edited != undefined)
				  .map((elem) => {
					delete elem.edited;
					delete elem.checked;
					return elem;
				  })
			  )
			).finally(() => {
			  this.spinner.hide();
			  this.onCancelClick();
			});
		  });
	  } else {
		lastValueFrom(
		  this.generalDataService.postData(
			this.apiParent + "/" + this.apiUrl,
			this.tableController.dataSet
			  .filter((row) => row.edited != undefined)
			  .map((elem) => {
				delete elem.edited;
				delete elem.checked;
				return elem;
			  })
		  )
		).finally(() => {
		  this.spinner.hide();
		  this.onCancelClick();
		});
	  }
	} */

	onExportAllClick(event)
	{
		event.stopPropagation();

		if (!!this.layout)
			this.savedParams = this.savedParams.set(
				"dataset",
				this.layout["sectionLayouts"].filter((sectionLayout) => sectionLayout.id == "LYO_SECTABLE").pop()?.dataset[0].id ?? ""
			);
		this.generalDataService.exportDataExcel(this.apiParent + "/" + this.apiUrl, "excel", this.savedParams, []).pipe(
			takeUntil(this._interactionCycle)
		).subscribe((blob) =>
		{
      // [REF]
      if (blob instanceof Blob) {
        saveAs(blob, `${this.titlePage}.xlsx`);
      } else {
        console.error('Received data is not a Blob');
      }
			this.spinner.hide();
		});
	}

	onSearch(
		page: number,
		size: number,
		sorting: Map<string, { order: number; type: "asc" | "desc" }>,
		filters: Array<string>,
		dts: string      = this.dts,
		refresh: boolean = false
	)
	{
		let params = new HttpParams().set("page", page).set("size", size).set("dts", dts);
		new Map([...sorting.entries()].sort((a, b) => a[1].order - b[1].order)).forEach((value, key, map) =>
		{
			if (!!!params.get("sort")) params = params.set("sort", refactorName(key) + (value.type == "desc" ? ",desc" : ""));
			else params = params.append("sort", refactorName(key) + (value.type == "desc" ? ",desc" : ""));
		});
		filters.forEach((filter) =>
		{
			if (!filter.includes("&"))
			{
				if (!!!params.get("search")) params = params.set("search", filter);
				else params = params.append("search", filter);
			}
			else
			{
				filter.split("&").forEach((value) =>
				{
					if (!!!params.get("search")) params = params.set("search", value);
					else params = params.append("search", value);
				});
			}
		});

		this.savedParams = params;

		this.spinner.showWithTitle("data", this.translateService.instant('LOADING_RESULTSET'));
		//.getData(this.apiParent + "/" + this.apiUrl, undefined, params)
		this.searchSubscription = this.generalDataService.getData(this.apiParent + "/" + this.apiUrl, undefined, params).pipe(
			takeUntil(this._interactionCycle)
		)
		//.getData(!!this.apiParent? (this.apiParent+ "/" + this.apiUrl) : this.apiUrl , undefined, params)
		.subscribe({
			next: (res) =>
			{
				if (refresh)
				{
					this.tableController.dataSet = res["oContent"]["rs"]["content"];
					if (!!this.layout) this.catchAllSelectedOptions(this.layout);
				}
				else
				{
					this.tableController.dataSet = !!this.tableController.dataSet
						? hasSubArray(this.tableController.dataSet, res["oContent"]["rs"]["content"])
						: res["oContent"]["content"]["rs"];

				}

				this.tableController.numElementsLoaded = this.tableController.dataSet.length;
				this.tableController.numElementsSize = res["oContent"]["rs"].totalElements;

				this.filterController._filterValues.next(res["oContent"]["fv"]);
				this.tableController._filterValues.next(res["oContent"]["tv"]);
				this.calculateSizeComplete();
				this.spinner.hide();
			},
			error: (error) =>
			{
				this.spinner.hide();
			}
		});
	}

	actionCatcher(event: string)
	{
		if (event.endsWith("_ALL"))
		{
      // OEM-5: added import preview logic
      if (event.startsWith("ACT_IMPORT_PREVIEW")) {
        {

          const dialogEvent = this.dialog.open(DndComponent, {
            data: {
              title: "UPLOADING",
              code: "Upload file: ",
              message: "Are you sure you want to upload this file?",
              btn: "Upload",
              type: "any",
              cancelBtn: "Close"
            }
          });

          dialogEvent.afterClosed().subscribe(async (dndResponse) =>
          {
            if (!!dndResponse && dndResponse.send)
            {
              const uploadFiles: File[] = dndResponse.files;

              for (let file of uploadFiles)
              {

                this.spinner.show();
                  this.generalDataService.previewExcel(
                    this.apiParent + "/" + this.apiUrl + "/import/preview",
                    file
                  ).pipe(
                    takeUntil(this._interactionCycle)
                  ).subscribe({
                    next: res => {
                      this.spinner.hide();
                      debugger;
                      this.dialog.open(ImportPreviewComponent, {
                        // width: '65vw',
                        height: '50vw',
                        data: {
                          layout: res.oContent,
                          apiParent: this.apiParent,
                          apiUrl: this.apiUrl,
                        }
                      }).afterClosed().subscribe( (res) => {
                          this.onSearch(0, this.savedPaginator.loader, this.sortedColumns, this.savedFilter, this.dts, true);
                        }
                      );
                    },
                    error : err => {
                      this.spinner.hide();
                    }
                  })
              }
            }
          });

        }
      }
      else
        if (event.startsWith("ACT_IMPORT"))
        {

          const dialogEvent = this.dialog.open(DndComponent, {
            data: {
              title: "UPLOADING",
              code: "Upload file: ",
              message: "Are you sure you want to upload this file?",
              btn: "Upload",
              type: "any",
              cancelBtn: "Close"
            }
          });

          dialogEvent.afterClosed().subscribe(async (dndResponse) =>
          {
            if (!!dndResponse && dndResponse.send)
            {
              const uploadFiles: File[] = dndResponse.files;

              for (let file of uploadFiles)
              {

                this.spinner.show();
                await firstValueFrom(
                  this.generalDataService.importExcel(
                    this.apiParent + "/" + this.apiUrl + "/import",
                    file
                  )
                ).then((res) =>
                {

                  if (res.size != 0)
                  {
                    saveAs(res, `${this.titlePage}_RESULT.xlsx`);
                  }

                  this.alertService.add({
                    type: ALERT_TYPE.SUCCESS,
                    message: "File uploaded",
                    timeout: 5000,
                    selfClose: null
                  });
                  this.onSearch(0, this.savedPaginator.loader, this.sortedColumns, this.savedFilter, this.dts, true);
                }).catch((err) =>
                {
                  console.log(err)
                  this.alertService.add({
                    type: ALERT_TYPE.WARNING,
                    message: "Something went wrong, please try again or contact webmaster",
                    timeout: 5000,
                    selfClose: null
                  });
                }).finally(() =>
                {
                  this.spinner.hide();
                });
              }
            }
          });

        }
			else
				if (event.startsWith("ACT_EXPORT_REPORT"))
				{
					this.spinner.show();
					this.generalDataService.exportDataExcel(
						//this.apiParent + "/" + this.apiUrl,
						(!!this.apiUrl ? this.apiParent + "/" + this.apiUrl : this.apiParent),
						"report-excel",
						this.savedParams.set(
							"dataset",
							this.layout!["sectionLayouts"].filter((sectionLayout) => sectionLayout.id == "LYO_SECTABLE").pop()?.dataset[0].id ?? ""
						),
						(this.tableController.selection.selected.length > 0 ? this.tableController.selection.selected : [])
					).pipe(takeUntil(this._interactionCycle)).subscribe((blob) =>
					{
						//saveAs(blob, `${this.titlePage}.xlsx`);
						this.spinner.hide();
					});

				}
				else
					if (event.startsWith("ACT_EXPORT"))
					{
						if (this.tableController.numElementsSize > 5000)
						{
							const dialogRef = this.dialog.open(DialogComponent, {
								data: {
									title: this.translateService.instant("LABEL_LARGE_AMOUNT_DATA"),
									content: this.translateService.instant("LABEL_LARGE_AMOUNT_DATA_CONFIRM"),
									cancelButtonLabel: this.translateService.instant("ACT_NO"),
									cancelButtonColor: "basic",
									confirmButtonLabel: this.translateService.instant("ACT_YES"),
									confirmButtonColor: "warn"
								}
							});
							dialogRef.afterClosed().subscribe((res) =>
							{
								if (res)
								{
									this.spinner.show();
									this.generalDataService.exportDataExcel(
										this.apiParent + "/" + this.apiUrl,
										"excel",
										this.savedParams.set(
											"dataset",
											this.layout!["sectionLayouts"].filter((sectionLayout) => sectionLayout.id == "LYO_SECTABLE").pop()?.dataset[0].id ?? ""
										),
										[]
									).pipe(takeUntil(this._interactionCycle)).subscribe((blob) =>
									{
                    // [REF]
                    if (blob instanceof Blob) {
                      saveAs(blob, `${this.titlePage}.xlsx`);
                    } else {
                      console.error('Received data is not a Blob');
                    }
										this.spinner.hide();
									});
								}
							});
						}
						else
						{
							this.spinner.show();
							this.generalDataService.exportDataExcel(
								//this.apiParent + "/" + this.apiUrl,
								(!!this.apiUrl ? this.apiParent + "/" + this.apiUrl : this.apiParent),
								"excel",
								this.savedParams.set(
									"dataset",
									this.layout!["sectionLayouts"].filter((sectionLayout) => sectionLayout.id == "LYO_SECTABLE").pop()?.dataset[0].id ?? ""
								),
								[]
							).pipe(takeUntil(this._interactionCycle)).subscribe((blob) =>
							{
                // [REF]
                if (blob instanceof Blob) {
                  saveAs(blob, `${this.titlePage}.xlsx`);
                } else {
                  console.error('Received data is not a Blob');
                }
								this.spinner.hide();
							});
						}
					}
					else
						if (event.startsWith("ACT_REPORT"))
						{

							this.spinner.show();

							this.generalDataService.reportEndUseRegime(this.apiParent + "/" + this.apiUrl + '/report').subscribe({
								next: res =>
								{
									this.spinner.hideAll();
									if (res.iResponseCode !== 200)
									{
										this.alertService.add({
											type: ALERT_TYPE.DANGER,
											message: `${res.iResponseCode} - ${this.translateService.instant(res.sResponseMessage)}`,
											timeout: 5000,
											selfClose: null
										});
										this.tableController.dataSet = cloneDeep(this.tableController.dataSet);
									}
									else
									{
										this.refreshData();
										this.alertService.add({
											type: ALERT_TYPE.SUCCESS,
											message: `${res.iResponseCode} - ${res.sResponseMessage}`,
											timeout: 3000,
											selfClose: null
										});
									}
								},
								error: (err) =>
								{
									this.spinner.hide();
									this.tableController.deletedRows = [];
									this.tableController.rowNumber = -1;
									this.alertService.add({
										type: ALERT_TYPE.WARNING,
										message: `${err.error.data.iResponseCode} - ${err.error.data.sResponseMessage}`,
										timeout: 5000,
										selfClose: null
									});
								}
							});
						}
						else
							if (event.startsWith("ACT_ADD_LANGUAGE"))
							{
								this.spinner.show();
								this.generalDataService.getAvailableLanguagesAndTranslations().subscribe((languages) =>
								{
									this.spinner.hideAll();
									const dialogRef = this.dialog.open(AddLanguageComponent, {
										width: "60vw",
										data: {
											availableLanguages: languages,
											confirmButtonLabel: "Ok",
											confirmButtonColor: "primary",
											cancelButtonLabel: "Close",
											cancelButtonColor: "accent"
										}
									});

									dialogRef.afterClosed().subscribe((res) =>
									{
										if (!!res)
										{
											const language: string = res.name;
											const languageToCopy: string = res.selected;
											this.spinner.show();
											this.generalDataService.setLanguageTranslation(language, languageToCopy).subscribe({
												next: (value) =>
												{
													this.spinner.hideAll();
													this.refreshData();
													this.alertService.add({
														type: ALERT_TYPE.SUCCESS,
														message: "Language '" + language + "' saved correctly from '" + languageToCopy + "'",
														timeout: 3000,
														selfClose: null
													});
												},
												error: (err) =>
												{
													this.spinner.hideAll();
													this.refreshData()
													this.alertService.add({
														type: ALERT_TYPE.DANGER,
														message: "An error occurred on save '" + language + "' language.",
														timeout: 5000,
														selfClose: null
													});
												}
											});
										}
									});
								});
							}
							else
								if (event.startsWith("ACT_CONVERT_FILE"))
								{
									const dialogEvent = this.dialog.open(DndComponent, {
										data: {
											title: "UPLOADING",
											code: "Upload file: ",
											message: "Are you sure you want to upload this file?",
											btn: "Upload",
											type: "any",
											cancelBtn: "Close"
										}
									});

									dialogEvent.afterClosed().subscribe(async (dndResponse) =>
									{
										if (!!dndResponse && dndResponse.send)
										{
											const uploadFiles: File[] = dndResponse.files;

											for (let file of uploadFiles)
											{

												this.spinner.show();
												await firstValueFrom(
													this.generalDataService.importExcel(
														this.apiParent + "/" + this.apiUrl + "/convert-file",
														file
													)
												).then((res) =>
												{
													if (res.size != 0)
													{
														saveAs(res, `${this.titlePage}_CONVERTED.xlsx`);
													}
													this.alertService.add({
														type: ALERT_TYPE.SUCCESS,
														message: "File converted",
														timeout: 5000,
														selfClose: null
													});
												}).catch((err) =>
												{
													console.log(err)
													this.alertService.add({
														type: ALERT_TYPE.WARNING,
														message: "Something went wrong, please try again or contact webmaster",
														timeout: 5000,
														selfClose: null
													});
												}).finally(() =>
												{
													this.spinner.hide();
												});
											}
										}
									});

								}
		}
    else if (event.endsWith("_MANUAL_ACTION"))
    {
      // ITOEM-5: added logic to handle manual action
      const realEventId = event.slice(0, -"_MANUAL_ACTION".length);
      const selectedRows = this.tableController.selection.selected.length > 0 ? this.tableController.selection.selected : [];
      const ids = selectedRows.map(row => row.id);

      if (ids == null || ids.length == 0) {
        this.dialog.open(DialogComponent, {
          data: {
            title: "No row selected",
            content: `Please select at least 1 row...`,
            confirmButtonLabel: this.translateService.instant("LABEL_OK"),
            confirmButtonColor: "basic"
          }
        });
        return;
      }

      this.monitorService.callManualAction(realEventId, ids).subscribe({
        next: res => {
          if(res === 0){
            this.alertService.add({
              type: ALERT_TYPE.SUCCESS,
              message: "Action executed.",
              timeout: 5000,
              selfClose: null
            });
          }else{
            this.alertService.add({
              type: ALERT_TYPE.WARNING,
              message: "Unable to execute the action.",
              timeout: 10000,
              selfClose: null
            });
          }

        },
        error: err => {
          this.alertService.add({
            type: ALERT_TYPE.DANGER,
            message: "Internal action error.",
            timeout: 10000,
            selfClose: null
          });
        }
      })
    }
    else
			if (event.endsWith("_ID"))
			{
				if (event.startsWith("ACT_DELETE"))
				{
					const dialogRef = this.dialog.open(DialogComponent, {
						data: {
							title: this.translateService.instant("LABEL_DELETE_TITLE"),
							content: this.translateService.instant("LABEL_CONFIRM"),
							cancelButtonLabel: this.translateService.instant("ACT_NO"),
							cancelButtonColor: "basic",
							confirmButtonLabel: this.translateService.instant("ACT_YES"),
							confirmButtonColor: "warn"
						}
					});

					dialogRef.afterClosed().subscribe((res) =>
					{
						if (res)
						{
							this.spinner.show();
							this.generalDataService.deleteData(this.apiParent + "/" + this.apiUrl, [this.tableController.dataSet[this.tableController.rowNumber]], "d").pipe(takeUntil(this._interactionCycle)).subscribe({
								next: (res) =>
								{

									this.tableController.dataSet.splice(this.tableController.rowNumber, 1);

									this.tableController.dataSet = cloneDeep(this.tableController.dataSet);
									this.tableController.deletedRows = [];
									this.tableController.rowNumber = -1;
									this.spinner.hide();
									this.alertService.add({
										type: ALERT_TYPE.SUCCESS,
										message: res.iResponseCode + " - " + res.sResponseMessage,
										timeout: 3000,
										selfClose: null
									});
									this.onCancelClick();
								},
								error: (err) =>
								{
									console.log(err);
									this.tableController.deletedRows = [];
									this.tableController.rowNumber = -1;
									this.tableController.dataSet = cloneDeep(this.tableController.dataSet);
									this.spinner.hide();
									this.alertService.add({
										type: ALERT_TYPE.WARNING,
										message: err.error.data.iResponseCode + " - " + err.error.data.sResponseMessage,
										timeout: 5000,
										selfClose: null
									});
									//this.onCancelClick();
								}
							});
						}
						else
						{
							this.tableController.deletedRows = [];
						}
					});
				}

				if (event.startsWith("ACT_SAVE"))
				{
					const rowNumber = cloneDeep(this.tableController.rowNumber);
					this.tableController.rowNumber = -1;

					const committedRow = cloneDeep(this.tableController.dataSet[rowNumber]);
					if (!committedRow.edited)
					{
						this.alertService.add({
							type: ALERT_TYPE.WARNING,
							message: "No data change",
							timeout: 5000,
							selfClose: null
						});
					}
					else
					{
						const filter_row = this.tableController.dataSet.filter((row) => row.edited);
						if (filter_row.length == 1)
						{
							this.spinner.showWithTitle('data', this.translateService.instant('save'))
							delete committedRow.editing;
							delete committedRow.checked;

							this.generalDataService.postData(
								this.apiParent + "/" + this.apiUrl,
								cloneDeep([committedRow]),
								new HttpParams().set('new', (committedRow.newRow ?? false))
							).pipe(takeUntil(this._interactionCycle)).subscribe({
								next: (res) =>
								{

									this.spinner.hide();
									this.tableController.deletedRows = [];
									// this.refreshData(new Map(), new Array<string>());
									if (res.iResponseCode != 200)
									{
										this.spinner.hideAll();
										this.alertService.add({
											type: ALERT_TYPE.DANGER,
											message: "" + res.iResponseCode + " - " + this.translateService.instant(res.sResponseMessage), //res.iResponseCode + ' - ' + res.sResponseMessage,
											timeout: 5000,
											selfClose: null
										});
										this.tableController.dataSet[rowNumber].editing = true
										this.tableController.dataSet = cloneDeep(this.tableController.dataSet);
									}
									else
									{

										//this.refreshData();

										this.alertService.add({
											type: ALERT_TYPE.SUCCESS,
											message: "" + res.iResponseCode + " - " + res.sResponseMessage, //res.iResponseCode + ' - ' + res.sResponseMessage,
											timeout: 3000,
											selfClose: null
										});

										this.onSearch(0, this.savedPaginator.loader, this.sortedColumns, this.savedFilter, this.dts, true);

										this.tableController.dataSet[rowNumber] = res.oContent;

										this.tableController.dataSet = cloneDeep(this.tableController.dataSet);

									}

									this.tableController.rowNumber = -1;

									//this.onCancelClick();
								},
								error: (err) =>
								{
									this.spinner.hide();
									this.tableController.deletedRows = [];
									this.tableController.rowNumber = -1;
									this.alertService.add({
										type: ALERT_TYPE.WARNING,
										message: err.error.data.iResponseCode + " - " + err.error.data.sResponseMessage,
										timeout: 5000,
										selfClose: null
									});
									//this.onCancelClick();
								}
							});
						}

						else
						{
							const dialogRef = this.dialog.open(SaveConfirmComponent, {
								data: {
									title: this.translateService.instant("LABEL_SAVE_ALL"),
									content: this.translateService.instant("LABEL_MANY_PENDING_CHANGES"),
									cancelButtonLabel: this.translateService.instant("ACT_NO"),
									cancelButtonColor: "basic",
									allButtonLabel: this.translateService.instant("Save all"),
									allButtonColor: "accent",
									confirmButtonLabel: this.translateService.instant("ACT_YES"),
									confirmButtonColor: "accent"
								}
							});

							dialogRef.afterClosed().subscribe((res) =>
							{

								if (res == 'one')
								{
									this.spinner.showWithTitle('data', this.translateService.instant('save'))
									delete committedRow.editing;
									delete committedRow.checked;


									this.generalDataService.postData(
										this.apiParent + "/" + this.apiUrl,
										cloneDeep([committedRow]),
										new HttpParams().set('new', (committedRow.newRow ?? false))
									).pipe(takeUntil(this._interactionCycle)).subscribe({
										next: (res) =>
										{

											this.spinner.hide();
											this.tableController.deletedRows = [];
											// this.refreshData(new Map(), new Array<string>());


											if (res.iResponseCode != 200)
											{
												this.spinner.hideAll();
												this.alertService.add({
													type: ALERT_TYPE.DANGER,
													message: "" + res.iResponseCode + " - " + this.translateService.instant(res.sResponseMessage), //res.iResponseCode + ' - ' + res.sResponseMessage,
													timeout: 5000,
													selfClose: null
												});
												this.tableController.dataSet[rowNumber].editing = true
												this.tableController.dataSet = cloneDeep(this.tableController.dataSet);
											}
											else
											{

												//this.refreshData();

												this.alertService.add({
													type: ALERT_TYPE.SUCCESS,
													message: "" + res.iResponseCode + " - " + res.sResponseMessage, //res.iResponseCode + ' - ' + res.sResponseMessage,
													timeout: 3000,
													selfClose: null
												});

												this.tableController.dataSet[rowNumber] = res.oContent;

												this.tableController.dataSet = cloneDeep(this.tableController.dataSet);

											}

											this.tableController.rowNumber = -1;

											//this.onCancelClick();
										},
										error: (err) =>
										{
											this.spinner.hide();
											this.tableController.deletedRows = [];
											this.tableController.rowNumber = -1;
											this.alertService.add({
												type: ALERT_TYPE.WARNING,
												message: err.error.data.iResponseCode + " - " + err.error.data.sResponseMessage,
												timeout: 5000,
												selfClose: null
											});
											//this.onCancelClick();
										}
									});
								}
								else
									if (res == 'all')
									{

										const filter_row = this.tableController.dataSet.filter((row) => row.edited);

										filter_row.forEach(cmtedRow =>
										{
											this.spinner.showWithTitle('data', this.translateService.instant('LOADING_SAVE'))
											delete cmtedRow.editing;
											delete cmtedRow.checked;


											this.generalDataService.postData(
												this.apiParent + "/" + this.apiUrl,
												cloneDeep([cmtedRow]),
												new HttpParams().set('new', (cmtedRow.newRow ?? false))
											).pipe(takeUntil(this._interactionCycle)).subscribe({
												next: (res) =>
												{

													this.spinner.hide();
													this.tableController.deletedRows = [];
													// this.refreshData(new Map(), new Array<string>());


													console.log(res)
													if (res.iResponseCode != 200)
													{
														this.alertService.add({
															type: ALERT_TYPE.DANGER,
															message: "" + res.iResponseCode + " - " + this.translateService.instant(res.sResponseMessage), //res.iResponseCode + ' - ' + res.sResponseMessage,
															timeout: 5000,
															selfClose: null
														});
														//this.tableController.dataSet[rowNumber] = cmtedRow.backup;
														this.tableController.dataSet = cloneDeep(this.tableController.dataSet);
													}
													else
													{
														this.alertService.add({
															type: ALERT_TYPE.SUCCESS,
															message: "" + res.iResponseCode + " - " + res.sResponseMessage, //res.iResponseCode + ' - ' + res.sResponseMessage,
															timeout: 3000,
															selfClose: null
														});

														//this.refreshData();

														this.onSearch(0, this.savedPaginator.loader, this.sortedColumns, this.savedFilter, this.dts, true);

														this.tableController.dataSet[rowNumber] = res.oContent;

														this.tableController.dataSet = cloneDeep(this.tableController.dataSet);

													}

													this.tableController.rowNumber = -1;

													//this.onCancelClick();
												},
												error: (err) =>
												{
													this.spinner.hide();
													this.tableController.deletedRows = [];
													this.tableController.rowNumber = -1;
													this.alertService.add({
														type: ALERT_TYPE.WARNING,
														message: err.error.data.iResponseCode + " - " + err.error.data.sResponseMessage,
														timeout: 5000,
														selfClose: null
													});
													//this.onCancelClick();
												}
											});
										});


									}
							});
						}
					}
				}
        // ITOEM-5 added restart table action
        if (event.startsWith("ACT_RELOAD"))
        {
          const rowNumber = cloneDeep(this.tableController.rowNumber);
          this.tableController.rowNumber = -1;

          const committedRow = cloneDeep(this.tableController.dataSet[rowNumber]);
          this.generalDataService.postData(
            this.apiParent + "/" + this.apiUrl + "/reload",
            cloneDeep([committedRow]),
          ).pipe(takeUntil(this._interactionCycle)).subscribe({
            next: (res) =>
            {

              this.spinner.hide();
              if (res.iResponseCode != 200)
              {
                this.spinner.hideAll();
                this.alertService.add({
                  type: ALERT_TYPE.DANGER,
                  message: "" + res.iResponseCode + " - " + this.translateService.instant(res.sResponseMessage), //res.iResponseCode + ' - ' + res.sResponseMessage,
                  timeout: 5000,
                  selfClose: null
                });
                this.tableController.dataSet = cloneDeep(this.tableController.dataSet);
              }
              else
              {

                this.alertService.add({
                  type: ALERT_TYPE.SUCCESS,
                  message: "" + res.iResponseCode + " - " + res.sResponseMessage, //res.iResponseCode + ' - ' + res.sResponseMessage,
                  timeout: 3000,
                  selfClose: null
                });

                this.onSearch(0, this.savedPaginator.loader, this.sortedColumns, this.savedFilter, this.dts, true);

                this.tableController.dataSet[rowNumber] = res.oContent;

                this.tableController.dataSet = cloneDeep(this.tableController.dataSet);

              }
              this.tableController.rowNumber = -1;

            },
            error: (err) =>
            {
              this.spinner.hide();
              this.tableController.deletedRows = [];
              this.tableController.rowNumber = -1;
              this.alertService.add({
                type: ALERT_TYPE.WARNING,
                message: err.error.data.iResponseCode + " - " + err.error.data.sResponseMessage,
                timeout: 5000,
                selfClose: null
              });
              //this.onCancelClick();
            }
          })
        }
        // ITOEM-5 added restart table action
        if (event.startsWith("ACT_AUDIT")){
          const rowNumber = cloneDeep(this.tableController.rowNumber);
          this.tableController.rowNumber = -1;
          const committedRow = cloneDeep(this.tableController.dataSet[rowNumber]);
          let id = committedRow.ID;
          this.dialog.open(AuditDialogComponent, {
                width: '80vw',
                data: {
                  title: "Audit details",
                  info: id,
                  closeButtonLabel: "Close",
                }
              });
        }

			}
			else
			{

				if (this.tableController.selection.selected.length == 0 && this.apiParent != 'rework')
				{

					const dialogRef = this.dialog.open(DialogComponent, {
						data: {
							title: "No row selected",
							content: `Please select at least 1 row...`,
							confirmButtonLabel: this.translateService.instant("LABEL_OK"),
							confirmButtonColor: "basic"
						}
					});
					return;
				}
				if (event.startsWith("ACT_EXPORT"))
				{
					if (!!this.layout)
						this.savedParams = this.savedParams.append(
							"dataset",
							this.layout["sectionLayouts"].filter((sectionLayout) => sectionLayout.id == "LYO_SECTABLE").pop()?.dataset[0].id ?? ""
						);
          this.spinner.show();
					this.generalDataService.exportDataExcel(
						(!!this.apiUrl ? this.apiParent + "/" + this.apiUrl : this.apiParent),
						"excel",
						this.savedParams.set(
							"dataset",
							this.layout!["sectionLayouts"].filter((sectionLayout) => sectionLayout.id == "LYO_SECTABLE").pop()?.dataset[0].id ?? ""
						),
						this.tableController.selection.selected
					).pipe(takeUntil(this._interactionCycle)).subscribe((blob) =>
					{
            // [REF]
            if (blob instanceof Blob) {
              saveAs(blob, `${this.titlePage}.xlsx`);
            } else {
              console.error('Received data is not a Blob');
            }
						this.spinner.hide();
					});
				}
				if (event.startsWith("ACT_DELETE"))
				{

					const dialogRef = this.dialog.open(DialogComponent, {
						// width: '250px',
						data: {
							title: this.translateService.instant("LABEL_DELETE_TITLE"),
							content: this.translateService.instant("LABEL_DELETE_CONFIRM"),
							//content: `Are you sure you want map Original Final Destination [ ${data.oldRecord.finalDestination} ] Destination CID ["${data.oldRecord.destinationCid}"] to "${data.record.rdcName}"?`,
							cancelButtonLabel: this.translateService.instant("ACT_NO"),
							cancelButtonColor: "basic",
							confirmButtonLabel: this.translateService.instant("ACT_YES"),
							confirmButtonColor: "warn"
						}
					});

					dialogRef.afterClosed().subscribe((res) =>
					{
						if (!!res)
						{
							if (this.tableController.selection.selected.length > 0)
							{
								const myselection: any [] = this.tableController.selection.selected;

								this.spinner.showWithTitle('data', this.translateService.instant('LOADING_DELETE'))
								forkJoin(myselection.map((value, i, _) =>
								{
									return this.generalDataService.deleteData(this.apiParent + "/" + this.apiUrl, [myselection[i]], "d")
								})).pipe(
									tap(res => this.spinner.hide()),
									takeUntil(this._interactionCycle)
								).subscribe({
									next: (res) =>
									{

										res.forEach((value, i) =>
										{
											this.alertService.add({
												type: value.iResponseCode < 300 ? ALERT_TYPE.SUCCESS : ALERT_TYPE.DANGER,
												message: value.iResponseCode + " - " + value.sResponseMessage,
												timeout: 3000,
												selfClose: null
											});
										})
										if (!res.some(r => r.iResponseCode >= 300))
										{
											this.tableController.selection.clear();
											this.tableController.deletedRows = [];
											this.tableController.rowNumber = -1;
										}
										this.onCancelClick();
									},
									error: (err) =>
									{
										this.tableController.selection.clear();
										this.tableController.deletedRows = [];
										this.tableController.rowNumber = -1;
										this.spinner.hide();
										this.alertService.add({
											type: ALERT_TYPE.WARNING,
											message: err.error.data.iResponseCode + " - " + err.error.data.sResponseMessage,
											timeout: 5000,
											selfClose: null
										});
										//this.onCancelClick();
									}
								});

							}

						}
					});
				}
        if (event.startsWith("ACT_APPROVE"))
        {
          if (this.tableController.selection.selected.length > 0)
          {
            const mySelection: any [] = this.tableController.selection.selected;
            this.spinner.showWithTitle('data', this.translateService.instant('LOADING_APPROVE_PROCESS'))
            // get ids to approve
            const selectedIds : any [] = mySelection.map((row) => row["ID"]);
            this.generalDataService.approveData(
              (!!this.apiUrl ? this.apiParent + "/" + this.apiUrl : this.apiParent),
              selectedIds)
              .pipe(takeUntil(this._interactionCycle))
              .subscribe({
                  next: (res) =>
                  {
                    this.tableController.selection.clear();
                    this.onSearch(0, this.savedPaginator.loader, this.sortedColumns, this.savedFilter, this.dts, true);
                    let recordUpdated = selectedIds.length - res.length;
                    res.map(message => {
                      this.alertService.add({
                        type: ALERT_TYPE.WARNING,
                        message: message,
                        timeout: 20000,
                        selfClose: null
                      });
                    });
                    if (recordUpdated > 0) {
                      if (recordUpdated == selectedIds.length) {
                        if (res.length == 0) {
                          this.alertService.add({
                            type: ALERT_TYPE.SUCCESS,
                            message: this.translateService.instant("PREVIEW_FULL_APPROVE"),
                            timeout: 20000,
                            selfClose: null
                          });
                        }
                      } else {
                        this.alertService.add({
                          type: ALERT_TYPE.SUCCESS,
                          message: this.translateService.instant("PREVIEW_PARTIAL_APPROVE"),
                          timeout: 5000,
                          selfClose: null
                        });
                      }
                    }
                  },
                  error: (err) =>
                  {
                    this.alertService.add({
                      type: ALERT_TYPE.DANGER,
                      message: this.translateService.instant("PREVIEW_UPDATE_ERROR"),
                      timeout: 5000,
                      selfClose: null
                    });
                  },
                  complete:() => {
                    this.spinner.hide();
                  }
                }
              );
          }
        }
        if (event.startsWith("ACT_OPEN"))
        {
          if (this.tableController.selection.selected.length > 0)
          {
            const mySelection: any [] = this.tableController.selection.selected;
            this.spinner.showWithTitle('data', this.translateService.instant('LOADING_OPENING_PROCESS'))
            // get ids to approve
            const selectedIds : any [] = mySelection.map((row) => row["ID"]);
            this.tableController.selection.clear();
            this.generalDataService.openData(
              (!!this.apiUrl ? this.apiParent + "/" + this.apiUrl : this.apiParent),
              selectedIds)
              .pipe(takeUntil(this._interactionCycle))
              .subscribe({
                  next: (res) =>
                  {
                    this.tableController.selection.clear();
                    this.onSearch(0, this.savedPaginator.loader, this.sortedColumns, this.savedFilter, this.dts, true);
                    let recordUpdated = selectedIds.length - res.length;
                    res.map(message => {
                      this.alertService.add({
                        type: ALERT_TYPE.WARNING,
                        message: message,
                        timeout: 20000,
                        selfClose: null
                      });
                    });
                    if (recordUpdated > 0) {
                      if (recordUpdated == selectedIds.length) {
                        if (res.length == 0) {
                          this.alertService.add({
                            type: ALERT_TYPE.SUCCESS,
                            message: this.translateService.instant("ALL_OPENED"),
                            timeout: 20000,
                            selfClose: null
                          });
                        }
                      } else {
                        this.alertService.add({
                          type: ALERT_TYPE.SUCCESS,
                          message: this.translateService.instant("PARTIALLY_OPENED"),
                          timeout: 5000,
                          selfClose: null
                        });
                      }
                    }
                  },
                  error: (err) =>
                  {
                    this.alertService.add({
                      type: ALERT_TYPE.DANGER,
                      message: this.translateService.instant("PREVIEW_UPDATE_ERROR"),
                      timeout: 5000,
                      selfClose: null
                    });
                  },
                  complete:() => {
                    this.spinner.hide();
                  }
                }
              );
          }
        }
        if (event.startsWith("ACT_CLOSE"))
        {
          const mySelection: any [] = this.tableController.selection.selected;
          // get ids to approve
          const selectedIds : any [] = mySelection.map((row) => row["ID"]);
          this.tableController.selection.clear();
          const dialogRef = this.dialog.open(DialogComponent, {
            data: {
              title: "Confirm closing",
              content: `This action will close all selected records, proceed?`,
              cancelButtonLabel: "Cancel",
              cancelButtonColor: "basic",
              confirmButtonLabel: "Close",
              confirmButtonColor: "warn"
            }
          });

          dialogRef.afterClosed().subscribe((res) => {
            if (!!res) {
              //this.onCloseRecordsClick.emit(ids);
              this.spinner.showWithTitle('data', this.translateService.instant('LOADING_OPENING_PROCESS'))
              this.generalDataService.closeData(
                (!!this.apiUrl ? this.apiParent + "/" + this.apiUrl : this.apiParent),
                selectedIds)
                .pipe(takeUntil(this._interactionCycle))
                .subscribe({
                    next: (res) =>
                    {
                      this.onSearch(0, this.savedPaginator.loader, this.sortedColumns, this.savedFilter, this.dts, true);
                      this.alertService.add({
                        type: ALERT_TYPE.SUCCESS,
                        message: this.translateService.instant("RECORD_CLOSED"),
                        timeout: 5000,
                        selfClose: null
                      });
                    },
                    error: (err) =>
                    {
                      this.spinner.hide();
                      this.alertService.add({
                        type: ALERT_TYPE.DANGER,
                        message: this.translateService.instant("RECORD_CLOSED_ERROR"),
                        timeout: 5000,
                        selfClose: null
                      });
                    },
                    complete:() => {
                      this.spinner.hide();
                    }
                  }
                );
            }
          });

        }

			}
	}

  notifyRecords(id?: string, action?: string, label?: string, description?: string) {
    if (!!id) {
      const mySelection: any [] = this.tableController.selection.selected;
      // get ids to approve
      const ids : any [] = mySelection.map((row) => row["ID"]);
      this.tableController.selection.clear();

      const dialog = this.dialog.open(ContactGroupDialogComponent, {
        width: '65vw',
        data: {
          title: label + ' - ' + description,
          ids: ids,
          typology: action,
          notificationId: id,
          closeButtonLabel: "Close",
          confirmButtonLabel: "Send"
        }
      });

      // dialog.afterClosed().subscribe(res => {
      //   if (!!res && res)
      //     this.onNotifyRecordsClick.emit(res);
      // });
      //
      // this.selection.clear();
    }


  }

	deleteOneRow()
	{
		const dialogRef = this.dialog.open(DialogComponent, {
			data: {
				title: this.translateService.instant("LABEL_DELETION_TITLE"),
				content: this.translateService.instant("LABEL_CONFIRM"),
				cancelButtonLabel: this.translateService.instant("ACT_NO"),
				cancelButtonColor: "basic",
				confirmButtonLabel: this.translateService.instant("ACT_YES"),
				confirmButtonColor: "warn"
			}
		});

		dialogRef.afterClosed().subscribe((res) =>
		{
			if (!!res)
			{
				this.spinner.show();
				this.generalDataService.deleteData(this.apiParent + "/" + this.apiUrl, this.tableController.deletedRows, "d").pipe(takeUntil(this._interactionCycle)).subscribe((res) =>
				{
					this.tableController.deletedRows = [];
					this.spinner.hide();
					this.onCancelClick();
				});
			}
		});
	}

	refreshDocumentData()
	{
		let params = new HttpParams().set("id", this.tableController["LOADING_CARD_ID"]);
		this.generalDataService.getDocumentsById(this.apiParent + "/" + this.apiUrl + "/docs", params).pipe(takeUntil(this._interactionCycle)).subscribe((res) =>
		{
			this.attachments = res["oContent"];
		});
	}

	private catchAllSelectedOptions(layout?: Layout)
	{
		layout?.sectionLayouts.map((sectionLayout: SectionLayout) =>
		{
			sectionLayout.dataset.map((dataset: DataSet) =>
			{
				dataset.datas?.map((data: Data) =>
				{
					if (
						data.valuesFrom.includes("table") &&
						data.valuesFrom.includes("search_key") &&
						data.valuesFrom.includes("return_value") &&
						!data.isResultsetLinked
					)
					{
						this.generalDataService.getSelectionOption(this.replaceFilterValues(data.valuesFrom)).then((res) =>
						{
							data.optionValues = res;
						});
					}
					else if (data.type == "DTY_STATUS")
          {
            // ITOEM-5: added status filter
            // status filter value are structured as follow: ERROR-ERROR-error-F44336;WARNING-WARNING-warning-FFEB3B; INFO-INFO-info-4CAF50
            const list = data.fixListValues.split(";");
            data.optionValues = list.map(
              (options) => {
                const optionList = options?.split("-") || [];
                return {
                  id: optionList[0] || options,
                  name: optionList[1] || options,
                  matIcon: optionList[2] || "help",
                  color: optionList[3] || "000",
                } as IMultiSelectOption;
              }
            );
          }
          else
					{
						if (data.fixListValues)
						{
							const list = data.fixListValues.split(";");
							data.optionValues = list.map(
								(option) =>
									({
										id: option,
										name: option
									} as IMultiSelectOption)
							);
						}
					}
				});

				dataset.filterset?.map((filter: FilterInterface) =>
				{
					if (filter.filterType == FILTER_TYPE.MULTI_SELECTION || filter.filterType == FILTER_TYPE.SELECTION)
					{
						if (filter.filterValues)
						{
							if (
								filter.filterValueSearch.includes("table") &&
								filter.filterValueSearch.includes("search_key") &&
								filter.filterValueSearch.includes("return_value") &&
								!filter.isResultsetLinked
							)
							{
								this.generalDataService.getSelectionOption(this.replaceFilterValues(filter.filterValueSearch)).then((res) =>
								{
									filter.filterValues = res;
								});
							}
							else
							{
								const list = filter.filterValueSearch.split(";");
								filter.filterValues = list.map(
									(option) =>
										({
											id: option,
											name: option
										} as IMultiSelectOption)
								);
							}
						}
					}
          else if (filter.filterType == FILTER_TYPE.STATUS) {
            // ITOEM-5: added status filter
            // status filter value are structured as follow: ERROR-ERROR-error-F44336;WARNING-WARNING-warning-FFEB3B; INFO-INFO-info-4CAF50
            const list = filter.filterValueSearch.split(";");
            filter.filterValues = list.map(
              (options) => {
                const optionList = options?.split("-") || [];

                return {
                  id: optionList[0] || options,
                  name: optionList[1] || options,
                  matIcon: optionList[2] || "help",
                  color: optionList[3] || "000",
                } as IMultiSelectOption;
              }
            );
          }
					return filter;

        });
			});
		});
	}

	private getPageLayout(pageId: string)
	{
		this.spinner.showWithTitle("data", this.translateService.instant('LOADING_LAYOUT_OF') + this.titlePageTranslate.transform(this.titlePage));
		//this.spinner.showWithTitle("data", "layout of " + this.titlePageTranslate.transform(this.titlePage));
		const newFilter: any = [];
		this.layoutService.getPageLayout(pageId).pipe(
			map((res) =>
			{
				this.layout = res?.layouts[0] ?? undefined;

				this.dts =
					first(
						this.layout?.sectionLayouts.filter((section) => section.id == "LYO_SECTABLE") ?? ([] as SectionLayout[])
					)?.dataset[0]?.id ?? "";

				this.ats =
					first(
						this.layout?.sectionLayouts.filter((section) => section.id == "LYO_SECTABLE") ?? ([] as SectionLayout[])
					)?.actionset[0]?.id ?? "";


				const filterSettings = sessionStorage.getItem("filter");
				if (!!filterSettings)
				{
					const filters = JSON.parse(filterSettings);
					if (!!filters[this.pageId] && !!filters[this.pageId].instance)
					{

						let oldFilters: FilterInterface[] = [];
						const flt = sessionStorage.getItem("FLT_" + this.pageId);
						if (flt)
						{
							oldFilters = JSON.parse(flt, replacer)
						}
						else
						{
							oldFilters = cloneDeep(filters[this.pageId].instance);
						}


						const newFilters = cloneDeep(
							this.layout?.sectionLayouts?.filter((sectionLayout) => sectionLayout.id == "LYO_SECFILT").flatMap((section) => section.dataset).flatMap((datasets) => datasets.filterset).filter((flts) => flts != null)
						) as FilterInterface[];
						/*const newFilters = cloneDeep(this.layout?.sectionLayouts.filter((sectionLayout) => sectionLayout.id == "LYO_SECFILT").pop()
						  ?.dataset[0]?.filterset ?? [])*/
						this.filterController.filterModels = newFilters.map((fltInt) =>
						{
							for (let f of oldFilters)
							{
								const flt = cloneDeep(f);
								if (fltInt.filterId == flt.filterId)
								{
									fltInt.firstValue = flt.firstValue;
									fltInt.filterType = flt.filterType;
									fltInt.lFilterOptions = flt.lFilterOptions;
									fltInt.columnLabel = flt.columnLabel;
									fltInt.columnName = flt.columnName;
									fltInt.filterValues = flt.filterValues;
									fltInt.filterValueSearch = flt.filterValueSearch;
									fltInt.selectedOption = flt.selectedOption;
									fltInt.filterSelectedOption = flt.filterSelectedOption;
								}
							}
							return fltInt;
						});
					}
					else
					{
						this.filterController.filterModels =
							this.layout?.sectionLayouts.filter((sectionLayout) => sectionLayout.id == "LYO_SECFILT").pop()?.dataset[0]?.filterset ?? [];
					}
					this.filterController.refreshViewedFilter();
				}
				else
				{
					this.filterController.filterModels =
						this.layout?.sectionLayouts.filter((sectionLayout) => sectionLayout.id == "LYO_SECFILT").pop()?.dataset[0]?.filterset ?? [];
					this.filterController.refreshViewedFilter();
				}

				const filterString = sessionStorage.getItem("FLT_" + this.pageId);
				if (!!filterString)
				{
					const _filterList: FilterInterface[] = JSON.parse(filterString, replacer);

					if (_filterList.length > 0)
					{
						this.filterController.filterModels = this.filterController.filterModels.map((flt) =>
						{
							if (_filterList.some((filter) => filter.columnName == flt.columnName))
							{
								return _filterList.filter((f) => (f.columnName == flt.columnName))[0];
							}
							return flt;
						});
					}

					this.filterController.filterModels.filter((model) => !model.isHide).forEach((filter) =>
					{
						//(filter)
						if (filter.filterSelectedOption.haveSecondParam && filter.filterSelectedOption.id == "_-d_")
						{
							if (this.checkStringOrNumberValidity(filter.firstValue)) newFilter.push(filter.columnName + "_>d_" + filter.firstValue);
							if (this.checkStringOrNumberValidity(filter.secondValue)) newFilter.push(filter.columnName + "_<d_" + filter.secondValue);
						}
						else
							if (filter.filterSelectedOption.haveSecondParam && filter.filterSelectedOption.id == "_-_")
							{
								if (this.checkStringOrNumberValidity(filter.firstValue)) newFilter.push(filter.columnName + "_>_" + filter.firstValue);
								if (this.checkStringOrNumberValidity(filter.secondValue)) newFilter.push(filter.columnName + "_<_" + filter.secondValue);
							}
							else
							{
								if (filter.filterSelectedOption.haveFirstParam)
								{
									if (this.checkStringOrNumberValidity(filter.firstValue) && filter.firstValue.length > 0)
									{
										if (filter.filterType == FILTER_TYPE.MULTI_SELECTION)
										{
											newFilter.push(
												filter.columnName + filter.filterSelectedOption.id + filter.firstValue.join("§")
											);
										}
										else
										{
											newFilter.push(filter.columnName + filter.filterSelectedOption.id + filter.firstValue);
										}
									}
								}
								else
								{
									newFilter.push(filter.columnName + filter.filterSelectedOption.id + "#");
								}
							}
					});
					this.filterController.refreshViewedFilter();
				}

				const layoutsString = localStorage.getItem("layout");

				const sorted = sessionStorage.getItem("sort_" + this.pageId);
				if (sorted)
				{
					this.tableController.sortedColumns = JSON.parse(sorted, reviver);
				}
				else
				{
					const dtArray: Data[] = Array.from(
						new Set(
							(res?.layouts ?? ([] as Layout[])).flatMap((l) =>
							{
								return l.sectionLayouts;
							}).flatMap((sl) => sl.dataset).flatMap((dts) => dts.datas ?? ([] as Data[]))
						)
					);

					const ds = dtArray.filter((d) => d.sortOrder).sort((a, b) => a.sortOrder! - b.sortOrder!) ?? [];
					this.sortedColumns = new Map(
						ds.map((d) => [d.columnName, {order: d.sortOrder!, type: d.sortCriteria ?? "desc"}])
					);
					this.tableController.sortedColumns = this.sortedColumns;
				}

				if (!!layoutsString)
				{
					let layouts = JSON.parse(layoutsString);
					layouts[pageId] = {
						instance: res?.layouts[0],
						instant: new Date()
					};
					localStorage.setItem("layout", JSON.stringify(layouts));
				}
				else
				{
					let layouts = {};
					layouts[pageId] = {
						instance: res?.layouts[0],
						instant: new Date()
					};
					localStorage.setItem("layout", JSON.stringify(layouts));
				}

			})
		).pipe(takeUntil(this._interactionCycle)).subscribe((res) =>
		{
			this.spinner.hide();
			this.refreshData(this.tableController.sortedColumns, newFilter);
		});
	}

	resetFilter(isResetting: boolean)
	{
		if (isResetting)
		{
			this.getPageLayout(this.pageId);
		}
	}

	replaceFilterValues(valuesFrom: string)
	{
		let researchString = cloneDeep(valuesFrom);
		const replaceArray =
				  valuesFrom.match(/_@[^\ ]*@_/gm)?.map((substr) =>
				  {
					  return {
						  toReplace: substr,
						  raplaceWith: ""
					  };
				  }) ?? [];

		for (let i = 0; i < replaceArray.length; i++)
		{
			researchString = researchString.replace(
				replaceArray[i].toReplace,
				"'" + replaceArray[i]?.raplaceWith?.replace(/\//gm, "-") + "'"
			);
		}

		return researchString;
	}

	async calculateSize()
	{
		let sizes = {};

		let dataSize: Map<string, ColumnDetail> = new Map<string, ColumnDetail>();

		if (this.layout)
		{
			const dtArray: Data[] = Array.from(
				new Set(this.layout.sectionLayouts.flatMap((sl) => sl.dataset).flatMap((dts) => dts.datas ?? ([] as Data[])))
			);

			for (let j = 0; j < dtArray.length; j++)
			{
				const objIndex: string = cloneDeep(dtArray[j].columnLabel);
				const width = get_tex_size(this.translateService.instant(objIndex), "table").width > 300 ? 300 : get_tex_size(this.translateService.instant(objIndex), "table").width;
				const values: any[] = cloneDeep(dtArray[j].optionValues);

				if (dataSize.has(objIndex))
				{
					if (width > (dataSize.get(objIndex)?.width ?? 1))
					{
						dataSize.set(objIndex, {width: width + 16, optionValues: values});
					}
				}
				else
				{
					dataSize.set(objIndex, {width: width + 16, optionValues: values});
				}

				if (!sizes.hasOwnProperty(objIndex))
				{
					sizes[objIndex] = [];
				}

				sizes[objIndex].push(width + 16);
			}

			for (let i = 0; i < this.tableController.dataSet.length; i++)
			{
				const obj = cloneDeep(this.tableController.dataSet[i]);
				Object.keys(obj).forEach((key) =>
				{
					// If the key doesn't exist in the sizes object, create an empty array
					if (!sizes.hasOwnProperty(key))
					{
						sizes[key] = [];
					}

					const widthSize = get_tex_size("" + (obj[key] ?? ""), "table").width;
					// Push the size of the value into the array for the corresponding key
					const columnDetail: ColumnDetail | undefined = dataSize.get(key);
					if (columnDetail)
					{
						if (columnDetail.optionValues && columnDetail.optionValues.length > 0)
						{
							const translateServiceText = transformIdToValueFromList(columnDetail, obj[key], obj[key]);
							const newWidthSize = get_tex_size(translateServiceText ?? "", "table").width;

							if (newWidthSize >= columnDetail.width)
							{
								sizes[key].push(newWidthSize + 16);
							}
							//sizes[key].push((newWidthSize >= (columnDetail.width)) ? newWidthSize : cloneDeep(columnDetail.width));
						}
						else
						{
							if (widthSize >= columnDetail.width)
							{
								sizes[key].push(widthSize + 16);
							}
							//sizes[key].push((widthSize >= (columnDetail.width)) ? widthSize : cloneDeep(columnDetail.width));
						}
					}
					else
					{
						sizes[key].push(widthSize + 16);
					}
				});
			}
		}

		return sizes;
	}

	resetSort()
	{
		const dtArray: Data[] = Array.from(
			new Set(this.layout?.sectionLayouts?.flatMap((sl) => sl.dataset).flatMap((dts) => dts.datas ?? ([] as Data[])))
		);

		const ds = dtArray.filter((d) => d.sortOrder).sort((a, b) => a.sortOrder! - b.sortOrder!) ?? [];
		this.sortedColumns = new Map(
			ds.map((d) => [d.columnName, {order: d.sortOrder!, type: d.sortCriteria ?? "desc"}])
		);
		this.tableController.sortedColumns = this.sortedColumns;

		sessionStorage.setItem("sort_" + this.pageId, JSON.stringify(this.sortedColumns, replacer));
		this.onSearch(0, this.tableController.dataSet.length, this.sortedColumns, this.savedFilter, this.dts, true);
	}

	private calculateSizeComplete()
	{
		this.calculateSize().then((sizes) =>
		{
			let columnWeight = {};
			Object.keys(sizes).forEach((key) =>
			{
				const arrSum: number = sizes[key].reduce((acc: number, curr: number, idx: number) =>
				{
					acc = acc + curr;
					return acc;
				});

				const arrSumPosition: number = sizes[key].reduce((acc: number, curr: number, idx: number) =>
				{
					acc = acc + curr * idx;
					return acc;
				});

				const size = arrSum / sizes[key].length;
				//TODO: Review this point to autosize column
        columnWeight[key] = ((size * 1.8) > 120 ? (size * 1.8) : 120);
			});

			this.tableController.columnWeight = columnWeight;
			this.tableController.calculateColumnWidth();
		});
	}


}

interface ColumnDetail
{
	width: number;
	optionValues: any[];
}
