<div class="dialog-container">
  <h2 mat-dialog-title>{{ ('LABEL_ADD_TRANSLATE_TITLE' | translate) }}</h2>

  <div class="my-5" mat-dialog-content>
    <div class="row text-left align-items-center">
      <div class="col-12">

        <div class="row text-left align-items-left">
          <div class="col-6 dialog-label-center"><label
            class="fw-bold">{{ ('LABEL_TRANSLATE_NAME' | translate) }}</label></div>
          <div class="col-6 dialog-label-center">
            <custom-text-field [(value)]="translationKey" class="d-inline-flex"></custom-text-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-between" mat-dialog-actions>
    <div>
      <custom-btn (click)="onCancelClick()" mat-dialog-close>{{ 'LABEL_BACK' | translate }}</custom-btn>
    </div>
    <custom-btn (click)="onSaveClick()" [color]="'primary'">{{ 'LABEL_SAVE' | translate }}
    </custom-btn>
  </div>
</div>
