import {Injectable} from '@angular/core';


interface SPINNER_SEQ {
  name: string;
  load: number;
}

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {

  numOfSpinner: SPINNER_SEQ[] = [];

  spinnerTitles: string[] = [];

  loading: boolean = false;

  isBlocked: boolean = false;


  constructor() {
  }

  hide(name: string = 'data', debounce: number = 10) {
    this.spinnerTitles.shift();
    if (this.numOfSpinner.filter(spinner => spinner.name == name && spinner.load > 1).length > 0) {
      this.numOfSpinner = this.numOfSpinner.map(spinner => {
        if (spinner.name == name) {
          spinner.load -= 1;
        }
        return spinner;
      })
    } else {
      this.numOfSpinner = this.numOfSpinner.filter(spinner => spinner.name != name)
      if (this.loading) {
        setTimeout(() => {
          this.loading = false;
        });
      }

    }
  }

  show(name: string = 'data') {
    if (this.numOfSpinner.filter(spinner => spinner.name == name).length > 0) {
      this.numOfSpinner = this.numOfSpinner.map(spinner => {
        if (spinner.name == name) {
          spinner.load += 1;
        }
        return spinner;
      })
    } else {
      this.numOfSpinner.push({
        name: name,
        load: 1
      });
      setTimeout(() => {
        this.loading = true;
      });
    }

  }

  showWithTitle(name: string = 'data', title: string) {
    this.spinnerTitles.push(title);
    if (this.numOfSpinner.some(spinner => spinner.name == name)) {
      this.numOfSpinner = this.numOfSpinner.map(spinner => {
        if (spinner.name == name) {
          spinner.load += 1;
        }
        return spinner;
      })


    } else {
      this.numOfSpinner.push({
        name: name,
        load: 1
      });
      setTimeout(() => {
        this.loading = true;
      });
    }

  }

  hideAll() {
    this.numOfSpinner = [];
    if (this.loading) {
      setTimeout(() => {
        this.loading = false;
      });
    }

  }

  unblock() {
    this.isBlocked = false;
  }

  block() {
    this.isBlocked = true;
  }
}
