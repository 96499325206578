import {Component, OnInit} from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  value = 50;

  constructor(private dialogRef: MatDialogRef<LoadingComponent>) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

}
