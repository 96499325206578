import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from "@angular/core";
import {MatFormFieldAppearance} from "@angular/material/form-field";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomCheckboxComponent),
      multi: true
    }
  ]
})
export class CustomCheckboxComponent implements ControlValueAccessor {
  private propagateChange = (_: any) => {
  };
  @Input() trueValue: any = true;
  @Input() falseValue: any = false;
  @Input() disabled: boolean = false;
  valor: any;

  constructor() {
  }

//when change the model, call to function "propagateChange" with the correct value
  change(id: any) {
    this.valor = id.checked;
    this.propagateChange(this.valor ? this.trueValue : this.falseValue)
  }

  //At first, we received a value, we must change the "aparence" of our component
  writeValue(obj: any): void {
    this.valor = (obj == this.trueValue);
  }

  //here we say to Angular that we're going to use the function
  //this.propagateChange when a change happends
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
  }
}

/*implements OnInit {

  @Input()
  value: any = "";

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter();

  @Input()
  appearance!: MatFormFieldAppearance;

  @Input()
  label: String | undefined;

  @Input()
  placeholder: String | undefined;

  @Input()
  disabled: Boolean | undefined = false;

  @Input()
  formControl;

  @Input()
  matAutocomplete;

  @Input()
  checkBoxType: 'true' | 'yes' = 'yes';

  @Input()
  columnId = "";

  constructor() {}

  ngOnInit(): void {
  }

  onValueChange($event: any) {
    if(this.checkBoxType == 'yes'){
      this.valueChange.emit(this.value ? 'Y' : 'N');
    }else{
      this.valueChange.emit(this.value);
    }

  }

}*/
