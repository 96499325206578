<div class="container">
  <mat-panel-title class="title">
    {{ 'TITLE_ATTACHMENT' | translate }}
  </mat-panel-title>

  <div class="action-button">

    <ng-container>

      <span> {{ 'Category' }}</span>
      <custom-select
        [(defaultValue)]="selectedCategory"
        [disabled]="categoryOptions == null || categoryOptions.length == 0"
        [values]="categoryOptions"
        [searchable] = "true"
        [extraClass] = "'filter-multi-select'">
      </custom-select>
    </ng-container>

    <button mat-raised-button style="height: 45px;"
            [disabled]="!(!!selectedCategory)" class="import-btn" (click)="onImportFile()">Import
    </button>
  </div>


  <detail-data-table
    #dataTableComponent
    [apiParent]="apiParent"
    [apiUrl]="apiUrl"
    [titlePage]="null"
    [pageId]="'PAG_MONITOR_ATTACHMENT'"
    [monitorId]="id"
  ></detail-data-table>
</div>
