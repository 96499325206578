import { Component, OnInit } from '@angular/core';
import { Destination } from 'src/app/shared/models/Destination';
import { GTNexusService } from 'src/app/services/gtnexus.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {Header} from "../../components/shared/monitor-data-table/admin-data-table.component";

@Component({
  selector: 'app-stage',
  templateUrl: './imported.component.html',
  styleUrls: ['./imported.component.scss']
})
export class ImportedComponent implements OnInit {

  displayedColumns: string[] = ['unit', 'label', 'swbNumber', 'shippingLine', 'vesselName', 'portOfLoading', 'etdPortOfLoading', 'portOfDischarge', 'etaPort'];
  containersHeaders: Observable<Header[]> = of([
    { id: 0, field: 'id', label: 'ID', editable: false, cellTextClass: "bold"},
    { id: 1, field: 'bl', label: 'BL #', editable: true, validation: {required: true}},
    { id: 2, field: 'carrier', label: 'Carrier', editable: true},
    { id: 3, field: 'vessel', label: 'Vessel', editable: true},
    { id: 4, field: 'voyage', label: 'Voyage', editable: true},
    { id: 5, field: 'pol', label: 'POL', editable: true},
    { id: 6, field: 'etdVessel', label: 'ETD Vessel', editable: true, validation: {type: "date"}},
    { id: 7, field: 'pod', label: 'POD', editable: true},
    { id: 8, field: 'etaVessel', label: 'ETA Vessel', editable: true, validation: {type: "date"}},
    { id: 9, field: 'containerType', label: 'Container Type', editable: true},
    { id: 10, field: 'containerNumber', label: 'Container Number', editable: true},
    { id: 11, field: 'shipper', label: 'Shipper', editable: true},
    { id: 12, field: 'destination', label: 'Destination', editable: true},
    { id: 13, field: 'consignee', label: 'Consignee', editable: true},
  ])
  destinationHeaders: Observable<Header[]> = of([
    { id: 0, field: 'id', label: 'ID', editable: true},
    { id: 1, field: 'destGTNexus', label: 'Destination GTNexus', editable: true},
    { id: 2, field: 'destCountry', label: 'Destination Contry', editable: true},
  ])
  containersDataSourceSubject = new BehaviorSubject<any[]>([]);
  containersDataSourceObservable = this.containersDataSourceSubject.asObservable();
  containersDataSource = [];

  destinationDataSourceSubject = new BehaviorSubject<any[]>([]);
  destinationDataSourceObservable = this.destinationDataSourceSubject.asObservable();
  destinationDataSource = [];

  loadingContainers: boolean = false;
  loadingDestinations: boolean = false;

  constructor(private gtnexusService: GTNexusService) { }

  ngOnInit(): void {
    this.getContainers()
    this.getDestinations()
  }

  selectTab($event: any){

  }

  getContainers(){
    this.loadingContainers = true;
    this.gtnexusService.getContainers(0, 20)
      .subscribe(
        (res: any) => {
          if(!!res && !!res.content){
            this.containersDataSource = res.content
            this.containersDataSourceSubject.next(this.containersDataSource);
          }

        },
        (err: any) => {},
        () => this.loadingContainers = false)
  }

  getDestinations(){
    this.loadingDestinations = true;
     this.gtnexusService.getDestinations(0, 20)
      .subscribe((res: any) => {
        if(!!res && !!res.content){
          this.destinationDataSource = res.content.map((destination: any): Destination => {
            return {
              id: destination.id,
              destGTNexus: destination.destinationGTNexus,
              destCountry: destination.destCountry
            }
          })
          this.destinationDataSourceSubject.next(this.destinationDataSource);
        }

      },
      (err: any) => {},
      () => this.loadingDestinations = false)
  }

  onContainerEdit($event: any){
    this.loadingContainers = true;
    this.gtnexusService.editContainers([$event])
      .subscribe(
        (res: any) => {},
        (err: any) => {},
        () => this.loadingContainers = false
      )
  }
}

// const MOCK_CONTAINERS: InboundContainer[] = [
//   {id: 1,unit: "SDA", label: "New", shippingLine: "Maersk", vesselName: "MAERSK GANGES 139W", portOfLoading: "Xiaolan", etdPortOfLoading: "29/09/2021", portOfDischarge: "Gothenburg", etaPort: "29/11/2021" },
//   {id: 2,unit: "MDA", label: "New", swbNumber: 213022492, shippingLine: "Maersk", vesselName: "S MORTEN MAERSK/137V", portOfLoading: "Beijiao", etdPortOfLoading: "22/09/2021", portOfDischarge: "Gothenburg", etaPort: "24/11/2021" },
//   {id: 3,unit: "SDA", label: "New", shippingLine: "MSC", vesselName: "MSC LAURENCE FL136W", portOfLoading: "Shangai", etdPortOfLoading: "09/10/2021", portOfDischarge: "Gaevie", etaPort: "23/11/2021" },
//   {id: 4,unit: "SDA", label: "New", shippingLine: "MSC", vesselName: "MSC LAURENCE FL136W", portOfLoading: "Shangai", etdPortOfLoading: "09/10/2021", portOfDischarge: "Gaevie", etaPort: "23/11/2021" },
//   {id: 5,unit: "MDA", label: "New", shippingLine: "Maersk", vesselName: "S MORTEN MAERSK/137V", portOfLoading: "Beijiao", etdPortOfLoading: "22/09/2021", portOfDischarge: "Gothenburg", etaPort: "24/11/2021" },
//   {id: 6,unit: "SDA", label: "New", shippingLine: "MSC", vesselName: "MSC LAURENCE FL136W", portOfLoading: "Shangai", etdPortOfLoading: "29/09/2021", portOfDischarge: "Gothenburg", etaPort: "29/11/2021" },
//   {id: 7,unit: "MDA", label: "New", swbNumber: 213022492, shippingLine: "Maersk", vesselName: "S MORTEN MAERSK/137V", portOfLoading: "Beijiao", etdPortOfLoading: "22/09/2021", portOfDischarge: "Gothenburg", etaPort: "24/11/2021" },
//   {id: 8,unit: "SDA", label: "New", shippingLine: "MSC", vesselName: "MSC LAURENCE FL136W", portOfLoading: "Shangai", etdPortOfLoading: "09/10/2021", portOfDischarge: "Gaevie", etaPort: "23/11/2021" },
//   {id: 9,unit: "SDA", label: "New", shippingLine: "Maersk", vesselName: "MAERSK GANGES 139W", portOfLoading: "Xiaolan", etdPortOfLoading: "29/09/2021", portOfDischarge: "Gothenburg", etaPort: "29/11/2021" },
// ]

// const MOCK_DESTINATIONS: Destination[] = [
//   {id: 1, destGTNexus: "Xiaolan", destCountry: "China"},
//   {id: 2, destGTNexus: "Shangai", destCountry: "China"},
//   {id: 3, destGTNexus: "Beijiao", destCountry: "China"},
//   {id: 4, destGTNexus: "Xiaolan", destCountry: "China"},
//   {id: 5, destGTNexus: "Beijiao", destCountry: "China"},
//   {id: 6, destGTNexus: "Shangai", destCountry: "China"},
//   {id: 7, destGTNexus: "Xiaolan", destCountry: "China"},
// ]


