import {Pipe, PipeTransform} from '@angular/core';
import {TranslatePipe, TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'strarrTranslate'
})
export class StringArrayTranslationPipe implements PipeTransform {


  constructor(private translate: TranslateService) {
  }

  transform(value: string | String, ...args: unknown[]): string {
    return value.split(';').map(val => this.translate.instant(val)).join('; ');
  }

}
