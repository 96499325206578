import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";

export interface DialogFIlterItem {
  id: String,
  label: String
}

export interface DialogData {
  dataTitle: String;
  viewList: DialogFIlterItem[];
  hideList: DialogFIlterItem[];
}

@Component({
  selector: 'filter-settings',
  templateUrl: './filter-settings.component.html',
  styleUrls: ['./filter-settings.component.scss']
})
export class FilterSettingsComponent {

  constructor(
    public dialogRef: MatDialogRef<FilterSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  drop(event: CdkDragDrop<DialogFIlterItem[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  transferArrayItem(itemIndex: number, to: 'view' | 'hide') {
    if (to === 'view') {
      transferArrayItem(
        this.data.hideList,
        this.data.viewList,
        itemIndex,
        this.data.viewList.length,
      );
    } else {
      transferArrayItem(
        this.data.viewList,
        this.data.hideList,
        itemIndex,
        this.data.hideList.length,
      );
    }

  }
}
